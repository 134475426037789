/**
 * Dashboard routes.
 */
define(
    'dashboard/dashboard.routes',['angular', './dashboard.controllers', 'common'],
    function (angular, controllers) {
        'use strict';

        var mod = angular.module('dashboard.routes', ['com.cd.common']);
        mod
            .config([
                '$routeProvider',
                'userResolve',
                'adminResolve',
                'physResolve',
                'userResolveNoLoginOk',
                '$locationProvider',
                function ($routeProvider, userResolve, adminResolve, physResolve, userResolveNoLoginOk, $locationProvider) {
                    $locationProvider.hashPrefix('');
                    $routeProvider.when(
                        '/dashboard', {
                            templateUrl: 'dashboard/dashboard.html',
                            controller: controllers.DashboardCtrl,
                            reloadOnSearch: false,
                            resolve: userResolve
                        }).when('/view-pdf', {
                        templateUrl: 'dashboard/view-pdf.html',
                        controller: controllers.ViewPDFCtrl
                    }).when('/view-signed-forms', {
                        templateUrl: 'dashboard/signed-forms.html',
                        controller: controllers.SignedFormsCtrl,
                        reloadONSearch: false,
                        resolve: userResolve
                    }).when('/pending-forms', {
                        templateUrl: 'dashboard/pending-forms.html',
                        controller: controllers.PendingFormsCtrl,
                        reloadONSearch: false,
                        resolve: userResolve
                    }).when('/view-admins', {
                        templateUrl: 'dashboard/view-admins.html',
                        controller: controllers.ViewAdminsCtrl,
                        reloadOnSearch: false,
                        resolve: userResolve
                    }).when('/help', {
                        templateUrl: 'home/help.html',
                        controller: controllers.HelpCtrl,
                        resolve: userResolveNoLoginOk
                    }).when(
                        '/usermgr', {
                            templateUrl: 'dashboard/usermgr.html',
                            controller: controllers.UserMgrCtrl,
                            controllerAs: 'ctrl',
                            reloadOnSearch: false,
                            resolve: adminResolve
                        }).when('/notes', {
                        templateUrl: 'home/notes.html',
                        controller: controllers.NotesCtrl,
                        resolve: userResolve
                    }).when(
                        '/uploadforms', {
                            templateUrl: 'dashboard/uploadform.html',
                            controller: controllers.UploadFormCtrl,
                            resolve: adminResolve
                        }).when(
                        '/viewSignable', {
                            templateUrl: 'dashboard/signable.html',
                            controller: controllers.SignableCtrl,
                            resolve: userResolve,
                            reloadOnSearch: false
                        }).when('/mdlookup', {
                        templateUrl: 'dashboard/md-lookup.html',
                        controller: controllers.MDLookupCtrl,
                        resolve: userResolve,
                        reloadOnSearch: false
                    }).when(
                        '/adminforms', {
                            templateUrl: 'dashboard/adminforms.html',
                            controller: controllers.AdminFormCtrl,
                            reloadOnSearch: false,
                            resolve: adminResolve
                        });
                }]);
        return mod;
    });

