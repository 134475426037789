define('dashboard/view-pdf.controller',['angular'], function (angular) {
    'use strict';
    var ViewPDFCtrl = function ($scope, userService, $mdDialog, $document, $location) {
        $scope.showPDF = false;
        $scope.init = function () {
            $scope.pdf = {
                src: ''
            };
            var id = +$location.search().id;
            var sid = $location.search().sid;
            var mode = $location.search().mode;
            $scope.downloadingForm = true;
            userService.checkLoginWithSid(sid).then(function (r) {
                $scope.user = r;
                if (mode === 'ad') {
                    userService.downloadAdvanceDirectiveAsImageById(id).then(function (response) {
                        $scope.imgs = [];
                        for (var i = 0; i < response.length; i++) {
                            $scope.imgs.push('data:image/png;base64, ' + response[i]);
                        }
                        $scope.showPDF = true;
                    });
                    if ($scope.user.smartSession === undefined) {
                        userService.downloadADForm(id).then(function (response) {
                            $scope.blobFile = new Blob([response.data], {type: "application/pdf"});
                            $scope.downloadingForm = false;
                        });
                    }
                } else {
                    userService.downloadFormAsImageById(id).then(function (response) {
                        $scope.imgs = [];
                        for (var i = 0; i < response.length; i++) {
                            $scope.imgs.push('data:image/png;base64, ' + response[i]);
                        }
                        $scope.showPDF = true;
                    });
                    if ($scope.user.smartSession === undefined) {
                        userService.downloadFormById(id).then(function (response) {
                            $scope.blobFile = new Blob([response.data], {type: "application/pdf"});
                            $scope.downloadingForm = false;
                        });

                    }
                }
            }, function () {
                // $location.path('/');
            });
        };
        $scope.downloadForm = function () {
            // window.navigator.msSaveOrOpenBlob($scope.blobFile, 'polst.pdf');
            window.print();
        };
        $scope.windowClose = function () {
            window.close();
        };

        function loadPDFJSCSS(filename) {
            var fileref = document.createElement("link");
            fileref.setAttribute("rel", "stylesheet");
            fileref.setAttribute("type", "text/css");
            fileref.setAttribute("href", filename);
            if (typeof fileref != "undefined")
                document.getElementsByTagName("head")[0].appendChild(fileref);
        }

        // loadPDFJSCSS("assets/pdf.js-viewer.css", "css"); ////dynamically load and add this .css file
        function removejscssfile(filename, filetype) {
            var targetelement = (filetype == "js") ? "script" : (filetype == "css") ? "link" : "none"; //determine element type to create nodelist from
            var targetattr = (filetype == "js") ? "src" : (filetype == "css") ? "href" : "none"; //determine corresponding attribute to test for
            var allsuspects = document.getElementsByTagName(targetelement);
            for (var i = allsuspects.length; i >= 0; i--) { //search backwards within nodelist for matching elements to remove
                if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(filename) !== -1) {
                    allsuspects[i].parentNode.removeChild(allsuspects[i]); //remove element by calling parentNode.removeChild()
                }
            }
        }

        $scope.$on("$destroy", function () {
            // removejscssfile('assets/pdf.js-viewer.css', 'css');
        });
    };
    ViewPDFCtrl.$inject = ['$scope', 'userService', '$mdDialog', '$document', '$location'];
    return ViewPDFCtrl;
});

