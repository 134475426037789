define('user/smart-auth.controller',['angular'], function (angular) {
    'use strict';
    var SmartAuthCtrl = function ($scope, helper, userService, $location, $document, $routeParams, $localStorage) {
        $localStorage.sid = undefined;
        delete $localStorage.sid;
        $scope.$on('$routeChangeSuccess', function () {
            var width = 780,
                height = 550,
                left = (screen.width - width) / 2,
                top = (screen.height - height) / 2,
                uniqueWindowId = "authorization-0f8070b0-0671-4027-bf02-60f83e46d3aa";
            if (top > 20) {
                top = top - 20;
            }
            var params = 'width=' + width + ', height=' + height;
            params += ', top=' + top + ', left=' + left;
            params += 'titlebar=no, location=yes';
            var location = $routeParams.url;
            window.open(location, "_self");
            // var loginWindow = window.open(location, uniqueWindowId, params);
            // window.addEventListener("message", function (e) {
            //     var oauthMessage = e.data;
            //     loginWindow.close();
            //     console.log('oauth message: ', oauthMessage);
            // }, false);
            // userService.setLogin(sid);
            // $location.path("/dashboard");
        });
    };
    SmartAuthCtrl.$inject = ['$scope', 'helper', 'userService', '$location', '$document', '$routeParams', '$localStorage'];
    return SmartAuthCtrl;
});

