define('ice/ice.services',['angular', 'common'], function (angular) {
    'use strict';
    var mod = angular.module('ice.services', ['com.cd.common']);
    mod.factory('iceService', ['$http', '$q', 'playRoutes', '$localStorage', function ($http, $q, playRoutes, $localStorage) {
        return {
            createIcePatient: function (ice_patient, medications, allergies, diagnosis, emergency_contacts) {
                return playRoutes.com.cd.controllers.Ice.createIcePatient().post({
                    sid: $localStorage.sid,
                    pref_org_id: $localStorage.pref_org_id,
                    ice_patient: ice_patient,
                    medications: medications,
                    allergies: allergies,
                    diagnosis: diagnosis,
                    emergency_contacts: emergency_contacts
                });
            },
            getIcePatients: function (type) {
                return playRoutes.com.cd.controllers.Ice.getIcePatients().post({
                    sid: $localStorage.sid,
                    pref_org_id: $localStorage.pref_org_id,
                    type: type
                });
            },
            getReviews: function() {
                return playRoutes.com.cd.controllers.Ice.getReviews().post({
                    sid: $localStorage.sid,
                });
            },
            getIcePatient: function(id) {
                return playRoutes.com.cd.controllers.Ice.getIcePatient().post({
                    sid: $localStorage.sid,
                    id: id
                });
            },
            updateIcePatient: function(ice_patient, medications, allergies, diagnosis, emergency_contacts, no_changes) {
                return playRoutes.com.cd.controllers.Ice.updateIcePatient().post({
                    sid: $localStorage.sid,
                    ice_patient: ice_patient,
                    medications: medications,
                    allergies: allergies,
                    diagnosis: diagnosis,
                    emergency_contacts: emergency_contacts,
                    no_changes: no_changes
                });
            }
        };
    }]);
    return mod;
});

