define('ice/ice-dashboard.controller',['angular'], function (angular) {
    'use strict';
    var IceDashboardCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document, userService, dataEntryService, $window,
                                     $mdDialog, $sce, helper, analyticsService, user, Papa, iceService) {
        // logged in user
        $scope.user = user.data.User;
        $scope.icePatients = [];
        $scope.reviews = [];
        $scope.first = true;
        $scope.sortBy = 'all';
        $scope.newPatient = function () {
            $location.url('/ice-patient');
        };
        $scope.getIcePatients = function () {
            $scope.myPromise = iceService.getIcePatients($scope.sortBy).then(function (d) {
                $scope.icePatients = d.data;
                for (var i = 0; i < $scope.icePatients.length; i++) {
                    var tmp = moment.utc($scope.icePatients[i].created, 'YYYY-MM-DD HH:mm:ss');
                    $scope.icePatients[i].created_date = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
                }
                iceService.getReviews().then(function (r) {
                    $scope.reviews = r.data;
                    // get the latest review for each patient
                    for (var i = 0; i < $scope.icePatients.length; i++) {
                        for (var j = 0; j < $scope.reviews.length; j++) {
                            if ($scope.icePatients[i].id === $scope.reviews[j].ice_patient_id) {
                                $scope.icePatients[i].last_review_name = $scope.reviews[j].reviewed_by;
                                $scope.icePatients[i].last_review_location = $scope.reviews[j].reviewed_location;
                                break;
                            }
                        }
                    }
                });
                $scope.onPaginate($scope.iceTable.page, $scope.iceTable.limit);
            });
        };
        $scope.getIcePatients();
        $scope.sortByType = function () {
            $scope.getIcePatients();
        };
        $scope.iceTable = {
            order: 'created',
            limit: 10,
            page: 1
        };
        $scope.onPaginate = function (page, limit) {
            $scope.iceTable.page = page;
            $scope.iceTable.limit = limit;
            // $scope.reorderForms();
        };
        $scope.onReorder = function (order) {
            console.log(order);
            if (order === 'fname') {
                $scope.icePatients.sort(function (a, b) {
                    return a.fname.toLowerCase() > b.fname.toLowerCase() ? 1 : b.fname.toLowerCase() > a.fname.toLowerCase() ? -1 : 0;
                });
            } else if (order === '-fname') {
                $scope.icePatients.sort(function (a, b) {
                    return a.fname.toLowerCase() > b.fname.toLowerCase() ? -1 : b.fname.toLowerCase() > a.fname.toLowerCase() ? 1 : 0;
                });
            } else if (order === 'lname') {
                $scope.icePatients.sort(function (a, b) {
                    return a.lname.toLowerCase() > b.lname.toLowerCase() ? 1 : b.lname.toLowerCase() > a.lname.toLowerCase() ? -1 : 0;
                });
            } else if (order === '-lname') {
                $scope.icePatients.sort(function (a, b) {
                    return a.lname.toLowerCase() > b.lname.toLowerCase() ? -1 : b.lname.toLowerCase() > a.lname.toLowerCase() ? 1 : 0;
                });
            } else if (order === 'created') {
                $scope.icePatients.sort(function (a, b) {
                    if (a.created === undefined && b.created === undefined) {
                        return 0;
                    } else if (a.created === undefined) {
                        return 1;
                    } else if (b.created === undefined) {
                        return -1;
                    }
                    var a2 = moment.utc(a.created, 'YYYY-MM-DD HH:mm:ss');
                    var b2 = moment.utc(b.created, 'YYYY-MM-DD HH:mm:ss');
                    if (a2.isBefore(b2)) {
                        return 1;
                    } else if (a2.isAfter(b2)) {
                        return -1;
                    } else {
                        return 0;
                    }

                });
            } else if (order === '-created') {
                $scope.icePatients.sort(function (a, b) {
                    if (a.created === undefined && b.created === undefined) {
                        return 0;
                    } else if (a.created === undefined) {
                        return -1;
                    } else if (b.created === undefined) {
                        return 1;
                    }
                    var a1 = moment.utc(a.created, 'YYYY-MM-DD HH:mm:ss');
                    var b1 = moment.utc(b.created, 'YYYY-MM-DD HH:mm:ss');
                    if (a1.isBefore(b1)) {
                        return -1;
                    } else if (a1.isAfter(b1)) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            } else if (order === 'created_by') {
                $scope.icePatients.sort(function (a, b) {
                    return a.created_by.toLowerCase() > b.created_by.toLowerCase() ? 1 : b.created_by.toLowerCase() > a.created_by.toLowerCase() ? -1 : 0;
                });
            } else if (order === '-created_by') {
                $scope.icePatients.sort(function (a, b) {
                    return a.created_by.toLowerCase() > b.created_by.toLowerCase() ? -1 : b.created_by.toLowerCase() > a.created_by.toLowerCase() ? 1 : 0;
                });
            } else if (order === 'reviewed_by') {
                $scope.icePatients.sort(function (a, b) {
                    return a.reviewed_by.toLowerCase() > b.reviewed_by.toLowerCase() ? 1 : b.reviewed_by.toLowerCase() > a.reviewed_by.toLowerCase() ? -1 : 0;
                });
            } else if (order === '-reviewed_by') {
                $scope.icePatients.sort(function (a, b) {
                    return a.reviewed_by.toLowerCase() > b.reviewed_by.toLowerCase() ? -1 : b.reviewed_by.toLowerCase() > a.reviewed_by.toLowerCase() ? 1 : 0;
                });
            } else if (order === 'reviewed_location') {
                $scope.icePatients.sort(function (a, b) {
                    return a.reviewed_location.toLowerCase() > b.reviewed_location.toLowerCase() ? 1 : b.reviewed_location.toLowerCase() > a.reviewed_location.toLowerCase() ? -1 : 0;
                });
            } else if (order === '-reviewed_location') {
                $scope.icePatients.sort(function (a, b) {
                    return a.reviewed_location.toLowerCase() > b.reviewed_location.toLowerCase() ? -1 : b.reviewed_location.toLowerCase() > a.reviewed_location.toLowerCase() ? 1 : 0;
                });
            }
        };
        $scope.viewPatient = function (p) {
            $location.path('/ice-patient').search({id: p.id, mode: 'view'});
        };
        $scope.reviewPatient = function(p) {
            $location.path('/ice-patient').search({id: p.id, mode: 'review'});
        };
    };
    IceDashboardCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'analyticsService', 'user', 'Papa', 'iceService'];
    return IceDashboardCtrl;
});

