/**
 * Dashboard controllers.
 */
define('analytics/analytics.controllers',['angular', './analytics-dashboard.controller', './view-polst.controller', './detailed-query-analytics.controller', './form-submission.controller'],
    function (angular, AnalyticsDashboardCtrl, ViewPolstCtrl, DetailedQueryAnalyticsCtrl, FormSubmissionCtrl) {
    'use strict';
    return {
        ViewPolstCtrl: ViewPolstCtrl,
        FormSubmissionCtrl: FormSubmissionCtrl,
        AnalyticsDashboardCtrl: AnalyticsDashboardCtrl,
        DetailedQueryAnalyticsCtrl: DetailedQueryAnalyticsCtrl
    };
});

