define('acp-billing/edit-insurance-acp.controller',['angular'], function (angular) {
    'use strict';
    var EditInsuranceAcpCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                         userService, $window, $mdDialog, $sce, helper, acpBillingService, visit) {
        $scope.visit = visit;
        $scope.editPatient = function (valid, ev) {
            $scope.addSubmitted = true;
            $scope.myPromise = acpBillingService.updateInsurance($scope.visit).then(function (r) {
                $mdDialog.hide({visit: $scope.visit});
            });
        };
        $scope.cancel = function () {
            $mdDialog.hide();
        };
    };
    EditInsuranceAcpCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', '$window', '$mdDialog', '$sce', 'helper', 'acpBillingService', 'visit'];
    return EditInsuranceAcpCtrl;
});
