define('dashboard/confirm-selection.controller',['angular'], function (angular) {
    'use strict';
    var ConfirmSelectionCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                         userService, $window, $mdDialog, $sce, helper, pt, polst, newPtDisabled, diffs) {
        $scope.newPtDisabled = newPtDisabled;
        $scope.pt = pt;
        $scope.diffs = diffs;
        var i = 0;
        $scope.expls = [];
        $scope.polst = polst;
        $scope.confirmSelection = function(){
            $mdDialog.hide({patinet: pt, confirmed: true});
        };
        $scope.cancelSelection = function () {
            $mdDialog.hide();
        };
    };
    ConfirmSelectionCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', '$window', '$mdDialog', '$sce', 'helper', 'pt', 'polst', 'newPtDisabled', 'diffs'];
    return ConfirmSelectionCtrl;
});
