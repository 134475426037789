define('dashboard/pending-forms.controller',['angular'], function (angular) {
    'use strict';
    var PendingFormsCtrl = function ($scope, $mdDialog, user, helper, userService, analyticsService, $location) {
        $scope.user = user.data.User;
        $scope.pendingFormTable = {
            order: 'created',
            limit: 10,
            page: 1
        };
        $scope.viewChanged = function () {
            $scope.pendingExportReady = false;
        };
        $scope.searchInput = '';
        $scope.initPending = true;

        userService.getPendingForms().then(function(response){
            $scope.initPending = false;
            $scope.pendingForms = response.data;
            $scope.pendingOnReorder('created');
            $scope.reorderPendingForms();
        });

        $scope.reorderPendingForms = function () {
            $scope.pendingExportReady = false;
            var start = (($scope.pendingFormTable.page - 1) * $scope.pendingFormTable.limit);
            var end = start + $scope.pendingFormTable.limit;

            var i = 0;
            $scope.pendingCorrectForms = [];
            if ($scope.searchInput === '') {
                if ($scope.pendingForms !== undefined) {
                    for (i = start; i < end && i < $scope.pendingForms.length; i++) {
                        $scope.pendingCorrectForms.push($scope.pendingForms[i]);
                    }
                }
            }
            if ($scope.searchInput !== '') {
                $scope.pendingCorrectForms = [];
                if ($scope.pendingForms !== undefined) {
                    for (i = start; i < end && i < $scope.pendingForms.length; i++) {
                        if ($scope.pendingForms[i].afid.toString().indexOf($scope.searchInput) >= 0 || $scope.pendingForms[i].fname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.pendingForms[i].lname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || ($scope.pendingForms[i].fname + ' ' + $scope.pendingForms[i].lname).toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0) {
                            $scope.pendingCorrectForms.push($scope.pendingForms[i]);
                        }
                    }

                }
            }
        };
        $scope.pendingShowDownload = false;
        $scope.downloadPendingForms = function () {
            var i = 0;
            var formsToDownload = [];
            if ($scope.searchInput === '') {
                if ($scope.pendingForms !== undefined) {
                    for (i = 0; i < $scope.pendingForms.length; i++) {
                        formsToDownload.push($scope.forms[i]);
                    }
                }
            }
            if ($scope.searchInput !== '') {
                if ($scope.pendingForms !== undefined) {
                    for (i = 0; i < $scope.pendingForms.length; i++) {
                        if ($scope.pendingForms[i].afid.toString().indexOf($scope.searchInput) >= 0 || $scope.pendingForms[i].fname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.pendingForms[i].lname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || ($scope.pendingForms[i].fname + ' ' + $scope.pendingForms[i].lname).toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0) {
                            formsToDownload.push($scope.pendingForms[i]);
                        }
                    }

                }
            }
            Papa.unparse(formsToDownload).then(function (r) {
                var blob = new Blob(["\ufeff", r]);
                var url = URL.createObjectURL(blob);
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.pendingMsFile = blob;
                    $scope.pendingFileURL = 'forms.csv';
                    window.navigator.msSaveOrOpenBlob(blob, 'forms.csv');
                    $scope.pendingShowDownload = true;
                } else {
                    $scope.pendingShowDownload = true;
                    $scope.pendingFileURL = URL.createObjectURL(blob);
                }
                $scope.pendingExportReady = true;
            });
        };
        $scope.changeAssignedPhysician = function(l, ev){
            $mdDialog.show({
                controller: 'ChangeAssignedPhysicianCtrl',
                templateUrl: 'dashboard/change-assigned-phys.html',
                locals: { polst: l, user: $scope.user},
                parent: document.body,
                clickOutsideToClose: true,
                fullscreen: true
            }).then(function (response) {
                if(response !== undefined){
                    l.phsyician = response.phys;
                }
            });
        };
        $scope.pendingOnReorder = function (order) {
            $scope.pendingForms = helper.orderForms(order, $scope.pendingForms);
            $scope.reorderPendingForms();
        };

        $scope.openForm = function (form) {
            $location.path("dashboard").search({p: 1, f: form.id});
        };

        ////////////////////////////////////////
        // Functions for rejected forms
        $scope.rejectedForms = [];
        $scope.statusFilter = 'todos';

        $scope.rejectedFormTable = {
            order: 'created',
            limit: 10,
            page: 1
        };
        $scope.pendingOnPaginate = function (page, limit) {
            $scope.pendingFormTable.page = page;
            $scope.pendingFormTable.limit = limit;
            $scope.reorderPendingForms();
        };
        $scope.generatePDF = function (f) {
            userService.downloadFormById(f.id).then(function (response) {
                var file = new Blob([response.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });
        };
    };
    PendingFormsCtrl.$inject = ['$scope', '$mdDialog', 'user', 'helper', 'userService', 'analyticsService', '$location'];
    return PendingFormsCtrl;
});

