/** Common filters. */
define('common/filters',['angular'], function(angular) {
  'use strict';

  var mod = angular.module('common.filters', []);
  /**
   * Extracts a given property from the value it is applied to.
   * {{{
   * (user | property:'name')
   * }}}
   */
  mod.filter('property', function(value, property) {
    if (angular.isObject(value)) {
      if (value.hasOwnProperty(property)) {
        return value[property];
      }
    }
  });
  mod.filter('tel', function(){
    return function(tel){
      if(!tel){return '';}
      var value = tel.toString().trim().replace(/^\+/,'');
      if(value.match(/[^0-9]/)){
        return tel;
      }
      var country, city, number;
      switch(value.length) {
        case 1:
        case 2:
        case 3:
          city = value;
          break;
        default:
          city = value.slice(0, 3);
          number = value.slice(3);
      }
      if(number){
        if(number.length > 3){
          number = number.slice(0,3) + "-" + number.slice(3,7);
        } else {
          number = number;
        }
        return ("(" + city + ") " + number).trim();
      } else {
        return "(" + city;
      }
    };
  });
  return mod;
});

