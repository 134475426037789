define('user/select-preferred-org',['angular'], function (angular) {
    'use strict';
    var SelectPreferredOrgCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                  userService, dataEntryService, $window, $sce, helper, user, $rootScope) {
        $scope.user = user.data.User;
        $scope.selectedOrgId = $localStorage.pref_org_id;
        $scope.orgSelected = function(){
            for(var i = 0; i < $scope.user.organizations.length; i++){
                if($scope.user.organizations[i].id === Number($scope.selectedOrgId)){
                    $scope.selectedOrgName = $scope.user.organizations[i].name;
                }
            }
        };
        $scope.selectOrg = function(){
            $localStorage.pref_org_id = Number($scope.selectedOrgId);
            $rootScope.$emit('rootScope:updateBadges', {});
            $rootScope.$emit('rootScope:changeOrg');
            $location.search({});
            $location.path( '/dashboard' );
        };

    };
    SelectPreferredOrgCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$sce', 'helper', 'user', '$rootScope'];
    return SelectPreferredOrgCtrl;
});

