define('data-entry/ad-verify.controller',['angular'], function (angular) {
    'use strict';
    var ADVerifyCtrl = function ($scope, userService, user, dataEntryService, $routeParams, $filter, helper, $mdDialog, $mdToast, $location, Papa) {
        $scope.user = user.data.User;
        $scope.invalidDocDate = true;
        $scope.form = {};
        $scope.afid = +$routeParams.id;
        $scope.listView = function () {
            $location.path('/data-entry/list');
        };
        dataEntryService.getAdvanceDirective($scope.user.sessionID, $scope.afid).then(function (response) {
            $scope.adform = response.data;
            var newDate = moment($scope.adform.date_submitted);
            $scope.newDate = $scope.adform.date_submitted;
            console.log($scope.adform);
            $scope.publish = 1;
            if ($scope.adform.metadata !== undefined) {
                Papa.parse($scope.adform.metadata, {}).then(function (r) {
                    var arrlength = r.data[0].length;
                    for (var j = 0; j < arrlength; j++) {
                        $scope.adform[r.data[0][j].trim()] = r.data[1][j].trim();
                        if (r.data[0][j].trim().toLowerCase() === 'first') {
                            $scope.adform.fname = r.data[1][j].trim();
                        }
                        if (r.data[0][j].trim().toLowerCase() === 'middle') {
                            $scope.adform.mname = r.data[1][j].trim();
                        }
                        if (r.data[0][j].trim().toLowerCase() === 'last') {
                            $scope.adform.lname = r.data[1][j].trim();
                        }
                    }
                });
            }
        });
        var updateLabels = function () {
            helper.updateADLabels($scope, $filter, true);
        };
        updateLabels();
        var reset = function () {
            $scope.adform = {phone: ''};
            $scope.patientSelected = false;
            $scope.patientMatched = false;
            $scope.ptSearchDone = false;
            $scope.searching = false;
            $scope.polst = {
                secD2: 2,
                gender: '',
                sigRelationship: 'Self',
                addOrders: $filter('translate')('additional_orders') + ":\n\n",
                foodAddOrders: $filter('translate')('additional_orders') + ":\n\n",
                notCompByPhys: !$scope.user.phyiscian
            };
            $scope.fid = undefined;
            $scope.formSubmitted = false;
            $scope.isEmpty = false;
            $scope.savedSig = false;
            $scope.savedSig2 = false;
            $scope.searchErr = false;
            $scope.numResults = false;
            $scope.oneResult = false;
            $scope.search = {};
            $scope.hideCreate = false;
            $scope.searchForm = undefined;

            $scope.publish = 1;

            if ($scope.form.sform !== undefined) {
                $scope.form.sform.$setPristine();
                $scope.form.sform.$setUntouched();
            }
            if ($scope.form.pform !== undefined) {
                $scope.form.pform.$setPristine();
                $scope.form.pform.$setUntouched();
            }
        };
        var reinit = function () {
            reset();
            $scope.numToSign = 0;
            userService.numToSign().then(function (response) {
                $scope.numToSign = response.data.num;
            }, function (e) {

            });
            $scope.p = Number($scope.afid);
            if ($scope.p === undefined || isNaN($scope.p)) {
                $scope.p = 0;
            }
            $scope.tabIndex = $scope.p;

        };

        // PO
        $scope.states = helper.getStates();
        $scope.adform = {};
        $scope.genders = [{
            id: 1,
            name: "MALE"
        }, {
            id: 2,
            name: "FEMALE"
        }, {
            id: 3,
            name: 'UNDIFFERENTIATED'
        }];
        $scope.processForm = function (l) {
            console.log(l);
        };

        $scope.claimForm = function (l) {
            console.log(l);
        };

        $scope.viewADPDF = function () {
            if ($scope.fileUrl !== undefined) {
                window.open($scope.fileAD_URL, "_blank");
            } else {
                dataEntryService.getABBYYPDF($scope.user.sessionID, $scope.afid).then(function (response) {
                    var file = new Blob([response.data], {type: "application/pdf"});
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        $scope.msFileAD = file;
                        $scope.fileAD_URL = 'tmp.pdf';
                        // window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                        $scope.showDownload = true;
                        window.navigator.msSaveOrOpenBlob($scope.msFileAD, 'AdvancedDirective.pdf');
                    } else {
                        $scope.showDownload = true;
                        $scope.fileAD_URL = URL.createObjectURL(file);
                        window.open($scope.fileAD_URL, "_blank");
                    }
                });
            }
        };
        $scope.viewExisting = function () {
            userService.downloadForm($scope.adform.patientID).then(function (response) {
                var file = new Blob([response.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    $scope.showDownload = true;
                } else {
                    console.log("not in .net version");
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });

        };


        $scope.searching = false;
        $scope.searchPts = function () {
            if (!$scope.searching) {
                $scope.searching = true;
                userService.matchPatient({
                    lname: $scope.adform.lname,
                    fname: $scope.adform.fname,
                    mname: $scope.adform.mname,
                    street: $scope.adform.street,
                    city: $scope.adform.city,
                    state: $scope.adform.state,
                    zip: $scope.adform.zip,
                    dob: $scope.adform.dob,
                    ssn: $scope.adform.ssn,
                    gender: $scope.adform.gender,
                    ins: $scope.adform.ins,
                    phone: $scope.adform.phone === undefined ? undefined : $scope.adform.phone.replace(/[^0-9]/g, ''),
                    mrn: $scope.adform.mrn,
                    orgid: $scope.adform.orgid
                }).then(function (response) {
                    $scope.pts = response.data;
                    $scope.newPtDisabled = false;
                    for (var zz = 0; zz < $scope.pts.length; zz++) {
                        $scope.pts[zz].score = Math.round(($scope.pts[zz].score * 100)) + "%";
                        if ($scope.pts[zz].score === '100%') {
                            $scope.newPtDisabled = true;
                        }
                    }
                    if ($scope.pts.length > 0) {
                        $mdDialog.show({
                            controller: 'SelectAFPatientCtrl',
                            templateUrl: 'data-entry/selectAFPatient.html',
                            locals: {pts: $scope.pts, polst: $scope.adform, newPtDisabled: $scope.newPtDisabled},
                            parent: document.body,
                            clickOutsideToClose: true,
                            fullscreen: true
                        }).then(function (response) {
                            $scope.searching = false;
                            if (response !== undefined && response.confirmed) {
                                $scope.patientSelected = true;
                                $scope.ptSearchDone = true;
                                $scope.patientMatched = true;
                                console.log(response);
                                if (response.patient !== undefined) {
                                    $scope.adform.patientID = response.patient.id;
                                    $scope.adform.dob = response.patient.dob;
                                    $scope.adform.fname = response.patient.fname;
                                    $scope.adform.lname = response.patient.lname;
                                    $scope.adform.mname = response.patient.mname;
                                    $scope.adform.street = response.patient.street;
                                    $scope.adform.city = response.patient.city;
                                    $scope.adform.state = response.patient.state;
                                    $scope.adform.zip = response.patient.zip;
                                    $scope.adform.phone = response.patient.phone;
                                    $scope.phone = response.patient.phone;
                                    $scope.adform.ssn = response.patient.ssn;
                                    $scope.adform.gender = response.patient.gender;
                                }
                                $scope.adform.sigName = $scope.adform.fname + " " + ($scope.adform.mname === undefined ? "" : $scope.adform.mname) + " " + $scope.adform.lname;
                                $scope.adform.sigStreet = $scope.adform.street;
                                $scope.adform.sigCity = $scope.adform.city;
                                $scope.adform.sigState = $scope.adform.state;
                                $scope.adform.sigZip = $scope.adform.zip;
                                $scope.adform.sigPhone = $scope.adform.phone;
                                dataEntryService.searchForCurrentAD($scope.adform.patientID).then(function (response) {
                                    $scope.hasAD = true;
                                    $scope.currentFormID = response.data.id;
                                    var tmp = moment.utc(response.data.doc_date);
                                    $scope.currentDate = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
                                    var newDate = moment($scope.adform.date_submitted);
                                    $scope.newDate = $scope.adform.date_submitted;
                                }, function (err) {
                                    // does not have a current AD
                                    $scope.hasAD = false;
                                });
                            }
                        });
                    } else {
                        $scope.searching = false;
                        $scope.patientSelected = true;
                        $scope.ptSearchDone = true;
                        $scope.patientMatched = true;
                        $scope.adform.sigName = $scope.adform.fname + " " + ($scope.adform.mname === undefined ? "" : $scope.adform.mname) + " " + $scope.adform.lname;
                        $scope.adform.sigStreet = $scope.adform.street;
                        $scope.adform.sigCity = $scope.adform.city;
                        $scope.adform.sigState = $scope.adform.state;
                        $scope.adform.sigZip = $scope.adform.zip;
                        $scope.adform.sigPhone = $scope.adform.phone;
                    }
                });
            }

        };
        $scope.statusChanged = function () {
            if ($scope.adform.codeStatus === 0) {
                $scope.adform.end_of_life_decision = 'DNR';
            } else {
                $scope.adform.end_of_life_decision = 'Full CodetaChanged';
            }
        };
        $scope.authorityChanged = function () {
            if ($scope.adform.agentAuthority === 0) {
                $scope.adform.agent_authority = 'immediate';
            } else {
                $scope.adform.agent_authority = 'incapacitated';
            }
        };
        $scope.validateDOB = function (dateStr) {
            $scope.invalidDOB = !helper.validateDate(dateStr);
        };
        $scope.validateDocDate = function (dateStr) {
            $scope.invalidDocDate = !helper.validateDate(dateStr);
        };
        $scope.creating = false;
        $scope.submitAD = function () {
            if (!$scope.creating) {
                $scope.creating = true;
                $scope.myPromise = dataEntryService.verifyAdvanceDirective($scope.adform, $scope.publish === 1, $scope.user.sessionID, $scope.afid, $scope.adform.mrn).then(function (response) {
                    $scope.creating = false;
                    $mdToast.show({
                        position: 'bottom right',
                        controller: 'ToastSuccessCtrl',
                        templateUrl: 'data-entry/upload-success.html'
                    });
                    $location.path('/data-entry/list');
                }, function (err) {
                    $scope.creating = false;
                });
            }
        };
        $scope.selectedOrg = undefined;
        $scope.searchTextChange = function (text) {
            $scope.selectedOrg = undefined;
        };
        $scope.selectedItemChange = function (item) {
            $scope.selectedOrg = item;
        };
        $scope.retrieveOrg = function (l) {
            return dataEntryService.retrieveOrg($scope.user.sessionID, l).then(function (data) {
                return data;
            }, function (e) {
                return [];
            });
        };
        $scope.viewCurrent = function () {
            userService.downloadADById($scope.currentFormID).then(function (response) {
                var file = new Blob([response.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    $scope.showDownload = true;
                } else {
                    console.log("not in .net version");
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });
        };
        $scope.viewPDF = function () {
            dataEntryService.getABBYYPDF($scope.user.sessionID, $scope.afid).then(function (response) {
                var file = new Blob([response.data], {type: "application/pdf"});
                $scope.pdfViewed = true;
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    $scope.showDownload = true;
                } else {
                    console.log("not in .net version");
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });
        };
        $scope.listView = function () {
            $location.path('/data-entry/list');
        };
        $scope.markAsUploadError = function () {
            dataEntryService.markUploadError($scope.user.sessionID, $scope.afid, null).then(function () {
                $scope.listView();
            });
        };
        $scope.rejectForm = function () {
            $mdDialog.show({
                controller: 'RejectPolstCtrl',
                templateUrl: 'data-entry/reject-polst.html',
                locals: {
                    afform: $scope.adform,
                    pid: $scope.adform.patientID,
                    afid: $scope.afid,
                    af: $scope.adform,
                    mrn: $scope.adform.mrn
                },
                parent: document.body,
                clickOutsideToClose: false,
                fullscreen: true
            }).then(function (response) {
                if (response !== undefined && response.rejected) {
                    $scope.listView();
                }
            });
        };
    };
    ADVerifyCtrl.$inject = ['$scope', 'userService', 'user', 'dataEntryService', '$routeParams', '$filter', 'helper', '$mdDialog', '$mdToast', '$location', 'Papa'];
    return ADVerifyCtrl;
});

