define('analytics/analytics-dashboard.controller',['angular'], function (angular) {
    'use strict';
    var AnalyticsDashboardCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document, userService, dataEntryService, $window, $mdDialog, $sce, helper, analyticsService, user, Papa) {
        $scope.user = user.data.User;
        // $scope.isHosAPI = $scope.user.org.type === 'Hospital API';

        var tmp = moment().subtract(30, 'days');
        $scope.startDate = tmp.toDate();
        $scope.endDate = moment().toDate();
        $scope.allDates = false;
        $scope.reportReady = false;
        $scope.clearReport = function () {
            $scope.reportReady = false;
            $scope.runningReport = false;
            $scope.exportReady = false;
        };
        var orgid = $scope.user.org.id;
        if ($localStorage.pref_org_id !== undefined) {
            orgid = $localStorage.pref_org_id;
        }
        $scope.isLLUFHIR = false;
        $scope.params = {byEncounterDepartment: false, byFacility: false};
        userService.getOrg($scope.user.sessionID, orgid).then(function (r) {
            $scope.org = r.data;
            $scope.isHosAPI = ($scope.org.type === 'Hospital API' || $scope.org.type === 'API Test');
            $scope.isLLUFHIR = $scope.org.name === 'LLU FHIR';
        });
        $scope.runningReport = false;
        $scope.runReport = function () {
            $scope.runningReport = true;
            if ($scope.user.org.type === 'Hospital API') {
                analyticsService.APIqueriesByDate($scope.user.sessionID, $scope.allDates, $scope.startDate, $scope.endDate).then(function (r) {

                    var total = 0;
                    var allTotal = 0;
                    var exactTotal = 0;

                    $scope.queries = [];
                    for (var i = 0; i < r.length; i++) {
                        total += r[i].num_searched;
                        allTotal += r[i].AllMatchCount;
                        exactTotal += r[i].ExactMatchCount;
                        $scope.queries.push(r[i]);

                    }
                    $scope.queries.push({
                        total: true,
                        AllMatchCount: allTotal,
                        ExactMatchCount: exactTotal,
                        num_searched: total,
                        facility_code: 'Total Searches'
                    });

                    $scope.onReorder('num_searched');
                    $scope.reportReady = true;
                    $scope.runningReport = false;
                });
            }

            if ($scope.user.org.type !== 'Hospital API') {
                analyticsService.queriesByDate($scope.user.sessionID, $scope.allDates, $scope.startDate, $scope.endDate, $scope.params.byEncounterDepartment, $scope.params.byFacility).then(function (r) {

                    var total = 0;
                    var allTotal = 0;
                    var exactTotal = 0;

                    $scope.queries = [];
                    for (var i = 0; i < r.length; i++) {
                        total += r[i].num_searched;
                        allTotal += r[i].AllMatchCount;
                        exactTotal += r[i].ExactMatchCount;
                        $scope.queries.push(r[i]);
                    }
                    $scope.queries.push({
                        total: true,
                        AllMatchCount: allTotal,
                        ExactMatchCount: exactTotal,
                        num_searched: total,
                        user: {firstname: 'Total', lastname: 'Queries'}
                    });
                    $scope.onReorder('num_searched');
                    $scope.reportReady = true;
                    $scope.runningReport = false;
                });
            }
        };
        $scope.reportTable = {
            order: 'num_searched'
        };
        $scope.onReorder = function (orderBy) {
            if (orderBy === 'num_searched') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        return a.num_searched > b.num_searched ? -1 : a.num_searched < b.num_searched ? 1 : 0;
                    }
                });
            } else if (orderBy === '-num_searched') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        return a.num_searched > b.num_searched ? 1 : a.num_searched < b.num_searched ? -1 : 0;
                    }
                });
            } else if (orderBy === 'exactMatch') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        return a.ExactMatchCount > b.ExactMatchCount ? -1 : a.ExactMatchCount < b.ExactMatchCount ? 1 : 0;
                    }
                });

            } else if (orderBy === '-exactMatch') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        return a.ExactMatchCount > b.ExactMatchCount ? 1 : a.ExactMatchCount < b.ExactMatchCount ? -1 : 0;
                    }
                });

            } else if (orderBy === 'allMatches') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        return a.AllMatchCount > b.AllMatchCount ? -1 : a.AllMatchCount < b.AllMatchCount ? 1 : 0;
                    }
                });

            } else if (orderBy === '-allMatches') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        return a.AllMatchCount > b.AllMatchCount ? 1 : a.AllMatchCount < b.AllMatchCount ? -1 : 0;
                    }
                });
            } else if (orderBy === 'facility_code') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        if (a.facility_code === undefined && b.facility_code === undefined) {
                            return 0;
                        } else if (a.facility_code === undefined) {
                            return 1;
                        } else if (b.facility_code === undefined) {
                            return -1;
                        }
                        return a.facility_code.toUpperCase() > b.facility_code.toUpperCase() ? 1 : a.facility_code.toUpperCase() < b.facility_code.toUpperCase() ? -1 : 0;
                    }
                });

            } else if (orderBy === '-facility_code') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        if (a.facility_code === undefined && b.facility_code === undefined) {
                            return 0;
                        } else if (a.facility_code === undefined) {
                            return -1;
                        } else if (b.facility_code === undefined) {
                            return 1;
                        }
                        return a.facility_code.toUpperCase() > b.facility_code.toUpperCase() ? -1 : a.facility_code.toUpperCase() < b.facility_code.toUpperCase() ? 1 : 0;
                    }
                });
            } else if (orderBy === 'fname') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        if (a.user.firstname === undefined && b.user.firstname === undefined) {
                            return 0;
                        } else if (a.user.firstname === undefined) {
                            return 1;
                        } else if (b.user.firstname === undefined) {
                            return -1;
                        }
                        return a.user.firstname.toUpperCase() > b.user.firstname.toUpperCase() ? 1 : a.user.firstname.toUpperCase() < b.user.firstname.toUpperCase() ? -1 : 0;
                    }
                });
            } else if (orderBy === '-fname') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        if (a.user.firstname === undefined && b.user.firstname === undefined) {
                            return 0;
                        } else if (a.user.firstname === undefined) {
                            return -1;
                        } else if (b.user.firstname === undefined) {
                            return 1;
                        }
                        return a.user.firstname.toUpperCase() > b.user.firstname.toUpperCase() ? -1 : a.user.firstname.toUpperCase() < b.user.firstname.toUpperCase() ? 1 : 0;
                    }
                });
            } else if (orderBy === 'lname') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        if (a.user.lastname === undefined && b.user.lastname === undefined) {
                            return 0;
                        } else if (a.user.lastname === undefined) {
                            return 1;
                        } else if (b.user.lastname === undefined) {
                            return -1;
                        }
                        return a.user.lastname.toUpperCase() > b.user.lastname.toUpperCase() ? 1 : a.user.lastname.toUpperCase() < b.user.lastname.toUpperCase() ? -1 : 0;
                    }
                });
            } else if (orderBy === '-lname') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        if (a.user.lastname === undefined && b.user.lastname === undefined) {
                            return 0;
                        } else if (a.user.lastname === undefined) {
                            return -1;
                        } else if (b.user.lastname === undefined) {
                            return 1;
                        }
                        return a.user.lastname.toUpperCase() > b.user.lastname.toUpperCase() ? -1 : a.user.lastname.toUpperCase() < b.user.lastname.toUpperCase() ? 1 : 0;
                    }
                });
            } else if (orderBy === '-email') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        return a.user.email.toUpperCase() > b.user.email.toUpperCase() ? -1 : a.user.email.toUpperCase() < b.user.email.toUpperCase() ? 1 : 0;
                    }
                });
            } else if (orderBy === 'email') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        return a.user.email.toUpperCase() > b.user.email.toUpperCase() ? 1 : a.user.email.toUpperCase() < b.user.email.toUpperCase() ? -1 : 0;
                    }
                });
            }
        };
        $scope.exportReady = false;
        $scope.downloadAnalytics = function () {
            var dataToDownload = [];
            for (var i = 0; i < $scope.queries.length; i++) {
                if ($scope.params.byEncounterDepartment || $scope.isHosAPI || $scope.params.byFacility) {
                    dataToDownload.push({
                        facility: $scope.queries[i].total ? 'Total' : $scope.queries[i].facility_code === undefined ? 'No Code' : $scope.queries[i].facility_code,
                        queries: $scope.queries[i].num_searched
                    });
                } else {
                    dataToDownload.push({
                        first_name: $scope.queries[i].user.firstname,
                        last_name: $scope.queries[i].user.lastname,
                        email: $scope.queries[i].user.email,
                        queries: $scope.queries[i].num_searched
                    });
                }
            }
            Papa.unparse(dataToDownload).then(function (r) {
                var blob = new Blob(["\ufeff", r]);
                var url = URL.createObjectURL(blob);
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = blob;
                    $scope.fileURL = 'analytics.csv';
                    window.navigator.msSaveOrOpenBlob(blob, 'analytics.csv');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(blob);
                }
                $scope.exportReady = true;
            });
        };
    };
    AnalyticsDashboardCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'analyticsService', 'user', 'Papa'];
    return AnalyticsDashboardCtrl;
});

