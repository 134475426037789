define('dashboard/md-lookup.controller',['angular'], function (angular) {
    'use strict';
    var MDLookupCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                 userService, dataEntryService, $window, $mdDialog,  $sce, helper) {
        $scope.mdSelected = function (item) {
            $scope.phys = item;
            if ($scope.phys !== undefined) {
                if ($scope.phys.lastLogin !== undefined) {
                    var tmp = moment.utc($scope.phys.lastLogin, 'YYYY-MM-DD HH:mm:ss');
                    $scope.phys.loginDate = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
                } else {
                    $scope.phys.loginDate = undefined;
                }
            }
        };
        $scope.searchTextChange = function () {
            $scope.phys = undefined;
        };
        $scope.physicianSearch = function (txt) {
            if (txt.length >= 1) {
                return userService.searchPhysicians(txt).then(function (data) {
                    for (var z = 0; z < data.length; z++) {
                        data[z].fullname = data[z].firstname + " " + data[z].lastname;
                    }
                    return data;
                });
            }
            return [];
        };
    };
    MDLookupCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper'];
    return MDLookupCtrl;
});

