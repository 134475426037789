define('user/reg.controller',['angular'], function (angular) {
    'use strict';
    var RegCtrl = function ($scope, helper, userService, $location, $document) {
        $scope.credentials = {
            initiator: true,
            access: true,
            signer: true,
            transfer: true,
            organization_id: 0,
            userType: 0
        };
        $scope.types = [{
            id: 0,
            name: "Select a Role"
        }, {
            id: 1,
            name: "Nurse"
        }, {
            id: 2,
            name: "Case Manager"
        }, {
            id: 3,
            name: "Social Worker"
        }, {
            id: 4,
            name: "Physician, PA, NP"
        }, {
            id: 5,
            name: "Admin"
        }, {
            id: 6,
            name: "Medical Assistants"
        }, {
            id: 7,
            name: "Paramedics"
        }];
        $scope.orgs = [{
            id: 0,
            name: "Select an Organization"
        }, {
            id: 1,
            name: "Example Nursing Facility"
        }, {
            id: 2,
            name: "Example Hospital"
        }, {
            id: 3,
            name: "Example Home Health/Hospice"
        }, {
            id: 4,
            name: "Example PCP"
        }, {
            id: 5,
            name: "Example Assisted & Residential Facility"
        }, {
            id: 6,
            name: "Example ED"
        }, {
            id: 7,
            name: "Example EMS/Fire Servie/Ambulance"
        }];
        $scope.setTouched = function () {
            setTimeout(function () {
                var elem = angular.element($document[0].querySelector('input[type=password]:-webkit-autofill'));

                if (elem.length) {
                    elem.parent().addClass('md-input-has-value');
                }
            }, 150);
        };
        $scope.attemptedEmails = [];
        $scope.registerUser = function (isValid) {
            $scope.regSubmitted = true;
            if (isValid && $scope.credentials.terms && $scope.credentials.organization_id > 0 && $scope.credentials.userType > 0) {
                $scope.isProcessing = true;
                userService.regUser($scope.credentials, $scope).then(function () {
                    $scope.isProcessing = false;
                    if ($scope.regSuccess) {
                        $location.path("/dashboard");
                    }
                });
            } else {
                userService.checkEmail($scope.credentials, $scope);
            }
        };
        $scope.checkEmailDupe = function () {
            if (helper.containsEmail({
                    email: $scope.credentials.email
                }, $scope.attemptedEmails)) {
                $scope.emailExists = true;
            } else {
                $scope.emailExists = false;
            }
        };

    };
    RegCtrl.$inject = ['$scope', 'helper', 'userService', '$location', '$document'];
    return RegCtrl;
});

