define('user/user-settings.controller',['angular'], function (angular) {
    'use strict';
    var UserSettingsCtrl = function ($scope, $routeParams, userService, $location, user) {
        $scope.user = user.data.User;
        $scope.verified = false;
        if($scope.user.cell_phone !== undefined){
            $scope.user.cell_phone = $scope.user.cell_phone.substring(2);
            $scope.verified_number = $scope.user.cell_phone;
           $scope.verified = true;
        }
        $scope.showCode = false;
        $scope.cellPhone = '';
        $scope.user.verification_code = '';

        $scope.showResend = false;
        $scope.timer = 30;
        var showTimer = function () {
            $scope.timer = $scope.timer - 1;
            $scope.$apply();
            if ($scope.timer > 0) {
                setTimeout(showTimer, 1000);
            } else {
                $scope.showResend = true;
            }
        };
        $scope.sendCode = function () {
            userService.sendSMSVerifyCode($scope.user.cell_phone).then(function (response) {
                $scope.showCode = true;
            });
        };

        $scope.resend = function () {
            $scope.codeError = false;
            $scope.verifyError = false;
            userService.sendSMSVerifyCode($scope.user.cell_phone).then(function (response) {
            });
        };
        $scope.removeVerifyError = function(){
            $scope.verifyError = false;
        };
        $scope.verify = function(){
            userService.setSMSNumber($scope.user.cell_phone, Number($scope.user.verification_code)).then(function(response){
                $scope.verifiedSuccess = true;
            }, function(e){
                $scope.codeError = true;
                $scope.verifyError = true;
            });
        };
    };
    UserSettingsCtrl.$inject = ['$scope', '$routeParams', 'userService', '$location', 'user'];
    return UserSettingsCtrl;
});
