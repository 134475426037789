define('user/set-sms.controller',['angular'], function (angular) {
    'use strict';
    var SetSMSCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                               userService, dataEntryService, $window, $mdDialog, $sce, helper, user) {
        $scope.user = angular.copy(user);
        $scope.exists = false;
        $scope.clearExists = function(){
            $scope.exists = false;
        };
        $scope.setSMS = function () {
            userService.addUserSMS($scope.user.id, $scope.user.cell_phone).then(function () {
                $mdDialog.hide({phone: '+1' + $scope.user.cell_phone});
            }, function(){
                $scope.exists = true;
            });
        };
        $scope.cancel = function () {
            $mdDialog.hide();
        };

    };
    SetSMSCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'user'];
    return SetSMSCtrl;
});

