define('dashboard/user-mgr.controller',['angular'], function (angular) {
    'use strict';
    var UserMgrCtrl = function ($scope, user, $location, $routeParams, userService, $mdDialog, helper, $mdToast, $document, $localStorage) {
        $scope.types = helper.getTypes();
        $scope.selected = [];
        $scope.selectedItem = {};
        $scope.orgs = [];
        $scope.user = user.data.User;

        $scope.searchInput = '';
        $scope.$watch('searchInput', function () {
            $scope.findMatches();
        });
        $scope.findMatches = function () {
            $scope.onPaginate(1, $scope.userTable.limit);
        };

        $scope.choices = ['All', 'Active', 'Inactive', 'Locked'];
        $scope.detailFilter = 'All';
        $scope.oldValue = '';
        $scope.getSelectedText = function () {
            if ($scope.detailFilter !== undefined && $scope.oldValue !== $scope.detailFilter) {
                // $scope.refine($scope.detailFilter)
                $scope.oldValue = $scope.detailFilter;
                $scope.onPaginate(1, $scope.userTable.limit);
            }
        };

        userService.getOrgs().then(function (response) {
            $scope.orgs = response.data;
            $scope.credentials.org = $scope.user.org;
            $scope.orgSearch = $scope.user.org.name;
        });
        $scope.userTable = {
            order: 'lastLogin',
            limit: 10,
            page: 1
        };
        $scope.removeSMS = function (l, ev) {
            var confirm = $mdDialog.confirm()
                .title('Are you sure you want to remove SMS authentication for ' + l.firstname + ' ' + l.lastname + '?')
                .textContent('This user will no longer be able to use ' + l.cell_phone + ' to log in.')
                .targetEvent(ev)
                .ok('Remove SMS')
                .cancel('Cancel');
            $mdDialog.show(confirm).then(function () {
                userService.removeUserSMS(l.id).then(function () {
                    l.cell_phone = undefined;
                });
            }, function () {
            });
        };
        $scope.addSMS = function (l) {
            $mdDialog.show({
                controller: 'SetSMSCtrl',
                templateUrl: 'user/set-sms.html',
                parent: angular.element(document.body),
                locals: {user: l},
                fullscreen: true
            }).then(function (data) {
                if (data != undefined && data.phone != undefined) {
                    l.cell_phone = data.phone;
                }
            });
        };
        $scope.prepUser = function (u) {
            u.created = new Date(u.created);
            if (u.lastLogin !== undefined) {
                var tmp = moment.utc(u.lastLogin, 'YYYY-MM-DD HH:mm:ss');
                u.loginDate = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
            } else {
                u.loginDate = undefined;
            }
        };
        $scope.unlockUser = function (l) {
            userService.unlockUser(l.id).then(function () {
                l.locked_out = false;
                l.active = true;
            });
        };
        $scope.swapActive = function (l) {
            userService.swapActive(l.email, l.active === true ? "1" : "0").then(function () {
                if (l.active === true) {
                    l.active = false;
                } else {
                    l.active = true;
                }
            });
        };
        $scope.onPaginate = function (page, limit) {
            $scope.userTable.page = page;
            $scope.userTable.limit = limit;
            $scope.reorderUsers();
        };
        $scope.reorderUsers = function () {
            var start = (($scope.userTable.page - 1) * $scope.userTable.limit);
            var end = start + $scope.userTable.limit;
            var i = 0;
            var tmp = [];

            if ($scope.searchInput !== '') {


                if ($scope.detailFilter === 'All') {
                    for (i = 0; i < $scope.users.length; i++) {
                        if ($scope.users[i].firstname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.users[i].lastname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.users[i].email.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || ($scope.users[i].firstname + ' ' + $scope.users[i].lastname).toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0) {
                            tmp.push($scope.users[i]);
                        }
                    }

                    $scope.totalUsers = tmp.length;
                    $scope.userView = [];
                    for (i = start; i < end && i < tmp.length; i++) {
                        $scope.userView.push(tmp[i]);
                    }
                } else {
                    for (i = 0; i < $scope.users.length; i++) {
                        if (($scope.users[i].active && $scope.detailFilter == 'Active') || (!$scope.users[i].active && !$scope.users[i].locked_out && $scope.detailFilter == 'Inactive') || ($scope.users[i].locked_out && $scope.detailFilter == 'Locked')) {

                            if ($scope.users[i].firstname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.users[i].lastname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.users[i].email.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || ($scope.users[i].firstname + ' ' + $scope.users[i].lastname).toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0) {
                                tmp.push($scope.users[i]);
                            }
                        }

                    }
                    $scope.totalUsers = tmp.length;
                    $scope.userView = [];
                    for (i = start; i < end && i < tmp.length; i++) {
                        $scope.userView.push(tmp[i]);
                    }

                }

            } else {
                if ($scope.detailFilter === 'All') {
                    $scope.totalUsers = $scope.users.length;
                    $scope.userView = [];
                    for (i = start; i < end && i < $scope.users.length; i++) {
                        $scope.userView.push($scope.users[i]);
                    }
                } else {
                    for (i = 0; i < $scope.users.length; i++) {
                        if (($scope.users[i].active && $scope.detailFilter == 'Active') || (!$scope.users[i].active && !$scope.users[i].locked_out && $scope.detailFilter == 'Inactive') || ($scope.users[i].locked_out && $scope.detailFilter == 'Locked')) {
                            tmp.push($scope.users[i]);
                        }
                    }
                    $scope.totalUsers = tmp.length;
                    $scope.userView = [];
                    for (i = start; i < end && i < tmp.length; i++) {
                        $scope.userView.push(tmp[i]);
                    }

                }
            }
        };


        $scope.userView = [];
        $scope.onReorder = function (order) {
            $scope.users = helper.orderUsers(order, $scope.users);
            $scope.reorderUsers();
        };
        $scope.addUser = {};
        $scope.swapTabs = function (idx) {
            $scope.tabIndex = idx;
            if (idx === 1) {
                $location.search('p', idx);
            } else {

                $location.search({p: idx});
            }
        };
        var reinit = function () {
            $scope.users = [];
            $scope.dataReady = false;
            $scope.myPromise = userService.getUsers().then(function (response) {
                console.log(response.data);
                for (var i = 0; i < response.data.length; i++) {
                    if ($scope.user.email !== response.data[i].email) {
                        if(response.data[i].email === undefined){
                            response.data[i].email = '';
                        }
                        if(response.data[i].firstname === undefined){
                            response.data[i].firstname = '';
                        }
                        if(response.data[i].lastname === undefined){
                            response.data[i].lastname = '';
                        }
                        $scope.users.push(response.data[i]);
                        $scope.prepUser($scope.users[$scope.users.length - 1]);
                    }
                }
                $scope.users.sort(function (a, b) {
                    if (a.lastLogin === undefined && b.lastLogin === undefined) {
                        return 0;
                    } else if (a.lastLogin === undefined) {
                        return 1;
                    } else if (b.lastLogin === undefined) {
                        return -1;
                    }
                    var a2 = moment.utc(a.lastLogin, 'YYYY-MM-DD HH:mm:ss');
                    var b2 = moment.utc(b.lastLogin, 'YYYY-MM-DD HH:mm:ss');
                    if (a2.isBefore(b2)) {
                        return 1;
                    } else if (a2.isAfter(b2)) {
                        return -1;
                    } else {
                        return 0;
                    }

                });
                $scope.dataReady = true;
                $scope.onPaginate($scope.userTable.page, $scope.userTable.limit);
            });
            if ($scope.addUser.aform !== undefined) {
                $scope.addUser.aform.$setPristine();
                $scope.addUser.aform.$setUntouched();
            }
            $scope.credentials.phone = $scope.user.org.phone;
            $scope.p = Number($routeParams.p);
            if ($scope.p === undefined || isNaN($scope.p)) {
                $scope.p = 0;
            }
            $scope.tabIndex = $scope.p;

        };
        $scope.showDetails = function (u, e) {
            $mdDialog.show({
                controller: 'UserDetailsCtrl',
                templateUrl: 'user/userDetails.html',
                locals: {user: u, orgs: $scope.orgs, admin: $scope.user},
                parent: document.body,
                targetEvent: e,
                clickOutsideToClose: true,
                fullscreen: true
            }).then(function (response) {
                if (response) {
                    $mdToast.show({
                        hideDealy: 10000,
                        position: 'bottom right',
                        controller: 'ToastCtrl',
                        templateUrl: 'user/userSavedToast.html',
                        locals: {credentials: response, orgs: $scope.orgs, admin: $scope.user}
                    });
                    var k = 0;
                    for (k = 0; k < $scope.userView.length; k++) {
                        if ($scope.userView[k].id === response.id) {
                            $scope.userView[k].firstName = response.firstname;
                            $scope.userView[k].lastName = response.lastname;
                            $scope.userView[k].firstname = response.firstname;
                            $scope.userView[k].lastname = response.lastname;
                            $scope.userView[k].phone = response.phone;
                            $scope.userView[k].userType = response.userType;
                        }
                    }
                    for (k = 0; k < $scope.users.length; k++) {
                        if ($scope.users[k].id === response.id) {
                            $scope.users[k].firstName = response.firstname;
                            $scope.users[k].firstname = response.firstname;
                            $scope.users[k].phone = response.phone;
                            $scope.users[k].lastName = response.lastname;
                            $scope.users[k].lastname = response.lastname;
                            $scope.users[k].userType = response.userType;
                        }
                    }
                }
            });
        };
        $scope.emailChanged = function () {
            $scope.emailExists = false;
        };
        $scope.attemptedEmails = [];
        $scope.checkEmailDupe = function () {
            if (helper.containsEmail({
                    email: $scope.credentials.email
                }, $scope.attemptedEmails)) {
                $scope.emailExists = true;
            } else {
                $scope.emailExists = false;
            }
        };
        $scope.finalizeAdd = function (u) {
            $mdToast.show({
                hideDealy: 10000,
                position: 'bottom right',
                controller: 'ToastCtrl',
                templateUrl: 'user/userAddedToast.html',
                locals: {credentials: u, orgs: $scope.orgs, admin: $scope.user}
            });
            $scope.credentials = {
                org: $scope.user.org,
                initiator: false,
                access: false,
                signer: false,
                transfer: false
            };
            $scope.credentials.phone = $scope.user.org.phone;
            $scope.addUser.aform.$setPristine();
            $scope.addUser.aform.$setUntouched();
            $scope.addSubmitted = false;
        };
        $scope.smsUnique = true;
        $scope.smsChange = function () {
            $scope.smsUnique = true;
        };
        $scope.addBetaUser = function (valid, ev) {
            $scope.addSubmitted = true;
            if (valid) {
                if ($scope.useSms) {
                    userService.checkSMSUnique($scope.credentials.cell_phone).then(function (r) {
                        console.log(r.data);
                        if (r.data.unique) {
                            $scope.credentials.org_id = $localStorage.pref_org_id;
                            $scope.credentials.email = undefined;
                            if ($scope.credentials.admin) {
                                var confirm = $mdDialog.confirm().title('Add ' + $scope.credentials.firstName + ' as admin?').textContent("You are about to add this user as admin.  Please confirm this choice").targetEvent(ev).ok("Yes, add admin.").cancel("Cancel");
                                $mdDialog.show(confirm).then(function () {
                                    $scope.isProcessing = true;
                                    $scope.myPromise2 = userService.addUser($scope.credentials).then(function (response) {
                                        $scope.isProcessing = false;
                                        $scope.finalizeAdd(response.data);
                                    }, function () {
                                        $scope.isProcessing = false;
                                    });
                                }, function () {
                                    $scope.isProcessing = false;
                                    $scope.credentials.admin = false;
                                });
                            } else {
                                $scope.isProcessing = true;
                                $scope.myPromise2 = userService.addUser($scope.credentials).then(function (response) {
                                        $scope.isProcessing = false;
                                        $scope.finalizeAdd(response.data);
                                    },
                                    function () {
                                        $scope.isProcessing = false;
                                    }
                                );
                            }
                        } else {
                            $scope.smsUnique = false;
                        }
                    });
                } else {
                    userService.checkEmail($scope.credentials, $scope).then(function () {
                        $scope.credentials.org_id = $localStorage.pref_org_id;
                        $scope.credentials.cell_phone = undefined;
                        if ($scope.credentials.admin) {
                            var confirm = $mdDialog.confirm().title('Add ' + $scope.credentials.firstName + ' as admin?').textContent("You are about to add this user as admin.  Please confirm this choice").targetEvent(ev).ok("Yes, add admin.").cancel("Cancel");
                            $mdDialog.show(confirm).then(function () {
                                $scope.isProcessing = true;
                                $scope.myPromise2 = userService.addUser($scope.credentials).then(function (response) {
                                    $scope.isProcessing = false;
                                    $scope.finalizeAdd(response.data);
                                }, function () {
                                    $scope.isProcessing = false;
                                });
                            }, function () {
                                $scope.isProcessing = false;
                                $scope.credentials.admin = false;
                            });
                        } else {
                            $scope.isProcessing = true;
                            $scope.myPromise2 = userService.addUser($scope.credentials).then(function (response) {
                                    $scope.isProcessing = false;
                                    $scope.finalizeAdd(response.data);
                                },
                                function () {
                                    $scope.isProcessing = false;
                                }
                            );
                        }
                    });
                }
            }
        };
        $scope.$on('$routeChangeSuccess', function () {
            reinit();
        });
        $scope.$on('$locationChangeSuccess', function () {
            reinit();
        });
        $scope.credentials = {
            org: $scope.user.org,
            initiator: false,
            access: false,
            signer: false,
            transfer: false
        };
        $scope.orgChanged = function () {
        };
        $scope.orgTxtChanged = function () {
        };
        $scope.searchOrgs = function (orgSearch) {
            var ret = [];
            if (orgSearch === undefined || orgSearch.length === 0) {
                orgSearch = "";
            } else {
                for (var i = 0; i < $scope.orgs.length; i++) {
                    if ($scope.orgs[i].name.toLowerCase().indexOf(orgSearch.toLowerCase()) >= 0) {
                        ret.push($scope.orgs[i]);
                    }
                }
            }
            return ret;
        };
        $scope.roleSelected = function () {
            if ($scope.credentials.usertype == 1) {
                $scope.credentials.access = true;
                $scope.credentials.signer = false;
                $scope.credentials.initiator = true;
                $scope.credentials.transfer = true;
                $scope.credentials.admin = false;
            } else if ($scope.credentials.usertype == 2) {
                $scope.credentials.access = true;
                $scope.credentials.signer = false;
                $scope.credentials.initiator = true;
                $scope.credentials.transfer = true;
                $scope.credentials.admin = false;
            } else if ($scope.credentials.usertype == 3) {
                $scope.credentials.access = true;
                $scope.credentials.signer = false;
                $scope.credentials.initiator = true;
                $scope.credentials.transfer = true;
                $scope.credentials.admin = false;
            } else if ($scope.credentials.usertype == 4) {
                $scope.credentials.access = true;
                $scope.credentials.signer = true;
                $scope.credentials.initiator = true;
                $scope.credentials.transfer = true;
                $scope.credentials.admin = false;
            } else if ($scope.credentials.usertype == 5) {
                $scope.credentials.access = true;
                $scope.credentials.signer = false;
                $scope.credentials.initiator = false;
                $scope.credentials.transfer = false;
                $scope.credentials.admin = true;
            } else if ($scope.credentials.usertype == 6) {
                $scope.credentials.access = true;
                $scope.credentials.signer = false;
                $scope.credentials.initiator = false;
                $scope.credentials.transfer = true;
                $scope.credentials.admin = false;
            } else if ($scope.credentials.usertype == 7) {
                $scope.credentials.access = true;
                $scope.credentials.signer = false;
                $scope.credentials.initiator = false;
                $scope.credentials.transfer = true;
                $scope.credentials.admin = false;
            }
        };
    };
    UserMgrCtrl.$inject = ['$scope', 'user', '$location', '$routeParams', 'userService', '$mdDialog', 'helper', '$mdToast', '$document', '$localStorage'];
    return UserMgrCtrl;
});

