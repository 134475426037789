define('data-entry/data-entry.module',['angular', './data-entry.routes', './data-entry.controllers', './data-entry.services'], function(angular, routes, controllers) {
    'use strict';
    var mod = angular.module('com.cd.dataEntry', ['ngRoute', 'data-entry.routes', 'data-entry.services']);
    mod.controller('MatchDetailsCtrl', controllers.MatchDetailsCtrl);
    mod.controller('RejectPolstCtrl', controllers.RejectPolstCtrl);
    mod.controller('ToastSuccessCtrl', controllers.ToastSuccessCtrl);
    mod.controller('ArchiveOrPublishPolstCtrl', controllers.ArchiveOrPublishPolstCtrl);
    mod.controller('SelectAFPatientCtrl', controllers.SelectAFPatientCtrl);
    mod.controller('SelectMDCtrl', controllers.SelectMDCtrl);
    mod.controller('PublishOrArchiveAdCtrl', controllers.PublishOrArchiveAdCtrl);
    mod.controller('ReviewPatientAlertCtrl', controllers.ReviewPatientAlertCtrl);
    return mod;
});
