define('acp-billing/acp-signup.controller',['angular'], function (angular) {
    'use strict';
    var ACPSignupCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                  userService, dataEntryService, $window, $mdDialog, $sce, helper, analyticsService,
                                  Papa, acpBillingService) {
        // Custom styling can be passed to options when creating an Element.
        var style = {
            base: {
                // Add your base input styles here. For example:
                fontSize: '16px',
                color: "#32325d",
            }
        };
        $scope.quantity = "";
        // Create an instance of the card Element.
        var card = window.stripeElements.create('card', {style: style});
        // Add an instance of the card Element into the `card-element` <div>.
        card.mount('#card-element');

        card.addEventListener('change', function(event) {
            var displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });
        function stripeTokenHandler(token) {
            // Insert the token ID into the form so it gets submitted to the server
            var form = document.getElementById('payment-form');
            var hiddenInput = document.createElement('input');
            hiddenInput.setAttribute('type', 'hidden');
            hiddenInput.setAttribute('name', 'stripeToken');
            hiddenInput.setAttribute('value', token.id);
            form.appendChild(hiddenInput);
            // hit java API
            $scope.myPromise = acpBillingService.subscribeCustomer(token.id, $scope.quantity).then(function() {

            });
        }
        // Create a token or display an error when the form is submitted
        var form = document.getElementById('payment-form');
        form.addEventListener('submit', function(event) {
            event.preventDefault();
            window.stripe.createToken(card).then(function(result) {
                if (result.error) {
                    // Inform the customer that there was an error.
                    var errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                }
        if(window.stripe === undefined){
            window.alert('Error loading Stripe, please contact support@caredirectives.org');
        } else {
            // Create an instance of the card Element.
            var card = window.stripeElements.create('card', {style: style});

            // Add an instance of the card Element into the `card-element` <div>.
            card.mount('#card-element');

            card.addEventListener('change', function (event) {
                var displayError = document.getElementById('card-errors');
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }
            });
        }
        });
        $scope.$on("$destroy", function() {
            // if (timer) {
            //     $timeout.cancel(timer);
            // }
        });
        // function stripeTokenHandler(token) {
        //         // Insert the token ID into the form so it gets submitted to the server
        //         var form = document.getElementById('payment-form');
        //         var hiddenInput = document.createElement('input');
        //         hiddenInput.setAttribute('type', 'hidden');
        //         hiddenInput.setAttribute('name', 'stripeToken');
        //         hiddenInput.setAttribute('value', token.id);
        //         form.appendChild(hiddenInput);
        //         // Submit the form
        //         // form.submit();
        //         // TODO, use our own service to hit the Java API
        //         acpBillingService.makePayment(token.id).then(function () {
        //             console.log("ran service call");
        //         });
        //     }
        //     // Create a token or display an error when the form is submitted
        //     var form = document.getElementById('payment-form');
        //     form.addEventListener('submit', function (event) {
        //         event.preventDefault();
        //
        //         window.stripe.createToken(card).then(function (result) {
        //             if (result.error) {
        //                 // Inform the customer that there was an error.
        //                 var errorElement = document.getElementById('card-errors');
        //                 errorElement.textContent = result.error.message;
        //             } else {
        //                 // Send the token to your server.
        //                 stripeTokenHandler(result.token);
        //             }
        //         });
        //     });
         });
    };
    ACPSignupCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'analyticsService', 'Papa', 'acpBillingService'];
    return ACPSignupCtrl;
});

