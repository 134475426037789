define('acp-billing/add-addendum.controller',['angular'], function (angular) {
    'use strict';
    var AddAddendumCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                         userService, $window, $mdDialog, $sce, helper, acpBillingService, visit) {
        $scope.visit = visit;
        $scope.editPatient = function (valid, ev) {
            $scope.addSubmitted = true;
            $scope.myPromise = acpBillingService.addAddendum($scope.visit.id, $scope.note).then(function (r) {
                $mdDialog.hide({note: $scope.note});
            });
        };
        $scope.cancel = function () {
            $mdDialog.hide();
        };
    };
    AddAddendumCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', '$window', '$mdDialog', '$sce', 'helper', 'acpBillingService', 'visit'];
    return AddAddendumCtrl;
});
