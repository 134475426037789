define('user/setpw.controller',['angular'], function (angular) {
    'use strict';
    var SetPWCtrl = function ($scope, $routeParams, userService, $location, $mdDialog) {
        $scope.isProd = window.isProd;
        $scope.credentials = {};
        $scope.mismatch = false;
        $scope.verified = false;
        $scope.invalidLink = false;
        $scope.error = false;
        $scope.$on('$routeChangeSuccess', function () {
            $scope.credentials.key = $routeParams.key;
            $scope.credentials.email = $routeParams.email;
            if($routeParams.change ==='1' || $routeParams.change === 1){
                $mdDialog.show({
                    controller: 'PwUpdateCtrl',
                    templateUrl: 'user/pw-update.html',
                    parent: document.body,
                    clickOutsideToClose: true,
                    fullscreen: true
                }).then(function (response) {
                });
            }
            userService.verifyPassswordLink({email: $routeParams.email, key: $routeParams.key}, $scope).then(function (r) {
                $scope.verified = true;
                if (r === undefined || !r.valid) {
                    $scope.invalidLink = true;
                }
            }, function(e){
                $scope.verified = true;
                $scope.invalidLink = true;
            });
        });
        $scope.passwordUpdate = function () {
            $scope.mismatch = false;
            $scope.error = false;
            $scope.pwUsed = false;
        };
        userService.reset();
        $scope.error = false;
        $scope.pwUsed = false;
        $scope.setPassword = function (valid) {
            $scope.regSubmitted = true;
            if (valid && $scope.credentials.password === $scope.credentials.password2) {
                $scope.myPromise = userService.setPassword($scope.credentials, $scope).then(function () {
                    if (!$scope.error) {
                        $location.search({});
                        $location.path("dashboard");
                    }
                });
            } else if ($scope.credentials.password !== $scope.credentials.password2) {
                $scope.mismatch = true;
            }
        };
    };
    SetPWCtrl.$inject = ['$scope', '$routeParams', 'userService', '$location', '$mdDialog'];
    return SetPWCtrl;
});
