define('ice/ice-delete-patient.controller',['angular'], function (angular) {
    'use strict';
    var IceDeletePatientCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document, userService, dataEntryService, $window,
                                         $mdDialog, $sce, helper, analyticsService, user, Papa, iceService) {
        $scope.user = userService.getUser();
        $scope.cancel = function () {
            $mdDialog.hide();
        };

    };
    IceDeletePatientCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'analyticsService', 'user', 'Papa', 'iceService'];
    return IceDeletePatientCtrl;
});
