define('data-entry/reject-polst.controller',['angular'], function (angular) {
    'use strict';
    var RejectPolstCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                    userService, dataEntryService, $window, $mdDialog, $sce, helper, afform, pid, afid, af) {
        $scope.user = userService.getUser();
        $scope.mdName = afform.provName;
        $scope.formType = afform.form_type;
        if($scope.formType !== 'AD'){
            $scope.formType = 'POLST';
        }
        $scope.pid = pid;
        $scope.reasoning = '';
        $scope.params = {nullify: true};
        $scope.mdSigMissing = $scope.ptSigMissing = $scope.demoMissing = $scope.sigMissing = $scope.dobMissing = $scope.dateMissing = false;
        $scope.cancel = function () {
            $mdDialog.hide();
        };
        $scope.rejecting = false;
        $scope.reject = function () {
            if(!$scope.rejecting){
                $scope.rejecting = true;
                dataEntryService.rejectABBYYForm($scope.user.sessionID, afid, $scope.reasoning, $scope.mdSigMissing, $scope.ptSigMissing, $scope.demoMissing, $scope.pid, $scope.params.nullify, afform, af, $scope.sigMissing, $scope.dobMissing, $scope.dateMissing).then(function (response) {
                    $scope.rejecting = false;
                    $mdDialog.hide({rejected: true});
                }, function(e){
                    $scope.rejecting = false;
                });
            }
        };
    };
    RejectPolstCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'afform', 'pid', 'afid', 'af'];
    return RejectPolstCtrl;
});

