/**
 * Dashboard routes.
 */
define('ice/ice.routes',['angular', './ice.controllers', 'common'], function (angular, controllers) {
    'use strict';
    var mod = angular.module('ice.routes', ['com.cd.common']);
    mod
        .config([
            '$routeProvider',
            'userResolve',
            'adminResolve',
            'physResolve',
            'acpBillingResolve',
            'dataResolve',
            '$locationProvider',
            function ($routeProvider, userResolve, adminResolve, physResolve, acpBillingResolve, dataResolve, $locationProvider) {
                $locationProvider.hashPrefix('');
                $routeProvider.when(
                    '/ice-dashboard', {
                        templateUrl: 'ice/ice-dashboard.html',
                        controller: controllers.IceDashboardCtrl,
                        reloadOnSearch: false,
                        resolve: userResolve
                    }).when('/ice-patient', {
                        templateUrl: 'ice/ice-patient.html',
                        controller: controllers.IceNewPatientCtrl,
                        reloadOnSearch: false,
                        resolve: userResolve
                });
            }]);
    return mod;
});

