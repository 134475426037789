/**
 * Dashboard routes.
 */
define('data-entry/data-entry.routes',['angular', './data-entry.controllers', 'common'], function (angular, controllers) {
    'use strict';

    var mod = angular.module('data-entry.routes', ['com.cd.common']);
    mod
        .config([
            '$routeProvider',
            'userResolve',
            'adminResolve',
            'physResolve',
            'dataResolve',
            '$locationProvider',
            function ($routeProvider, userResolve, adminResolve, physResolve, dataResolve, $locationProvider) {
                $locationProvider.hashPrefix('');
                $routeProvider.when(
                    '/data-entry/list', {
                        templateUrl: 'data-entry/list-view.html',
                        controller: controllers.DataEntryListCtrl,
                        reloadOnSearch: false,
                        resolve: dataResolve
                    }).when('/data-entry/patient-matching', {
                    templateUrl: 'data-entry/madie-patient-matching.html',
                    controller: controllers.MadiePatientMatchingCtrl,
                    resolve: dataResolve
                }).when('/data-entry/verify-ad', {
                    templateUrl: 'data-entry/ad-verify.html',
                    reloadOnSearch: false,
                    controller: controllers.ADVerifyCtrl,
                    resolve: dataResolve
                }).when('/data-entry/ad-list', {
                    templateUrl: 'data-entry/ad-list-view.html',
                    controller: controllers.ADDataListViewCtrl,
                    resolve: dataResolve
                }).when('/adupload', {
                    templateUrl: 'data-entry/adupload.html',
                    controller: controllers.ADUploadCtrl,
                    resolve: dataResolve
                }).when(
                    '/data-entry/upload-csv', {
                        templateUrl: 'data-entry/upload-csv.html',
                        controller: controllers.DataEntryUploadCsvCtrl,
                        reloadOnSearch: false,
                        resolve: dataResolve
                    }).when(
                    '/data-entry/upload', {
                        templateUrl: 'data-entry/upload-view.html',
                        controller: controllers.DataEntryUploadCtrl,
                        reloadOnSearch: false,
                        resolve: dataResolve
                    }).when(
                    '/data-entry/verify', {
                        templateUrl: 'data-entry/verify-view.html',
                        controller: controllers.DataEntryVerifyCtrl,
                        reloadOnSearch: false,
                        resolve: dataResolve
                    });
            }]);
    return mod;
});

