define('home/idle.controller',['angular'], function (angular) {
    'use strict';
    var IdleCtrl = function ($scope, countdown, $mdDialog) {
        $scope.countdown = countdown;
        $scope.closeDialog = function () {
        };
        $scope.logout = function () {
        };
        function updateCoutndown(countdown){
            console.log('update ', countdown);
            $scope.countdown = countdown;
        }
    };
    IdleCtrl.$inject = ['$scope', 'countdown', '$mdDialog'];
    return IdleCtrl;
});
