/**
 * Home routes.
 */
define('home/home.routes',['angular', './home.controllers', 'common'], function (angular, controllers) {
    'use strict';
    var mod = angular.module('home.routes', ['com.cd.common']);
    mod.config(['$routeProvider', '$locationProvider', function ($routeProvider, $locationProvider) {
        $locationProvider.hashPrefix('');
        $routeProvider.when('/', {
            templateUrl: 'home/home.html',
            controller: controllers.HomeCtrl
        }).when('/contact', {
            templateUrl: 'home/contact.html',
            controller: controllers.ContactCtrl
        }).when('/agreement', {
            templateUrl: 'home/agreement-link.html',
            controller: controllers.AgreementLinkCtrl
        }).when('/privacy', {
            templateUrl: 'home/privacy.html',
            controller: controllers.PrivacyCtrl
        }).when('/terms', {templateUrl: 'home/terms.html', controller: controllers.TermsCtrl}).otherwise({
            templateUrl: 'home/notFound.html'
        });
    }]);
    return mod;
});

