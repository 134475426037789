define('dashboard/user-details.controller',['angular'], function (angular) {
    'use strict';
    var UserDetailsCtrl = function ($scope, $mdDialog, user, helper, orgs, userService, admin) {
        $scope.selected = [];
        $scope.selectedItem = {};
        $scope.orgs = orgs;
        $scope.attemptedEmails = [];
        $scope.user = angular.copy(user);
        $scope.admin = admin;
        $scope.types = helper.getTypes();
        if(admin.is_super_user){
            $scope.types.push({id: 8, name: 'Data Entry'});
        }
        $scope.user.lastName = $scope.user.lastname;
        $scope.user.firstName = $scope.user.firstname;
        $scope.user.userphone = $scope.user.phone;
        $scope.addBetaUser = function (valid, ev) {
            $scope.addSubmitted = true;
            if (valid) {
                $scope.isProcessing = true;
                $scope.myPromise = userService.editUser($scope.user).then(function (response) {
                        $scope.isProcessing = false;
                        $mdDialog.hide(response.data);
                    },
                    function () {
                        $scope.isProcessing = false;
                    }
                );
            }
        };
        $scope.roleSelected = function () {
            if ($scope.user.usertype == 1) {
                $scope.user.access = true;
                $scope.user.signer = false;
                $scope.user.initiator = true;
                $scope.user.transfer = true;
                $scope.user.admin = false;
            } else if ($scope.user.usertype == 2) {
                $scope.user.access = true;
                $scope.user.signer = false;
                $scope.user.initiator = true;
                $scope.user.transfer = true;
                $scope.user.admin = false;
            } else if ($scope.user.usertype == 3) {
                $scope.user.access = true;
                $scope.user.signer = false;
                $scope.user.initiator = true;
                $scope.user.transfer = true;
                $scope.user.admin = false;
            } else if ($scope.user.usertype == 4) {
                $scope.user.access = true;
                $scope.user.signer = true;
                $scope.user.initiator = true;
                $scope.user.transfer = true;
                $scope.user.admin = false;
            } else if ($scope.user.usertype == 5) {
                $scope.user.access = true;
                $scope.user.signer = false;
                $scope.user.initiator = false;
                $scope.user.transfer = false;
                $scope.user.admin = true;
            } else if ($scope.user.usertype == 6) {
                $scope.user.access = true;
                $scope.user.signer = false;
                $scope.user.initiator = false;
                $scope.user.transfer = true;
                $scope.user.admin = false;
            } else if ($scope.user.usertype == 7) {
                $scope.user.access = true;
                $scope.user.signer = false;
                $scope.user.initiator = false;
                $scope.user.transfer = true;
                $scope.user.admin = false;
            } else if($scope.user.usertype == 8){
                $scope.user.access = true;
                $scope.user.signer = false;
                $scope.user.initiator = false;
                $scope.user.transfer = true;
                $scope.user.admin = false;

            }
        };
        for (var i = 0; i < $scope.types.length; i++) {
            if ($scope.user.userType == $scope.types[i].name) {
                $scope.user.usertype = $scope.types[i].id.toString();
                $scope.roleSelected();
            }
        }
        $scope.removeOrg = function (o) {
            var tmp = [];
            for (var j = 0; j < $scope.user.organizations.length; j++) {
                if (o.id == $scope.user.organizations[j].id) {
                    $scope.user.organizations.splice(j, 1);
                }
            }
        };
        $scope.orgChanged = function () {
            if ($scope.org !== undefined && $scope.org !== null) {
                $scope.removeOrg($scope.org);
                $scope.user.organizations.push($scope.org);
                $scope.org = undefined;
                $scope.orgSearch = "";
            }
        };
        $scope.cancel = function () {
            $mdDialog.hide();
        };
        $scope.searchOrgs = function (orgSearch) {
            var ret = [];
            if (orgSearch === undefined || orgSearch.length === 0) {
                orgSearch = "";
            } else {
                for (var i = 0; i < $scope.orgs.length; i++) {
                    if ($scope.orgs[i].name.toLowerCase().indexOf(orgSearch.toLowerCase()) >= 0) {
                        ret.push($scope.orgs[i]);
                    }
                }
            }
            return ret;
        };
    };
    UserDetailsCtrl.$inject = ['$scope', '$mdDialog', 'user', 'helper', 'orgs', 'userService', 'admin'];
    return UserDetailsCtrl;
});

