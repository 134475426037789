/**
 * Dashboard controllers.
 */
define('ice/ice.controllers',['angular', './ice-dashboard.controller', './ice-patient.controller', './ice-delete-patient.controller'],
    function (angular, IceDashboardCtrl, IceNewPatientCtrl, IceDeletePatientCtrl) {
        'use strict';
        return {
            IceDashboardCtrl: IceDashboardCtrl,
            IceNewPatientCtrl: IceNewPatientCtrl,
            IceDeletePatientCtrl: IceDeletePatientCtrl
        };
    });

