/**
 * Common functionality.
 */
define(
    'common/main',['angular', './services/helper', './services/playRoutes', './filters', './directives/example'],
    function (angular) {
        'use strict';

        return angular
            .module('com.cd.common', ['common.helper', 'common.playRoutes', 'common.filters', 'common.directives.example', 'ngMaterial', 'pascalprecht.translate'])
            .config(
                [
                    '$mdThemingProvider',
                    '$translateProvider',
                    '$locationProvider',
                    function ($mdThemingProvider, $translateProvider, $locationProvider) {
                        // $locationProvider.html5Mode({enabled: true, requireBase: true});
                        $mdThemingProvider.definePalette('customPrimary', {
                            '50': 'eaf6f4',
                            '100': 'cbe7e3',
                            '200': 'a9d8d1',
                            '300': '87c8be',
                            '400': '6dbcb0',
                            '500': '53b0a2',
                            '600': '4ca99a',
                            '700': '42a090',
                            '800': '399786',
                            '900': '298775',
                            'A100': 'cafff4',
                            'A200': '97ffea',
                            'A400': '64ffe0',
                            'A700': '4bffdb',
                            'contrastDefaultColor': 'light',
                            'contrastDarkColors': [
                                '50',
                                '100',
                                '200',
                                '300',
                                '400',
                                '500',
                                '600',
                                '700',
                                'A100',
                                'A200',
                                'A400',
                                'A700'
                            ],
                            'contrastLightColors': [
                                '800',
                                '900'
                            ]
                        });
                        $mdThemingProvider.definePalette('customSecondary', {
                            '50': 'fcf0ea',
                            '100': 'f8d9ca',
                            '200': 'f4c0a7',
                            '300': 'efa684',
                            '400': 'eb9369',
                            '500': 'e8804f',
                            '600': 'e57848',
                            '700': 'e26d3f',
                            '800': 'de6336',
                            '900': 'd85026',
                            'A100': 'ffffff',
                            'A200': 'ffe8e1',
                            'A400': 'ffbfae',
                            'A700': 'ffab95',
                            'contrastDefaultColor': 'light',
                            'contrastDarkColors': [
                                '50',
                                '100',
                                '200',
                                '300',
                                '400',
                                '500',
                                '600',
                                '700',
                                '800',
                                'A100',
                                'A200',
                                'A400',
                                'A700'
                            ],
                            'contrastLightColors': [
                                '900'
                            ]
                        });
                        $mdThemingProvider.definePalette('darkBlue', {
                            '50': 'e3e8ec',
                            '100': 'b8c7d0',
                            '200': '89a1b0',
                            '300': '5a7b90',
                            '400': '365f79',
                            '500': '134361',
                            '600': '113d59',
                            '700': '0e344f',
                            '800': '0b2c45',
                            '900': '061e33',
                            'A100': '6cafff',
                            'A200': '3993ff',
                            'A400': '0677ff',
                            'A700': '006bec',
                            'contrastDefaultColor': 'light',
                            'contrastDarkColors': [
                                '50',
                                '100',
                                '200',
                                'A100',
                                'A200'
                            ],
                            'contrastLightColors': [
                                '300',
                                '400',
                                '500',
                                '600',
                                '700',
                                '800',
                                '900',
                                'A400',
                                'A700'
                            ]
                        });
                        $mdThemingProvider.definePalette('darkblue2', {
                            '50': 'e0edf2',
                            '100': 'b3d2de',
                            '200': '80b4c8',
                            '300': '4d96b2',
                            '400': '2680a2',
                            '500': '006991',
                            '600': '006189',
                            '700': '00567e',
                            '800': '004c74',
                            '900': '003b62',
                            'A100': '92cbff',
                            'A200': '5fb3ff',
                            'A400': '2c9bff',
                            'A700': '138fff',
                            'contrastDefaultColor': 'light',
                            'contrastDarkColors': [
                                '50',
                                '100',
                                '200',
                                '300',
                                'A100',
                                'A200',
                                'A400'
                            ],
                            'contrastLightColors': [
                                '400',
                                '500',
                                '600',
                                '700',
                                '800',
                                '900',
                                'A700'
                            ]
                        });
                        // $mdThemingProvider.theme('default').primaryPalette('deep-purple').accentPalette('light-blue');
                        $mdThemingProvider.theme('default').primaryPalette('darkblue2').accentPalette('deep-orange');
                        var errorToast = $mdThemingProvider.extendPalette('red', {});
                        $mdThemingProvider.definePalette('error-toast', errorToast);
                        $mdThemingProvider.theme("error-toast");
                        $translateProvider
                            .translations(
                                'en',
                                {
                                    hello: "Hello world",
                                    Why_should_I_Fill_Out_a_POLST_Form: "Why should I Fill Out a <b>POLST</b> Form?",
                                    Create_your_POLST_form: "Create your POLST form",
                                    create_your_account: 'Create Your Account',
                                    edit_your_account: 'Edit Your Account',
                                    submit: 'Submit',
                                    given_name: "First Name",
                                    family_name: "Last Name",
                                    next_step: 'Next Step',
                                    additional_name: "Middle Name",
                                    polst_p1: "When you are seriously ill, making sure your loved ones and doctors know what kinds of medical treatment you want as you approach the end of your life is very important. Physician Orders for Life-Sustaining Treatment (POLST) can help do just that.",
                                    polst_p2: "POLST is a form that clearly states what kinds of medical treatment patients want toward the end of their lives. Printed on bright pink paper, and signed by both a doctor and patient, POLST helps give seriously-ill patients more control over their care.",
                                    polst_p3: "POLST also helps you talk with your healthcare team and your loved ones about your choices. In this way, POLST can help reduce patient and family suffering, and make sure that your wishes are known and honored.",
                                    get_started: 'Get Started',
                                    choose_your_language: 'Choose Your Language',
                                    cancel: "cancel",
                                    Edit_your_POLST_form: "Edit your POLST form",
                                    HIPAA_disclosure_warning: "HIPAA PERMITS DISCLOSURE OF POLST TO OTHER HEALTHCARE PROVIDERS AS NECESSARY",
                                    physician_contact_warning: 'First follow these orders, then contact physician.',
                                    legality_section_warning: 'A copy of the signed POLST form is a legally valid physician order. Any section not completed implies full treatment for that section.',
                                    advance_directive_warning: 'POLST complements an Advance Directive and is not intended to replace that document.',
                                    Patient_First_Name: "Patient First Name",
                                    Patient_Last_Name: "Patient Last Name",
                                    Patient_Middle_Name: "Patient Middle Name",
                                    Patient_Date_of_Birth: "Patient Date of Birth",
                                    Medical_Record_Number: "Medical Record #",
                                    Date_Form_Prepared: "Date Form Prepared",
                                    birth_date: "Date of Birth",
                                    gender: "Gender",
                                    check_one: "Check One",
                                    A: "A",
                                    B: "B",
                                    C: "C",
                                    D: "D",
                                    cpr_header: "Cardiopulmonary Resuscitation (CPR)",
                                    cpr_warning1: "If patient has no pulse and is not breathing",
                                    cpr_warning2: "If patient is NOT in cardiopulmonary arrest, follow orders in Sections B and C.",
                                    cpr_option1: "<b>Attempt Resuscitation/CPR</b> (Selecting CPR in Section A <b><u>requires selecting</u></b> Full Treatment in Section B)",
                                    cpr_option2: "<b>Do Not Attempt Resuscitation/DNR</b> (Allow Natural Death)",
                                    medical_interventions_header: "Medical Interventions:",
                                    medical_interventions_warning: "If patient is found with a pulse and/or is breathing",
                                    medical_interventions_option1: "<b><u>Full Treatment</u> – primary goal of prolonging life by all medically effective means.</b><br />In addition to treatment described in Selective Treatment and Comfort-Focused Treatment, use intubation, advanced airway interventions, mechanical ventilation, and cardioversion as indicated.",
                                    medical_interventions_option1a: "<b>Trial Period of Full Treatment.</b>",
                                    medical_interventions_option2: "<b><u>Selective Treatment</u> – goal of treating medical conditions while avoiding burdensome measures.</b> In addition to treatment described in Comfort-Focused Treatment, use medical treatment, IV antibiotics, and IV fluids as indicated. Do not intubate. May use non-invasive positive airway pressure. Generally avoid intensive care.",
                                    medical_interventions_option2a: "<b>Request transfer to hospital <u>only</u></b> if comfort needs cannot be met in current location.",
                                    medical_interventions_option3: "<b><u>Comfort-Focused Treatment</u> – primary goal of maximizing comfort.</b> Relieve pain and suffering with medication by any route as needed; use oxygen, suctioning, and manual treatment of airway obstruction. Do not use treatments listed in Full and Selective Treatment unless consistent with comfort goal. <b><i>Request transfer to hospital <u>only</u> if comfort needs cannot be met in current location.</i></b>",
                                    additional_orders: "Additional Orders",
                                    artificial_nutrition_heading: "Artificially Administered Nutrition:",
                                    artificial_nutrition_warning: "Offer food by mouth if feasible and desired.",
                                    artificial_nutrition_option1: "Long-term artificial nutrition, including feeding tubes.",
                                    artificial_nutrition_option2: "Trial period of artificial nutrition, including feeding tubes.",
                                    artificial_nutrition_option3: "No artificial means of nutrition, including feeding tubes.",
                                    signatures_header: "Information and Signatures:",
                                    discussed_with: "Discussed with:",
                                    discussed_with_patient: "Patient (Patient Has Capacity)",
                                    discussed_with_decisionmaker: "Legally Recognized Decisionmaker",
                                    ad_option1: "Advance Directive dated",
                                    available_and_reviewed: "available and reviewed",
                                    ad_option2: "Advance Directive not available",
                                    ad_option3: "No Advance Directive",
                                    healthcare_agent: "Healthcare Agent if named in Advance Directive:",
                                    name: "Name",
                                    phone: "Phone",
                                    physician_signature_header: "Signature of Physician",
                                    physician_legal: "My signature below indicates to the best of my knowledge that these orders are consistent with the patient’s medical condition and preferences.",
                                    patient_signature_header: "Signature of Patient or Legally Recognized Decisionmaker",
                                    patient_signature_legal: "I am aware that this form is voluntary. By signing this form, the legally recognized decisionmaker acknowledges that this request regarding resuscitative measures is consistent with the known desires of, and with the best interest of, the patient who is the subject of the form. Your POLST is being added to a secure electronic registry to be accessible by health providers, as permitted by HIPAA.",
                                    physician_name: 'Physician Name',
                                    physician_phone: 'Physician Phone Number',
                                    physician_license: 'License Number',
                                    physician_signature: 'Physician Signature',
                                    date: 'Date',
                                    legal_rep_name: 'Print Name',
                                    legal_rep_phone: 'Phone Number',
                                    legal_rep_relationship: 'Relationship',
                                    legal_rep_signature: 'Signature',
                                    legal_rep_address: 'Mailing Address',
                                    street: 'Street',
                                    state: 'State',
                                    city: 'City',
                                    zip: 'Zip',
                                    male: "Male",
                                    female: "Female",
                                    patient_information: 'Patient Information',
                                    Name_last_first_middle: "Name (last, first, middle):",
                                    Healthcare_Provider_Assisting_with_Form_Preparation: "Healthcare Provider Assisting with Form Preparation",
                                    not_completed_by_physician: " N/A if POLST is completed by signing physician",
                                    title: 'Title',
                                    none: 'None',
                                    additional_contact: 'Additional Contact',
                                    provider_directions: "Directions for Healthcare Provider",
                                    completing_POLST: "Completing POLST",
                                    completing_option1: "Completing a POLST form is voluntary. California law requires that a POLST form be followed by healthcare providers, and provides immunity to those who comply in good faith. In the hospital setting, a patient will be assessed by a physician who will issue appropriate orders that are consistent with the patient’s preferences.",
                                    completing_option2: "POLST does not replace the Advance Directive. When available, review the Advance Directive and POLST form to ensure consistency, and update forms appropriately to resolve any conflicts.",
                                    completing_option3: "POLST must be completed by a healthcare provider based on patient preferences and medical indications.",
                                    completing_option4: "A legally recognized decisionmaker may include a court-appointed conservator or guardian, agent designated in an Advance Directive, orally designated surrogate, spouse, registered domestic partner, parent of a minor, closest available relative, or person whom the patient’s physician believes best knows what is in the patient’s best interest and will make decisions in accordance with the patient’s expressed wishes and values to the extent known.",
                                    completing_option5: "A legally recognized decisionmaker may execute the POLST form only if the patient lacks capacity or has designated that the decisionmaker’s authority is effective immediately.",
                                    completing_option6: "POLST must be signed by a physician and the patient or decisionmaker to be valid. Verbal orders are acceptable with follow-up signature by physician in accordance with facility/community policy.",
                                    completing_option7: "If a translated form is used with patient or decisionmaker, attach it to the signed English POLST form.",
                                    completing_option8: "Use of original form is strongly encouraged. Photocopies and FAXes of signed POLST forms are legal and valid. A copy should be retained in patient’s medical record, on Ultra Pink paper when possible",
                                    using_POLST: "Using POLST",
                                    reviewing_POLST: "Reviewing POLST",
                                    modifying_POLST: "Modifying and Voiding POLST",
                                    section_a: 'Section A',
                                    section_b: 'Section B',
                                    using_option1: "Any incomplete section of POLST implies full treatment for that section.",
                                    using_option2: "If found pulseless and not breathing, no defibrillator (including automated external defibrillators) or chest compressions should be used on a patient who has chosen “Do Not Attempt Resuscitation.”",
                                    using_option3: "When comfort cannot be achieved in the current setting, the patient, including someone with “Comfort-Focused Treatment,” should be transferred to a setting able to provide comfort (e.g., treatment of a hip fracture).",
                                    using_option4: "Non-invasive positive airway pressure includes continuous positive airway pressure (CPAP), bi-level positive airway pressure (BiPAP), and bag valve mask (BVM) assisted respirations.",
                                    using_option5: "IV antibiotics and hydration generally are not “Comfort-Focused Treatment.”",
                                    using_option6: "Treatment of dehydration prolongs life. If a patient desires IV fluids, indicate “Selective Treatment” or “Full Treatment.”",
                                    using_option7: "Depending on local EMS protocol, “Additional Orders” written in Section B may not be implemented by EMS personnel.",
                                    reviewing_recommended_when: "It is recommended that POLST be reviewed periodically. Review is recommended when:",
                                    reviewing_option1: "The patient is transferred from one care setting or care level to another, or",
                                    reviewing_option2: "There is a substantial change in the patient’s health status, or",
                                    reviewing_option3: "The patient’s treatment preferences change.",
                                    modifying_option1: "A patient with capacity can, at any time, request alternative treatment or revoke a POLST by any means that indicates intent to revoke. It is recommended that revocation be documented by drawing a line through Sections A through D, writing “VOID” in large letters, and signing and dating this line.",
                                    modifying_option2: "A legally recognized decisionmaker may request to modify the orders, in collaboration with the physician, based on the known desires of the patient or, if unknown, the patient’s best interests, The patient is transferred from one care setting or care level to another, or, if unknown, the patient’s best interests.",
                                    MOLST_other: "Other:",
                                    MOLST_create_your_MOLST_form: "Create your MOLST form",
                                    MOLST_form_warning1: "These orders are based on the person’s medical condition and wishes at the time the orders were issued.",
                                    MOLST_form_warning2: "Any section not completed does not invalidate the form and implies full treatment for that section.",
                                    MOLST_form_warning3: "Everyone shall be treated with dignity and respect, with attention to their comfort needs.",
                                    MOLST_cardic_override: "When not in cardiopulmonary arrest, follow orders in Sections B, C, and D",
                                    MOLST_medical_interventions_warning: "If person has a pulse and/or is breathing.",
                                    MOLST_full_treatment_option: "<b>Full Treatment:</b> Includes care described in this section",
                                    MOLST_full_treatment_direction1: "Use intubation, mechanical ventilation, and cardioversion as indicated.",
                                    MOLST_limited_treatment_option: "<b>Limited Additional Interventions:</b> Includes care described below in this section.",
                                    MOLST_limited_treatment_direction1: " Use medical treatment, IV fluids, and cardiac monitor as indicated. Do not use intubation or mechanical ventilation. May consider airway support such as CPAP or BiPAP.",
                                    MOLST_full_treatment_direction2: "Transfer to hospital if indicated. Avoid intensive care.",
                                    MOLST_comfort_measures_option: "<b>Comfort Measures Only:</b>",
                                    MOLST_comfort_measures_direction1: "Use medication by any route, positioning, wound care, and other measures to relieve pain and suffering. Use oxygen, oral suction, and manual treatment of airway obstruction as needed for comfort.",
                                    MOLST_comfort_measures_direction2: "Do not transfer to hospital, unless comfort needs cannot be met in current location.",
                                    MOLST_antibiotics_header: "Antibiotics",
                                    MOLST_antibiotics_option1: "<b>Use antibiotics if clinically indicated.</b>",
                                    MOLST_antibiotics_option2: "<b>Determine use or limitation of antibiotics when infection occurs.</b>",
                                    MOLST_antibiotics_option3: "<b>No antibiotics.</b> Use other measures to relieve symptoms of infection.",
                                    MOLST_artificial_nutrition_option1: "Long-term hydration/nutrition by tube",
                                    MOLST_artificial_nutrition_option2: "Trial period of hydration/nutrition by tube",
                                    MOLST_artificial_nutrition_option3: "No hydration/nutrition by tube",
                                    MOLST_signatures_header: "Basis For Orders And Signatures:",
                                    MOLST_discussed_with: "These orders were discussed with:",
                                    MOLST_discussed_with_patient: "Patient",
                                    MOLST_discussed_with_care_agent: "Health Care Agent",
                                    MOLST_discussed_with_kin: "Next of Kin/Surrogate",
                                    MOLST_discussed_with_gaurdian: "Court-Appointed Guardian",
                                    MOLST_discussed_with_parent_of_minor: "Parent of Minor",
                                    MOLST_patient_signature_header: "Signature Of Patient",
                                    MOLST_other_contact_header: "Other Contact Information",
                                    MOLST_completing_MOLST: "Completing MOLST",
                                    MOLST_completing_option1: "The MOLST form must be completed by a health care professional based on patient preferences and medical indications or decisions by the patient or a surrogate.",
                                    MOLST_completing_option2: "At the time a MOLST is completed, any current advance directive, if available, must be reviewed.",
                                    MOLST_completing_option3: "MOLST must be signed by a physician/PA/APRN and the patient or surrogate to be valid. Verbal orders are acceptable with follow-up signature by physician/PA/APRN in accordance with facility/community policy.",
                                    MOLST_completing_option4: "Use of the original form is strongly encouraged whenever possible. Photocopies and faxes of signed MOLST forms should be respected where necessary.",
                                    MOLST_using_MOLST: "Using MOLST",
                                    MOLST_using_option1: "If a person’s condition changes and time permits, the patient or surrogate must be contacted to assure that the MOLST orders are reviewed and updated as appropriate.",
                                    MOLST_using_option2: "If any section is not completed it implies full treatment. The healthcare provider should follow other appropriate methods to determine ongoing treatment.",
                                    MOLST_using_option3: "An automated external defibrillator (AED) should not be used on a person who has chosen “Do Not Attempt Resuscitation”",
                                    MOLST_using_option4: "Oral fluids and nutrition must always be offered if medically feasible.",
                                    MOLST_using_option5: "When comfort cannot be achieved in the current setting, the person, including a person that designates “comfort measures only,” should be transferred to a setting able to provide comfort",
                                    MOLST_using_option6: "An IV medication or IV fluids to enhance comfort may be appropriate in some cases for a person who has chosen “Comfort Measures Only.”",
                                    MOLST_using_option7: "A person with decision-making capacity, or the authorized surrogate (if the person lacks capacity), can revoke the MOLST at any time and request alternative treatment.",
                                    MOLST_review_MOLST: "Review of MOLST",
                                    MOLST_review_option1: "This form should be reviewed periodically (consider at least annually) and a new form completed when:",
                                    MOLST_review_suboption1: "The person is transferred from one care setting or care level to another, or",
                                    MOLST_review_suboption2: "There is a substantial change in the person’s health status, or",
                                    MOLST_review_suboption3: "The person’s treatment preferences change.",
                                    MOLST_revoking_MOLST: "Revoking MOLST",
                                    MOLST_revoking_option1: "If the MOLST becomes invalid or is replaced by an updated version, draw a line through sections A through E of the invalid MOLST, write “VOID” in large letters across the form, and sign and date the form.",
                                    MOST_create_header: "Create your MOST form",
                                    MOST_info: "This is a Physician Order Sheet based on the person’s medicalmcondition and wishes. Any section not completed indicates full treatment for that section. When the need occurs, first follow these orders, then contact physician.",
                                    MOST_cpr_option1: "<b>Attempt Resuscitation (CPR)</b>",
                                    MOST_cpr_option2: "<b>Do Not Attempt Resuscitation (DNR/no CPR)</b>",
                                    MOST_full_treatment_option1: "<b>Full Scope of Treatment",
                                    MOST_full_treatment_direction1: "Use intubation, advanced airway interventions, mechanical ventilation, cardioversion as indicated, medical treatment, IV fluids, etc.; also provide comfort measures.",
                                    MOST_limited_treatment_option: "<b>Limited Additional Interventions",
                                    MOST_limited_treatment_direction1: "Use medical treatment, IV fluids and cardiac monitoring as indicated. Do not use intubation or mechanical ventilation; also provide comfort measures.",
                                    MOST_comfort_measures_direction1: "Keep clean, warm and dry. Use medication by any route, positioning, wound care and other measures to relieve pain and suffering. Use oxygen, suction and manual treatment of airway obstruction as needed for comfort. ",
                                    MOST_comfort_measures_direction2: "Do not transfer to hospital unless comfort needs cannot be met in current location.",
                                    MOST_antibiotics_option1: "<b>Antibiotics if life can be prolonged.</b>",
                                    MOST_section_d_direction: "Check One Box Only In Each Column",
                                    MOST_artificial_nutrition_header: "Medically Administered Fluids and Nutition",
                                    MOST_artificial_nutrition_warning: "Offer oral fluids and nutrition if physically feasible.",
                                    MOST_artificial_nutrition_option1: "IV fluids long-term if indicated",
                                    MOST_artificial_nutrition_option2: "IV fluids for a defined trial period",
                                    MOST_artificial_nutrition_option3: "No IV fluids (provide other measures to ensure comfort)",
                                    MOST_artificial_nutrition_option4: "Feeding tube long-term if indicated",
                                    MOST_artificial_nutrition_option5: "Feeding tube for a defined trial period",
                                    MOST_artificial_nutrition_option6: "No feeding tube",
                                    MOST_IV: "IV:",
                                    MOST_feeding_tube: "Feeding Tube:",
                                    MOST_discussed_with_header: "Discussed With and Agreed To By",
                                    MOST_discussed_with_direction: "Basis for order must be documented in medical record.",
                                    MOST_discussed_with_option1: "Patient",
                                    MOST_discussed_with_option2: "Parent or gaurdian if patient or minor",
                                    MOST_discussed_with_option3: "Legal gaurdian of the person ",
                                    MOST_discussed_with_option4: "Attorney-in-fact with power to make health care decisions",
                                    MOST_discussed_with_option5: "Spouse",
                                    MOST_discussed_with_option6: "Majority of patient's reasonably available parents and adult children",
                                    MOST_discussed_with_option7: "Majority of patient's reasonably available adult sibling",
                                    MOST_discussed_with_option8: "An individual with an established relationship with the patient who is acting in good faith and can reliably convey the wishes of the patient",
                                    MOST_discussed_with_option9: "Health care agent",
                                    MOST_completing_MOST: "Completing These Medical Orders",
                                    MOST_completing_option1: "Must be completed by a health care professional based on patient preferences and medical indications. ",
                                    MOST_completing_option2: "These Medical Orders must be signed by a physician, advanced practice nurse, or physician assistant to be valid. Physician Assistants must include physician name and contact information. ",
                                    MOST_completing_option3: "Verbal orders are acceptable with follow-up signature by physician or advanced practice nurse in accordance with facility policy.",
                                    MOST_completing_option4: "Original form strongly encouraged. Photocopy, fax, and electronic image of signed MOST forms are legal and valid. ",
                                    MOST_using_MOST: "Using These Medical Orders",
                                    MOST_using_option1: "Any section of these Medical Orders not completed implies full treatment for that section. ",
                                    MOST_using_option2: "A semi-automatic external defibrillator (AED) should not be used on a person who has chosen “Do Not Attempt Resuscitation.” ",
                                    MOST_using_option3: "Comfort care is never optional; Oral fluids and nutrition must always be offered if medically feasible. ",
                                    MOST_using_option4: "When comfort cannot be achieved in the current setting, the person, including someone with “Comfort Measures Only,” should be transferred to a setting able to provide comfort (e.g., pinning of a hip fracture). ",
                                    MOST_using_option5: "A person who chooses “Comfort Measures Only” or “Limited Additional Interventions,” should not be entered into a trauma system. EMS should contact Medical Control for further orders or direction regarding transfers. ",
                                    MOST_using_option6: "IV medication to enhance comfort may be appropriate for a person who has chosen “Comfort Measures Only.” ",
                                    MOST_using_option7: "Treatment of dehydration is a measure that may prolong life. A person who desires IV fluids should indicate “Limited Interventions” or “Full Treatment.” ",
                                    MOST_using_option8: "If a health care provider considers these orders medically inappropriate, he or she may discuss concerns with the patient or authorized surrogate and revise orders with consent of patient or surrogate. ",
                                    MOST_using_option9: "If a health care provider or facility cannot comply with the orders due to policy or personal ethics, the provider or facility must arrange for transfer to the patient to another provider or facility and provide appropriate care in the meantime. ",
                                    MOST_review_MOST: "Reviewing These Medcial Orders",
                                    MOST_review_option1: "These Medical Orders should be reviewed regularly and when the person is transferred from one care setting or care level to another, there is a substantial change in the person’s health status, the person’s treatment preferences change, or when contact information changes."
                                })
                            .translations(
                                'es',
                                {
                                    Create_your_POLST_form: "crear su forma POLST",
                                    Date_Form_Prepared: "fecha formulario preparado",
                                    Edit_your_POLST_form: "editar su formulario POLST",
                                    HIPAA_disclosure_warning: "HIPAA permite la divulgación de POLST a otros proveedores de atención médica cuando sea necesario",
                                    Healthcare_Provider_Assisting_with_Form_Preparation: "profesional de la salud ayudando con la preparación de la forma",
                                    Medical_Record_Number: "historial médico #",
                                    Name_last_first_middle: '"Nombre (apellido, nombre, segundo nombre)',
                                    Patient_Date_of_Birth: "fecha de nacimiento del paciente",
                                    Patient_First_Name: "paciente primero nombre",
                                    Patient_Last_Name: "paciente apellido",
                                    Patient_Middle_Name: "segundo nombre del paciente",
                                    Why_should_I_Fill_Out_a_POLST_Form: "¿Por qué debo llenar un formulario <b>POLST?</b>",
                                    ad_option1: "directiva anticipada fecha",
                                    ad_option2: "directiva anticipada no está disponible",
                                    ad_option3: "ninguna directiva anticipada",
                                    additional_contact: "contacto adicional",
                                    additional_name: "segundo nombre",
                                    additional_orders: "pedidos adicionales",
                                    advance_directive_warning: "POLST complementa una directiva anticipada y no pretende sustituir ese documento.",
                                    artificial_nutrition_heading: '"administración artificial de nutrición',
                                    artificial_nutrition_option1: "a largo plazo la nutrición artificial, incluyendo los tubos de alimentación.",
                                    artificial_nutrition_option2: "período de prueba de la nutrición artificial, incluyendo los tubos de alimentación.",
                                    artificial_nutrition_option3: "sin medios artificiales de la nutrición, incluidos los tubos de alimentación.",
                                    artificial_nutrition_warning: "ofrecer alimentos por la boca si es posible y deseado.",
                                    available_and_reviewed: "disponible y revisado",
                                    birth_date: "fecha de nacimiento",
                                    check_one: "marque uno",
                                    choose_your_language: "elige tu idioma",
                                    completing_POLST: "POLST completar",
                                    completing_option1: "completando un formulario POLST es voluntaria. La ley de California requiere que una forma POLST ser seguido por los proveedores de salud, y proporciona inmunidad a los que cumplen de buena fe. en el ámbito hospitalario, el paciente será evaluado por un médico que emitirá órdenes apropiadas que sean consistentes con las preferencias del paciente.",
                                    completing_option2: "POLST no reemplaza la directiva anticipada. cuando esté disponible, revise la directiva anticipada y la forma POLST para garantizar la coherencia, y las formas de actualización apropiada para resolver cualquier conflicto.",
                                    completing_option3: "POLST debe ser completada por un profesional de la salud basado en las preferencias del paciente y las indicaciones médicas.",
                                    completing_option4: "un tomador de decisiones legalmente reconocida puede incluir un tutor designado por el tribunal o el tutor, agente designado en una directiva anticipada, sustituto designado por vía oral, cónyuge, pareja de hecho registrada, padre de un menor de edad, pariente más cercano disponible, o persona a la que el médico del paciente cree que mejor conoce lo que es en el mejor interés del paciente y tomará decisiones de acuerdo con los deseos expresados ​​por el paciente y los valores en la medida conocida.",
                                    completing_option5: "un tomador de decisiones legalmente reconocida puede ejecutar el formulario POLST sólo si el paciente carece de capacidad o ha designado que la autoridad del poder de decisión es efectiva inmediatamente.",
                                    completing_option6: "POLST debe ser firmado por un médico y el paciente o con poder de decisión sea válida. órdenes verbales son aceptables con el seguimiento de la firma por el médico de acuerdo con la política de instalación / comunidad.",
                                    completing_option7: "si un formulario traducido se utiliza con el paciente o con poder de decisión, adjuntarlo al formulario POLST Inglés firmado.",
                                    completing_option8: "uso de la forma original es altamente recomendable. fotocopias y faxes de forma POLST firmados son legales y válidos. una copia debe conservarse en el expediente médico del paciente, en papel rosa de ultra cuando sea posible",
                                    cpr_header: "la resucitación cardiopulmonar (CPR)",
                                    cpr_option1: "<b>intentar la reanimación / cpr</b> (cpr seleccionando en el apartado a <u><b>requiere la selección de</b></u> un tratamiento completo en el apartado b)",
                                    cpr_option2: "<b>no intente reanimación / DNR</b> (permitir la muerte natural)",
                                    cpr_warning1: "si el paciente no tiene pulso y no respira",
                                    cpr_warning2: "si el paciente no está en parada cardiorrespiratoria, seguir las órdenes en las secciones B y c.",
                                    create_your_account: "Crea tu cuenta",
                                    discussed_with: '"discutido con',
                                    discussed_with_decisionmaker: "decisor legalmente reconocida",
                                    discussed_with_patient: "paciente (paciente tiene capacidad)",
                                    edit_your_account: "editar su cuenta",
                                    family_name: 'apellido',
                                    get_started: 'empezar',
                                    given_name: 'nombre',
                                    healthcare_agent: '"agente de atención médica si nombra en directiva anticipada',
                                    legal_rep_address: "dirección de correo",
                                    legal_rep_name: "Nombre de impresión",
                                    legal_rep_phone: "número de teléfono",
                                    legal_rep_relationship: 'relación',
                                    legal_rep_signature: 'firma',
                                    legality_section_warning: "una copia del formulario POLST firmado una orden médico legalmente válida. cualquier sección no implica completado el tratamiento completo para esa sección.",
                                    medical_interventions_header: '"intervenciones médicas',
                                    medical_interventions_option1: "<b><u>tratamiento completo</u> - principal objetivo de prolongar la vida por todos los medios médicamente eficaces.</b> <br /> además del tratamiento descrito en el tratamiento selectivo y tratamiento comodidad centrada, el uso de la intubación, las intervenciones avanzadas de la vía aérea, ventilación mecánica, y cardioversión como se indica.",
                                    medical_interventions_option1a: "<b>período de prueba de tratamiento completo.</b>",
                                    medical_interventions_option2: "<b><u>tratamiento selectivo</u> -. objetivo del tratamiento de condiciones médicas, evitando medidas onerosas,</b> además del tratamiento descrito en el tratamiento comodidad centrada, utilice el tratamiento médico, iv antibióticos y líquidos por vía intravenosa como se indica. no intubar. puede utilizar presión positiva no invasiva de la vía aérea. en general, evitar la terapia intensiva.",
                                    medical_interventions_option2a: "<b>solicitud de transferencia al hospital <u>sólo</u></b> si las necesidades de confort no se pueden cumplir en la ubicación actual.",
                                    medical_interventions_option3: "<b><u>comodidad centrada tratamiento</u> - principal objetivo de maximizar la comodidad</b> aliviar el dolor y el sufrimiento con la medicación por cualquier vía que sea <b>necesario.;</b> utilizar el oxígeno, aspiración, y el tratamiento manual de la obstrucción de la vía aérea. no use tratamientos indicados en el tratamiento completo y selectivo a menos consistente con la meta comodidad. <i><b>solicitud de transferencia al hospital <u>sólo</u> si las necesidades de confort no se pueden cumplir en la ubicación actual.</b></i>",
                                    medical_interventions_warning: "si el paciente se encuentra con un pulso y / o está respirando",
                                    modifying_POLST: "modificar y anular POLST",
                                    modifying_option1: "un paciente con capacidad puede, en cualquier momento, solicitar un tratamiento alternativo o revocar una POLST por cualquier medio que indica la intención de revocar. se recomienda que la revocación se documentará mediante la elaboración de una línea a través de las secciones A a D, escribiendo &quot;vacío&quot; en letras grandes y firmar y fechar esta línea.",
                                    modifying_option2: "un tomador de decisiones legalmente reconocida podrá solicitar para modificar las órdenes, en colaboración con el médico, en base a los deseos conocidos del paciente o, si no se conoce, el interés superior del paciente, el paciente se transfiere de un ambiente de cuidado o cuidan nivel a otro, o , si no se conoce, el interés superior del paciente.",
                                    next_step: "paso siguiente",
                                    not_completed_by_physician: "n / a si POLST se completa con la firma del médico",
                                    patient_information: "la información del paciente",
                                    patient_signature_header: "firma del paciente o tomador de decisiones legalmente reconocida",
                                    patient_signature_legal: "Soy consciente de que este formulario es voluntaria. al firmar este formulario, que toma las decisiones legalmente reconocida reconoce que esta solicitud relativa a las medidas de resucitación es consistente con los deseos conocidos, y con el mejor interés de la paciente que es el sujeto de la forma.",
                                    physician_contact_warning: "primero siga estas órdenes, entonces póngase en contacto con el médico.",
                                    physician_legal: "mi firma a continuación indica que lo mejor de mi conocimiento que estas órdenes son consistentes con condiciones y preferencias clínica del paciente.",
                                    physician_license: "número de licencia",
                                    physician_name: "nombre del médico",
                                    physician_phone: "el número de teléfono del médico",
                                    physician_signature: "firma del médico",
                                    physician_signature_header: "firma del médico",
                                    POLST_p1: "cuando usted está gravemente enfermo, asegurándose de que sus seres queridos y los médicos saben qué tipo de tratamiento médico que usted desea cuando se aproxima al final de su vida es muy importante. órdenes del médico para el tratamiento de soporte vital (POLST) pueden ayudar a hacer precisamente eso.",
                                    POLST_p2: "POLST es una forma que indica claramente qué tipo de pacientes de tratamiento médico quieren hacia el final de sus vidas. impreso en papel de color rosa brillante, y firmado tanto por el médico y el paciente, POLST ayuda a dar los pacientes gravemente enfermos más control sobre su cuidado.",
                                    POLST_p3: "POLST también ayuda a hablar con su equipo de atención médica y sus seres queridos sobre sus opciones. de esta manera, POLST puede ayudar a reducir el sufrimiento del paciente y la familia, y asegúrese de que sus deseos son conocidos y respetados.",
                                    provider_directions: "instrucciones de cuidado de la salud",
                                    reviewing_POLST: "POLST revisar",
                                    reviewing_option1: "el paciente se transfiere de ajuste o la atención el nivel uno la atención a otro, o",
                                    reviewing_option2: "hay un cambio sustancial en el estado de salud del paciente, o",
                                    reviewing_option3: "preferencias de tratamiento del paciente cambian.",
                                    reviewing_recommended_when: '"se recomienda que POLST se revisa periódicamente. Se recomienda revisión cuando',
                                    section_a: "una sección de",
                                    section_b: "sección b",
                                    signatures_header: '"la información y firmas',
                                    using_POLST: "utilizando POLST",
                                    using_option1: "cualquier sección incompleta de POLST implica un tratamiento completo para esa sección.",
                                    using_option2: "si es encontrado sin pulso y no respira, sin desfibrilador (incluyendo desfibriladores externos automáticos) o compresiones torácicas se deben utilizar en un paciente que ha elegido &quot;no intente resucitación.&quot;",
                                    using_option3: "cuando la comodidad no se puede lograr en la configuración actual, el paciente, incluyendo alguien con &quot;tratamiento comodidad-centrado,&quot; debe ser transferido a un entorno capaz de proporcionar comodidad (por ejemplo, el tratamiento de una fractura de cadera).",
                                    using_option4: "presión positiva no invasiva de la vía aérea incluye la presión positiva continua en la vía aérea (CPAP), con dos niveles de presión positiva (BiPAP), y la máscara de válvula de bolsa (BVM) respiraciones asistidas.",
                                    using_option5: "iv antibióticos e hidratación en general, no son &quot;trato comodidad centrada.&quot;",
                                    using_option6: "tratamiento de la deshidratación prolonga la vida. si un paciente desea líquidos por vía intravenosa, indique &quot;tratamiento selectivo&quot; o &quot;tratamiento completo&quot;.",
                                    using_option7: "dependiendo del protocolo local ems, &quot;órdenes adicionales&quot; escritas en la sección b no podrán ser aplicadas por personal de EMS."
                                })
                            .translations(
                                'hmn',
                                {
                                    Create_your_POLST_form: "tsim koj POLST daim ntawv",
                                    Date_Form_Prepared: "hnub daim ntawv npaj",
                                    Edit_your_POLST_form: "kho kom raug koj POLST daim ntawv",
                                    HIPAA_disclosure_warning: "HIPAA tso cai qhia tawm ntawm POLST mus rau lwm cov kws kho mob raws li tsim nyog",
                                    Healthcare_Provider_Assisting_with_Form_Preparation: "tus kws kho mob pab nrog daim ntawv npaj",
                                    Medical_Record_Number: "ntaub ntawv kho mob #",
                                    Name_last_first_middle: '"lub npe (lub xeem, npe, npe nrab)',
                                    Patient_Date_of_Birth: "tus neeg mob lub hnub yug",
                                    Patient_First_Name: "tus neeg mob lub npe",
                                    Patient_Last_Name: "tus neeg mob lub xeem lub npe",
                                    Patient_Middle_Name: "tus neeg mob lub npe nrab",
                                    Why_should_I_Fill_Out_a_POLST_Form: "yog vim li cas yuav tsum kuv sau tawm ib tug <b>POLST</b> daim ntawv no?",
                                    ad_option1: "qhia ua ntej hnub tim",
                                    ad_option2: "qhia ua ntej tsis muaj",
                                    ad_option3: "tsis muaj kev qhia ua ntej",
                                    additional_contact: "ntxiv hu rau",
                                    additional_name: "lub npe nrab",
                                    additional_orders: "ntxiv txiav txim",
                                    advance_directive_warning: "POLST complements kev qhia ua ntej thiab tsis yog yuav los hloov uas daim ntawv.",
                                    artificial_nutrition_heading: '"artificially muab kev noj haus',
                                    artificial_nutrition_option1: "ntev-lub sij hawm dag noj haus, xws li noj hlab.",
                                    artificial_nutrition_option2: "sij hawm mus sib ntawm kev noj haus dag, xws li noj hlab.",
                                    artificial_nutrition_option3: "tsis muaj dag txhais tau tias ntawm kev noj haus, xws li noj hlab.",
                                    artificial_nutrition_warning: "muaj khoom noj khoom haus los ntawm qhov ncauj yog tias ua tau thiab qhov kev kawm.",
                                    available_and_reviewed: "muaj thiab saib",
                                    birth_date: "hnub yug",
                                    check_one: "mus saib ib tug",
                                    choose_your_language: "xaiv koj cov lus",
                                    completing_POLST: "sau POLST",
                                    completing_option1: "ua kom tiav ib tug POLST daim ntawv yog nyob ntawm siab. California txoj cai lij choj yuav tsum tau hais tias ib tug POLST daim ntawv yuav ua raws li los ntawm cov kws kho mob, thiab kev tiv thaiv rau cov neeg muab kev pab uas ua raws li nyob rau hauv txoj kev ntseeg zoo. nyob rau hauv lub tsev kho mob qhov chaw, ib tug neeg mob yuav raug soj ntsuam los ntawm ib tug kws kho mob uas yuav muab tsim nyog txiav txim uas yog muaj raws li tus neeg mob lub nyiam.",
                                    completing_option2: "POLST tsis hloov lub qhia ua ntej. thaum muaj, rov qab saib cov kev qhia ua ntej thiab POLST daim ntawv los xyuas kom meej taub hau, thiab hloov tshiab ntaub ntawv kom tsim nyog kom daws tau tej kev tsis sib haum.",
                                    completing_option3: "POLST yuav tsum tau ua kom tiav los ntawm ib tug kws kho mob raws li nyob rau hauv tus neeg mob nyiam thiab kev kho mob indications.",
                                    completing_option4: "ib tug legally paub tias decisionmaker tej zaum yuav muaj ib tug lub tsev hais plaub-tsa saib xyuas los yog tus neeg saib xyuas, tus neeg sawv cev xaiv nyob rau hauv ib tug qhia ua ntej, hais lus xaiv surrogate, txij nkawm, sau npe domestic khub, niam txiv ntawm ib tug menyuam yaus, ze muaj tus txheeb ze, los yog tus neeg uas tus neeg mob tus kws kho mob ntseeg tau hais tias qhov zoo tshaj plaws paub dab tsi yog nyob rau hauv tus neeg mob lub qhov zoo tshaj plaws kev txaus siab thiab yuav ua rau kev txiav txim siab nyob rau hauv raws li tus neeg mob lub qhia kev xav tau thiab qhov tseem ceeb rau cov neeg paub.",
                                    completing_option5: "ib tug legally pom tau hais tias tej zaum yuav ua decisionmaker lub POLST daim ntawv yog hais tias tus neeg mob tsis muaj peev xwm los yog tau pom zoo kom lub decisionmaker txoj cai yog zoo tam sim ntawd.",
                                    completing_option6: "POLST yuav tsum tau kos npe rau los ntawm ib tug kws kho mob thiab tus neeg mob los yog decisionmaker yuav siv tau. hais lus txiav txim yog tau nrog soj qab taug kos npe los ntawm tus kws kho mob nyob rau hauv raws li chaw / lub zej lub zos txoj cai.",
                                    completing_option7: "Yog hais tias ib tug txhais daim ntawv no yog siv nrog tus neeg mob los yog decisionmaker, muab nws mus rau lub kos npe rau lus Askiv POLST daim ntawv.",
                                    completing_option8: "siv uas yog thawj daim ntawv no yog xav kom ua heev. luam thiab faxes ntawm kos npe POLST ntaub ntawv yog kev cai lij choj thiab siv tau. ib daim qauv yuav tsum tau muab khaws cia tseg nyob rau hauv tus neeg mob tus kws kho mob cov ntaub ntawv, nyob rau ultra liab ntawv thaum tau",
                                    cpr_header: "cardiopulmonary resuscitation (CPR)",
                                    cpr_option1: "<b>sim resuscitation / CPR</b> (xaiv kev cawm siav nyob rau hauv seem ib tug <u><b>yuav tsum tau xaiv</b></u> tag nrho kev kho mob nyob rau hauv seem b)",
                                    cpr_option2: "<b>tsis txhob sim resuscitation / DNR</b> (cia tej yam ntuj tso kev tuag)",
                                    cpr_warning1: "Yog hais tias tus neeg mob twb tsis muaj mem tes thiab tsis ua pa",
                                    cpr_warning2: "Yog hais tias tus neeg mob yog tsis nyob rau hauv cardiopulmonary raug ntes, ua raws li txiav txim nyob rau hauv seem b thiab c.",
                                    create_your_account: "tsim koj tus account",
                                    discussed_with: '"sib tham nrog',
                                    discussed_with_decisionmaker: "raws li txoj cai pom tau hais tias decisionmaker",
                                    discussed_with_patient: "tus neeg mob (tus neeg mob muaj peev xwm)",
                                    edit_your_account: "kho kom raug koj tus account",
                                    family_name: "lub xeem lub npe",
                                    get_started: "tau pib",
                                    given_name: "thawj lub npe",
                                    healthcare_agent: '"tus kws kho tus neeg sawv cev yog tias muaj npe nyob rau hauv kev qhia ua ntej',
                                    legal_rep_address: "chaw nyob xa ntawv",
                                    legal_rep_name: "luam lub npe",
                                    legal_rep_phone: "xov tooj",
                                    legal_rep_relationship: "kev sib raug zoo",
                                    legal_rep_signature: "kos npe",
                                    legality_section_warning: "ib daim qauv ntawm cov kos npe POLST daim ntawv yog ib tug raug cai siv tau tus kws kho mob tau txiav txim. tej seem tsis tiav implies tag nrho kev kho mob rau cov uas seem.",
                                    medical_interventions_header: '"kev kho mob tiv thaiv',
                                    medical_interventions_option1: "<b><u>tag nrho kev kho mob</u> - lub hom phiaj ntawm lub neej tom los ntawm tag nrho cov muaj mob txhais tau tias zoo.</b> <br /> nyob rau hauv tas li ntawd rau kev kho mob tau piav nyob rau hauv kev kho mob thiab kev nplij siab xim-teem kev kho mob, kev siv intubation, advanced caj pas pab, neeg kho tshuab cua, thiab cardioversion raws li qhia.",
                                    medical_interventions_option1a: "<b>sij hawm mus sib ntawm tag nrho cov kev kho mob.</b>",
                                    medical_interventions_option2: "<b><u>xim kev kho mob</u> - lub hom phiaj ntawm kev kho kev kho mob tej yam kev mob thaum uas tsis txhob ua nra hnyav rau kev ntsuas.</b> Tsis tas li ntawd nyob rau hauv kev kho tau piav nyob rau hauv kev nplij siab-teem kev kho mob, siv tshuaj kho mob, tshuaj tua kab mob iv, thiab iv kua raws li qhia. tsis intubate. tej zaum yuav siv uas tsis yog-tus zoo caj pas siab. feem ntau tsis txhob intensive kev saib xyuas.",
                                    medical_interventions_option2a: "<b>thov hloov lwm lub tsev rau lub tsev kho mob <u>yog</u></b> hais tias kev nplij siab xav tau kev pab yuav tsis tau ntsib nyob rau hauv tam sim no qhov chaw.",
                                    medical_interventions_option3: "<b><u>kev nplij siab-teem kev kho mob</u> - lub hom phiaj ntawm maximizing kev nplij siab.</b> txo qhov mob thiab kev txom nyem nrog tshuaj los ntawm tej yam txoj kev raws li xav tau; siv oxygen, tshuab nqus quav thiab, thiab phau ntawv kev kho mob ntawm caj pas dab tsi thaiv. tsis siv kev kho mob uas muaj npe nyob rau hauv tag nrho thiab xim kev kho mob tshwj tsis yog muaj raws li kev nplij siab lub hom phiaj. <i><b>thov hloov lwm lub tsev rau lub tsev kho mob <u>yog</u> hais tias kev nplij siab xav tau kev pab yuav tsis tau ntsib nyob rau hauv tam sim no qhov chaw.</b></i>",
                                    medical_interventions_warning: "Yog hais tias tus neeg mob yog muaj nyob nrog ib tug mem tes thiab / los yog ua pa",
                                    modifying_POLST: "modifying thiab voiding POLST",
                                    modifying_option1: "ib tug neeg mob nrog lub peev xwm ua tau, thaum twg los tau lub sij hawm, thov kom lwm kev kho mob los yog thim ib tug POLST los ntawm tej yam uas txhais tau tias uas qhia txhob txwm ua kom thim. nws yog pom zoo uas thim rov qab yuav tsum tau sau tseg los ntawm nqus tau ib tug kab los ntawm seem ib tug los ntawm d, sau ntawv &quot;tsis&quot; nyob rau hauv loj tsiaj ntawv, thiab kos npe rau thiab yos no kab.",
                                    modifying_option2: "ib tug legally paub tias decisionmaker yuav thov kom hloov tus txiav txim, nyob rau hauv kev sib koom tes nrog cov kws kho mob, raws li nyob rau hauv lub npe hu lub siab nyiam ntawm tus neeg mob lossis, yog tias tsis paub hais tias, tus neeg mob lub qhov zoo tshaj plaws kev txaus siab, tus neeg mob yog kis los ntawm ib tug qhov chaw zov los yog saib xyuas theem mus rau lwm lub, los yog , yog tias tsis paub hais tias, tus neeg mob lub qhov zoo tshaj plaws kev txaus siab.",
                                    next_step: "kauj ruam tom ntej",
                                    not_completed_by_physician: "n / a yog POLST yog ua tiav los ntawm kev kos npe rau tus kws kho mob",
                                    patient_information: "tus neeg mob tej ntaub ntawv",
                                    patient_signature_header: "kos npe ntawm tus neeg mob los yog raug cai pom tau hais tias decisionmaker",
                                    patient_signature_legal: "Kuv paub hais tias daim ntawv no yog nyob ntawm siab. los ntawm kos npe rau daim ntawv no, lub raug cai pom tau hais tias decisionmaker lees paub tias qhov kev thov txog resuscitative ntsuas yog sib xws nrog lub npe hu siab nyiam, thiab nrog qhov zoo tshaj plaws kev txaus siab ntawm, tus neeg mob uas yog hais txog txoj kev rau hauv daim ntawv.",
                                    physician_contact_warning: "thawj raws li cov txiav txim, ces hu rau tus kws kho mob.",
                                    physician_legal: "kuv kos npe hauv qab no qhia rau qhov zoo tshaj plaws ntawm kuv paub hais tias cov txiav txim yog zoo ib yam nrog tus neeg mob lub mob thiab nyiam.",
                                    physician_license: "daim ntawv tso cai tooj",
                                    physician_name: "tus kws kho mob lub npe",
                                    physician_phone: "tus kws kho mob xov tooj",
                                    physician_signature: "tus kws kho mob kos npe",
                                    physician_signature_header: "kos npe ntawm tus kws kho mob",
                                    POLST_p1: "thaum koj yog mob loj heev, ua zoo saib kom koj tus hlub thiab cov kws kho mob paub yog dab tsi hom kev kho mob kev kho mob uas koj xav tau raws li koj txoj kev ua lub kawg ntawm koj lub neej yog ib qho tseem ceeb heev. tus kws kho mob txiav txim rau lub neej phem tshaj kev kho mob (POLST) yuav pab ua li ntawd.",
                                    POLST_p2: "POLST yog ib daim ntawv ntawd uas hais tias yog dab tsi hom kev kho mob kev kho mob cov neeg mob xav mus rau qhov kawg ntawm lawv lub neej. luam tawm nyob rau kaj liab ntawv, thiab kos npe rau los ntawm ob tug kws kho mob thiab tus neeg mob, POLST pab muab tiag-mob cov neeg mob ntau tswj lawv saib xyuas.",
                                    POLST_p3: "POLST tseem yuav pab koj tham nrog koj tus kws kho pab neeg thiab koj tus hlub txog koj cov kev xaiv. nyob rau hauv no txoj kev, POLST yuav pab txo cov neeg mob thiab tsev neeg kev txom nyem, thiab kom paub tseeb tias koj cov kev xav tau paub thiab sam xeeb.",
                                    provider_directions: "qhia rau tus kws kho mob",
                                    reviewing_POLST: "saib POLST",
                                    reviewing_option1: "tus neeg mob yog kis los ntawm ib tug los yog qhov chaw zov zov theem mus rau lwm lub, los yog",
                                    reviewing_option2: "muaj ib tug ntau heev nyob rau hauv kev hloov rau tus neeg mob txoj kev kho mob li cas, los yog",
                                    reviewing_option3: "tus neeg mob txoj kev kho mob nyiam hloov.",
                                    reviewing_recommended_when: '"nws yog pom zoo uas POLST tau saib tsis tseg. xyuas yog pom zoo thaum',
                                    section_a: "seem ib tug",
                                    section_b: "seem b",
                                    signatures_header: '"ntaub ntawv thiab kos npe',
                                    using_POLST: "siv POLST",
                                    using_option1: "tej tsis tiav seem ntawm POLST implies tag nrho kev kho mob rau cov uas seem.",
                                    using_option2: "yog tias pom pulseless thiab ua tsis tau pa, tsis muaj defibrillator (xws li siv lwm defibrillators) los yog nias lub xub ntiag yuav tsum tau siv nyob rau hauv ib tug neeg mob uas tau xaiv &quot;tsis txhob sim resuscitation.&quot;",
                                    using_option3: "thaum kev nplij siab tsis tau tiav nyob rau hauv lub tam sim no qhov chaw, tus neeg mob, nrog rau ib tug neeg uas &quot;kev nplij siab-teem kev kho mob,&quot; yuav tsum tau pauv mus rau ib qhov chaw muaj peev xwm mus muab kev nplij siab (piv txwv li, kev kho mob ntawm ib tug hip puas).",
                                    using_option4: "uas tsis yog-tus zoo caj pas siab muaj xws li mus zoo caj pas siab (cpap), lis-theem zoo caj pas siab (bipap), thiab hnab valve daim npog qhov ncauj (bvm) pab respirations.",
                                    using_option5: "iv tshuaj tua kab mob thiab co feem ntau yog tsis &quot;xis-teem kev kho mob.&quot;",
                                    using_option6: "kev kho mob ntawm lub cev qhuav dej prolongs lub neej. Yog hais tias ib tug neeg mob siab nyiam iv kua, qhia &quot;xim kev kho mob&quot; los yog &quot;tag nrho kev kho mob.&quot;",
                                    using_option7: "nyob ntawm seb ntawm lub zos EMS raws tu qauv, &quot;ntxiv txiav txim&quot; sau nyob rau hauv seem b tej zaum yuav tsis muab los siv los ntawm EMS cov neeg."
                                })
                            .translations(
                                'hy',
                                {
                                    Create_your_POLST_form: "ստեղծել ձեր POLST ձեւը",
                                    Date_Form_Prepared: "ամսաթիվը ձեւը պատրաստվել",
                                    Edit_your_POLST_form: "խմբագրել ձեր POLST ձեւը",
                                    HIPAA_disclosure_warning: "HIPAA թույլ է տալիս բացահայտման POLST այլ բուժաշխատողների, որքան անհրաժեշտ է",
                                    Healthcare_Provider_Assisting_with_Form_Preparation: "առողջապահության մատակարար աջակցել ձեւի նախապատրաստման",
                                    Medical_Record_Number: "Բժշկական #",
                                    Name_last_first_middle: "անունը (վերջին, առաջին, միջին)",
                                    Patient_Date_of_Birth: "համբերատար ծննդյան ամսաթիվը",
                                    Patient_First_Name: "հիվանդի անունը",
                                    Patient_Last_Name: "հիվանդի ազգանունը",
                                    Patient_Middle_Name: "հիվանդի հայրանուն",
                                    Why_should_I_Fill_Out_a_POLST_Form: "Ինչու ես պետք է լրացնել <b>POLST</b> ձեւ.",
                                    ad_option1: "նախապես հրահանգ թվագրված",
                                    ad_option2: "նախօրոք հրահանգ մատչելի չէ",
                                    ad_option3: "ոչ նախապես հրահանգ",
                                    additional_contact: "լրացուցիչ Կապ",
                                    additional_name: 'հայրանուն',
                                    additional_orders: "լրացուցիչ պատվեր",
                                    advance_directive_warning: "POLST լրացնում է նախապես հրահանգ եւ չի պատրաստվում փոխարինել այդ փաստաթուղթը.",
                                    artificial_nutrition_heading: '"արհեստականորեն կառավարվում սնուցումը',
                                    artificial_nutrition_option1: "երկարաժամկետ արհեստական ​​սնուցում, այդ թվում, կերակրման խողովակներ.",
                                    artificial_nutrition_option2: "փորձաշրջանը արհեստական ​​սնուցման, այդ թվում կերակրման խողովակներ.",
                                    artificial_nutrition_option3: "ոչ արհեստական ​​միջոցները սնուցման, այդ թվում կերակրման խողովակները.",
                                    artificial_nutrition_warning: '"առաջարկում է ուտելիք է բերանը, եթե հնարավոր է եւ ցանկալի',
                                    available_and_reviewed: "մատչելի եւ վերանայվել",
                                    birth_date: "ծննդյան ամսաթիվ",
                                    check_one: "ստուգեք մեկը",
                                    choose_your_language: "Ընտրեք ձեր լեզուն",
                                    completing_POLST: "Ավարտելուց POLST",
                                    completing_option1: '"ավարտում է POLST ձեւ կամավոր է. Կալիֆորնիայի օրենքը պահանջում է, որ POLST ձեւը է հետեւել բուժաշխատողների, եւ ապահովում է դիմադրողականությունը նրանց, ովքեր համապատասխանում են բարեխղճորեն',
                                    completing_option2: '"POLST չի փոխարինել նախապես հրահանգը',
                                    completing_option3: '"POLST պետք է ավարտվի է բժշկի վրա հիմնված հիվանդի նախասիրությունների եւ բժշկական ցուցումների',
                                    completing_option4: "իրավաբանորեն ճանաչված decisionmaker կարող է ներառել դատարանի նշանակած պահապան կամ խնամակալի, գործակալը նախատեսված է նախապես Հրահանգի, բանավոր նախատեսված փոխարինող, ամուսինը, գրանցված ներքին գործընկեր, ծնող մի անչափահասի, ամենամոտ հասանելի հարաբերական կամ անձը, ում հիվանդի բժիշկը կարծում է լավագույն գիտի, թե ինչ է հիվանդի շահերից, եւ որոշումներ կկայացնի, համաձայն հիվանդի արտահայտած ցանկությունների եւ արժեքների վրա այնքանով, որքանով հայտնի է.",
                                    completing_option5: "իրավաբանորեն ճանաչված decisionmaker կարող կատարել POLST ձեւը, միայն այն դեպքում, եթե հիվանդը չունի կարողությունները կամ նշանակված որ decisionmaker իր հեղինակությունը արդյունավետ անմիջապես.",
                                    completing_option6: "POLST պետք է ստորագրված բժշկի եւ հիվանդի կամ decisionmaker լինել վավեր. բանավոր պատվեր են ընդունելի հետեւել- up ստորագրման բժշկի համաձայն վայրի / համայնքային քաղաքականության.",
                                    completing_option7: "եթե թարգմանված ձեւը, որն օգտագործվում է հիվանդի կամ decisionmaker, կցել այն ստորագրված անգլերեն POLST ձեւով.",
                                    completing_option8: '"Օգտագործումը օրիգինալ ձեւով խրախուսվում',
                                    cpr_header: "Սիրտ վերակենդանացման (CPR)",
                                    cpr_option1: "<b>փորձ վերակենդանացման / CPR</b> (ընտրելով CPR բաժնում ա <u><b>պահանջում է ընտրելու</b></u> լիարժեք բուժում բաժնում բ)",
                                    cpr_option2: "<b>չեն փորձում վերակենդանացման / DNR</b> (թույլ են բնական մահով)",
                                    cpr_warning1: "Եթե ​​հիվանդը չունի զարկերակը եւ չի շնչում",
                                    cpr_warning2: '"եթե հիվանդը չէ, Սիրտ ձերբակալության, հետեւեք պատվերներ բաժիններում B եւ C',
                                    create_your_account: "ստեղծել ձեր հաշիվը",
                                    discussed_with: '"հետ քննարկել',
                                    discussed_with_decisionmaker: "իրավաբանորեն ճանաչել է decisionmaker",
                                    discussed_with_patient: "հիվանդը (հիվանդը ունի հզորությունը)",
                                    edit_your_account: "խմբագրել ձեր հաշիվը",
                                    family_name: 'ազգանուն',
                                    get_started: 'սկսել',
                                    given_name: 'անուն',
                                    healthcare_agent: '"առողջապահության գործակալ, եթե անունով նախապես հրահանգի',
                                    legal_rep_address: "փոստային հասցեն",
                                    legal_rep_name: "տպագիր անունը",
                                    legal_rep_phone: 'հեռախոսահամարը',
                                    legal_rep_relationship: 'հարաբերությունները',
                                    legal_rep_signature: 'ստորագրություն',
                                    legality_section_warning: '"պատճենը ստորագրված POLST ձեւով օրինական ուժի մեջ բժիշկ կարգը. ցանկացած բաժինը չի ավարտվել ենթադրում է ամբողջական բուժում այդ հատվածում',
                                    medical_interventions_header: '"Բժշկական միջամտությունները',
                                    medical_interventions_option1: '"<b><u>լրիվ բուժում</u> - առաջնային նպատակն է երկարաձգելու կյանքը բոլոր բժշկական արդյունավետ միջոցներով',
                                    medical_interventions_option1a: "<b>դատավարությունը ժամկետը լրիվ բուժման.</b>",
                                    medical_interventions_option2: '"<b><u>ընտրովի բուժում</u> - նպատակը բուժման բժշկական պայմանների, իսկ խուսափելով ծանր միջոցներ',
                                    medical_interventions_option2a: "<b>խնդրանքը փոխանցում է հիվանդանոց, <u>միայն</u></b> եթե Կոմֆորտ կարիքները չեն կարող հանդիպել ներկա գտնվելու.",
                                    medical_interventions_option3: '"<b><u>Հարմարավետ կենտրոնացած բուժում</u> - առաջնային նպատակն է առավելագույնին հասցնելով հարմարավետության.</b> թեթեւացնել ցավը եւ տառապանքը դեղերի ցանկացած երթուղու, ինչպես նաեւ անհրաժեշտ է. օգտագործել թթվածին, suctioning եւ մեխանիկական բուժումը ուղեգիծ խոչընդոտման. չեն օգտագործում բուժում նշված լիարժեք եւ ընտրովի վերաբերմունքի, եթե համահունչ հարմարավետության նպատակ',
                                    medical_interventions_warning: "Եթե ​​հիվանդը հայտնաբերվել է իմպուլսում եւ / կամ շնչում",
                                    modifying_POLST: "ձեւափոխման եւ Դատարկումը POLST",
                                    modifying_option1: '"հիվանդը հզորությամբ կարող է ցանկացած ժամանակ պահանջել այլընտրանքային բուժում, կամ ուժը կորցրած է ճանաչում է POLST ցանկացած միջոցներով, որոնք նշված մտադրությունը չեղյալ. խորհուրդ է տրվում, որ չեղյալ է փաստաթղթավորվեն կազմելով մի տող միջոցով բաժիններից մի միջոցով d, գրելու «առոչինչ&quot; - ի խոշոր տառերով, եւ ստորագրման եւ ժամադրությունների այդ գիծը',
                                    modifying_option2: "իրավաբանորեն ճանաչված decisionmaker կարող է պահանջել փոփոխել պատվերներ, հետ համատեղ բժշկի, որը հիմնված է հայտնի ցանկությունների հիվանդի կամ, եթե հայտնի չէ, հիվանդի լավագույն շահերը, հիվանդը փոխանցվում է մեկ խնամքի ընդլայնված կամ խնամքի մակարդակից մյուսին, կամ եթե անհայտ, հիվանդի լավագույն շահերը.",
                                    next_step: "Հաջորդ քայլը",
                                    not_completed_by_physician: "n / a եթե POLST է ավարտվել ստորագրելով բժիշկ",
                                    patient_information: "հիվանդի տեղեկատվություն",
                                    patient_signature_header: "ստորագրությունը հիվանդի կամ իրավաբանորեն ճանաչվի decisionmaker",
                                    patient_signature_legal: "Ես տեղյակ եմ, որ այս ձեւը կամավոր. ստորագրելով այս ձեւը, որ իրավաբանորեն ճանաչվի decisionmaker գիտակցում է, որ այս խնդրանքը կապված resuscitative միջոցառումների համահունչ հայտնի ցանկութիւններով, եւ լավագույն շահերից, հիվանդի, ով առարկա ձեւով.",
                                    physician_contact_warning: "Առաջին հետեւեք այս պատվերներ, ապա դիմեք բժշկի.",
                                    physician_legal: '"իմ ստորագրությունը ստորեւ ցույց է տալիս, որ լավագույն իմ գիտելիքների, որ այդ պատվերները համահունչ հիվանդի առողջական վիճակի եւ նախասիրությունների',
                                    physician_license: "լիցենզիայի համարը",
                                    physician_name: "բժիշկ անունը",
                                    physician_phone: "բժիշկ հեռախոսահամարը",
                                    physician_signature: "բժիշկ ստորագրությունը",
                                    physician_signature_header: "ստորագրությունը բժշկի",
                                    POLST_p1: "երբ դուք լրջորեն հիվանդ է, դարձնելով համոզված է, ձեր սիրելիների եւ բժիշկները գիտեն, թե ինչ տեսակի բուժման դուք ցանկանում եք, ինչպես եք մոտենալ վերջը ձեր կյանքում շատ կարեւոր է. բժիշկ պատվերներ կյանքի համար անհրաժեշտ բուժում (POLST) կարող է օգնել դա անել հենց դա.",
                                    POLST_p2: '"POLST մի ձեւ է, որը հստակ նշում է, թե ինչ տեսակի բժշկական բուժման հիվանդների ցանկանում վերջում իրենց կյանքում',
                                    POLST_p3: '"POLST նաեւ օգնում է ձեզ խոսել ձեր առողջապահական թիմի եւ ձեր սիրելիների եւ ձեր ընտրության. Այս կերպ, POLST կարող է օգնել նվազեցնել համբերատար ու ընտանեկան տառապանքը, եւ համոզվեք, որ ձեր ցանկությունները հայտնի են եւ պատիվ',
                                    provider_directions: "ուղղությունները բժշկի",
                                    reviewing_POLST: "վերանայման POLST",
                                    reviewing_option1: "հիվանդը փոխանցվում է մեկ խնամքի ընդլայնված կամ խնամքի մակարդակից մյուսին, կամ",
                                    reviewing_option2: "կա էական փոփոխություն հիվանդի առողջական վիճակի, կամ",
                                    reviewing_option3: "հիվանդի բուժման նախապատվություններ փոխել.",
                                    reviewing_recommended_when: "խորհուրդ է տրվում, որ POLST է պարբերաբար վերանայվեն. վերանայում, խորհուրդ է տրվում, երբ.",
                                    section_a: "բաժինը է",
                                    section_b: "բաժինը բ",
                                    signatures_header: '"տեղեկատվություն եւ ստորագրությունները',
                                    using_POLST: "օգտագործելով POLST",
                                    using_option1: '"ցանկացած թերի բաժինը POLST ենթադրում է ամբողջական բուժում այդ հատվածում',
                                    using_option2: "եթե պարզվի pulseless, եւ ոչ շնչում, ոչ defibrillator (այդ թվում, ավտոմատացված արտաքին դեֆիբրիլյատորի) կամ կրծքավանդակի compressions պետք է օգտագործվել որպես հիվանդի, ով ընտրել «չեն փորձում վերակենդանացում»",
                                    using_option3: "երբ հարմարավետությունը չի կարող հասնել ներկայիս ընդլայնված նիստում, հիվանդը, այդ թվում, ինչ - որ մեկը, «հարմարավետության կենտրոնացած բուժում,« պետք է փոխանցվում է ընդլայնված կարող է ապահովել հարմարավետության (օրինակ, բուժում է հիփ Կոտրվածք).",
                                    using_option4: "ոչ ինվազիվ դրական օդուղիները ճնշում ներառում շարունակական դրական օդուղիները ճնշում (CPAP), bi-մակարդակի դրական օդուղիները ճնշում (bipap) եւ տոպրակ փական դիմակ (bvm) օժանդակվող respirations.",
                                    using_option5: "IV հակաբիոտիկների եւ hydration, ընդհանուր առմամբ, չեն «Հարմարավետ կենտրոնացած բուժում»",
                                    using_option6: "բուժում ջրազրկելը երկարացնում կյանքը. եթե հիվանդը ցանկությունները IV հեղուկների, ցույց են տալիս, «ընտրողական վերաբերմունք» կամ «լիարժեք բուժում»",
                                    using_option7: "կախված տեղական ems արձանագրության, «լրացուցիչ պատվեր» գրված բաժնում բ չի կարող իրականացվել է EMS անձնակազմի."
                                })
                            .translations(
                                'ja',
                                {
                                    Create_your_POLST_form: 'あなたPOLSTフォームを作成します',
                                    Date_Form_Prepared: '日付の形式を用意します',
                                    Edit_your_POLST_form: 'あなたPOLSTフォームを編集',
                                    HIPAA_disclosure_warning: 'HIPAAは、必要に応じて他の医療提供者にPOLSTの開示を許可します',
                                    Healthcare_Provider_Assisting_with_Form_Preparation: 'フォームの準備を支援医療サービス提供者',
                                    Medical_Record_Number: 'カルテ＃',
                                    Name_last_first_middle: '名前（最後、最初、中央）：',
                                    Patient_Date_of_Birth: '誕生の患者日',
                                    Patient_First_Name: '患者の最初の名前',
                                    Patient_Last_Name: '患者姓',
                                    Patient_Middle_Name: '患者のミドルネーム',
                                    Why_should_I_Fill_Out_a_POLST_Form: 'なぜ<b>POLST</b>フォームに必要事項を記入する必要がありますか？',
                                    ad_option1: '事前指示は、日付',
                                    ad_option2: '事前指示は利用できません',
                                    ad_option3: '何の事前指示はありません',
                                    additional_contact: '付加コンタクト',
                                    additional_name: 'ミドルネーム',
                                    additional_orders: '追加注文',
                                    advance_directive_warning: 'POLSTは、事前指示を補完し、その文書に代わるものではありません。',
                                    artificial_nutrition_heading: '人工的に栄養を投与：',
                                    artificial_nutrition_option1: '栄養チューブを含む長期的な人工栄養、。',
                                    artificial_nutrition_option2: '栄養チューブを含む人工栄養の試用期間、。',
                                    artificial_nutrition_option3: '栄養チューブを含む栄養のない人工的な手段、。',
                                    artificial_nutrition_warning: '実行可​​能かつ必要に応じて、口から食べ物を提供しています。',
                                    available_and_reviewed: '利用可能、レビュー',
                                    birth_date: '生年月日',
                                    check_one: '1をチェック',
                                    choose_your_language: 'あなたの言語を選択します',
                                    completing_POLST: '完成POLST',
                                    completing_option1: 'POLSTフォームを完成することは任意です。カリフォルニア州法はPOLSTフォームは医療提供者が続いていることを必要とし、誠実に遵守する人たちに対する免疫を提供します。病院環境において、患者は、患者の好みと一致する適切な命令を発行する医師によって評価されます。',
                                    completing_option2: 'POLSTは事前指示に代わるものではありません。利用可能な場合、一貫性を確保するために事前指示とPOLSTフォームを確認し、更新フォームは適切に任意の競合を解決します。',
                                    completing_option3: 'POLSTは、患者の好みや医学的適応に基づいて医療提供者によって完了する必要があります。',
                                    completing_option4: '法的に認められdecisionmakerは経口代理、配偶者、登録の国内パートナー、患者の医師が最良のは何を知っていると考えているマイナー、使用可能な最も近い相対的、または人の親を指定、事前指示で指定された国選コンサまたは保護者、剤を含んでいてもよいです患者の最善の利益であると知られている程度の患者の発現し希望や値に応じて決定を下すことになります。',
                                    completing_option5: '患者は能力を欠いているかdecisionmakerの権限がすぐに有効であることを指定した場合にのみ、法的に認められdecisionmakerはPOLSTフォームを実行してもよいです。',
                                    completing_option6: 'POLSTが有効であることを医師と患者またはdecisionmakerが署名しなければなりません。口頭での注文は、施設/コミュニティ政策に応じて医師によるフォローアップのシグネチャを持つ許容可能です。',
                                    completing_option7: '翻訳フォームが患者またはdecisionmakerで使用されている場合は、署名された英語POLSTフォームに添付します。',
                                    completing_option8: '元のフォームの使用が強く推奨されています。署名されたPOLSTフォームの複写やファックスは法的および有効です。コピーが可能な場合、超ピンクの紙の上で、患者の医療記録に保持する必要があります',
                                    cpr_header: '心肺蘇生法（CPR）',
                                    cpr_option1: "<b>試み蘇生/ CPR（a</b>項においてCPRを選択するには、セクションBの完全な治療法を<u><b>選択する必要があります</b></u> ）",
                                    cpr_option2: "<b>蘇生/ DNRをしようとしないでください</b> （自然死を可能にします）",
                                    cpr_warning1: '患者にはパルスがなく、呼吸をしていない場合',
                                    cpr_warning2: '患者が心肺停止にない場合、セクションBとCに命令に従います。',
                                    create_your_account: 'アカウントの作成',
                                    discussed_with: 'して説明：',
                                    discussed_with_decisionmaker: '法的に認められdecisionmaker',
                                    discussed_with_patient: '患者（患者は能力を持っています）',
                                    edit_your_account: 'あなたのアカウントを編集',
                                    family_name: '苗字',
                                    get_started: '始めます',
                                    given_name: 'ファーストネーム',
                                    healthcare_agent: '事前指示で指定されたヘルスケア·エージェントの場合：',
                                    legal_rep_address: '送り先',
                                    legal_rep_name: '印刷名',
                                    legal_rep_phone: '電話番号',
                                    legal_rep_relationship: '関係',
                                    legal_rep_signature: '署名',
                                    legality_section_warning: '署名されたPOLSTフォームのコピーは、法的に有効な医師の指示です。完了していない任意のセクションは、そのセクションのための完全な治療を意味します。',
                                    medical_interventions_header: '医学的介入：',
                                    medical_interventions_option1: "<b><u>フル治療</u> -すべての医学的に効果的な手段によって寿命を延ばすの主な目的。</b> <br />示されるように、選択的治療と快適に焦点を当てた治療に記載されている治療に加えて、挿管、高度な気道介入、機械換気、および電気的除細動を使用しています。",
                                    medical_interventions_option1a: '<b>完全な治療の試用期間。</b>',
                                    medical_interventions_option2: "<b><u>選択的処理</u> - 。</b>示すように<b>厄介な措置を回避しながら、医学的状態を治療する目的</b>快適に焦点を当てた治療に記載されている治 ​​療のほかには、医療、IV抗生物質、およびIV流体を使用しています。挿管はありません。非侵襲性気道陽圧を使用することができます。一般に集中治療を避けます。",
                                    medical_interventions_option2a: "快適ニーズが現在の場所で会ったことができない場合<b><u>に限り、</u>病院への要求転送</b> 。",
                                    medical_interventions_option3: "<b><u>快適に焦点を当てた治療</u> -快適さを最大化の主な目的</b>痛みを緩和し、必要に応じて任意の経路で薬で苦しんで<b>。</b>酸素、吸引、および気道閉塞の手動処理を使用しています。快適な目標と一致しない限り、完全かつ選択的な治療に記載されている治 ​​療法を使用しないでください。 <i><b>病院に要求転送心地のニーズが現在の場所で会ったことができない場合<u>に限られ</u>ます。</b></i>",
                                    medical_interventions_warning: '患者は、パルスで発見され、および/または呼吸している場合',
                                    modifying_POLST: '修正しPOLST排尿',
                                    modifying_option1: '容量を持つ患者は、任意の時点で、代替治療を要求するか、または取り消す意向を示している任意の手段によってPOLSTを取り消すことができます。それは、失効が大きな文字、および署名の「空洞」の書き込み、a〜dのセクションを通る線を描くと、この行に日付を記入することによって文書化されることをお勧めします。',
                                    modifying_option2: '法的に認められdecisionmakerは、患者の既知の欲望や、不明な場合は、患者の最善の利益に基づいて、医師と共同で、注文を変更することを要求することができる、患者は別の治療環境やケアレベルから転送された、または、不明な場合は、患者の最善の利益。',
                                    next_step: '次のステップ',
                                    not_completed_by_physician: "N / A POLSTは、医師に署名することによって完了した場合",
                                    patient_information: '患者情報',
                                    patient_signature_header: '患者の署名または法的に認めdecisionmaker',
                                    patient_signature_legal: '私は、このフォームは任意であることを理解しています。このフォームに署名することによって、法的に認めdecisionmakerは蘇生措置については、この要求は、既知の欲望と一致して、フォームの対象である患者の最善の利益であることを認めています。',
                                    physician_contact_warning: '最初の医師に連絡し、これらの命令に従います。',
                                    physician_legal: '以下に私の署名は、これらの命令は、患者の医学的状態や好みと一致していることを私の知る限りに示します。',
                                    physician_license: 'ライセンス番号',
                                    physician_name: '医師名',
                                    physician_phone: '医師の電話番号',
                                    physician_signature: '医師の署名',
                                    physician_signature_header: '医師の署名',
                                    POLST_p1: 'あなたが重病である場合、あなたが近づくとあなたの愛する人と、医師があなたの人生の終わりが非常に重要で、必要な医療の種類を知っているを確認すること。生命維持治療（POLST）のための医師のオーダーはちょうどそれを行うことができます。',
                                    POLST_p2: 'POLSTは明らかに医療患者の種類は、彼らの人生の終わりに向かって何をしたいと述べフォームです。鮮やかなピンクの紙に印刷し、医師と患者の双方が署名し、POLSTは真剣に、病気の患者に彼らの世話をより詳細に制御を与えることができます。',
                                    POLST_p3: 'POLSTはまた、あなたの医療チームとあなたの選択についてあなたの愛する人と話をするのに役立ちます。このように、POLSTは、患者と家族の苦しみを軽減し、あなたの願いが知られており、光栄されていることを確認することができます。',
                                    provider_directions: '医療サービス提供者のための指示',
                                    reviewing_POLST: '見直しPOLST',
                                    reviewing_option1: '患者は別の治療環境やケアレベルから転送されますか、',
                                    reviewing_option2: 'そこに患者の健康状態の実質的な変化がありますか、',
                                    reviewing_option3: '患者の治療の好みが変化します。',
                                    reviewing_recommended_when: 'それはPOLSTが定期的にレビューすることをお勧めします。レビューはときにお勧めします。',
                                    section_a: 'セクションA',
                                    section_b: 'セクションB',
                                    signatures_header: '情報と署名：',
                                    using_POLST: 'POLSTを使用して、',
                                    using_option1: 'POLSTの不完全なセクションは、そのセクションの完全な治療を意味します。',
                                    using_option2: '脈見つかり、呼吸していない場合は、何も（自動体外式除細動器を含む）除細動器や胸部圧迫は、選択された患者に使用されるべきではない」蘇生を試みないでください。」',
                                    using_option3: '快適が現在の設定で実現することができない場合には、持つ人を含む患者、「快適性に焦点を当てた治療は、「快適さを提供することができる設定に転送する必要があります（例えば、股関節骨折の治療）。',
                                    using_option4: '非侵襲性気道陽圧は、持続的気道陽圧（CPAP）、バイレベル気道陽圧（BIPAP）、及びバッグバルブマスク（BVM）呼吸補助を含みます。',
                                    using_option5: 'IV抗生物質および水和は、一般的に「慰安に焦点を当てた治療。」ではありません',
                                    using_option6: '脱水の治療は、寿命を延ばします。患者は静脈内輸液を希望する場合、「選択的治療」または示す「完全治療を。」',
                                    using_option7: 'ローカルEMSのプロトコルに応じて、セクションBに書き込まれた「追加注文」は、EMS担当者によって実装されない場合があります。'
                                })
                            .translations(
                                'ko',
                                {
                                    Create_your_POLST_form: "당신의 POLST 양식을 만들",
                                    Date_Form_Prepared: "날짜 형태는 제조",
                                    Edit_your_POLST_form: "당신의 POLST 양식을 편집",
                                    HIPAA_disclosure_warning: "HIPAA는 필요에 따라 다른 의료 서비스 제공자에 POLST의 공개를 허용",
                                    Healthcare_Provider_Assisting_with_Form_Preparation: "양식 준비와 지원 의료 공급자",
                                    Medical_Record_Number: "의료 기록 #",
                                    Name_last_first_middle: '"이름 (성, 이름, 중간)',
                                    Patient_Date_of_Birth: "출생의 환자 날짜",
                                    Patient_First_Name: "환자 이름",
                                    Patient_Last_Name: "환자의 성",
                                    Patient_Middle_Name: "환자의 중간 이름",
                                    Why_should_I_Fill_Out_a_POLST_Form: "이유는 <b>POLST</b> 양식을 작성해야합니까?",
                                    ad_option1: "사전 의료 지시서​​는 일자",
                                    ad_option2: "사용할 수없는 사전 의료 지시서",
                                    ad_option3: "아무런 사전 지시서 없습니다",
                                    additional_contact: "추가 접촉",
                                    additional_name: "중간 이름",
                                    additional_orders: "추가 주문",
                                    advance_directive_warning: "POLST는 사전 지시서를 보완하고 해당 문서를 대체하기위한 것이 아닙니다.",
                                    artificial_nutrition_heading: '"인위적으로 영양 관리',
                                    artificial_nutrition_option1: "장기 공급 튜브를 포함하는 인공 영양,.",
                                    artificial_nutrition_option2: "공급 튜브를 포함하는 인공 영양의 시험 기간.",
                                    artificial_nutrition_option3: "공급 튜브를 포함하여 영양의 인공 수단.",
                                    artificial_nutrition_warning: "가능 원하는 경우 입으로 음식을 제공합니다.",
                                    available_and_reviewed: "가능하고 검토",
                                    birth_date: '생년월일',
                                    check_one: "하나를 확인",
                                    choose_your_language: "언어를 선택하세요",
                                    completing_POLST: "완료 POLST",
                                    completing_option1: "POLST 양식을 작성하는 것은 자발적이다. 캘리포니아 법은 POLST 양식 의료 공급자가 따라야 할 것을 요구하고, 성실하게 준수하는 사람들에게 면역을 제공합니다. 병원 환경에서, 환자는 환자의 선호도와 일치하는 적절한 명령을 발행 할 것이다 의사에 의해 평가 될 것이다.",
                                    completing_option2: "POLST는 사전 의료 지시서​​를 대체하지 않습니다. 가능한 경우, 일관성을 보장하기 위해 사전 의료 지시서​​ 및 POLST 양식을 검토하고 갱신 형태는 적절하게 충돌을 해결하기 위해.",
                                    completing_option3: "POLST는 환자의 환경 및 의료 지시에 따라 의료 공급자에 의해 완료해야합니다.",
                                    completing_option4: "법적으로 인정 결정권자 가장 무엇을 알고있는 환자의 의사가 믿고 법원이 임명 관리인 또는 보호자, 대리인 사전 지시서에 지정, 구두로 지정 대리, 배우자, 등록 동거인, 부모 미성년자의, 가장 가까운 가능한 친척, 또는 사람을 포함 할 수있다 환자의 최선의 이익에 알려진 정도에 환자의 표현 소원과 값에 따라 결정을 내릴 것입니다.",
                                    completing_option5: "환자가 용량이 부족하거나 의사 결정자의 권한은 즉시 효과가 있음을 지정된 경우에만 법적으로 인정 결정권자는 POLST 양식을 실행할 수 있습니다.",
                                    completing_option6: "POLST는 의사의 서명이 있어야하고 환자 나 의사 결정자가 유효 할 수 있습니다. 구두 주문은 시설 / 지역 사회 정책에 따라 의사의 후속 서명이 허용됩니다.",
                                    completing_option7: "번역 형태는 환자 나 의사 결정자와 함께 사용하는 경우, 서명 영어 POLST 양식에 첨부.",
                                    completing_option8: "원래의 형태로 사용할 것을 강력히 권장합니다. 사본 및 서명 POLST 양식의 팩스는 법적 유효합니다. 사본은 매우 분홍색 종이에, 환자의 의료 기록을 유지해야 가능하면",
                                    cpr_header: "심폐 소생술 (CPR)",
                                    cpr_option1: "<b>소생 / 심폐 소생술을 시도</b> (섹션에서 심폐 소생술을 선택하면 섹션 B에서 전체 치료를 <u><b>선택해야 함)</b></u>",
                                    cpr_option2: "<b>소생 / 노이즈 제거를 시도하지 마십시오</b> (자연의 죽음을 허용)",
                                    cpr_warning1: "환자는 맥박이없고 호흡을하지 않는 경우",
                                    cpr_warning2: "환자가 심폐 정지에없는 경우, 섹션 B와 C의 명령을 따르십시오.",
                                    create_your_account: "계정을 만들",
                                    discussed_with: '"논의',
                                    discussed_with_decisionmaker: "법적으로 인정 결정권자",
                                    discussed_with_patient: "환자 (환자는 용량을하고있다)",
                                    edit_your_account: "당신의 계정을 편집",
                                    family_name: '성',
                                    get_started: '시작',
                                    given_name: '이름',
                                    healthcare_agent: '"의료 에이전트는 사전 의료 지시서​​에 이름이있는 경우',
                                    legal_rep_address: "우편 주소",
                                    legal_rep_name: "인쇄 이름",
                                    legal_rep_phone: "전화 번호",
                                    legal_rep_relationship: '관계',
                                    legal_rep_signature: '서명',
                                    legality_section_warning: "서명 POLST 양식의 복사본은 법적으로 유효한 의사 순서입니다. 완료되지 않은 부분은 그 부분에 대한 완전한 치료를 의미한다.",
                                    medical_interventions_header: '"의료 개입',
                                    medical_interventions_option1: "<b><u>전체 치료</u> - 모든 의학적 효과에 의해 수명을 연장의 주요 목표.</b> <br /> 선택적 치료와 편안함에 초점을 맞춘 치료를 사용 삽관, 고급기도 개입, 기계 호흡 및 심장 율동에 설명 된 치료에 추가하여 지시 된 바와 같이.",
                                    medical_interventions_option1a: "<b>전체 치료의 시험 기간.</b>",
                                    medical_interventions_option2: "<b><u>선택적 치료</u> -. 부담 조치를 피하면서 건강 상태를 치료의 목표</b> 편안함에 초점을 맞춘 치료에 설명 된 치료 외에는, 지시 된 바와 같이 치료, IV 항생제 및 IV 유체를 사용합니다. 삽관하지 않습니다. 비 침습적 긍정적 인기도 압력을 사용할 수 있습니다. 일반적으로 집중 치료를 피할 수 있습니다.",
                                    medical_interventions_option2a: "편안함의 요구가 현재 위치에서 충족 될 수없는 <b><u>경우에만</u> 병원에 요청 전송.</b>",
                                    medical_interventions_option3: "<b><u>편안함에 초점을 맞춘 치료</u> - 편안함을 극대화의 기본 목표</b> 고통과 필요에 따라 어떤 경로로 약물 치료와 고통을 <b>완화.;</b> 산소, 흡인, 및기도 폐쇄의 수동 치료를 사용합니다. 전체 및 선택적 치료 편안함 목표와 일치하지 않는. <i><b>편안한 요구 현재 위치에서 충족 될 수없는 <u>경우에만</u> 병원에 요청 전송에</b></i> 나열된 처리를 사용하지 <i><b>마십시오.</b></i>",
                                    medical_interventions_warning: "환자는 펄스 발견 및 / 또는 호흡되면",
                                    modifying_POLST: "수정하고 POLST 무효화",
                                    modifying_option1: "용량을 가진 환자는 언제든지 대체 치료를 요청하거나 취소 할 의도를 나타내는 수단으로 POLST을 취소 할 수 있습니다. 그것은 해지 큰 글자, 서명에 &quot;무효&quot;쓰기, D를 통해 섹션을 통해 선 그리기 및이 줄을 데이트를 문서화하는 것이 좋습니다.",
                                    modifying_option2: "법적으로 인정 결정권자는 알려진 환자의 욕망 또는 알 수없는 경우, 환자의 최선의 이익이 환자가 하나의 관리 설정에서 전송되거나 다른 수준의 관심, 또는에 따라 의사와 공동으로, 주문을 수정 요청할 수있다 알 수없는 경우, 환자의 최선의 이익.",
                                    next_step: "다음 단계",
                                    not_completed_by_physician: "N / A POLST는 의사의 서명이 완료되면",
                                    patient_information: "환자 정보",
                                    patient_signature_header: "환자의 서명 또는 법적으로 인정 결정권자",
                                    patient_signature_legal: "나는이 양식은 자발적인 것으로 알고 있습니다. 이 양식에 서명함으로써, 법적으로 인정 결정권자는 소생 조치에 대한 요청이의 알려진 욕망과 일관성이 있음을 인정하고, 양식의 대상이되는 환자의 최선의 이익에.",
                                    physician_contact_warning: "먼저 의사에게 연락,이 명령을 따르십시오.",
                                    physician_legal: "내 서명은 아래에 이러한 주문이 환자의 건강 상태 및 환경 설정과 일치하는지 내 지식의 최선을 나타냅니다.",
                                    physician_license: "라이센스 번호",
                                    physician_name: "의사 이름",
                                    physician_phone: "의사 전화 번호",
                                    physician_signature: "의사 서명",
                                    physician_signature_header: "의사의 서명",
                                    POLST_p1: "당신이 확인하고, 중증 때 사랑하는 사람과 의사는 당신의 인생의 끝이 매우 중요하다 접근으로 당신이 원하는 치료의 종류 알고있다. 생명 유지 치료 (POLST)에 대한 의사의 주문은 그냥 할 수 있습니다.",
                                    POLST_p2: "POLST 명확하게 치료 환자의 종류는 그들의 삶의 끝으로 원하는 것을 진술 한 형태이다. 의사와 환자 모두 밝은 분홍색 종이에 인쇄, 서명, POLST 심각-병 환자에게 자신의 치료에 대한 제어를 제공하는 데 도움이됩니다.",
                                    POLST_p3: "POLST은 또한 당신의 의료 팀과 당신의 선택에 대한 당신의 사랑하는 사람과 이야기하는 데 도움이됩니다. 이러한 방식으로, POLST는 환자와 가족의 고통을 줄이는 데 도움이, 그리고 당신의 소원이 알려진 영광 있는지 확인 할 수 있습니다.",
                                    provider_directions: "의료 공급자에 대한 방향",
                                    reviewing_POLST: "검토 POLST",
                                    reviewing_option1: "환자는 다른 하나의 관리 설정 또는 관리 수준에서 전송, 또는",
                                    reviewing_option2: "이 환자의 건강 상태에서 실질적인 변화가 있거나",
                                    reviewing_option3: "환자의 치료 환경 설정을 변경할 수 있습니다.",
                                    reviewing_recommended_when: '"그것은 POLST는 정기적으로 검토하는 것이 좋습니다. 검토는시 추천',
                                    section_a: '섹션',
                                    section_b: "섹션 B",
                                    signatures_header: '"정보 및 서명',
                                    using_POLST: "POLST를 사용하여",
                                    using_option1: "POLST의 완료되지 않은 부분은 그 부분에 대한 완전한 치료를 의미한다.",
                                    using_option2: "맥박이 발견되지 않는 경우 (자동 외부 제세 동기 포함)에는 제세 동기 호흡하거나 흉부 압박을 선택한 환자에 사용되어야한다 &quot;인공 호흡을 시도하지 마십시오.&quot;",
                                    using_option3: "편안함은 현재의 설정으로 달성 할 수없는 경우에, 가진 사람 환자를 포함하여, &quot;안락 집중 치료&quot;편안함을 제공 할 수있는 설정으로 전송한다 (예를 들면, 고관절 골절의 치료).",
                                    using_option4: "비 침습적 긍정적 인기도 압력은 지속적기도 양압 (CPAP), 양방향 레벨기도 양압 (bipap) 및 백 밸브 마스크 (BVM)를 이용한 호흡이 포함되어 있습니다.",
                                    using_option5: "IV 항생제 및 수화는 일반적으로 &quot;편안함에 초점을 맞춘 치료.&quot;아니다",
                                    using_option6: "탈수의 치료는 수명을 연장. 환자가 IV 유체를 원한다면, &quot;선택적 치료&quot;또는 표시 &quot;전체 치료를.&quot;",
                                    using_option7: "지역 EMS 프로토콜에 따라, 섹션 B에 쓰여진 &quot;추가 주문은&quot;EMS 담당자가 구현되지 않을 수 있습니다."
                                })
                            .translations(
                                'ru',
                                {
                                    Create_your_POLST_form: "создать свой POLST форму",
                                    Date_Form_Prepared: "Дата форму, подготовленную",
                                    Edit_your_POLST_form: "редактировать POLST форму",
                                    HIPAA_disclosure_warning: "HIPAA позволяет раскрытие POLST других медицинских работников, как надо",
                                    Healthcare_Provider_Assisting_with_Form_Preparation: "Поставщик здравоохранения помощи в подготовке форм",
                                    Medical_Record_Number: "медицинская запись #",
                                    Name_last_first_middle: '"наименование (фамилию, имя, отчество)',
                                    Patient_Date_of_Birth: "Дата рождения пациента",
                                    Patient_First_Name: "Пациент имя",
                                    Patient_Last_Name: "Пациент фамилия",
                                    Patient_Middle_Name: "Пациент отчество",
                                    Why_should_I_Fill_Out_a_POLST_Form: "почему я должен заполнить форму <b>POLST?</b>",
                                    ad_option1: "директивный от",
                                    ad_option2: "директивный не доступен",
                                    ad_option3: "нет директивный",
                                    additional_contact: "дополнительный контакт",
                                    additional_name: 'отчество',
                                    additional_orders: "дополнительные заказы",
                                    advance_directive_warning: "POLST дополняет предварительное распоряжение и не предназначено заменить этот документ.",
                                    artificial_nutrition_heading: '"искусственно вводить питание',
                                    artificial_nutrition_option1: "долгосрочные искусственное питание, в том числе питательные трубки.",
                                    artificial_nutrition_option2: "испытательный срок искусственного питания, в том числе питательные трубки.",
                                    artificial_nutrition_option3: "нет искусственных средств питания, в том числе питательные трубки.",
                                    artificial_nutrition_warning: "предложить пищу через рот, если это возможно и желательно.",
                                    available_and_reviewed: "доступны и рассмотрены",
                                    birth_date: "Дата рождения",
                                    check_one: "проверить один",
                                    choose_your_language: "выбрать язык",
                                    completing_POLST: "завершения POLST",
                                    completing_option1: "завершения POLST форму является добровольным. Калифорнийский закон требует, чтобы POLST форма последует медицинских работников, а также предоставляет иммунитет тем, кто добросовестно выполнять. в условиях стационара, пациент будет оцениваться врачом, который будет выдавать соответствующие приказы, которые согласуются с предпочтениями пациента.",
                                    completing_option2: "POLST не заменит предварительные указания. если таковой имеется, рассмотреть предварительные указания и POLST форму, чтобы обеспечить согласованность и обновление формы надлежащим образом решить любые конфликты.",
                                    completing_option3: "POLST должны быть завершены к поставщику здравоохранения на основе предпочтений пациента и медицинских показаний.",
                                    completing_option4: "юридически признано лицо, принимающее решения, может включать в себя консерватора назначенный судом или опекуна, агент, назначенный в передовой директивы, в устной форме, обозначенный суррогат, супруга, зарегистрированный внутренний партнера, родителя несовершеннолетнего, ближе доступен родственника или человека, которого врач пациента считает знает лучше, что В интересах пациента и будет принимать решения в соответствии с выраженными пожеланиями пациента и ценностей в известной степени.",
                                    completing_option5: "юридически признаны лицо, принимающее решения, может выполнить POLST форму, только если пациент не способность или назначил, что полномочия лицо, принимающее решения в силу немедленно.",
                                    completing_option6: "POLST должен быть подписан врачом и пациент или лицо, принимающее решения в силе. словесные заказы приемлемы с последующей подписания врачом в соответствии с политикой объект / сообщества.",
                                    completing_option7: "если перевод форма используется с пациентом или лицо, принимающее решения, приложите его к подписанному английский POLST форме.",
                                    completing_option8: "Использование оригинальной формы настоятельно рекомендуется. ксерокопии и факсы подписанных POLST форм законным и обоснованным. Копия должна быть сохранена в медицинской пациента, на ультра розовой бумаге, когда можно",
                                    cpr_header: "сердечно-легочная реанимация (СЛР)",
                                    cpr_option1: "<b>попытаться реанимации / CPR</b> (СЛР выбора в разделе а <u><b>требуется выбрать</b></u> полный курс лечения в разделе б)",
                                    cpr_option2: "<b>не пытайтесь реанимации / DNR</b> (позволить естественной смертью)",
                                    cpr_warning1: "если пациент не имеет импульса и не дышит",
                                    cpr_warning2: "если пациент не в сердечно ареста, выполнять приказы в разделах В и С.",
                                    create_your_account: "создать учетную запись",
                                    discussed_with: '"обсудил с',
                                    discussed_with_decisionmaker: "юридически признаны лицо, принимающее решения",
                                    discussed_with_patient: "Пациент (пациент имеет емкость)",
                                    edit_your_account: "редактировать запись",
                                    family_name: 'Фамилия',
                                    get_started: 'начать',
                                    given_name: 'имя',
                                    healthcare_agent: '"здравоохранение агентом, если назван в заранее директивы',
                                    legal_rep_address: "почтовый адрес",
                                    legal_rep_name: "Имя печать",
                                    legal_rep_phone: "номер телефона",
                                    legal_rep_relationship: 'отношения',
                                    legal_rep_signature: 'подпись',
                                    legality_section_warning: "Копия подписанного POLST форме юридическую силу порядок врач. любое сечение не завершена подразумевает полное лечение для этого раздела.",
                                    medical_interventions_header: '"медицинские вмешательства',
                                    medical_interventions_option1: "<b><u>Полная обработка</u> - главная цель продления жизни всех медицинской эффективных средств.</b> <br /> в дополнение к лечению, описанному в селективной очистки и обработки комфорт-ориентированной, использование передовых интубации, вмешательства дыхательных путей, ИВЛ, и кардиоверсии, как указано.",
                                    medical_interventions_option1a: "<b>Испытательный срок полного излечения.</b>",
                                    medical_interventions_option2: "<b><u>селективная очистка</u> -. Цель лечения заболеваний, избегая обременительных мер</b> в дополнение к лечению, описанному в лечении комфорт-ориентированной, использовать медицинское лечение, внутривенные антибиотики, и капельницу, как указано. не интубации. может использовать неинвазивный положительное давление в дыхательных путях. как правило, избегают интенсивной терапии.",
                                    medical_interventions_option2a: "<b>запрос на перенос в больницу, <u>только</u></b> если потребности комфорт не могут быть удовлетворены в текущем местоположении.",
                                    medical_interventions_option3: "<b><u>комфорт-ориентированного лечения</u> - основная цель максимизации комфорта</b> облегчить боль и страдания с помощью лекарств по любому маршруту, как <b>необходимого.</b> использовать кислород, отсасывание, и ручное лечение обструкции дыхательных путей. не используйте лечения, перечисленные в полной и селективной очистки, если в соответствии с комфортом цели. <i><b>передачи запроса в больницу, <u>только</u> если потребности комфорт не могут быть удовлетворены в текущем местоположении.</b></i>",
                                    medical_interventions_warning: "если пациент находится с импульсом и / или дыхания",
                                    modifying_POLST: "изменения и аннулирования POLST",
                                    modifying_option1: "Пациент с мощностью может в любое время запросить альтернативное лечение или отменить POLST любыми средствами, которые указывает намерение отозвать. рекомендуется отзыва документально рисуя линию через разделах через D, писать &quot;пустота&quot; большими буквами, и подписания и знакомства эту линию.",
                                    modifying_option2: "юридически признано лицо, принимающее решения, может просить, чтобы изменить заказы, в сотрудничестве с врачом, на основе известных желаний пациента или, если неизвестно, интересы пациента, больного переводят из одной установки по уходу или заботятся уровня на другой, или , если неизвестно, интересы пациента.",
                                    next_step: "следующий шаг",
                                    not_completed_by_physician: "н / если POLST завершается подписанием врачу",
                                    patient_information: "информация для пациентов",
                                    patient_signature_header: "подпись пациента или юридически признаны лицо, принимающее решения",
                                    patient_signature_legal: "я знаю, что эта форма является добровольным. подписав эту форму, юридически признаны лицо, принимающее решения признает, что этот запрос о реанимационных мероприятий согласуется с известными желаний, и интересах, пациента, который является предметом виде.",
                                    physician_contact_warning: "сначала выполните эти заказы, то свяжитесь с врачом.",
                                    physician_legal: "моя подпись ниже указывает, насколько мне известно, что эти заказы в соответствии с состоянием здоровья, и предпочтений пациента.",
                                    physician_license: "лицензионный номер",
                                    physician_name: "Врач имя",
                                    physician_phone: "Врач номер телефона",
                                    physician_signature: "врач подпись",
                                    physician_signature_header: "подпись врача",
                                    POLST_p1: "когда вы серьезно больны, убедившись, что ваши близкие и врачи знают, какие виды лечения вы хотите, как вы приближаетесь к концу своей жизни это очень важно. врач заказы для поддержания жизни лечения (POLST) может помочь сделать это.",
                                    POLST_p2: "POLST это форма, ясно говорится, что виды медицинских лечения больных хотят к концу своей жизни. печатаются на ярко-розовой бумаге, и подписанное как врача и пациента, POLST помогает дать серьезно больных пациентов больше контроля над их помощи.",
                                    POLST_p3: "POLST также поможет вам поговорить с вашей командой здравоохранения и ваших близких о ваших вариантов. таким образом, POLST может помочь уменьшить пациента и семьи страдания, и убедитесь, что ваши пожелания известны, и честь.",
                                    provider_directions: "направления врачом",
                                    reviewing_POLST: "обзор POLST",
                                    reviewing_option1: "Пациент переведен из одного ухода установки или ухода уровня на другой, или",
                                    reviewing_option2: "есть существенное изменение в состоянии здоровья пациента, или",
                                    reviewing_option3: "предпочтения лечения пациента изменить.",
                                    reviewing_recommended_when: '"рекомендуется POLST периодически пересматриваться. Обзор рекомендуется при',
                                    section_a: 'раздел',
                                    section_b: "Раздел B",
                                    signatures_header: '"Информация и подписи',
                                    using_POLST: "используя POLST",
                                    using_option1: "любой неполной раздел POLST подразумевает полное лечение для этого раздела.",
                                    using_option2: "если нашли пульса и дыхания не не, не дефибриллятор (в том числе автоматизированных внешних дефибрилляторов) или непрямой массаж сердца должны быть использованы на пациента, который выбрал &quot;не пытайтесь реанимации.&quot;",
                                    using_option3: "когда комфорт не могут быть достигнуты в текущем месте, пациент, в том числе кто-то с &quot;лечения комфорт-ориентированной&quot;, должны быть переданы в обстановке, способной обеспечить комфорт (например, лечение перелома шейки бедра).",
                                    using_option4: "неинвазивным положительным давлением в дыхательных путях включает в себя непрерывное положительное давление в дыхательных путях (CPAP), двухуровневое положительное давление в дыхательных путях (BiPAP), и маску мешок клапана (BVM) помогали Дыхание.",
                                    using_option5: "IV антибиотики и увлажнение, как правило, не &quot;лечение комфорт-ориентированной.&quot;",
                                    using_option6: "лечение обезвоживания продлевает жизнь. если пациент желает капельницу, указывают на &quot;селективной очистки&quot; или &quot;полный курс лечения.&quot;",
                                    using_option7: "в зависимости от местных протокола EMS, &quot;дополнительные заказы&quot;, написанные в разделе б не могут быть реализованы персонала EMS."
                                })
                            .translations(
                                'tl',
                                {
                                    Create_your_POLST_form: "lumikha ng iyong POLST anyo",
                                    Date_Form_Prepared: "naghanda ng form na petsa",
                                    Edit_your_POLST_form: "i-edit ang iyong mga POLST anyo",
                                    HIPAA_disclosure_warning: "Pinapahintulutan hipaa pagsisiwalat ng POLST sa iba pang mga healthcare providers tulad ng kinakailangan",
                                    Healthcare_Provider_Assisting_with_Form_Preparation: "healthcare provider ang pagtulong sa paghahanda ng form",
                                    Medical_Record_Number: "medical record #",
                                    Name_last_first_middle: '"name (huling, una, gitna)',
                                    Patient_Date_of_Birth: "pasyente na petsa ng kapanganakan",
                                    Patient_First_Name: "pasyente unang pangalan",
                                    Patient_Last_Name: "pasyente apelyido",
                                    Patient_Middle_Name: "pasyente gitnang pangalan",
                                    Why_should_I_Fill_Out_a_POLST_Form: "kung bakit ako ay dapat na punan ang isang <b>POLST</b> form?",
                                    ad_option1: "paunang direktiba na may petsang",
                                    ad_option2: "Hindi magagamit ang paunang tagubilin",
                                    ad_option3: "walang paunang direktiba",
                                    additional_contact: "karagdagang contact",
                                    additional_name: "gitnang pangalan",
                                    additional_orders: "karagdagang mga order",
                                    advance_directive_warning: "POLST complements ang paunang tagubilin at hindi inilaan upang palitan ang dokumento na iyon.",
                                    artificial_nutrition_heading: '"artipisyal na pinangangasiwaan nutrisyon',
                                    artificial_nutrition_option1: "pang-matagalang artipisyal na nutrisyon, kabilang ang pagpapakain tubes.",
                                    artificial_nutrition_option2: "panahon ng pagsubok ng mga artipisyal na nutrisyon, kabilang ang pagpapakain tubes.",
                                    artificial_nutrition_option3: "walang artipisyal na paraan ng nutrisyon, kabilang ang pagpapakain tubes.",
                                    artificial_nutrition_warning: "Nag-aalok ng pagkain sa pamamagitan ng bibig kung magagawa at ninanais.",
                                    available_and_reviewed: "magagamit at susuriin",
                                    birth_date: "petsa ng kapanganakan",
                                    check_one: "check isa",
                                    choose_your_language: "piliin ang iyong wika",
                                    completing_POLST: "pagkumpleto POLST",
                                    completing_option1: "pagkumpleto ng isang POLST form ay kusang-loob. nangangailangan ng California law na ang isang POLST anyo ay sinundan sa pamamagitan ng kalusugan, at nagbibigay ng kaligtasan sa sakit sa mga taong sumunod sa mabuting pananampalataya. sa mga setting ng ospital, ang isang pasyente ay tinasa sa pamamagitan ng isang manggagamot na isyu ng angkop na mga order na pare-pareho sa mga kagustuhan ng pasyente.",
                                    completing_option2: "POLST hindi palitan ang paunang tagubilin. kapag available, repasuhin ang mga paunang tagubilin at POLST form na ito upang matiyak na ang pagbabago, at i-update ang mga anyo nang naaangkop upang malutas ang anumang mga kontrahan.",
                                    completing_option3: "ay dapat na makumpleto POLST sa pamamagitan ng isang healthcare provider batay sa mga kagustuhan ng pasyente at indications medikal.",
                                    completing_option4: "isang legal na kinikilala decisionmaker maaaring magsama ng isang hukuman-hinirang conservator o tagapangalaga, ahente itinalaga sa isang paunang direktiba, pasalita itinalagang kahalili, asawa, nakarehistrong domestic partner, magulang ng isang menor de edad, na pinakamalapit na makukuha ng kamag-anak, o tao kung kanino naniniwala manggagamot ng pasyente pinakamahusay na nakakaalam kung ano sa pinakamabuting kapakanan ng pasyente at gumawa ng mga desisyon ayon sa ipinahayag kagustuhan ng pasyente at mga halaga sa lawak na kilala.",
                                    completing_option5: "isang legal na kinikilala decisionmaker maaaring maipatupad ang POLST form na lamang kung ang pasyente kawalan ng kapasidad o may nakatakdang na awtoridad ang decisionmaker ay epektibo kaagad.",
                                    completing_option6: "POLST dapat na pinirmahan ng isang doktor at mga pasyente o decisionmaker na wasto. pandiwang order ay katanggap-tanggap sa mga follow-up na lagda sa pamamagitan ng manggagamot alinsunod sa patakaran pasilidad / komunidad.",
                                    completing_option7: "kung ang isang isinalin form ay ginagamit sa mga pasyente o decisionmaker, ilakip ito sa mga naka-sign english POLST form.",
                                    completing_option8: "paggamit ng orihinal na form ay Matindi hinihikayat. photocopy at faxes ng sign POLST forms ay legal at balido. isang kopya ay dapat na mananatili sa medical record ng pasyente, sa ultra pink paper kapag posible",
                                    cpr_header: "cardiopulmonary resuscitation (CPR)",
                                    cpr_option1: "<b>pagtatangka resuscitation / cpr</b> (pagpili ng cpr sa seksyon ng <u><b>ay nangangailangan ng pagpili ng</b></u> buong paggamot sa section b)",
                                    cpr_option2: "<b>hindi pagtatangka resuscitation / dnr</b> (payagan natural kamatayan)",
                                    cpr_warning1: "kung ang pasyente ay walang pulso at hindi paghinga",
                                    cpr_warning2: "kung ang pasyente ay hindi sa cardiopulmonary aresto, sundin ang mga order sa mga seksyon b at c.",
                                    create_your_account: "lumikha ng iyong account",
                                    discussed_with: '"napag-usapan na may',
                                    discussed_with_decisionmaker: "kinikilala ng batas decisionmaker",
                                    discussed_with_patient: "pasyente (mga pasyente ay may kapasidad)",
                                    edit_your_account: "i-edit ang iyong account",
                                    family_name: 'apelyido',
                                    get_started: 'makapagsimula',
                                    given_name: 'pangalan',
                                    healthcare_agent: '"healthcare ahente kung pinangalanan sa paunang tagubilin',
                                    legal_rep_address: "mailing address",
                                    legal_rep_name: "name print",
                                    legal_rep_phone: "numero ng telepono",
                                    legal_rep_relationship: 'relasyon',
                                    legal_rep_signature: 'lagda',
                                    legality_section_warning: "isang kopya ng mga naka-sign POLST form ay isang legal na may bisa manggagamot order. Nagpapahiwatig anumang mga seksyon hindi nakumpleto ang buong paggamot para sa section na iyon.",
                                    medical_interventions_header: '"medical pamamagitan',
                                    medical_interventions_option1: "<b><u>buong paggamot</u> - pangunahing layunin ng prolonging buhay ng lahat ng mga medikal na epektibong paraan.</b> <br /> bilang karagdagan sa paggamot ng inilarawan sa mga pumipili ng paggamot at kaginhawahan-pokus sa paggamot, ang paggamit intubation, advanced na daan pamamagitan, mekanikal bentilasyon, at cardioversion gaya ng ipinahiwatig.",
                                    medical_interventions_option1a: "<b>panahon ng pagsubok ng buong paggamot.</b>",
                                    medical_interventions_option2: "<b><u>pumipili ng paggamot</u> -. layunin ng pagpapagamot ng mga medikal na kondisyon habang pag-iwas pabigat panukala</b> sa karagdagan sa paggamot ng inilarawan sa kaginhawaan-pokus sa paggamot, gamitin ang mga medikal na paggamot, iv antibiotics, at iv fluids tulad ng ipinahiwatig. huwag intubate. Maaaring gamitin ng mga di-nagsasalakay positibong daan presyon. karaniwang maiwasan intensive care.",
                                    medical_interventions_option2a: "<b>transfer kahilingan sa ospital <u>lamang</u></b> kung ang pangangailangan kaginhawaan hindi maaaring matugunan sa kasalukuyang lokasyon.",
                                    medical_interventions_option3: "<b><u>aliw-pokus sa paggamot</u> - ang pangunahing layunin ng pag-maximize sa kaginhawaan</b> hitsura sakit at paghihirap sa mga gamot sa pamamagitan ng anumang mga ruta bilang <b>kinakailangan.;</b> gamitin ang oxygen, suctioning, at mano-manong paggamot ng daan-abala. huwag gumamit ng paggamot na nakalista sa buong at pumipili paggamot maliban kung pare-pareho sa mga layunin na kaginhawahan. <i><b>transfer kahilingan sa ospital <u>lamang</u> kung ang mga pangangailangan ng kaginhawaan ay hindi maaaring matugunan sa kasalukuyang lokasyon.</b></i>",
                                    medical_interventions_warning: "kung ang pasyente ay natagpuan sa isang tibok at / o ay paghinga",
                                    modifying_POLST: "pagbabago at voiding POLST",
                                    modifying_option1: "isang pasyente na may kapasidad maaari, sa anumang oras, humiling ng alternatibong paggamot o bawiin ang isang POLST pamamagitan ng anumang paraan na nagpapahiwatig ng layunin upang bawiin. ito ay inirerekomenda na ang pagbawi ay dokumentado sa pamamagitan ng pagguhit ng isang linya sa pamamagitan ng mga seksyon ng isang sa pamamagitan d, pagsulat &quot;void&quot; sa malalaking titik, at pag-sign at dating ng linyang ito.",
                                    modifying_option2: "isang legal na kinikilala decisionmaker ay maaaring humiling na baguhin ang mga order, sa pakikipagtulungan sa mga manggagamot, batay sa mga kilalang kagustuhan ng pasyente o, kung hindi kilala, pinakamahusay na mga interes ng pasyente, ang pasyente ay inilipat mula sa isang setting ng pangangalaga o pag-aalaga sa antas sa iba, o , kung hindi kilala, pinakamahusay na mga interes ng pasyente.",
                                    next_step: "susunod na hakbang",
                                    not_completed_by_physician: "n / a kung POLST ay natapos sa pamamagitan ng pag-sign manggagamot",
                                    patient_information: "pasyente na impormasyon",
                                    patient_signature_header: "pirma ng pasyente o kinikilala ng batas decisionmaker",
                                    patient_signature_legal: "i am ng kamalayan na ang form na ito ay kusang-loob. sa pamamagitan ng pagpirma ng form na ito, ang mga legal na kinikilala decisionmaker Kinikilala na ang kahilingang ito hinggil resuscitative panukala ay pare-pareho sa mga kilala hinahangad ng, at sa mga pinakamahusay na interes ng, ang mga pasyente na siyang paksa ng form.",
                                    physician_contact_warning: "sundin muna ang mga order, at pagkatapos ay makipag-ugnay sa manggagamot.",
                                    physician_legal: "ang aking lagda sa ibaba ay nagpapahiwatig sa abot ng aking kaalaman na ang mga order ay pare-pareho sa mga medikal na kondisyon at mga kagustuhan ng pasyente.",
                                    physician_license: "numero ng lisensya sa",
                                    physician_name: "manggagamot name",
                                    physician_phone: "manggagamot na numero ng telepono",
                                    physician_signature: "manggagamot signature",
                                    physician_signature_header: "pirma ng manggagamot",
                                    POLST_p1: "kapag ikaw ay malubha, na tinitiyak na ang iyong mga mahal sa buhay at mga doktor kung ano ang mga uri ng medikal na paggamot na gusto mo bilang diskarte mo ang dulo ng iyong buhay ay napakahalaga. maaaring makatulong sa manggagamot order para buhay-nagtutukod paggamot (POLST) ko na lang.",
                                    POLST_p2: "POLST ay isang form na malinaw na estado kung ano ang uri ng mga pasyente ng medikal na paggamot na gusto papunta sa dulo ng kanilang mga buhay. nakalimbag sa maliwanag na kulay-rosas na papel, at pinirmahan ng parehong isang doktor at pasyente, POLST tumutulong magbigay ng seryoso-sama ng mga pasyente ng karagdagang kontrol sa kanilang pangangalaga.",
                                    POLST_p3: "POLST tumutulong din kayong makipag-usap sa iyong koponan sa kalusugan at ang iyong mga mahal sa buhay tungkol sa iyong mga pagpipilian. sa ganitong paraan, POLST maaaring makatulong na mabawasan pasyente at pamilya na paghihirap, at siguraduhin na ang iyong mga kagustuhan ay kilala at pinarangalan.",
                                    provider_directions: "direksyon para sa healthcare provider",
                                    reviewing_POLST: "pagrepaso POLST",
                                    reviewing_option1: "ang mga pasyente ay inilipat mula sa isang care setting o care level sa iba, o",
                                    reviewing_option2: "doon ay isang malaking pagbabago sa kalagayan ng kalusugan ng pasyente, o",
                                    reviewing_option3: "baguhin ang mga kagustuhan ng paggamot ng pasyente.",
                                    reviewing_recommended_when: '"ito ay inirerekomenda na POLST na regular na susuriin. pagsusuri ay inirerekomenda kapag',
                                    section_a: "section ng",
                                    section_b: "section b",
                                    signatures_header: '"impormasyon at mga lagda',
                                    using_POLST: "gamit POLST",
                                    using_option1: "anumang hindi kumpleto seksyon ng POLST nagpapahiwatig buong paggamot para sa section na iyon.",
                                    using_option2: "kung natagpuan pulseless at hindi paghinga, walang defibrillator (kabilang ang automated panlabas defibrillator) o chest compression dapat gamitin sa isang pasyente na may napili &quot;huwag magtangkang resuscitation.&quot;",
                                    using_option3: "kapag hindi maaaring makamit ang kaginhawahan sa mga kasalukuyang setting, ang mga pasyente, kabilang ang isang tao na may &quot;treatment comfort-pokus,&quot; ay dapat na ilipat sa isang magagawang magbigay ng kaginhawaan setting (halimbawa, paggamot ng isang hip bali).",
                                    using_option4: "di-nagsasalakay positibong daan presyon kabilang ang patuloy na positibong panghimpapawid na daan presyon (cpap), bi-level positibong daan presyon (bipap), at bag balbula mask (BVM) tinulungan respirations.",
                                    using_option5: "iv antibiotics at hydration pangkalahatan ay hindi &quot;treatment comfort-pokus.&quot;",
                                    using_option6: "paggamot ng pag-aalis magpapahaba ng buhay. kung ang isang pasyente hinahangad iv fluids, ipahiwatig &quot;pumipili treatment&quot; o &quot;full paggamot.&quot;",
                                    using_option7: "depende sa mga lokal ems protocol, &quot;karagdagang mga order&quot; na nakasulat sa section b hindi maaaring ipatupad sa pamamagitan ng EMS tauhan."
                                })
                            .translations(
                                'vi',
                                {
                                    Create_your_POLST_form: "tạo ra hình thức POLST của bạn",
                                    Date_Form_Prepared: "hình thức ngày chuẩn bị",
                                    Edit_your_POLST_form: "chỉnh sửa hình thức POLST của bạn",
                                    HIPAA_disclosure_warning: "hipaa phép tiết lộ của POLST để chăm sóc sức khỏe khác khi cần thiết",
                                    Healthcare_Provider_Assisting_with_Form_Preparation: "cung cấp dịch vụ chăm sóc sức khỏe phụ giúp chuẩn bị hình thức",
                                    Medical_Record_Number: "y tế kỷ lục #",
                                    Name_last_first_middle: '"Tên (họ, tên, chữ lót)',
                                    Patient_Date_of_Birth: "ngày bệnh nhân sinh",
                                    Patient_First_Name: "Tên đầu tiên bệnh nhân",
                                    Patient_Last_Name: "Bệnh nhân tên cuối cùng",
                                    Patient_Middle_Name: "Tên đệm của bệnh nhân",
                                    Why_should_I_Fill_Out_a_POLST_Form: "tại sao tôi phải điền vào một mẫu <b>POLST?</b>",
                                    ad_option1: "hướng dẫn trước ngày",
                                    ad_option2: "chỉ dẫn trước không có sẵn",
                                    ad_option3: "không có chỉ dẫn trước",
                                    additional_contact: "liên hệ kèm",
                                    additional_name: "tên đệm",
                                    additional_orders: "đơn đặt hàng bổ sung",
                                    advance_directive_warning: "POLST bổ sung một chỉ dẫn trước và không có ý định thay thế tài liệu đó.",
                                    artificial_nutrition_heading: '"giả tạo quản lý dinh dưỡng',
                                    artificial_nutrition_option1: "dài hạn dinh dưỡng nhân tạo, bao gồm cả ống dẫn thức ăn.",
                                    artificial_nutrition_option2: "thời gian dùng thử của dinh dưỡng nhân tạo, bao gồm cả ống dẫn thức ăn.",
                                    artificial_nutrition_option3: "phương tiện không có nhân tạo về dinh dưỡng, bao gồm cả ống dẫn thức ăn.",
                                    artificial_nutrition_warning: "cung cấp thực phẩm bằng miệng nếu khả thi và mong muốn.",
                                    available_and_reviewed: "có sẵn và xem xét lại",
                                    birth_date: "ngày sinh",
                                    check_one: "kiểm tra một",
                                    choose_your_language: "chọn ngôn ngữ của bạn",
                                    completing_POLST: "hoàn POLST",
                                    completing_option1: "hoàn thành một hình thức POLST là tự nguyện. luật california đòi hỏi một hình thức POLST được theo sau bởi các nhà cung cấp chăm sóc y tế, và cung cấp miễn dịch cho những người thực hiện trong đức tin tốt. trong môi trường bệnh viện, bệnh nhân sẽ được đánh giá bởi một bác sĩ, những người sẽ ra lệnh thích hợp sao cho phù hợp với sở thích của bệnh nhân.",
                                    completing_option2: "POLST không thay thế các chỉ dẫn trước. khi có sẵn, xem lại các chỉ dẫn trước và hình thức POLST để đảm bảo thống nhất, và các hình thức cập nhật một cách thích hợp để giải quyết bất kỳ xung đột.",
                                    completing_option3: "POLST phải được hoàn thành bởi một nhà cung cấp chăm sóc sức khỏe dựa trên sở thích của bệnh nhân và chỉ dẫn y tế.",
                                    completing_option4: "một decisionmaker công nhận về mặt pháp lý có thể bao gồm một bảo tòa án chỉ định hoặc người giám hộ, đại lý được chỉ định trong chỉ thị trước, thay thế bằng miệng được chỉ định, người phối ngẫu, đăng ký đối tác trong nước, công ty mẹ của một trẻ vị thành niên, gần gũi nhất có liên quan, hoặc người mà bác sĩ của bệnh nhân tin rằng tốt nhất biết được những gì là lợi ích tốt nhất của bệnh nhân và sẽ đưa ra quyết định phù hợp với mong muốn thể hiện của bệnh nhân và các giá trị đến mức được biết.",
                                    completing_option5: "một decisionmaker công nhận về mặt pháp lý có thể thực hiện các hình thức POLST chỉ nếu bệnh nhân thiếu năng lực hoặc đã được chỉ định thẩm quyền của decisionmaker là có hiệu quả ngay lập tức.",
                                    completing_option6: "POLST phải có chữ ký của một bác sĩ và bệnh nhân hoặc decisionmaker có giá trị. lệnh bằng lời nói là chấp nhận được với chữ ký theo dõi bởi bác sĩ theo chính sách cơ sở / cộng đồng.",
                                    completing_option7: "nếu một hình thức dịch được sử dụng với bệnh nhân hoặc decisionmaker, gắn nó vào mẫu POLST tiếng anh ký.",
                                    completing_option8: "sử dụng các hình thức ban đầu được khuyến khích mạnh mẽ. photocopy và fax của các hình thức POLST ký là hợp pháp và hợp lệ. một bản sao nên được giữ lại trong hồ sơ bệnh án của bệnh nhân, trên giấy màu hồng cực khi có thể",
                                    cpr_header: "hồi sức tim phổi (CPR)",
                                    cpr_option1: "<b>cố gắng hồi sức / CPR</b> (CPR chọn trong phần một <u><b>đòi hỏi phải lựa</b></u> điều trị đầy đủ trong phần b)",
                                    cpr_option2: "<b>không cố gắng hồi sức / DNR</b> (để cho cái chết tự nhiên)",
                                    cpr_warning1: "nếu bệnh nhân không có xung và không thở",
                                    cpr_warning2: "nếu bệnh nhân không phải là ngưng tim, làm theo đơn đặt hàng trong phần b và c.",
                                    create_your_account: "tạo tài khoản của bạn",
                                    discussed_with: '"thảo luận với',
                                    discussed_with_decisionmaker: "decisionmaker công nhận về mặt pháp lý",
                                    discussed_with_patient: "bệnh nhân (bệnh nhân có năng lực)",
                                    edit_your_account: "chỉnh sửa tài khoản của bạn",
                                    family_name: "tên họ",
                                    get_started: "bắt đầu",
                                    given_name: 'Tên',
                                    healthcare_agent: '"đại lý chăm sóc sức khỏe nếu có tên trong chỉ dẫn trước',
                                    legal_rep_address: "địa chỉ mail",
                                    legal_rep_name: "Tên in",
                                    legal_rep_phone: "số điện thoại",
                                    legal_rep_relationship: "mối quan hệ",
                                    legal_rep_signature: "chữ ký",
                                    legality_section_warning: "một bản sao của mẫu POLST ký là một trật tự bác giá trị pháp lý. bất kỳ phần không hoàn thành nghĩa điều trị đầy đủ cho phần đó.",
                                    medical_interventions_header: '"can thiệp y tế',
                                    medical_interventions_option1: "<b><u>đầy đủ điều trị</u> - Mục tiêu chính của việc kéo dài cuộc sống của tất cả các phương tiện có hiệu quả về mặt y tế.</b> <br /> ngoài việc điều trị được mô tả trong điều trị chọn lọc và điều trị thoải mái tập trung, sử dụng ống nội khí quản, các can thiệp đường thở tiên tiến, hệ thống thông gió cơ khí, và sốc như được chỉ ra.",
                                    medical_interventions_option1a: "<b>thời gian thử nghiệm điều trị đầy đủ.</b>",
                                    medical_interventions_option2: "<b><u>chọn lọc điều trị</u> -. Mục tiêu của điều trị bệnh lý nội khoa trong khi tránh các biện pháp nặng nề</b> trong thời với điều trị được mô tả trong điều trị thoải mái tập trung, sử dụng điều trị y tế, iv kháng sinh, và iv chất lỏng theo chỉ định. không đặt nội khí quản. có thể sử dụng áp lực đường thở dương không xâm lấn. nói chung là tránh chăm sóc đặc biệt.",
                                    medical_interventions_option2a: "<b>yêu cầu chuyển đến bệnh viện <u>chỉ</u></b> khi thoải mái nhu cầu không thể được đáp ứng trong vị trí hiện tại.",
                                    medical_interventions_option3: "<b><u>thoải mái tập trung vào điều trị</u> - Mục tiêu chính của việc tối đa hóa sự thoải mái</b> làm giảm đau và đau khổ với thuốc bằng bất cứ đường nào khi cần <b>thiết.</b> sử dụng oxy, hút, và điều trị nhãn hiệu của tắc nghẽn đường thở. không sử dụng phương pháp điều trị được liệt kê đầy đủ và chọn lọc điều trị trừ khi phù hợp với mục tiêu thoải mái. <i><b>yêu cầu chuyển đến bệnh viện <u>chỉ</u> khi thoải mái nhu cầu không thể được đáp ứng trong vị trí hiện tại.</b></i>",
                                    medical_interventions_warning: "nếu bệnh nhân được tìm thấy với một xung và / hoặc đang thở",
                                    modifying_POLST: "sửa đổi và tránh POLST",
                                    modifying_option1: "một bệnh nhân với khả năng có thể, bất cứ lúc nào, yêu cầu điều trị thay thế hoặc hủy bỏ một POLST bằng bất kỳ phương tiện mà chỉ ra ý định thu hồi. đó là khuyến cáo rằng sự thu hồi được tài liệu bằng cách vẽ một đường thẳng đi qua một phần thông qua d, viết &quot;void&quot; viết bằng chữ lớn, ký kết và hẹn hò với dòng này.",
                                    modifying_option2: "một decisionmaker công nhận về mặt pháp lý có thể yêu cầu thay đổi đơn đặt hàng, hợp tác với các bác sĩ, dựa trên những mong muốn được biết đến của bệnh nhân hoặc, nếu không rõ, lợi ích tốt nhất của bệnh nhân, bệnh nhân được chuyển từ một môi trường chăm sóc hoặc chăm sóc cấp độ khác, hoặc , nếu không rõ, lợi ích tốt nhất của người bệnh.",
                                    next_step: "bước tiếp theo",
                                    not_completed_by_physician: "n / a nếu POLST được hoàn thành bằng cách ký tên bác sĩ",
                                    patient_information: "thông tin bệnh nhân",
                                    patient_signature_header: "chữ ký của bệnh nhân hoặc decisionmaker công nhận hợp pháp",
                                    patient_signature_legal: "tôi nhận thức được rằng hình thức này là tự nguyện. bởi ký đơn này, các decisionmaker công nhận về mặt pháp lý thừa nhận rằng yêu cầu này liên quan đến các biện pháp resuscitative là phù hợp với mong muốn được biết đến, và với những lợi ích tốt nhất của, bệnh nhân là đối tượng của biểu mẫu.",
                                    physician_contact_warning: "đầu tiên theo các đơn đặt hàng, sau đó liên hệ với bác sĩ.",
                                    physician_legal: "chữ ký của tôi dưới đây chỉ để tốt nhất của kiến ​​thức của tôi là những đơn đặt hàng phù hợp với điều kiện và ưu đãi y tế của bệnh nhân.",
                                    physician_license: "Số giấy phép",
                                    physician_name: "tên của bác sĩ",
                                    physician_phone: "số điện thoại của bác sĩ",
                                    physician_signature: "chữ ký bác sĩ",
                                    physician_signature_header: "chữ ký của bác sĩ",
                                    POLST_p1: "khi bạn đang bị bệnh nặng, chắc chắn những người thân và bác sĩ yêu của bạn biết những gì loại điều trị y tế mà bạn muốn khi bạn tiếp cận kết thúc cuộc đời của bạn là rất quan trọng. đơn đặt hàng cho bác sĩ điều trị duy trì sự sống (POLST) có thể giúp làm điều đó.",
                                    POLST_p2: "POLST là một hình thức trong đó ghi rõ những loại bệnh nhân điều trị y tế muốn về phía cuối của cuộc đời họ. in trên giấy màu hồng tươi sáng, và chữ ký của cả bác sĩ và bệnh nhân, POLST giúp cho bệnh nhân bị bệnh nặng, kiểm soát nhiều hơn chăm sóc của họ.",
                                    POLST_p3: "POLST cũng giúp bạn nói chuyện với đội ngũ chăm sóc sức khỏe của bạn và những người thân yêu của bạn về sự lựa chọn của bạn. theo cách này, POLST có thể giúp làm giảm bệnh nhân và gia đình đau khổ, và chắc chắn rằng mong muốn của bạn được biết đến và tôn vinh.",
                                    provider_directions: "hướng cho nhà cung cấp chăm sóc sức khỏe",
                                    reviewing_POLST: "rà soát POLST",
                                    reviewing_option1: "bệnh nhân được chuyển từ một thiết lập chăm sóc hoặc chăm sóc cấp độ khác, hoặc",
                                    reviewing_option2: "có sự thay đổi đáng kể trong tình trạng sức khỏe của bệnh nhân, hoặc",
                                    reviewing_option3: "ưu đãi về điều trị của bệnh nhân thay đổi.",
                                    reviewing_recommended_when: '"đó là khuyến cáo rằng POLST được xem xét định kỳ. xem xét được khuyến cáo khi',
                                    section_a: "Phần một",
                                    section_b: "phần b",
                                    signatures_header: '"thông tin và chữ ký',
                                    using_POLST: "sử dụng POLST",
                                    using_option1: "bất kỳ phần chưa đầy đủ của POLST ngụ ý điều trị đầy đủ cho phần đó.",
                                    using_option2: "nếu tìm thấy pulseless và không thở, không có máy khử rung tim (bao gồm cả máy khử rung tim tự động bên ngoài) hoặc ép ngực nên được sử dụng trên một bệnh nhân đã được lựa chọn &quot;không cố gắng hồi sức.&quot;",
                                    using_option3: "khi thoải mái, không có thể đạt được trong bối cảnh hiện nay, bệnh nhân, trong đó có một người nào đó với &quot;điều trị thoải mái tập trung vào&quot;, nên được chuyển đến một thiết lập có thể cung cấp sự thoải mái (ví dụ, điều trị gãy xương hông).",
                                    using_option4: "Thở áp lực dương không xâm lấn bao gồm áp lực dương liên tục đường thở (CPAP), hai mức áp lực đường thở dương (bipap), và van túi mặt nạ (BVM) respirations hỗ trợ.",
                                    using_option5: "iv kháng sinh và hydrat hóa nói chung không phải là &quot;điều trị thoải mái tập trung.&quot;",
                                    using_option6: "điều trị mất nước kéo dài tuổi thọ. nếu một bệnh nhân mong muốn iv chất lỏng, cho biết &quot;điều trị chọn lọc&quot; hoặc &quot;điều trị đầy đủ.&quot;",
                                    using_option7: "tùy thuộc vào giao thức ems địa phương, &quot;thêm đơn đặt hàng&quot; được viết trong phần b có thể không được thực hiện bởi nhân viên ems."
                                })
                            .translations(
                                'zh-CN',
                                {
                                    Create_your_POLST_form: '创建POLST形式',
                                    Date_Form_Prepared: '日期形式准备',
                                    Edit_your_POLST_form: '编辑您POLST形式',
                                    HIPAA_disclosure_warning: 'HIPAA允许披露POLST到其他医疗服务提供必要的',
                                    Healthcare_Provider_Assisting_with_Form_Preparation: '医疗服务提供者协助编写形式',
                                    Medical_Record_Number: '病历＃',
                                    Name_last_first_middle: '名字（最后，第一，中）：',
                                    Patient_Date_of_Birth: '出生日期患者',
                                    Patient_First_Name: '病人的名字',
                                    Patient_Last_Name: '病人姓',
                                    Patient_Middle_Name: '病人中间名',
                                    Why_should_I_Fill_Out_a_POLST_Form: '我为什么要填写一份表格<b>POLST？</b>',
                                    ad_option1: '约会预设医疗指示',
                                    ad_option2: '不可用预设医疗指示',
                                    ad_option3: '没有预设医疗指示',
                                    additional_contact: '其他联系',
                                    additional_name: '中间名字',
                                    additional_orders: '追加订单',
                                    advance_directive_warning: 'POLST补充预设医疗指示，不适合于替换该文件。',
                                    artificial_nutrition_heading: '人为地给予营养：',
                                    artificial_nutrition_option1: '长期人工营养，包括喂食管。',
                                    artificial_nutrition_option2: '人工营养，包括喂养管的试用期。',
                                    artificial_nutrition_option3: '营养无人工手段，包括喂食管。',
                                    artificial_nutrition_warning: '如果可行的话，并希望提供经口进食。',
                                    available_and_reviewed: '提供审查',
                                    birth_date: '出生日期',
                                    check_one: '二选一',
                                    choose_your_language: '选择你的语言',
                                    completing_POLST: '完成POLST',
                                    completing_option1: '完成POLST形式是自愿的。加州法律要求POLST形式其次是医疗服务提供者，并提供免疫力那些谁遵守诚信。在医院环境中，患者将由医生谁将发出适当的命令是与患者的喜好一致的进行评估。',
                                    completing_option2: 'POLST不会取代预设医疗指示。时可用，审查预设医疗指示和POLST形式，以确保一致性和更新的形式适当地解决任何冲突。',
                                    completing_option3: 'POLST必须由医疗服务提供者根据患者的喜好和医学指征来完成。',
                                    completing_option4: '一个法律认可的决策者可能包括法院指定的保护者或监护人，代理人指定的预设医疗指示，口头指定代理，配偶，国内注册伴侣，父母的未成年人，最近的可用相对，或人：即病人的医师认为，最了解什么在病人的最佳利益，并会根据病人本身的意愿和价值观已知的范围内作出决定。',
                                    completing_option5: '一个法律认可的决策者只有在病人没有能力或者已经指定了决策者的权威立即生效可以执行POLST形式。',
                                    completing_option6: 'POLST必须由医师进行签名和病人或决策者是有效的。口头订单是可以接受随访签字医师按照设施/社会政策。',
                                    completing_option7: '如果翻译的形式用于病人或决策者，将其连接到签署英语POLST形式。',
                                    completing_option8: '使用原始形式大力鼓励。副本及签署POLST形式传真合法有效。副本应保留在病人的病历，对超粉红色的纸时可能',
                                    cpr_header: '心肺复苏术（CPR）',
                                    cpr_option1: "<b>试图复苏/ CPR（</b>在第一个选择CPR <u><b>要求</b></u> B部分<u><b>选择</b></u>全处理）",
                                    cpr_option2: "<b>不要试图复苏/ DNR（</b>允许自然死亡）",
                                    cpr_warning1: '如果病人没有脉搏，没有呼吸',
                                    cpr_warning2: '如果病人是不是在心跳呼吸骤停，按照节b和c的订单。',
                                    create_your_account: '创建帐户',
                                    discussed_with: '与讨论：',
                                    discussed_with_decisionmaker: '法律认可的决策者',
                                    discussed_with_patient: '病人（患者有能力）',
                                    edit_your_account: '编辑您的帐户',
                                    family_name: '姓',
                                    get_started: '上手',
                                    given_name: '名字',
                                    healthcare_agent: '医疗保健剂，如果命名为预设医疗指示：',
                                    legal_rep_address: '邮寄地址',
                                    legal_rep_name: '打印的名字',
                                    legal_rep_phone: '电话号码',
                                    legal_rep_relationship: '关系',
                                    legal_rep_signature: '签名',
                                    legality_section_warning: '已签署的POLST表格的副本是合法有效的医嘱。没有完成任何部分意味着完全处理该节。',
                                    medical_interventions_header: '医疗干预措施：',
                                    medical_interventions_option1: "<b><u>全力救治</u> -所有医疗有效手段延长寿命的首要目标。</b> <br />除了在选择性治疗和舒适为重点治疗，使用插管，先进的气道的干预，机械通气，和复律描述治疗所指示的。",
                                    medical_interventions_option1a: '<b>全力救治试用期。</b>',
                                    medical_interventions_option2: "<b><u>选择性治疗</u> 。 -治疗的医疗条件，同时避免繁冗措施的目标</b>除了在舒适为重点治疗所述治疗，使用医疗，四抗菌素，并作为表示四流体。不插管。可以使用无创正压通气。一般避免重症监护室。",
                                    medical_interventions_option2a: '<b>请求转移到医院的<u>只有</u></b>舒适需求不能在当前位置得到满足。',
                                    medical_interventions_option3: "<b><u>舒适性为重点的治疗</u> -最大限度地提高舒适性的首要目标</b>缓解疼痛和药物根据需要任意航线的痛苦<b>。</b>使用氧气，吸痰，并人工处理气道阻塞。不使用完全和有选择性的治疗，除非与舒适的目标是一致的。 <i><b>请求转移到医院的<u>只有</u>舒适需求不能在当前的位置来满足</b></i>上市的治疗<i><b>。</b></i>",
                                    medical_interventions_warning: '如果患者被发现具有脉冲和/或呼吸',
                                    modifying_POLST: '修改和排尿POLST',
                                    modifying_option1: '有行为能力的患者可以在任何时间，要求替代治疗或通过任何手段，指示意图撤销撤销POLST。建议撤销由A到D通过绘制段线，写大字，和签名“无效”，并建此行被记录在案。',
                                    modifying_option2: '一个法律认可的决策者可能会要求修改订单，与医生合作，根据病人的或已知的欲望，如有不明，病人的最佳利益，患者从一个护理机构转让或护理水平到另一个，或，如有不明，病人的最佳利益。',
                                    next_step: '下一步',
                                    not_completed_by_physician: "N / A如果POLST由医师签字完成",
                                    patient_information: '患者信息',
                                    patient_signature_header: '病人签名或法律承认的决策者',
                                    patient_signature_legal: '我知道，这种形式是自愿的。通过签署该表格，法律承认的决策者认识到，这项要求对于复苏措施是已知的愿望相一致，并与，病人谁是形式主语的最佳利益。',
                                    physician_contact_warning: '首先按照这些订单，然后与医生取得联系。',
                                    physician_legal: '我下面的签名表示尽我所知，这些订单都与病人的医疗条件和偏好是一致的。',
                                    physician_license: '许可证号',
                                    physician_name: '医生的名字',
                                    physician_phone: '医生电话号码',
                                    physician_signature: '医师签名',
                                    physician_signature_header: '医师签名',
                                    POLST_p1: '当你身患重病，确保你的亲人和医生知道你想，当你接近你的生活到底是很重要的是什么类型的医疗待遇。医嘱为维持生命治疗（POLST）可以帮助做到这一点。',
                                    POLST_p2: 'POLST是一种形式，明确规定什么样的药物治疗的患者要对自己的生命结束。双方医生和病人印在鲜艳的粉红色的纸，并签名，POLST给予帮助抢救危重患者更好地控制自己的照顾。',
                                    POLST_p3: 'POLST还可以帮助您与您的医疗团队和你的选择，你的亲人交谈。通过这种方式，POLST可以帮助减少病人和家属的痛苦，并确保你的愿望是已知的，也很荣幸。',
                                    provider_directions: '方向的医务人员',
                                    reviewing_POLST: '回顾POLST',
                                    reviewing_option1: '患者从一个护理机构或保健水平转移到另一个，或',
                                    reviewing_option2: '有在病人的健康状况的显着变化，或者',
                                    reviewing_option3: '该患者的治疗偏好的变化。',
                                    reviewing_recommended_when: '建议POLST定期审查。审查时，建议：',
                                    section_a: '第一个',
                                    section_b: 'B节',
                                    signatures_header: '信息和签名：',
                                    using_POLST: '使用POLST',
                                    using_option1: 'POLST任何不完整的部分意味着完全处理该节。',
                                    using_option2: '如果发现无脉和没有呼吸，没有除颤器（包括自动体外除颤器），或者胸部按压应该在谁选择了一个病人使用“不要试图复苏。”',
                                    using_option3: '当舒适不能在当前设置来实现，所述患者，包括有人用“舒适为重点治疗，”应被转移到能够提供舒适的设定（例如，治疗髋部骨折的）。',
                                    using_option4: '无创正压通气包括持续气道正压（CPAP），双水平气道正压通气（BIPAP）和袋阀面罩（BVM）辅助呼吸。',
                                    using_option5: '四，抗生素和水化一般都不是“舒适为重点的待遇。”',
                                    using_option6: '脱水治疗，延长生命。如果病人想要静脉输液，表示“选择性治理”或“全处理。”',
                                    using_option7: '根据当地的EMS协议，写在B节“追加订单”可能不会被EMS人员实施。'
                                })
                            .translations(
                                'zh-TW',
                                {
                                    Create_your_POLST_form: '創建POLST形式',
                                    Date_Form_Prepared: '日期形式準備',
                                    Edit_your_POLST_form: '編輯您POLST形式',
                                    HIPAA_disclosure_warning: 'HIPAA允許披露POLST到其他醫療服務提供必要的',
                                    Healthcare_Provider_Assisting_with_Form_Preparation: '醫療服務提供者協助編寫形式',
                                    Medical_Record_Number: '病歷＃',
                                    Name_last_first_middle: '名字（最後，第一，中）：',
                                    Patient_Date_of_Birth: '出生日期患者',
                                    Patient_First_Name: '病人的名字',
                                    Patient_Last_Name: '病人姓',
                                    Patient_Middle_Name: '病人中間名',
                                    Why_should_I_Fill_Out_a_POLST_Form: '我為什麼要填寫一份表格<b>POLST？</b>',
                                    ad_option1: '約會預設醫療指示',
                                    ad_option2: '不可用預設醫療指示',
                                    ad_option3: '沒有預設醫療指示',
                                    additional_contact: '其他聯繫',
                                    additional_name: '中間名字',
                                    additional_orders: '追加訂單',
                                    advance_directive_warning: 'POLST補充預設醫療指示，不適合於替換該文件。',
                                    artificial_nutrition_heading: '人為地給予營養：',
                                    artificial_nutrition_option1: '長期人工營養，包括餵食管。',
                                    artificial_nutrition_option2: '人工營養，包括餵養管的試用期。',
                                    artificial_nutrition_option3: '營養無人工手段，包括餵食管。',
                                    artificial_nutrition_warning: '如果可行的話，並希望提供經口進食。',
                                    available_and_reviewed: '提供審查',
                                    birth_date: '出生日期',
                                    check_one: '二選一',
                                    choose_your_language: '選擇你的語言',
                                    completing_POLST: '完成POLST',
                                    completing_option1: '完成POLST形式是自願的。加州法律要求POLST形式其次是醫療服務提供者，並提供免疫力那些誰遵守誠信。在醫院環境中，患者將由醫生誰將發出適當的命令是與患者的喜好一致的進行評估。',
                                    completing_option2: 'POLST不會取代預設醫療指示。時可用，審查預設醫療指示和POLST形式，以確保一致性和更新的形式適當地解決任何衝突。',
                                    completing_option3: 'POLST必須由醫療服務提供者根據患者的喜好和醫學指徵來完成。',
                                    completing_option4: '一個法律認可的決策者可能包括法院指定的保護者或監護人，代理人指定的預設醫療指示，口頭指定代理，配偶，國內註冊伴侶，父母的未成年人，最近的可用相對，或人：即病人的醫師認為，最了解什麼在病人的最佳利益，並會根據病人本身的意願和價值觀已知的範圍內作出決定。',
                                    completing_option5: '一個法律認可的決策者只有在病人沒有能力或者已經指定了決策者的權威立即生效可以執行POLST形式。',
                                    completing_option6: 'POLST必須由醫師進行簽名和病人或決策者是有效的。口頭訂單是可以接受隨訪簽字醫師按照設施/社會政策。',
                                    completing_option7: '如果翻譯的形式用於病人或決策者，將其連接到簽署英語POLST形式。',
                                    completing_option8: '使用原始形式大力鼓勵。副本及簽署POLST形式傳真合法有效。副本應保留在病人的病歷，對超粉紅色的紙時可能',
                                    cpr_header: '心肺復甦術（CPR）',
                                    cpr_option1: "<b>試圖復甦/ CPR（</b>在第一個選擇CPR <u><b>要求</b></u> B部分<u><b>選擇</b></u>全處理）",
                                    cpr_option2: "<b>不要試圖復甦/ DNR（</b>允許自然死亡）",
                                    cpr_warning1: '如果病人沒有脈搏，沒有呼吸',
                                    cpr_warning2: '如果病人是不是在心跳呼吸驟停，按照節b和c的訂單。',
                                    create_your_account: '創建帳戶',
                                    discussed_with: '與討論：',
                                    discussed_with_decisionmaker: '法律認可的決策者',
                                    discussed_with_patient: '病人（患者有能力）',
                                    edit_your_account: '編輯您的帳戶',
                                    family_name: '姓',
                                    get_started: '上手',
                                    given_name: '名字',
                                    healthcare_agent: '醫療保健劑，如果命名為預設醫療指示：',
                                    legal_rep_address: '郵寄地址',
                                    legal_rep_name: '打印的名字',
                                    legal_rep_phone: '電話號碼',
                                    legal_rep_relationship: '關係',
                                    legal_rep_signature: '簽名',
                                    legality_section_warning: '已簽署的POLST表格的副本是合法有效的醫囑。沒有完成任何部分意味著完全處理該節。',
                                    medical_interventions_header: '醫療干預措施：',
                                    medical_interventions_option1: "<b><u>全力救治</u> -所有醫療有效手段延長壽命的首要目標。</b> <br />除了在選擇性治療和舒適為重點治療，使用插管，先進的氣道的干預，機械通氣，和复律描述治療所指示的。",
                                    medical_interventions_option1a: '<b>全力救治試用期。</b>',
                                    medical_interventions_option2: "<b><u>選擇性治療</u> 。 -治療的醫療條件，同時避免繁冗措施的目標</b>除了在舒適為重點治療所述治療，使用醫療，四抗菌素，並作為表示四流體。不插管。可以使用無創正壓通氣。一般避免重症監護室。",
                                    medical_interventions_option2a: '<b>請求轉移到醫院的<u>只有</u></b>舒適需求不能在當前位置得到滿足。',
                                    medical_interventions_option3: "<b><u>舒適性為重點的治療</u> -最大限度地提高舒適性的首要目標</b>緩解疼痛和藥物根據需要任意航線的痛苦<b>。</b>使用氧氣，吸痰，並人工處理氣道阻塞。不使用完全和有選擇性的治療，除非與舒適的目標是一致的。 <i><b>請求轉移到醫院的<u>只有</u>舒適需求不能在當前的位置來滿足</b></i>上市的治療<i><b>。</b></i>",
                                    medical_interventions_warning: '如果患者被發現具有脈衝和/或呼吸',
                                    modifying_POLST: '修改和排尿POLST',
                                    modifying_option1: '有行為能力的患者可以在任何時間，要求替代治療或通過任何手段，指示意圖撤銷撤銷POLST。建議撤銷由A到D通過繪製段線，寫大字，和簽名“無效”，並建此行被記錄在案。',
                                    modifying_option2: '一個法律認可的決策者可能會要求修改訂單，與醫生合作，根據病人的或已知的慾望，如有不明，病人的最佳利益，患者從一個護理機構轉讓或​​護理水平到另一個，或，如有不明，病人的最佳利益。',
                                    next_step: '下一步',
                                    not_completed_by_physician: "N / A如果POLST由醫師簽字完成",
                                    patient_information: '患者信息',
                                    patient_signature_header: '病人簽名或法律承認的決策者',
                                    patient_signature_legal: '我知道，這種形式是自願的。通過簽署該表格，法律承認的決策者認識到，這項要求對於復甦措施是已知的願望相一致，並與，病人誰是形式主語的最佳利益。',
                                    physician_contact_warning: '首先按照這些訂單，然後與醫生取得聯繫。',
                                    physician_legal: '我下面的簽名表示盡我所知，這些訂單都與病人的醫療條件和偏好是一致的。',
                                    physician_license: '許可證號',
                                    physician_name: '醫生的名字',
                                    physician_phone: '醫生電話號碼',
                                    physician_signature: '醫師簽名',
                                    physician_signature_header: '醫師簽名',
                                    POLST_p1: '當你身患重病，確保你的親人和醫生知道你想，當你接近你的生活到底是很重要的是什麼類型的醫療待遇。醫囑為維持生命治療（POLST）可以幫助做到這一點。',
                                    POLST_p2: 'POLST是一種形式，明確規定什麼樣的藥物治療的患者要對自己的生命結束。雙方醫生和病人印在鮮豔的粉紅色的紙，並簽名，POLST給予幫助搶救危重患者更好地控制自己的照顧。',
                                    POLST_p3: 'POLST還可以幫助您與您的醫療團隊和你的選擇，你的親人交談。通過這種方式，POLST可以幫助減少病人和家屬的痛苦，並確保你的願望是已知的，也很榮幸。',
                                    provider_directions: '方向的醫務人員',
                                    reviewing_POLST: '回顧POLST',
                                    reviewing_option1: '患者從一個護理機構或保健水平轉移到另一個，或',
                                    reviewing_option2: '有在病人的健康狀況的顯著變化，或者',
                                    reviewing_option3: '該患者的治療偏好的變化。',
                                    reviewing_recommended_when: '建議POLST定期審查。審查時，建議：',
                                    section_a: '第一個',
                                    section_b: 'B節',
                                    signatures_header: '信息和簽名：',
                                    using_POLST: '使用POLST',
                                    using_option1: 'POLST任何不完整的部分意味著完全處理該節。',
                                    using_option2: '如果發現無脈和沒有呼吸，沒有除顫器（包括自動體外除顫器），或者胸部按壓應該在誰選擇了一個病人使用“不要試圖復甦。”',
                                    using_option3: '當舒適不能在當前設置來實現，所述患者，包括有人用“舒適為重點治療，”應被轉移到能夠提供舒適的設定（例如，治療髖部骨折的）。',
                                    using_option4: '無創正壓通氣包括持續氣道正壓（CPAP），雙水平氣道正壓通氣（BIPAP）和袋閥面罩（BVM）輔助呼吸。',
                                    using_option5: '四，抗生素和水化一般都不是“舒適為重點的待遇。”',
                                    using_option6: '脫水治療，延長生命。如果病人想要靜脈輸液，表示“選擇性治理”或“全處理。”',
                                    using_option7: '根據當地的EMS協議，寫在B節“追加訂單”可能不會被EMS人員實施。'
                                });
                        $translateProvider.useSanitizeValueStrategy(null);
                    }]);

    });

define('common', ['common/main'], function (main) { return main; });

