define('acp-billing/billing-dashboard.controller',['angular'], function (angular) {
    'use strict';
    var BillingDashboardCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document, userService, dataEntryService, $window, $mdDialog, $sce, helper, analyticsService, user, Papa, acpBillingService) {
        $scope.user = user.data.User;
        $scope.newNote = function () {
            $location.path('/acp-note').search({});
        };
        $scope.visitsReady = false;
        $scope.visits = [];
        $scope.allDates = true;
        $scope.noteStatus = 'all';
        $scope.clearReport = function(){
            $scope.queries = [];
            $scope.view = [];
            $scope.reportReady = false;
            $scope.runningReport = false;
        };
        $scope.emptyVisit = false;
        $scope.runReport = function() {
            $scope.runningReport = true;
            acpBillingService.getVisits($scope.noteStatus, $scope.allDates, $scope.startDate, $scope.endDate, $scope.query).then(function (d) {
                var r = d.data;
                if(r.length === 0){
                    $scope.emptyVisit = true;
                } else {
                    $scope.emptyVisit = false;
                }
                $scope.queries = [];
                for (var i = 0; i < r.length; i++) {
                    r[i].createdTimestamp = moment.utc(r[i].created);
                    r[i].created = r[i].createdTimestamp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY')
                    r[i].visitTimestamp = moment.utc(r[i].visit_date);
                    r[i].visit_date = r[i].visitTimestamp.format('MM/DD/YYYY');
                    if (r[i].status === 'complete') {
                        r[i].status = 'Signed';
                    }
                    if (r[i].status === 'pending_signature') {
                        r[i].status = 'Pending Signature';
                    }
                    if (r[i].conversation_duration === '0-15') {
                        r[i].codes = 'N/A';
                    } else if (r[i].conversation_duration === '16-45') {
                        r[i].codes = '99497';
                    } else if (r[i].conversation_duration === '46-75') {
                        r[i].codes = '99497, 99498';
                    } else if (r[i].conversation_duration === '76-105') {
                        r[i].codes = '99497, 99498, 99498';
                    } else if (r[i].conversation_duration === '106-135') {
                        r[i].codes = '99497, 99498, 99498, 99498';
                    }
                    $scope.queries.push(r[i]);
                }
                $scope.onReorder('doc_date');
                $scope.reportReady = true;
                $scope.onPaginate($scope.detailedQuery.page, $scope.detailedQuery.limit);
                $scope.runningReport = false;
                $scope.visitsReady = true;
            });
        };
        $scope.runReport();
        $scope.signVisit = function(id){
            $location.path('/acp-note').search({id: id, sign: 1});
        };
        $scope.viewVisit = function (id) {
            $location.path('/acp-note').search({id: id});
        };
        $scope.detailedQuery = {page: 1, limit: 10};
        $scope.onPaginate = function (page, limit) {
            $scope.detailedQuery.page = page;
            $scope.detailedQuery.limit = limit;
            $scope.orderUsers();
        };
        $scope.orderUsers = function () {
            var start = (($scope.detailedQuery.page - 1) * $scope.detailedQuery.limit);
            var end = (($scope.detailedQuery.page) * $scope.detailedQuery.limit);
            $scope.view = [];
            for (var i = start; i < end && i < $scope.queries.length; ++i) {
                $scope.view.push($scope.queries[i]);
            }
        };
        $scope.downloadCSV = function () {
            var formsToDownload = [];
            if ($scope.queries !== undefined) {
                for (var i = 0; i < $scope.queries.length; i++) {
                    formsToDownload.push({
                        'First Name': $scope.queries[i].fname,
                        'Last Name': $scope.queries[i].lname,
                        'Document Date': $scope.queries[i].created,
                        'Visit Date': $scope.queries[i].visit_date,
                        'Duration': $scope.queries[i].conversation_duration + ' minutes',
                        'Codes': $scope.queries[i].codes,
                        'Status': $scope.queries[i].status,
                        'Plan Name': $scope.queries[i].plan_name,
                        'Member ID': $scope.queries[i].insured_id,
                        'Group ID': $scope.queries[i].group_id,
                        'Effective Date': $scope.queries[i].effective_date
                    });
                }
            }
            Papa.unparse(formsToDownload).then(function (r) {
                var blob = new Blob(["\ufeff", r]);
                var url = URL.createObjectURL(blob);
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.pendingMsFile = blob;
                    $scope.fileURL = 'forms.csv';
                    window.navigator.msSaveOrOpenBlob(blob, 'forms.csv');
                    $scope.pendingShowDownload = true;
                } else {
                    $scope.pendingShowDownload = true;
                    $scope.fileURL = URL.createObjectURL(blob);
                }
                $scope.exportReady = true;
            });
        };
        $scope.exportReady = false;
        $scope.view = [];
        $scope.onReorder = function (orderBy) {
            console.log(orderBy);
            if (orderBy === 'fname') {
                $scope.queries.sort(function (a, b) {
                    if (a.fname === undefined && b.fname === undefined) {
                        return 0;
                    } else if (a.fname === undefined) {
                        return 1;
                    } else if (b.fname === undefined) {
                        return -1;
                    }
                    return a.fname.toUpperCase() > b.fname.toUpperCase() ? 1 : a.fname.toUpperCase() < b.fname.toUpperCase() ? -1 : 0;
                });
            } else if (orderBy === '-fname') {
                $scope.queries.sort(function (a, b) {
                    if (a.fname === undefined && b.fname === undefined) {
                        return 0;
                    } else if (a.fname === undefined) {
                        return -1;
                    } else if (b.fname === undefined) {
                        return 1;
                    }
                    return a.fname.toUpperCase() > b.fname.toUpperCase() ? -1 : a.fname.toUpperCase() < b.fname.toUpperCase() ? 1 : 0;
                });
            } else if (orderBy === 'status') {
                $scope.queries.sort(function (a, b) {
                    if (a.status === undefined && b.status === undefined) {
                        return 0;
                    } else if (a.status === undefined) {
                        return 1;
                    } else if (b.status === undefined) {
                        return -1;
                    } else {
                        return a.status > b.status ? -1 : a.status < b.status ? 1 : 0;
                    }
                });
            } else if (orderBy === '-status') {
                $scope.queries.sort(function (a, b) {
                    if (a.status === undefined && b.status === undefined) {
                        return 0;
                    } else if (a.status === undefined) {
                        return -1;
                    } else if (b.status === undefined) {
                        return 1;
                    } else {
                        return a.status > b.status ? 1 : a.status < b.status ? -1 : 0;
                    }
                });
            } else if (orderBy === 'visit_date') {
                $scope.queries.sort(function (a, b) {
                    if (a.visitTimestamp === undefined && b.visitTimestamp === undefined) {
                        return 0;
                    } else if (a.visitTimestamp === undefined) {
                        return 1;
                    } else if (b.visitTimestamp === undefined) {
                        return -1;
                    } else {
                        if (a.visitTimestamp.isBefore(b.visitTimestamp)) {
                            return 1;
                        } else if (a.visitTimestamp.isAfter(b.visitTimestamp)) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                });
            } else if (orderBy === '-visit_date') {
                $scope.queries.sort(function (a, b) {
                    if (a.visitTimestamp === undefined && b.visitTimestamp === undefined) {
                        return 0;
                    } else if (a.visitTimestamp === undefined) {
                        return -1;
                    } else if (b.visitTimestamp === undefined) {
                        return 1;
                    } else {
                        if (a.visitTimestamp.isBefore(b.visitTimestamp)) {
                            return -1;
                        } else if (a.visitTimestamp.isAfter(b.visitTimestamp)) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }
                });
            } else if (orderBy === 'doc_date') {
                $scope.queries.sort(function (a, b) {
                    if (a.createdTimestamp === undefined && b.createdTimestamp === undefined) {
                        return 0;
                    } else if (a.createdTimestamp === undefined) {
                        return 1;
                    } else if (b.createdTimestamp === undefined) {
                        return -1;
                    } else {
                        if (a.createdTimestamp.isBefore(b.createdTimestamp)) {
                            return 1;
                        } else if (a.createdTimestamp.isAfter(b.createdTimestamp)) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                });
            } else if (orderBy === '-doc_date') {
                $scope.queries.sort(function (a, b) {
                    if (a.createdTimestamp === undefined && b.createdTimestamp === undefined) {
                        return 0;
                    } else if (a.createdTimestamp === undefined) {
                        return -1;
                    } else if (b.createdTimestamp === undefined) {
                        return 1;
                    } else {
                        if (a.createdTimestamp.isBefore(b.createdTimestamp)) {
                            return -1;
                        } else if (a.createdTimestamp.isAfter(b.createdTimestamp)) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }
                });
            } else if (orderBy === 'lname') {
                $scope.queries.sort(function (a, b) {
                    if (a.lname === undefined && b.lname === undefined) {
                        return 0;
                    } else if (a.lname === undefined) {
                        return 1;
                    } else if (b.lname === undefined) {
                        return -1;
                    }
                    return a.lname.toUpperCase() > b.lname.toUpperCase() ? 1 : a.lname.toUpperCase() < b.lname.toUpperCase() ? -1 : 0;
                });
            } else if (orderBy === '-lname') {
                $scope.queries.sort(function (a, b) {
                    if (a.lname === undefined && b.lname === undefined) {
                        return 0;
                    } else if (a.lname === undefined) {
                        return -1;
                    } else if (b.lname === undefined) {
                        return 1;
                    }
                    return a.lname.toUpperCase() > b.lname.toUpperCase() ? -1 : a.lname.toUpperCase() < b.lname.toUpperCase() ? 1 : 0;
                });
            }
        };
    };
    BillingDashboardCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'analyticsService', 'user', 'Papa', 'acpBillingService'];
    return BillingDashboardCtrl;
});

