define('dashboard/help.controller',['angular'], function (angular) {
    'use strict';
    var HelpCtrl = function ($scope, userService, user) {
        if(user !== undefined && user.data !== undefined){
            $scope.user = user.data.User;
        }
    };
    HelpCtrl.$inject = ['$scope', 'userService', 'user'];
    return HelpCtrl;
});

