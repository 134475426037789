/**
 * Home controllers.
 */
define('home/home.controllers',['angular', './contact.controller', './header.controller', './home.controller', './agreement-link.controller', './idle.controller', '../dashboard/notes.controller'], function (angular, ContactCtrl, HeaderCtrl, HomeCtrl, AgreementLinkCtrl, IdleCtrl) {
    'use strict';
    return {
        HeaderCtrl: HeaderCtrl,
        ContactCtrl: ContactCtrl,
        HomeCtrl: HomeCtrl,
        AgreementLinkCtrl: AgreementLinkCtrl,
        IdleCtrl: IdleCtrl
    };

});

