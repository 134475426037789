/**
 * Dashboard controllers.
 */
define('data-entry/data-entry.controllers',['angular', './archive-or-publish-polst.controller', './select-af-patient.controller', './list-view.controller', './upload-view.controller', './verify-view.controller', './ad-upload.controller', './toast-success.controller', './select-md.controller', './reject-polst.controller', './publish-or-archive-ad.controller', './madie-patient-matching.controller', './match-details.controller', './upload-csv.controller', './review-patient-alert.controller', './ad-list-view.controller', './ad-verify.controller'], function (angular, ArchiveOrPublishPolstCtrl, SelectAFPatientCtrl, DataEntryListCtrl, DataEntryUploadCtrl, DataEntryVerifyCtrl, ADUploadCtrl, ToastSuccessCtrl,SelectMDCtrl, RejectPolstCtrl, PublishOrArchiveAdCtrl, MadiePatientMatchingCtrl, MatchDetailsCtrl, DataEntryUploadCsvCtrl, ReviewPatientAlertCtrl, ADDataListViewCtrl, ADVerifyCtrl) {
    'use strict';
    return {
        ADDataListViewCtrl: ADDataListViewCtrl,
        ADVerifyCtrl: ADVerifyCtrl,
        ReviewPatientAlertCtrl: ReviewPatientAlertCtrl,
        DataEntryUploadCsvCtrl: DataEntryUploadCsvCtrl,
        MatchDetailsCtrl: MatchDetailsCtrl,
        MadiePatientMatchingCtrl: MadiePatientMatchingCtrl,
        RejectPolstCtrl: RejectPolstCtrl,
        SelectMDCtrl: SelectMDCtrl,
        ArchiveOrPublishPolstCtrl: ArchiveOrPublishPolstCtrl,
        SelectAFPatientCtrl: SelectAFPatientCtrl,
        ToastSuccessCtrl: ToastSuccessCtrl,
        ADUploadCtrl: ADUploadCtrl,
        DataEntryListCtrl: DataEntryListCtrl,
        DataEntryUploadCtrl: DataEntryUploadCtrl,
        DataEntryVerifyCtrl: DataEntryVerifyCtrl,
        PublishOrArchiveAdCtrl: PublishOrArchiveAdCtrl

    };
});

