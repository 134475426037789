define('dashboard/finish-new-polst.controller',['angular'], function (angular) {
    'use strict';
    var FinishNewPolstCtrl = function ($scope, $mdToast, $mdDialog ) {
        var isDlgOpen = false;
        $scope.closeToast = function ($event) {
            $mdToast.hide().then(function () {
            });
            $event.stopPropagation();
        };
    };
    FinishNewPolstCtrl.$inject = ['$scope', '$mdToast', '$mdDialog'];
    return FinishNewPolstCtrl;
});

