define('home/agreement-link.controller',['angular'], function (angular) {
    'use strict';
    var AgreementLinkCtrl = function ($scope, userService, $location) {
        $scope.dataReady = false;
        $scope.isError = false;
        userService.getSnfByLink({key: $location.search().key, email: $location.search().email}).then(function (d) {
            $scope.dataReady = true;
            $scope.org = d;
        }, function (e) {
            $scope.dataReady = true;
            $scope.isError = true;
            // hit error
        });
    };
    AgreementLinkCtrl.$inject = ['$scope', 'userService', '$location'];
    return AgreementLinkCtrl;
});

