/**
 * Dashboard routes.
 */
define('analytics/analytics.routes',['angular', './analytics.controllers', 'common'], function (angular, controllers) {
    'use strict';

    var mod = angular.module('analytics.routes', ['com.cd.common']);
    mod
        .config([
            '$routeProvider',
            'userResolve',
            'adminResolve',
            'physResolve',
            'dataResolve',
            'analyticsResolve',
            '$locationProvider',
            function ($routeProvider, userResolve, adminResolve, physResolve, dataResolve, analyticsResolve, $locationProvider) {
                $locationProvider.hashPrefix('');
                $routeProvider.when(
                    '/analytics/dashboard', {
                        templateUrl: 'analytics/analytics-dashboard.html',
                        controller: controllers.AnalyticsDashboardCtrl,
                        reloadOnSearch: false,
                        resolve: analyticsResolve
                    })
                    .when('/analytics/form-submission', {
                        templateUrl: 'analytics/form-submission.html',
                        controller: controllers.FormSubmissionCtrl,
                        reloadOnSearch: false,
                        resolve: analyticsResolve
                    })
                    .when('/analytics/view-polst', {
                        templateUrl: 'analytics/view-polst.html',
                        controller: controllers.ViewPolstCtrl,
                        reloadOnSearch: false,
                        resolve: analyticsResolve
                    })
                    .when('/analytics/detailed-query', {
                        templateUrl: 'analytics/detailed-query-analytics.html',
                        controller: controllers.DetailedQueryAnalyticsCtrl,
                        reloadOnSearch: false,
                        resolve: analyticsResolve
                    });
            }]);
    return mod;
});

