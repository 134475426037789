define('dashboard/fax-form.controller',['angular'], function (angular) {
    'use strict';
    var FaxFormCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                userService, dataEntryService, $window, $mdDialog, $sce, helper, user, $q) {
        $scope.user = user;
        $scope.phone = '';
        $scope.faxForm = function () {
            var deferred = $q.defer();
            $scope.myPromise = deferred.promise;
            setTimeout(function () {
                deferred.resolve();
                $mdDialog.hide();
            }, 3000);
        };
        $scope.cancel = function () {
            $mdDialog.hide();
        };

    };
    FaxFormCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'user', '$q'];
    return FaxFormCtrl;
});

