define('dashboard/admin-form.controller',['angular'], function (angular) {
    'use strict';
    var AdminFormCtrl = function ($scope, userService, $location, $routeParams, $localStorage, helper, $filter, user) {
        $scope.user = user.data.User;
        $scope.formTemplate = "dashboard/POLST.html";
        $scope.formTable = {
            order: 'created',
            page: 1,
            limit: 5
        };
        var getForm = function () {
            $scope.hideCreate = true;
            $scope.hideCreate2 = true;
            $scope.patientMatched = true;
            userService.getForm($scope.fid).then(function (response) {
                $scope.polst = response.data;
                if ($scope.polst.sigURL) {
                    $scope.savedSig = true;
                }
                if ($scope.polst.physSigURL) {
                    $scope.savedSig2 = true;
                }
            });
        };
        $scope.formsVisible = [];
        $scope.pdfs = [];
        $scope.sig = {};
        $scope.form = {};
        $scope.onPaginate = function (page, limit) {
            $scope.formsVisible = [];
            for (var i = (page - 1) * 5; i < $scope.forms.length && i < page * 5; i++) {
                var f = $scope.forms[i];
                if (f.pdfInfo === undefined) {
                    $scope.generatePDF(f);
                }
                $scope.formsVisible.push(f);
            }
        };
        $scope.generatePDF = function (f) {
            f.pdfInfo = {};
            userService.downloadFormById(f.id).then(function (response) {
                var file = new Blob([response.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    f.pdfInfo.msFile = file;
                    // window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    f.pdfInfo.showDownload = true;
                } else {
                    f.pdfInfo.showDownload = true;
                    f.pdfInfo.fileURL = URL.createObjectURL(file);
                }
            });

        };
        $scope.showForm = function (f) {
            $location.search({p: 1, f: f.id});
        };
        $scope.viewPDF = function (f) {
            var i;
            if (f === undefined) {
                for (i = 0; i < $scope.forms.length; i++) {
                    if ($scope.forms[i].id === $scope.fid) {
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob($scope.forms[i].pdfInfo.msFile, 'polst.pdf');
                        } else {
                            window.open($scope.forms[i].pdfInfo.fileURL, "_blank");
                        }
                    }
                }

            } else {
                for (i = 0; i < $scope.forms.length; i++) {
                    if ($scope.forms[i].id === f.id) {
                        window.open($scope.forms[i].pdfInfo.fileURL, "_blank");
                    }
                }
            }
        };
        $scope.onReorder = function (order) {
            if (order === 'created') {
                $scope.forms.sort(function (a, b) {
                    return a.createdDate > b.createdDate ? -1 : b.createdDate > a.createdDate ? 1 : 0;
                });
            } else if (order === '-created') {
                $scope.forms.sort(function (a, b) {
                    return -1 * (a.createdDate > b.createdDate ? -1 : b.createdDate > a.createdDate ? 1 : 0);
                });
            } else if (order === 'fname') {
                $scope.forms.sort(function (a, b) {
                    return a.fname > b.fname ? -1 : b.fname > a.fname ? 1 : 0;
                });
            } else if (order === '-fname') {
                $scope.forms.sort(function (a, b) {
                    return -1 * (a.fname > b.fname ? -1 : b.fname > a.fname ? 1 : 0);
                });
            } else if (order === 'lname') {
                $scope.forms.sort(function (a, b) {
                    return a.lname > b.lname ? -1 : b.lname > a.lname ? 1 : 0;
                });
            } else if (order === '-lname') {
                $scope.forms.sort(function (a, b) {
                    return -1 * (a.lname > b.lname ? -1 : b.lname > a.lname ? 1 : 0);
                });
            }
            $scope.onPaginate($scope.formTable.page, $scope.formTable.limit);
        };
        var getForms = function () {
            userService.getForms().then(function (response) {
                $scope.forms = response.data;
                for (var i = 0; i < $scope.forms.length; i++) {
                    $scope.forms[i].createdDate = new Date($scope.forms[i].created);
                }
                $scope.onReorder("created");
                $scope.onPaginate($scope.formTable.page, $scope.formTable.limit);
            });
        };
        getForms();
        $scope.genders = [{
            id: 1,
            name: "MALE"
        }, {
            id: 2,
            name: "FEMALE"
        }, {
            id: 3,
            name: 'UNKNOWN'
        }];
        var updateLabels = function () {
            helper.updatePolstLabels($scope, $filter, aoChange);
        };
        updateLabels();
        $scope.$watch(function () {
            return $localStorage.lang;
        }, function () {
            updateLabels();
        });
        $scope.swapTabs = function (idx) {
            $scope.tabIndex = idx;
            if (idx === 1) {
                $location.search('p', idx);
            } else {

                $location.search({p: idx});
            }
        };
        $scope.fid = undefined;
        $scope.physician = {};
        $scope.polst = {};
        $scope.physicianSearch = function (txt) {
            return [];
        };
        var reset = function () {
            $scope.patientMatched = false;
            $scope.ptSearchDone = false;
            $scope.polst = {
                secD2: 2,
                gender: '',
                sigRelationship: 'Self',
                // addOrders: $filter('translate')('additional_orders') + ":\n\n",
                // foodAddOrders: $filter('translate')('additional_orders') + ":\n\n",
                notCompByPhys: $scope.user.userType == "Physician, PA, NP"
            };
            if ($scope.sig.clear !== undefined) {
                $scope.sig.clear();
            }
            $scope.fid = undefined;
            $scope.formSubmitted = false;
            $scope.isEmpty = false;
            $scope.savedSig = false;
            $scope.searchErr = false;
            $scope.numResults = false;
            $scope.oneResult = false;
            $scope.search = {};
            $scope.hideCreate = false;
            $scope.searchForm = undefined;
            if ($scope.form.pform !== undefined) {
                $scope.form.pform.$setPristine();
                $scope.form.pform.$setUntouched();
            }
        };
        var reinit = function () {
            reset();
            $scope.p = Number($routeParams.p);
            if ($scope.p === undefined || isNaN($scope.p)) {
                $scope.p = 0;
            }
            if ($location.search().f !== undefined) {
                $scope.fid = Number($routeParams.f);
                getForm();
            }
            $scope.tabIndex = $scope.p;
            if ($scope.p === 0) {
                getForms();
            }
            if ($scope.fid === undefined && $scope.p === 1) {
                $location.search({p: 0});
            }

        };
        $scope.$on('$routeChangeSuccess', function () {

            reinit();
        });
        $scope.$on('$locationChangeSuccess', function () {
            reinit();
        });
        // POLST:
        var aoChange = false;
        $scope.taChanged = function () {
            aoChange = true;
        };
        $scope.polst = {
            secD2: 2,
            addOrders: $filter('translate')('additional_orders') + ":\n\n",
            foodAddOrders: $filter('translate')('additional_orders') + ":\n\n"
        };
    };
    AdminFormCtrl.$inject = ['$scope', 'userService', '$location', '$routeParams', '$localStorage', 'helper', '$filter', 'user'];
    return AdminFormCtrl;
});

