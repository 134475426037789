define('data-entry/madie-patient-matching.controller',['angular'], function (angular) {
    'use strict';
    var MadiePatientMatchingCtrl = function ($scope, userService, user, dataEntryService, $location, $mdDialog) {
        $scope.user = user.data.User;
        $scope.formTable = {
            order: 'created',
            limit: 10,
            page: 1
        };
        $scope.viewDetails = function(l){
            $mdDialog.show({
                controller: 'MatchDetailsCtrl',
                templateUrl: 'data-entry/match-details.html',
                locals: {user: $scope.user, patient: l},
                parent: document.body,
                clickOutsideToClose: true,
                fullscreen: true
            }).then(function (response) {
                if(response !== undefined && response.reviewed){
                    l.is_reviewed = true;
                } else if(response !== undefined && response.confirmed){
                    l.is_reviewed = true;
                    l.assigned_patient_id = response.patient.id;
                }
            });
        };
        $scope.searchInput = '';
        $scope.$watch('searchInput', function () {
            $scope.findMatches();
        });
        $scope.findMatches = function () {
            if($scope.matches !== undefined){
                $scope.onPaginate(1, $scope.formTable.limit);
            }
        };
        dataEntryService.getPatientMatches($scope.user.sessionID).then(function (res) {
            $scope.matches = res.data;
            for (var i = 0; i < $scope.matches.length; i++) {
                var tmp = moment.utc($scope.matches[i].created, 'YYYY-MM-DD HH:mm:ss');
                $scope.matches[i].createdDate = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
                $scope.matches.sort(function (a, b) {
                    if (a.created === undefined && b.created === undefined) {
                        return 0;
                    } else if (a.created === undefined) {
                        return 1;
                    } else if (b.created === undefined) {
                        return -1;
                    }
                    var a2 = moment.utc(a.created, 'YYYY-MM-DD HH:mm:ss');
                    var b2 = moment.utc(b.created, 'YYYY-MM-DD HH:mm:ss');
                    if (a2.isBefore(b2)) {
                        return 1;
                    } else if (a2.isAfter(b2)) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                $scope.onPaginate($scope.formTable.page, $scope.formTable.limit);
            }
        });
        $scope.onPaginate = function (page, limit) {
            $scope.formTable.page = page;
            $scope.formTable.limit = limit;
            $scope.reorderForms();
        };
        $scope.reorderForms = function () {
            var start = (($scope.formTable.page - 1) * $scope.formTable.limit);
            var end = start + $scope.formTable.limit;
            $scope.formView = [];
            if ($scope.searchInput !== '') {
                var tmp = [];
                for (var i = 0; i < $scope.matches.length; i++) {
                    if ($scope.matches[i].id.toString().toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.matches[i].phone.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.matches[i].session_id.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.matches[i].fname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.matches[i].lname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.matches[i].email.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || ($scope.matches[i].fname + ' ' + $scope.matches[i].lastname).toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0) {
                        tmp.push($scope.matches[i]);
                    }
                }
                $scope.formView = [];
                for (var i = start; i < end && i < tmp.length; i++) {
                    $scope.formView.push(tmp[i]);
                }
            } else {
                for (var i = start; i < end && i < $scope.matches.length; i++) {
                    $scope.formView.push($scope.matches[i]);
                }
            }
        };
        $scope.formView = [];
        $scope.onReorder = function (order) {
            console.log(order);
            if (order === 'id') {
                $scope.matches.sort(function (a, b) {
                    return a.id > b.id ? -1 : a.id < b.id ? 1 : 0;
                });
            } else if (order === '-id') {
                $scope.matches.sort(function (a, b) {
                    return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
                });
            } else if (order === '-created') {
                $scope.matches.sort(function (a, b) {
                    if (a.created === undefined && b.created === undefined) {
                        return 0;
                    } else if (a.created === undefined) {
                        return -1;
                    } else if (b.created === undefined) {
                        return 1;
                    }
                    var a1 = moment.utc(a.created, 'YYYY-MM-DD HH:mm:ss');
                    var b1 = moment.utc(b.created, 'YYYY-MM-DD HH:mm:ss');
                    if (a1.isBefore(b1)) {
                        return -1;
                    } else if (a1.isAfter(b1)) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            } else if (order === 'sessionid') {
                $scope.matches.sort(function (a, b) {
                    return a.session_id.toLowerCase() > b.session_id.toLowerCase() ? -1 : b.session_id.toLowerCase() > a.session_id.toLowerCase() ? 1 : 0;
                });
            } else if (order === '-sessionid') {
                $scope.matches.sort(function (a, b) {
                    return a.session_id.toLowerCase() > b.session_id.toLowerCase() ? 1 : b.session_id.toLowerCase() > a.session_id.toLowerCase() ? -1 : 0;
                });
            } else if (order === 'created') {
                $scope.matches.sort(function (a, b) {
                    if (a.created === undefined && b.created === undefined) {
                        return 0;
                    } else if (a.created === undefined) {
                        return 1;
                    } else if (b.created === undefined) {
                        return -1;
                    }
                    var a2 = moment.utc(a.created, 'YYYY-MM-DD HH:mm:ss');
                    var b2 = moment.utc(b.created, 'YYYY-MM-DD HH:mm:ss');
                    if (a2.isBefore(b2)) {
                        return 1;
                    } else if (a2.isAfter(b2)) {
                        return -1;
                    } else {
                        return 0;
                    }

                });
            }
            $scope.reorderForms();
        };
    };
    MadiePatientMatchingCtrl.$inject = ['$scope', 'userService', 'user', 'dataEntryService', '$location', '$mdDialog'];
    return MadiePatientMatchingCtrl;
});

