define('user/resetpw.controller',['angular'], function (angular) {
    'use strict';
    var ResetPWCtrl = function ($scope, userService, $location) {
        $scope.emailNotFound = false;
        $scope.isProd = window.isProd;
        $scope.credentialsChanged = function () {
            $scope.regSubmitted = false;
            $scope.emailNotFound = false;
        };
        $scope.emailSent = false;
        $scope.resetPassword = function (valid) {
            $scope.regSubmitted = true;
            if (valid) {
                $scope.myPromise = userService.resetPassword({
                    email: $scope.credentials.email
                }, $scope).then(function () {
                });
            }
        };
    };
    ResetPWCtrl.$inject = ['$scope', 'userService', '$location'];
    return ResetPWCtrl;
});

