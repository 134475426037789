define('dashboard/generic-toast.controller',['angular'], function (angular) {
    'use strict';
    var GenericToastCtrl = function ($scope, $mdToast, $mdDialog, msg) {
        var isDlgOpen = false;
        $scope.msg = msg;
        $scope.closeToast = function ($event) {
            if (isDlgOpen)
                return;
            $mdToast.hide().then(function () {
                isDlgOpen = false;
            });
            $event.stopPropagation();
        };
    };
    GenericToastCtrl.$inject = ['$scope', '$mdToast', '$mdDialog', 'msg'];
    return GenericToastCtrl;
});

