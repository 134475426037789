define('acp-billing/acp-billing.services',['angular', 'common'], function (angular) {
    'use strict';
    var mod = angular.module('acp-billing.services', ['com.cd.common']);
    mod.factory('acpBillingService', ['$http', '$q', 'playRoutes', '$localStorage', function ($http, $q, playRoutes, $localStorage) {
        return {
            signVisitMD: function (visit) {
                return playRoutes.com.cd.controllers.ACPVisits.signVisitMD().post({
                    visit: visit,
                    sid: $localStorage.sid,
                    pref_org_id: $localStorage.pref_org_id
                });
            },
            addAddendum: function (id, note) {
                return playRoutes.com.cd.controllers.ACPVisits.addAddendum().post({
                    sid: $localStorage.sid,
                    note: note,
                    id: id,
                    pref_org_id: $localStorage.pref_org_id
                });
            },
            updateInsurance: function (visit) {
                return playRoutes.com.cd.controllers.ACPVisits.updateInsurance().post({
                    plan_name: visit.plan_name,
                    insured_id: visit.insured_id,
                    group_id: visit.group_id,
                    effective_date: visit.effective_date,
                    sid: $localStorage.sid,
                    id: visit.id,
                    pref_org_id: $localStorage.pref_org_id
                });
            },
            saveVisit: function (visit) {
                return playRoutes.com.cd.controllers.ACPVisits.saveVisit().post({
                    visit: visit,
                    sid: $localStorage.sid,
                    pref_org_id: $localStorage.pref_org_id
                });
            },
            getVisit: function (id) {
                return playRoutes.com.cd.controllers.ACPVisits.getVisit().post({
                    sid: $localStorage.sid,
                    id: id,
                    pref_org_id: $localStorage.pref_org_id
                });
            },
            getVisits: function (noteStatus, allDates, startDate, endDate, query) {
                return playRoutes.com.cd.controllers.ACPVisits.getVisits().post({
                    sid: $localStorage.sid,
                    noteStatus: noteStatus,
                    allDates: allDates,
                    query: query,
                    startDate: startDate !== undefined ? startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate() : undefined,
                    endDate: endDate !== undefined ? endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate() : undefined,
                    pref_org_id: $localStorage.pref_org_id
                });
            },
            updateVisit: function (id, visit) {
                return playRoutes.com.cd.controllers.ACPVisits.updateVisit().post({
                    id: id,
                    visit: visit,
                    sid: $localStorage.sid
                });
            },
            subscribeCustomer: function (token, quantity) {
                return playRoutes.com.cd.controllers.ACPVisits.subscribeCustomer().post({
                    token: token,
                    quantity: quantity,
                    makePayment: function (token) {
                        return playRoutes.com.cd.controllers.ACPVisits.makePayment().post({
                            token: token
                        });
                    },
                });
            }
        };
    }]);
    return mod;
});

