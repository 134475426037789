define('home/home.module',['angular', './home.routes', './home.controllers'], function(angular, routes, controllers) {
    'use strict';
    var mod = angular.module('com.cd.home', ['ngRoute', 'home.routes', 'ngIdle']);
    mod.controller("HeaderCtrl", controllers.HeaderCtrl).config(['IdleProvider', 'KeepaliveProvider', function(IdleProvider, KeepaliveProvider) {
        IdleProvider.idle(1140);
        IdleProvider.timeout(60);
        KeepaliveProvider.interval(60);
        // IdleProvider.idle(10);
        // IdleProvider.timeout(20);
        // KeepaliveProvider.interval(10);
    }]);
    mod.run(['Idle', function(Idle) {
        // only watch Idle on login or refresh
    }]);
    mod.controller("IdleCtrl", controllers.IdleCtrl);
    return mod;
});
