define('user/unsub.controller',['angular'], function (angular) {
    'use strict';
    var UnsubCtrl = function ($scope, $routeParams, userService, $location) {
        $scope.credentials = {};
        $scope.mismatch = false;
        $scope.verified = false;
        $scope.invalidLink = false;
        $scope.error = false;
        $scope.$on('$routeChangeSuccess', function () {
            $scope.credentials.key = $routeParams.key;
            $scope.credentials.email = $routeParams.email;
            userService.verifyUnsubLink({email: $routeParams.email, key: $routeParams.key}, $scope).then(function (r) {
                if (r === undefined || !r.valid) {
                    $scope.verified = true;
                    $scope.invalidLink = true;
                } else {
                    $scope.myPromise = userService.unsub($scope.credentials, $scope).then(function (re) {
                        $scope.verified = true;
                    });
                }
            });
        });
    };
    UnsubCtrl.$inject = ['$scope', '$routeParams', 'userService', '$location'];
    return UnsubCtrl;
});
