/**
 * A common directive. It would also be ok to put all directives into one file,
 * or to define one RequireJS module that references them all.
 */
define('common/directives/example',['angular'], function (angular) {
    'use strict';

    var mod = angular.module('common.directives.example', []);
    mod.directive('example', ['$log', function ($log) {
        return {
            restrict: 'AE',
            link: function (/* scope, el, attrs */) {
                $log.info('Here prints the example directive from /common/directives.');
            }
        };
    }]);

    mod.directive("hasVe", function() {
        return {
            restrict : "A",
            require : "ngModel",

            link : function(scope, element, attributes, ngModel) {
                var hasVe = attributes.hasVe;
                ngModel.$validators.hasVe = function(modelValue) {
                    return hasVe !== "true";
                };

                // NOTE: This looks like a shortcut, but it works
                setTimeout(function() {
                    ngModel.$touched = true;
                    scope.$apply();
                }, 100);
            }
        };
    });
    /**
     * Usage: <md-autocomplete (...) autocomplete-blur
     * md-input-id="autocomplete-input-id"> (...) </md-autocomplete>
     */

    mod.directive('objectReloadable', function () {
        var link = function (scope, element, attrs) {
            var currentElement = element;

            scope.$watch('documentSrc', function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    scope.documentSrc = newValue;
                    var html = '<object type="application/pdf" data="' + newValue + '"></object>';
                    var replacementElement = angular.element(html);
                    currentElement.replaceWith(replacementElement);
                    currentElement = replacementElement;
                }
            });
        };

        return {
            restrict: 'E',
            scope: false,
            link: link
        };
    });
    mod.directive('inputClear', function inputClear() {
        return {
            restrict: 'A',
            compile: function (element, attrs) {
                var color = attrs.inputClear;
                var style = color ? "color:" + color + ";" : "";
                var action = attrs.ngModel + " = ''";
                element.after(
                    '<md-button class="animate-show md-icon-button md-primary x-button"' +
                    'ng-show="' + attrs.ngModel + '" ng-click="' + action + '">' +
                    '<div>x</div>' +
                    '</md-button>');
            }
        };
    });
    mod.directive('autocompleteBlur', function () {
        return {
            link: function (scope, element, attrs) {
                setTimeout(clearInputBoxOnBlur, 0);

                function clearInputBoxOnBlur() {
                    element.find("#autocomplete-input-id").bind("blur", function () {
                        angular.element(this).val('');
                    });
                }
            }
        };
    });
    mod.directive("ngMobileClick", [function () {
        return function (scope, elem, attrs) {
            elem.bind("touchstart click", function (e) {
                e.preventDefault();
                e.stopPropagation();

                scope.$apply(attrs.ngMobileClick);
            });
        };
    }]);
    mod.directive('ngHref', ['$location', function ($location) {
        return {
            link: function (scope, elem, attrs) {
                if (attrs.ngHref) {
                    elem.on('touchend', function () {
                        $location.url(attrs.ngHref.replace("#", ""));
                    });
                }
            },
            priority: 1
        };
    }]);
    mod.directive('errSrc', function () {
        return {
            link: function (scope, element, attrs) {
                element.bind('error', function () {
                    if (attrs.src !== attrs.errSrc) {
                        attrs.$set('src', attrs.errSrc);
                    }
                });
            }
        };
    });
    mod.directive('fileUpload', function () {
        return {
            scope: true,        //create a new scope
            link: function (scope, el, attrs) {
                el.bind('change', function (event) {
                    var files = event.target.files;
                    //iterate files since 'multiple' may be specified on the element
                    for (var i = 0; i < files.length; i++) {
                        //emit event upward
                        scope.$emit("fileSelected", {file: files[i]});
                    }
                });
            }
        };
    });
    /*
     *   Validate phone number
     */
    mod.directive('phoneInput', ['$filter', '$browser', function ($filter, $browser) {
        var existingData = false;
        return {
            require: 'ngModel',
            link: function ($scope, $element, $attrs, ngModelCtrl) {
                var listener = function () {
                    var value = $filter('tel')($element.val().replace(/[^0-9]/g, ''), false);
                    $element.val(value);
                    setTimeout(function () {
                        if (value.length < 14 && value.length > 0 && (existingData || ngModelCtrl.$touched)) {
                            ngModelCtrl.$setValidity('pattern', false);
                        } else {
                            ngModelCtrl.$setValidity('pattern', true);
                        }
                    });
                };
                $scope.$watch($element.val(), function () {
                    if ($element.val().length > 0) {
                        existingData = true;
                    }
                    listener();
                });
                ngModelCtrl.$parsers.push(function (viewValue) {
                    return viewValue.replace(/[^0-9]/g, '').slice(0, 10);
                });
                ngModelCtrl.$render = function () {
                    $element.val($filter('tel')(ngModelCtrl.$viewValue, false));
                };
                $element.bind('change', function () {
                    $browser.defer(listener);
                });
                $element.bind('blur', function () {
                    $browser.defer(listener);
                });
                $element.bind('keydown', function (event) {
                    var key = event.keyCode;
                    if (key === 91 || (15 < key && key < 19) || (37 <= key && key <= 40)) {
                        return;
                    }
                    $browser.defer(listener);
                });
                $element.bind('paste cut', function () {
                    $browser.defer(listener);
                });
            }
        };
    }]);
    mod.directive('signaturePadCanvas', ['$window',
        function ($window) {
            var signaturePad, canvas, element, EMPTY_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=';
            return {
                restrict: 'EA',
                replace: true,
                template: '<div class="signature" ng-style="{height: height + \'px\', width: width + \'px\'}"><canvas height="{{ height }}" width="{{ width }}"></canvas></div>',
                scope: {
                    accept: '=',
                    clear: '=',
                    dataurl: '=',
                    height: '@',
                    ngShow: '=',
                    width: '@'
                },
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.accept = function () {
                            var signature = {};
                            if (!$scope.signaturePad.isEmpty()) {
                                signature.dataUrl = $scope.signaturePad.toDataURL();
                                signature.isEmpty = false;
                            } else {
                                signature.dataUrl = EMPTY_IMAGE;
                                signature.isEmpty = true;
                            }
                            return signature;
                        };

                        $scope.clear = function () {
                            $scope.signaturePad.clear();
                        };
                        $scope.$watch("ngShow", function () {
                            setTimeout(function () {
                                $scope.onResize();
                            });
                        });
                        $scope.$watch("dataurl", function (dataUrl) {
                            if (dataUrl) {
                                $scope.signaturePad.fromDataURL(dataUrl);
                            } else {
                                $scope.signaturePad.fromDataURL(EMPTY_IMAGE);
                            }
                        });
                    }
                ],
                link: function (scope, element, atrs) {
                    canvas = element.find('canvas')[0];
                    scope.signaturePad = new SignaturePad(canvas);
                    if (!scope.height) {
                        scope.height = 100;
                    }
                    if (!scope.width) {
                        scope.width = 400;
                    }
                    if (scope.signature && !scope.signature.$isEmpty && scope.signature.dataUrl) {
                        scope.signaturePad.fromDataURL(scope.signature.dataUrl);
                    }
                    scope.onResize = function () {
                        if (scope.ngShow) {
                            var canvas = element.find('canvas')[0];
                            var ratio = $window.devicePixelRatio || 1;
                            canvas.width = element[0].parentElement.offsetWidth;
                            canvas.height = scope.height;// * ratio;//canvas.offsetHeight * ratio;
                            canvas.getContext("2d").scale(ratio, ratio);
                            canvas.getContext("2d").scale(1, 1);
                            scope.signaturePad.clear();
                        }
                    };
                    angular.element($window).bind('resize', function () {
                        scope.onResize();
                    });
                }
            };
        }
    ]);
    mod.directive('signaturePad', ['$window',
        function ($window) {
            var signaturePad, canvas, element, EMPTY_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=';
            return {
                restrict: 'EA',
                replace: true,
                template: '<div class="signature" ng-style="{height: height + \'px\', width: width + \'px\'}"><canvas height="{{ height }}" width="{{ width }}"></canvas></div>',
                scope: {
                    // accept: '=',
                    // clear: '=',
                    dataurl: '=',
                    height: '@',
                    ngShow: '=',
                    width: '@'
                },
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.accept = function () {
                            var signature = {};
                            if (!$scope.signaturePad.isEmpty()) {
                                signature.dataUrl = $scope.signaturePad.toDataURL();
                                signature.isEmpty = false;
                            } else {
                                signature.dataUrl = EMPTY_IMAGE;
                                signature.isEmpty = true;
                            }
                            return signature;
                        };

                        $scope.clear = function () {
                            $scope.signaturePad.clear();
                        };
                        $scope.$watch("ngShow", function () {
                            setTimeout(function () {
                                $scope.onResize();
                            });
                        });
                        $scope.$watch("dataurl", function (dataUrl) {
                            if (dataUrl) {
                                $scope.signaturePad.fromDataURL(dataUrl);
                            } else {
                                $scope.signaturePad.fromDataURL(EMPTY_IMAGE);
                            }
                        });
                    }
                ],
                link: function (scope, element, atrs) {
                    canvas = element.find('canvas')[0];
                    scope.signaturePad = new SignaturePad(canvas);
                    if (!scope.height) {
                        scope.height = 100;
                    }
                    if (!scope.width) {
                        scope.width = 400;
                    }
                    if (scope.signature && !scope.signature.$isEmpty && scope.signature.dataUrl) {
                        scope.signaturePad.fromDataURL(scope.signature.dataUrl);
                    }
                    scope.onResize = function () {
                        if (scope.ngShow) {
                            var canvas = element.find('canvas')[0];
                            var ratio = $window.devicePixelRatio || 1;
                            canvas.width = element[0].parentElement.offsetWidth;
                            canvas.height = scope.height;// * ratio;//canvas.offsetHeight * ratio;
                            //canvas.getContext("2d").scale(ratio, ratio);
//                            canvas.getContext("2d").scale(1, 1);
//                             scope.signaturePad.clear();
                        }
                    };
                    angular.element($window).bind('resize', function () {
                        scope.onResize();
                    });
                }
            };
        }
    ]);
    return mod;
});

