define('data-entry/select-af-patient.controller',['angular'], function (angular) {
    'use strict';
    var SelectAfPatientCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                        userService, $window, $mdDialog, $sce, helper, pts, afform, newPtDisabled) {
        $scope.newPtDisabled = newPtDisabled;
        $scope.pts = pts;
        for (var zz = 0; zz < pts.length; zz++) {
            $scope.pts[zz].phone = $scope.pts[zz].phone === undefined ? undefined : $filter('tel')($scope.pts[zz].phone.replace(/[^0-9]/g, ''), false);
            if ($scope.pts[zz].score === '200%') {
                $scope.pts[zz].mrnMatch = true;
                $scope.pts[zz].score = '100%';
            }
        }
        afform.fname = afform.fname.toUpperCase();
        afform.lname = afform.lname.toUpperCase();
        if (afform.mname !== undefined) {
            afform.mname = afform.mname.toUpperCase();
        }
        afform.dob = moment(afform.dob.toUpperCase(), 'M/D/YYYY').format('MM/DD/YYYY');
        if (afform.PatientDOB1 !== undefined) {
            afform.PatientDOB1 = moment(afform.PatientDOB1.toUpperCase(), 'M/D/YYYY').format('MM/DD/YYYY');
        }
        if (afform.street !== undefined) {
            afform.street = afform.street.toUpperCase();
        }
        if (afform.city !== undefined) {
            afform.city = afform.city.toUpperCase();
        }
        if (afform.state !== undefined) {
            afform.state = afform.state.toUpperCase();
        }
        if (afform.zip !== undefined) {
            afform.zip = afform.zip.toUpperCase();
        }
        if (afform.phone !== undefined) {
            afform.phone = $filter('tel')(afform.phone.replace(/[^0-9]/g, ''), false);
            $scope.phone = afform.phone;
        }
        if (afform.ssn !== undefined) {
            afform.ssn = afform.ssn === undefined ? '' : afform.ssn.toUpperCase();
        }
        if (afform.gender !== undefined) {
            afform.gender = afform.gender.toUpperCase();
        }
        $scope.afform = afform;
        $scope.cancel = function () {
            $mdDialog.hide();
        };
        $scope.swapValue = function (d, p) {
            for (var i = 0; i < $scope.diffs.length; i++) {
                if ($scope.diffs[i].v === d) {
                    var tmp = $scope.diffs[i].o;
                    $scope.diffs[i].o = $scope.diffs[i].n;
                    $scope.diffs[i].n = tmp;
                    $scope.selectedPt[d] = $scope.diffs[i].n;
                }
            }
            console.log($scope.selectedPt);
        };
        $scope.ptSelected = function (p) {
            $scope.patientMatched = true;
            if (p !== undefined) {
                // existing patient, confirm data differences with user
                var diffs = [];
                if (p.fname !== $scope.compare.fname) {
                    diffs.push({lbl: "First Name", v: 'fname', o: p.fname, n: afform.fname});
                }
                if (p.lname !== $scope.compare.lname) {
                    diffs.push({lbl: "Last Name", v: 'lname', o: p.lname, n: afform.lname});
                }
                if (p.mname !== $scope.compare.mname) {
                    diffs.push({lbl: "Middle Name", v: 'mname', o: p.name, n: afform.mname});
                }
                if (p.street !== $scope.compare.street) {
                    diffs.push({lbl: "Street", v: 'street', o: p.street, n: afform.street});
                }
                if (p.city !== $scope.compare.city) {
                    diffs.push({lbl: "City", v: 'city', o: p.city, n: afform.city});
                }
                if (p.state !== $scope.compare.state) {
                    diffs.push({lbl: "State", v: 'state', o: p.state, n: afform.state});
                }
                if (p.zip !== $scope.compare.zip) {
                    diffs.push({lbl: "Zip", v: 'zip', o: p.zip, n: afform.zip});
                }
                if (p.dob !== $scope.compare.dob) {
                    diffs.push({lbl: "DOB", v: 'dob', o: p.dob, n: afform.dob});
                }
                if (p.gender !== $scope.compare.gender) {
                    diffs.push({lbl: "Gender", v: 'gender', o: p.gender, n: afform.gender});
                }
                if (p.ssn !== $scope.compare.ssn) {
                    diffs.push({lbl: "SSN", v: 'ssn', o: p.ssn, n: afform.ssn});
                }
                if (p.phone !== $scope.compare.phone) {
                    diffs.push({lbl: "Phone", v: 'phone', o: p.phone, n: afform.phone});
                }
                if (diffs.length > 0) {
                    $scope.selected = true;
                    $scope.diffs = diffs;
                    $scope.selectedPt = p;
                    for (var i = 0; i < $scope.diffs.length; i++) {
                        var tmp = $scope.diffs[i].o;
                        $scope.diffs[i].o = $scope.diffs[i].n;
                        $scope.diffs[i].n = tmp;
                        $scope.selectedPt[$scope.diffs[i].v] = $scope.diffs[i].o;
                    }
                } else {
                    $mdDialog.hide({patient: p, confirmed: true});
                }
            } else {
                $mdDialog.hide({confirmed: true});
            }
        };
        $scope.confirm = function () {
            for (var i = 0; i < $scope.diffs.length; i++) {
                $scope.selectedPt[$scope.diffs[i].v] = $scope.diffs[i].o;
            }
            $mdDialog.hide({confirmed: true, patient: $scope.selectedPt});
        };
        $scope.unconfirm = function () {
            $mdDialog.hide();
        };
        $scope.viewADPDF = function (id) {
            if ($scope.fileUrl !== undefined) {
                window.open($scope.fileAD_URL, "_blank");
            } else {
                userService.downloadADForm(id).then(function (response) {
                    var file = new Blob([response.data], {type: "application/pdf"});
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        $scope.msFileAD = file;
                        $scope.fileAD_URL = 'tmp.pdf';
                        // window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                        $scope.showDownload = true;
                        window.navigator.msSaveOrOpenBlob($scope.msFileAD, 'AdvancedDirective.pdf');
                    } else {
                        $scope.showDownload = true;
                        $scope.fileAD_URL = URL.createObjectURL(file);
                        window.open($scope.fileAD_URL, "_blank");
                    }
                });
            }
        };
        $scope.viewExisting = function (id) {
            userService.downloadForm(id).then(function (response) {
                var file = new Blob([response.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'afform.pdf');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });

        };
        $scope.compare = $scope.afform;
    };
    SelectAfPatientCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', '$window', '$mdDialog', '$sce', 'helper', 'pts', 'polst', 'newPtDisabled'];
    return SelectAfPatientCtrl;
});
