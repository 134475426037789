define('user/smart-session-invalid.controller',['angular'], function (angular) {
    'use strict';
    var SmartSessionInvalidCtrl = function ($scope, userService, $location) {
        $scope.emailNotFound = false;
        $scope.isProd = window.isProd;
        $scope.credentialsChanged = function () {
            $scope.regSubmitted = false;
            $scope.emailNotFound = false;
        };
        $scope.emailSent = false;
        $scope.resetPassword = function (valid) {
            $scope.regSubmitted = true;
            if (valid) {
                $scope.myPromise = userService.resetPassword({
                    email: $scope.credentials.email
                }, $scope).then(function () {
                });
            }
        };
        $scope.continue = function () {
            window.location.href = "about:home";
            // if (typeof window.home == 'function'){ // The rest of the world
            //     window.home();
            // } else if (document.all) { // For IE
            //     window.location.href = "about:home";
            // } else {
            //     widnow.alert('Please click on your browser\'s Home button.');
            // }
        };
    };
    SmartSessionInvalidCtrl.$inject = ['$scope', 'userService', '$location'];
    return SmartSessionInvalidCtrl;
});

