define('dashboard/toast-error.controller',['angular'], function (angular) {
    'use strict';
    var ToastFormErrCtrl = function ($scope, $mdToast) {
        $scope.closeToast = function ($event) {
            $mdToast.hide();
            $event.stopPropagation();
        };
    };
    ToastFormErrCtrl.$inject = ['$scope', '$mdToast'];
    return ToastFormErrCtrl;
});

