define('super-user/org-admin.controller',['angular'], function (angular) {
    'use strict';
    var OrgAdminCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                 userService, dataEntryService, $window, $mdDialog, $sce, helper, superUserService, user) {
        $scope.user = user.data.User;
        $scope.addOrg = function () {
            $location.path('super-user/addOrg');
        };

        $scope.searchInput = '';
        $scope.$watch('searchInput', function () {
            $scope.findMatches();
        });
        $scope.findMatches = function () {
            $scope.onPaginate(1, $scope.orgTable.limit);
        };

        $scope.dataReady = false;
        superUserService.getOrgs($scope.user.sessionID).then(function (r) {
            $scope.orgs = r.data;
            $scope.onReorder('name');
            $scope.dataReady = true;
        });
        $scope.orgQueries = function(o){
            $location.path('/analytics/detailed-query').search({oid: o.id});
        };
        $scope.orgDetails = function (o) {
            $location.path('super-user/manage-org').search({o: o.id});
        };

        $scope.orgAnalytics = function (o) {
            $location.path('super-user/org-analytics').search({o: o.id});
        };

        $scope.allOrgAnalytics = function (o) {
            $location.path('super-user/all-org-analytics');
        };

        $scope.onPaginate = function (page, limit) {
            $scope.orgTable.page = page;
            $scope.orgTable.limit = limit;
            $scope.reorderOrgs();
        };
        // $scope.reorderOrgs = function () {
        //     var start = (($scope.orgTable.page - 1) * $scope.orgTable.limit);
        //     var end = start + $scope.orgTable.limit;
        //     $scope.orgView = [];
        //     for (var i = start; i < end && i < $scope.orgs.length; i++) {
        //         $scope.orgView.push($scope.orgs[i]);
        //     }
        // };

        $scope.reorderOrgs = function () {
            var start = (($scope.orgTable.page - 1) * $scope.orgTable.limit);
            var end = start + $scope.orgTable.limit;

            if ($scope.searchInput !== '') {
                
                var tmp = [];
                for (var i = 0; i < $scope.orgs.length; i++) {
                    if ($scope.orgs[i].name.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0) {
                        tmp.push($scope.orgs[i]);
                    }
                }

                $scope.totalOrgs = tmp.length;
                $scope.orgView = [];
                for (var i = start; i < end && i < tmp.length; i++) {
                    $scope.orgView.push(tmp[i]);
                }

            } else {
                if($scope.orgs !== undefined) {
                    $scope.totalOrgs = $scope.orgs.length;
                    $scope.orgView = [];
                    for (var i = start; i < end && i < $scope.orgs.length; i++) {
                        $scope.orgView.push($scope.orgs[i]);
                    }
                }
            }
        };
        $scope.orgView = [];
        $scope.orgTable = {
            order: 'name',
            limit: 100,
            page: 1
        };
        $scope.onReorder = function (order) {
            if (order === '-name') {
                $scope.orgs.sort(function (a, b) {
                    return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : b.name.toLowerCase() > a.name.toLowerCase() ? 1 : 0;
                });
            } else if (order === 'name') {
                $scope.orgs.sort(function (a, b) {
                    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
                });
            } else if (order === '-type') {
                $scope.orgs.sort(function (a, b) {
                    return a.type.toLowerCase() > b.type.toLowerCase() ? -1 : b.type.toLowerCase() > a.type.toLowerCase() ? 1 : 0;
                });
            } else if (order === 'type') {
                $scope.orgs.sort(function (a, b) {
                    return a.type.toLowerCase() > b.type.toLowerCase() ? 1 : b.type.toLowerCase() > a.type.toLowerCase() ? -1 : 0;
                });
            }
            $scope.onPaginate($scope.orgTable.page, $scope.orgTable.limit);
        };
        $scope.searchTextChange = function () {
            $scope.org = undefined;
            $scope.orgUsers = undefined;
        };
        $scope.selectedItemChange = function (item) {
            if (item !== undefined) {
                $scope.org = item;
                superUserService.getOrgAdmins(item.id, $scope.user.sessionID).then(function (r) {
                    $scope.orgUsers = r;
                });
            }
        };
    };
    OrgAdminCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'superUserService', 'user'];
    return OrgAdminCtrl;
});

