define('super-user/user-orgs.controller',['angular'], function (angular) {
    'use strict';
    var UserOrgsCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                 userService, dataEntryService, superUserService, $window, $mdDialog, $sce, helper, user, $rootScope, admin) {
        $scope.user = user;
        $scope.getOrgs = function(){
            superUserService.getUserOrgs($scope.user.id, admin.sessionID).then(function(resp){
                $scope.orgs = resp;
            });
        };
        $scope.getOrgs();
        $scope.removeOrg = function(id){
            superUserService.removeUserOrg($scope.user.id, admin.sessionID, id).then(function(){
                $scope.getOrgs();
            });
        };
        $scope.retrieveOrg = function (l) {
            return dataEntryService.retrieveOrg(admin.sessionID, l).then(function (data) {
                var cpy = [];
                for(var i = 0; i < data.length; i++){
                    var found = false;
                    for(var j = 0; j < $scope.orgs.length; j++){
                        if($scope.orgs[j].id === data[i].id){
                            found = true;
                        }
                    }
                    if(!found){
                        cpy.push(data[i]);
                    }
                }
                return cpy;
            }, function (e) {
                return [];
            });
        };
        $scope.searchTextChange = function (text) {
            $scope.selectedItem = undefined;
        };
        $scope.selectedItemChange = function (item) {
            $scope.selectedItem = item;
            if(item !== undefined) {
                superUserService.addUserOrg($scope.user.id, admin.sessionID, item.id).then(function () {
                    $scope.getOrgs();
                });
            }
        };
        $scope.cancel = function(){
            $mdDialog.hide(true);
        };

    };
    UserOrgsCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', 'superUserService', '$window', '$mdDialog', '$sce', 'helper', 'user', '$rootScope', 'admin'];
    return UserOrgsCtrl;
});

