define('data-entry/match-details.controller',['angular'], function (angular) {
    'use strict';
    var MatchDetailsCtrl = function ($scope, userService, user, dataEntryService, $location, $mdDialog, patient, $filter) {
        $scope.user = user;
        var afform = patient;
        if (afform.mname !== undefined) {
            afform.mname = afform.mname.toUpperCase();
        }
        afform.fname = afform.fname.toUpperCase();
        afform.lname = afform.lname.toUpperCase();
        afform.dob = moment(afform.dob.toUpperCase(), 'M/D/YYYY').format('MM/DD/YYYY');
        afform.street = afform.street.toUpperCase();
        afform.city = afform.city.toUpperCase();
        afform.state = afform.state.toUpperCase();
        afform.zip = afform.zip.toUpperCase();
        afform.phone = $filter('tel')(afform.phone.replace(/[^0-9]/g, ''), false);
        $scope.phone = afform.phone;
        afform.ssn = afform.ssn === undefined ? '' : afform.ssn.toUpperCase();
        afform.gender = afform.gender.toUpperCase();
        $scope.afform = afform;
        $scope.num_pts_checked = 0;
        $scope.loadingData = false;
        userService.matchPatient(patient).then(function (pts) {
            $scope.pts = pts.data;
            if($scope.pts.length === 0){
                $scope.loadingData = false;
            }
            for (var zz = 0; zz < $scope.pts.length; zz++) {
                const ii = zz;
                dataEntryService.hasMadieAccount($scope.pts[zz].id, $scope.user.sessionID).then(function(response){
                    if(response.data.hasAccount === true){
                        $scope.pts[ii].disableSelect = true;
                    }
                    $scope.num_pts_checked++;
                    if($scope.num_pts_checked === $scope.pts.length){
                        $scope.loadingDadta = false;
                    }
                });
                $scope.pts[zz].phone = $scope.pts[zz].phone === undefined ? undefined : $filter('tel')($scope.pts[zz].phone.replace(/[^0-9]/g, ''), false);
                if ($scope.pts[zz].score === '200%') {
                    $scope.pts[zz].mrnMatch = true;
                    $scope.pts[zz].score = '100%';
                }
            }
        });
        $scope.swapValue = function (d, p) {
            for (var i = 0; i < $scope.diffs.length; i++) {
                if ($scope.diffs[i].v === d) {
                    var tmp = $scope.diffs[i].o;
                    $scope.diffs[i].o = $scope.diffs[i].n;
                    $scope.diffs[i].n = tmp;
                    $scope.selectedPt[d] = $scope.diffs[i].n;
                }
            }
        };
        $scope.ptSelected = function (p) {
            $scope.patientMatched = true;
            if (p !== undefined) {
                // existing patient, confirm data differences with user
                var diffs = [];
                if (p.fname !== $scope.compare.fname) {
                    diffs.push({lbl: "First Name", v: 'fname', o: p.fname, n: afform.fname});
                }
                if (p.lname !== $scope.compare.lname) {
                    diffs.push({lbl: "Last Name", v: 'lname', o: p.lname, n: afform.lname});
                }
                if (p.mname !== $scope.compare.mname) {
                    diffs.push({lbl: "Middle Name", v: 'mname', o: p.name, n: afform.mname});
                }
                if (p.street !== $scope.compare.street) {
                    diffs.push({lbl: "Street", v: 'street', o: p.street, n: afform.street});
                }
                if (p.city !== $scope.compare.city) {
                    diffs.push({lbl: "City", v: 'city', o: p.city, n: afform.city});
                }
                if (p.state !== $scope.compare.state) {
                    diffs.push({lbl: "State", v: 'state', o: p.state, n: afform.state});
                }
                if (p.zip !== $scope.compare.zip) {
                    diffs.push({lbl: "Zip", v: 'zip', o: p.zip, n: afform.zip});
                }
                if (p.dob !== $scope.compare.dob) {
                    diffs.push({lbl: "DOB", v: 'dob', o: p.dob, n: afform.dob});
                }
                if (p.gender !== $scope.compare.gender) {
                    diffs.push({lbl: "Gender", v: 'gender', o: p.gender, n: afform.gender});
                }
                if (p.ssn !== $scope.compare.ssn) {
                    diffs.push({lbl: "SSN", v: 'ssn', o: p.ssn, n: afform.ssn});
                }
                if (p.phone !== $scope.compare.phone) {
                    diffs.push({lbl: "Phone", v: 'phone', o: p.phone, n: afform.phone});
                }
                if (diffs.length > 0) {
                    $scope.selected = true;
                    $scope.diffs = diffs;
                    $scope.selectedPt = p;
                } else {
                    $mdDialog.hide({patient: p, confirmed: true});
                }
            } else {
                $mdDialog.hide({confirmed: true});
            }
        };
        $scope.submitted = false;
        $scope.confirm = function () {
            console.log($scope.selectedPt);
            if(!$scope.submitted){
                $scope.submitted = true;
                dataEntryService.updatePatientMatch($scope.selectedPt, $scope.user.sessionID, $scope.afform.assigned_patient_id, $scope.afform.id).then(function(response){
                    $mdDialog.hide({confirmed: true, patient: $scope.selectedPt});
                });
            }
        };
        $scope.unconfirm = function () {
            $scope.selected = false;
            $scope.diffs = [];
            $scope.selectedPt = undefined;
        };
        $scope.viewExisting = function (id) {
            userService.downloadForm(id).then(function (response) {
                var file = new Blob([response.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'afform.pdf');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });

        };
        $scope.cancel = function () {
            $mdDialog.hide();
        };
        $scope.reviewed = function () {
            $scope.myPromise = dataEntryService.markAsReviewed($scope.afform.id, $scope.user.sessionID).then(function () {
                $mdDialog.hide({reviewed: true});
            });
        };
        $scope.compare = $scope.afform;
    };
    MatchDetailsCtrl.$inject = ['$scope', 'userService', 'user', 'dataEntryService', '$location', '$mdDialog', 'patient', '$filter'];
    return MatchDetailsCtrl;
});

