define('home/home.controller',['angular'], function (angular) {
    'use strict';
    var HomeCtrl = function ($scope, userService, $document, $location) {
        console.log('inside of homectrl');
        userService.logout();
        // .then(function () {
        //     console.log('inside of logout return');
        // }, function () {
        //     console.log('inside of error in homectrl');
        // });
        $scope.isProd = window.isProd;
        $scope.credentialsChanged = function () {
            $scope.loginSubmitted = false;
            $scope.lockedout = false;
        };
        $scope.loginMethodChanged = function () {
            if ($scope.useSMS) {
                $scope.showCode = false;
            }
        };
        $scope.phoneNumberChanged = function(){
            $scope.numberNotFound = false;
        };
        $scope.numberNotFound = false;
        $scope.sendingCode = false;
        $scope.sendCode = function () {
            if (!$scope.sendingCode) {
                $scope.sendingCode = true;
                userService.sendSMSLoginCode($scope.smsLogin.cell_phone).then(function (response) {
                    $scope.sendingCode = false;
                    $scope.showCode = true;
                }, function (err) {
                    $scope.sendingCode = false;
                    $scope.numberNotFound = true;
                });
            }
        };

        $scope.resend = function () {
            $scope.codeError = false;
            $scope.verifyError = false;
            userService.sendSMSLoginCode($scope.smsLogin.cell_phone).then(function (response) {
            });
        };
        $scope.removeVerifyError = function () {
            $scope.verifyError = false;
        };
        $scope.smsLogin = {cell_phone: '', verification_code: ''};
        $scope.verifying = false;
        $scope.verify = function () {
            if(!$scope.verifying){
                $scope.verifying = true;
                userService.verifySMSLoginCode($scope.smsLogin.cell_phone, Number($scope.smsLogin.verification_code), $scope).then(function (response) {
                    $scope.verifying = false;
                    if ($scope.validLogin) {
                        $scope.verifiedSuccess = true;
                        $location.path('/dashboard');
                    } else {
                        $scope.codeError = true;
                        $scope.verifyError = true;
                    }
                }, function (e) {
                    $scope.verifying = false;
                    $scope.codeError = true;
                    $scope.verifyError = true;
                });
            }
        };
        $scope.credentials = {};
        $scope.lockedOut = false;
        $scope.loginUser = function (credentials) {
            $scope.lockedout = false;
            $scope.isProcessing = true;
            $scope.loginSubmitted = true;
            $scope.myPromise = userService.loginUser(credentials, $scope).then(function () {
                $scope.isProcessing = false;
                if ($scope.validLogin) {
                    $location.path("/dashboard");
                } else if ($scope.redirect === true) {
                    $location.path("/setpassword").search({
                        key: $scope.redirectKey,
                        email: $scope.redirectEmail,
                        change: 1
                    });
                }
            });
        };
        $scope.setTouched = function () {
            setTimeout(function () {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {

                } else {
                    // var elem = angular.element($document[0].querySelector('input[type=password]:-webkit-autofill'));
                    // if (elem !== undefined && elem.length) {
                    //     elem.parent().addClass('md-input-has-value');
                    // }
                }
            }, 150);
        };
        userService.checkLogin().then(function () {
            if (userService.getUser() === undefined) {
                $scope.isLoggedIn = false;
            } else {
                $scope.isLoggedIn = true;
            }
        }, function () {
            $scope.isLoggedIn = false;
        });

    };
    HomeCtrl.$inject = ['$scope', 'userService', '$document', '$location'];
    return HomeCtrl;
});

