define('super-user/manage-org-users.controller',['angular'], function (angular) {
    'use strict';
    var OrgAdminCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                 userService, dataEntryService, $window, $mdDialog, $sce, helper, superUserService, user) {
        $scope.user = user.data.User;
        $scope.onPaginate = function (page, limit) {
            $scope.userTable.page = page;
            $scope.userTable.limit = limit;
            $scope.reorderUsers();
        };

        $scope.searchInput = '';
        $scope.$watch('searchInput', function () {
            $scope.findMatches();
        });
        $scope.findMatches = function () {
            $scope.onPaginate(1, $scope.userTable.limit);
        };

        $scope.choices = ['All', 'Active', 'Inactive', 'Locked'];
        $scope.detailFilter = 'All';
        $scope.oldValue = '';
        $scope.getSelectedText = function () {
            if ($scope.detailFilter !== undefined && $scope.oldValue !== $scope.detailFilter) {
                // $scope.refine($scope.detailFilter)
                $scope.oldValue = $scope.detailFilter;
                $scope.onPaginate(1, $scope.userTable.limit);
            }
        };

        $scope.unlockUser = function (l) {
            superUserService.unlockUser(l.id, $scope.user.sessionID).then(function () {
                l.locked_out = false;
                l.active = true;
            });
        };
        $scope.removeSMS = function (l, ev){
            var confirm = $mdDialog.confirm()
                .title('Are you sure you want to remove SMS authentication for ' + l.firstname + ' ' + l.lastname + '?')
                .textContent('This user will no longer be able to use ' + l.cell_phone + ' to log in.')
                .targetEvent(ev)
                .ok('Remove SMS')
                .cancel('Cancel');
            $mdDialog.show(confirm).then (function() {
                userService.removeUserSMS(l.id).then (function(){
                    l.cell_phone = undefined;
                });
            }, function() {
            });
        };
        $scope.addSMS = function (l){
            $mdDialog.show({
                controller: 'SetSMSCtrl',
                templateUrl: 'user/set-sms.html',
                parent: angular.element(document.body),
                locals: {user: l},
                fullscreen: true
            }).then(function (data) {
                if (data != undefined && data.phone != undefined) {
                    l.cell_phone = data.phone;
                }
            });
        };
        $scope.prepUser = function (u) {
            u.created = new Date(u.created);
            if (u.lastLogin !== undefined) {
                var tmp = moment.utc(u.lastLogin, 'YYYY-MM-DD HH:mm:ss');
                u.loginDate = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
            } else {
                u.loginDate = undefined;
            }
        };
        $scope.swapActive = function (l) {
            userService.swapActive(l.email, l.active === true ? "1" : "0").then(function () {
                if (l.active === true) {
                    l.active = false;
                } else {
                    l.active = true;
                }
            });
        };
        $scope.goBack = function () {
            $location.path('/super/orgs').search({});
        };
        $scope.users = [];
        var orgid = +$location.search()['o'];
        $scope.orgid = orgid;
        $scope.dataReady = false;
        superUserService.getOrg($scope.user.sessionID, orgid).then(function (r) {
            $scope.org = r.data;
            superUserService.getOrgUsers($scope.user.sessionID, orgid).then(function (response) {
                for (var i = 0; i < response.data.length; i++) {
                    if ($scope.user.email !== response.data[i].email) {
                        if (response.data[i].email === undefined) {
                            response.data[i].email = '';
                        }
                        if (response.data[i].firstname === undefined) {
                            response.data[i].firstname = '';
                        }
                        if (response.data[i].lastname === undefined) {
                            response.data[i].lastname = '';
                        }
                    $scope.users.push(response.data[i]);
                    $scope.prepUser($scope.users[$scope.users.length - 1]);
                    }
                }
                $scope.users.sort(function (a, b) {
                    if (a.lastLogin === undefined && b.lastLogin === undefined) {
                        return 0;
                    } else if (a.lastLogin === undefined) {
                        return 1;
                    } else if (b.lastLogin === undefined) {
                        return -1;
                    }
                    var a2 = moment.utc(a.lastLogin, 'YYYY-MM-DD HH:mm:ss');
                    var b2 = moment.utc(b.lastLogin, 'YYYY-MM-DD HH:mm:ss');
                    if (a2.isBefore(b2)) {
                        return 1;
                    } else if (a2.isAfter(b2)) {
                        return -1;
                    } else {
                        return 0;
                    }

                });
                $scope.dataReady = true;
                $scope.onPaginate($scope.userTable.page, $scope.userTable.limit);
            });
        });
        $scope.reorderUsers = function () {
            var start = (($scope.userTable.page - 1) * $scope.userTable.limit);
            var end = start + $scope.userTable.limit;

            if ($scope.searchInput !== '') {


                if ($scope.detailFilter === 'All') {
                    var tmp = [];
                    for (var i = 0; i < $scope.users.length; i++) {
                        if ($scope.users[i].firstname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.users[i].lastname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.users[i].email.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || ($scope.users[i].firstname + ' ' + $scope.users[i].lastname).toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0) {
                            tmp.push($scope.users[i]);
                        }
                    }

                    $scope.totalUsers = tmp.length;
                    $scope.userView = [];
                    for (var i = start; i < end && i < tmp.length; i++) {
                        $scope.userView.push(tmp[i]);
                    }
                } else {
                    var tmp = [];
                    for (var i = 0; i < $scope.users.length; i++) {
                        if (($scope.users[i].active && $scope.detailFilter == 'Active') || (!$scope.users[i].active && !$scope.users[i].locked_out && $scope.detailFilter == 'Inactive') || ($scope.users[i].locked_out && $scope.detailFilter == 'Locked')) {

                            if ($scope.users[i].firstname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.users[i].lastname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.users[i].email.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || ($scope.users[i].firstname + ' ' + $scope.users[i].lastname).toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0) {
                                tmp.push($scope.users[i]);
                            }
                        }

                    }
                    $scope.totalUsers = tmp.length;
                    $scope.userView = [];
                    for (var i = start; i < end && i < tmp.length; i++) {
                        $scope.userView.push(tmp[i]);
                    }

                }

            } else {

                if ($scope.detailFilter === 'All') {
                    $scope.totalUsers = $scope.users.length;
                    $scope.userView = [];
                    for (var i = start; i < end && i < $scope.users.length; i++) {
                        $scope.userView.push($scope.users[i]);
                    }
                } else {
                    var tmp = [];
                    for (var i = 0; i < $scope.users.length; i++) {
                        if (($scope.users[i].active && $scope.detailFilter == 'Active') || (!$scope.users[i].active && !$scope.users[i].locked_out && $scope.detailFilter == 'Inactive') || ($scope.users[i].locked_out && $scope.detailFilter == 'Locked')) {
                            tmp.push($scope.users[i]);
                        }
                    }
                    $scope.totalUsers = tmp.length;
                    $scope.userView = [];
                    for (var i = start; i < end && i < tmp.length; i++) {
                        $scope.userView.push(tmp[i]);
                    }

                }
            }
        };
        $scope.userView = [];
        $scope.onReorder = function (order) {
            $scope.users = helper.orderUsers(order, $scope.users);
            $scope.reorderUsers();
        };
        $scope.userTable = {
            order: 'lastLogin',
            limit: 10,
            page: 1
        };
        $scope.prepUser = function (u) {
            u.created = new Date(u.created);
            if (u.lastLogin !== undefined) {
                var tmp = moment.utc(u.lastLogin, 'YYYY-MM-DD HH:mm:ss');
                u.loginDate = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
            } else {
                u.loginDate = undefined;
            }
        };
        $scope.addUser = function () {
            $location.path('/super-user/add-user').search({o: $scope.orgid});
        };
        $scope.showOrgs = function (u, e) {
            $mdDialog.show({
                controller: 'UserOrgsCtrl',
                templateUrl: 'super-user/user-orgs.html',
                locals: {user: u, orgs: $scope.orgs, admin: $scope.user},
                parent: document.body,
                targetEvent: e,
                clickOutsideToClose: true,
                fullscreen: true
            }).then(function (response) {

            });
        };
        $scope.showDetails = function (u, e) {
            $mdDialog.show({
                controller: 'UserDetailsCtrl',
                templateUrl: 'user/userDetails.html',
                locals: {user: u, orgs: $scope.orgs, admin: $scope.user},
                parent: document.body,
                targetEvent: e,
                clickOutsideToClose: true,
                fullscreen: true
            }).then(function (response) {
                if (response) {
                    $mdToast.show({
                        hideDealy: 10000,
                        position: 'bottom right',
                        controller: 'ToastCtrl',
                        templateUrl: 'user/userSavedToast.html',
                        locals: {credentials: response, orgs: $scope.orgs, admin: $scope.user}
                    });
                    var k = 0;
                    for (k = 0; k < $scope.userView.length; k++) {
                        if ($scope.userView[k].id === response.id) {
                            $scope.userView[k].firstName = response.firstname;
                            $scope.userView[k].lastName = response.lastname;
                            $scope.userView[k].firstname = response.firstname;
                            $scope.userView[k].lastname = response.lastname;
                            $scope.userView[k].phone = response.phone;
                            $scope.userView[k].userType = response.userType;
                        }
                    }
                    for (k = 0; k < $scope.users.length; k++) {
                        if ($scope.users[k].id === response.id) {
                            $scope.users[k].firstName = response.firstname;
                            $scope.users[k].firstname = response.firstname;
                            $scope.users[k].phone = response.phone;
                            $scope.users[k].lastName = response.lastname;
                            $scope.users[k].lastname = response.lastname;
                            $scope.users[k].userType = response.userType;
                        }
                    }
                }
            });
        };
    };
    OrgAdminCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'superUserService', 'user'];
    return OrgAdminCtrl;
});

