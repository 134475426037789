define('dashboard/change-assigned-physician.controller',['angular'], function (angular) {
    'use strict';
    var ChangeAssignedPhysicianCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                    userService, $window, $mdDialog, $sce, helper, user, polst) {
        $scope.polst = polst;
        if($scope.polst.phsyician !== undefined){
            if ($scope.polst.phsyician.lastLogin !== undefined) {
                var tmp = moment.utc($scope.polst.phsyician.lastLogin, 'YYYY-MM-DD HH:mm:ss');
                $scope.polst.phsyician.loginDate = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
            }
        }
        $scope.processing = false;
        $scope.changeAssignment = function (valid, ev) {
            $scope.addSubmitted = true;
            if($scope.phys !== undefined && !$scope.processing){
                $scope.processing = true;
                $scope.myPromise = userService.changeAssignedPhysician($scope.polst.id, $scope.phys.id).then(function(response){
                    $scope.processing = false;
                    $mdDialog.hide({phys: $scope.phys});
                }, function(){
                    $scope.processing = false;
                });
            }
        };
        $scope.cancel = function () {
            $mdDialog.hide();
        };
        $scope.mdSelected = function (item) {
            $scope.phys = item;
            if ($scope.phys !== undefined) {
                if ($scope.phys.lastLogin !== undefined) {
                    var tmp = moment.utc($scope.phys.lastLogin, 'YYYY-MM-DD HH:mm:ss');
                    $scope.phys.loginDate = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
                } else {
                    $scope.phys.loginDate = undefined;
                }
            }
        };
        $scope.searchTextChange = function () {
            $scope.phys = undefined;
        };
        $scope.physicianSearch = function (txt) {
            if (txt.length >= 1) {
                return userService.searchPhysicians(txt).then(function (data) {
                    for (var z = 0; z < data.length; z++) {
                        data[z].fullname = data[z].firstname + " " + data[z].lastname;
                    }
                    return data;
                });
            }
            return [];
        };
    };
    ChangeAssignedPhysicianCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', '$window', '$mdDialog', '$sce', 'helper', 'user', 'polst'];
    return ChangeAssignedPhysicianCtrl;
});
