define('dashboard/upload.controller',['angular'], function (angular) {
    'use strict';
    var UploadFormCtrl = function ($scope, userService, user, $location, $routeParams, $filter, $localStorage, helper, $mdToast, $mdDialog, $document) {
        $scope.searchPts = function () {
            userService.matchPatient({
                lname: $scope.polst.lname,
                fname: $scope.polst.fname,
                mname: $scope.polst.mname,
                street: $scope.polst.street,
                city: $scope.polst.city,
                state: $scope.polst.state,
                zip: $scope.polst.zip,
                dob: $scope.polst.dob,
                ssn: $scope.polst.ssn,
                gender: $scope.polst.gender,
                ins: $scope.polst.ins,
                phone: $scope.polst.phone
            }).then(function (response) {
                $scope.pts = response.data;
                for (var zz = 0; zz < $scope.pts.length; zz++) {
                    console.log($scope.pts[zz]);
                    $scope.pts[zz].score = Math.round(($scope.pts[zz].score * 100)) + "%";
                }
                $scope.ptSearchDone = true;
            });

        };
        $scope.physSelected = function(item){
            if(item !== undefined) {
                $scope.polst.supervisingPhysEmail = item.email;
                $scope.polst.provName = item.firstname + " " + item.lastname;
                $scope.polst.provPhone = item.phone;
                $scope.polst.provLicenseNumber = item.licenseNo;
            }
        };
        $scope.ptSelected = function (p) {
            $scope.patientMatched = true;
            if(p !== undefined) {
                $scope.polst.patientID = p.id;
            }
            $scope.polst.sigName = $scope.polst.fname + " " + ($scope.polst.mname === undefined ? "" : $scope.polst.mname) + " " + $scope.polst.lname;
            $scope.polst.sigStreet = $scope.polst.street;
            $scope.polst.sigCity = $scope.polst.city;
            $scope.polst.sigState = $scope.polst.state;
            $scope.polst.sigZip = $scope.polst.zip;
            $scope.polst.sigPhone = $scope.polst.phone;
        };
        $scope.physician = {};
        $scope.physicianSearch = function (txt) {
            if (txt.length >= 2) {
                return userService.searchPhysicians(txt).then(function (data) {
                    for (var z = 0; z < data.length; z++) {
                        data[z].fullname = data[z].firstname + " " + data[z].lastname;
                    }
                    return data;
                });
            }
            return [];
        };
        // $scope.hospiceInvalid = true;
        $scope.hospiceChanged = function () {
            // $scope.hospiceInvalid = true;
            if ($scope.polst.onHospice === 0) {
                $scope.hospiceInvalid = false;
            } else {
                if ($scope.hospice.selectedItem !== undefined && $scope.hospice.selectedItem !== null) {
                    $scope.hospiceInvalid = false;
                }
            }
        };
        $scope.hospice = {};
        $scope.hospiceSearch = function (txt) {
            if (txt.length >= 1) {
                return userService.searchHospice(txt).then(function (data) {
                    return data;
                });

            }
            return [];
        };
        $scope.addForm = function () {
            $location.path("/addform");
        };
        $scope.secAChanged = function () {
            if ($scope.polst.secA === 0 && $scope.polst.secB !== 0) {
                $scope.polst.secB = undefined;
                $scope.uncheckB();
            } else if ($scope.polst.secA === 1 && $scope.polst.secB === 0) {
                // $scope.polst.secB = undefined;
                // $scope.uncheckB();
            }
        };
        $scope.resetEmpty2 = function () {
            $scope.isEmpty2 = false;
        };
        $scope.clearSig2 = function () {
            $scope.isEmpty2 = false;
            $scope.savedSig2 = false;
            $scope.polst.physSigURL = undefined;
        };
        $scope.sign2 = function (sig2) {
            if (sig2.isEmpty) {
                $scope.isEmpty2 = true;
            } else {
                $scope.isEmpty2 = false;
                $scope.savedSig2 = true;
                $scope.polst.physSigURL = sig2.dataUrl;
            }
        };
        $scope.resetEmpty = function () {
            $scope.isEmpty = false;
        };
        $scope.clearSig = function () {
            $scope.isEmpty = false;
            $scope.savedSig = false;
            $scope.polst.sigURL = undefined;
        };
        $scope.sign = function (sig) {
            if (sig.isEmpty) {
                $scope.isEmpty = true;
            } else {
                $scope.isEmpty = false;
                $scope.savedSig = true;
                $scope.polst.sigURL = sig.dataUrl;
            }
        };
        var aoChange = false;
        $scope.taChanged = function () {
            aoChange = true;
        };
        $scope.genders = [{
            id: 1,
            name: "MALE"
        }, {
            id: 2,
            name: "FEMALE"
        }, {
            id: 3,
            name: 'UNDIFFERENTIATED'
        }];
        $scope.polst = {
            secD2: 2,
            onHospice: 0
            // addOrders: $filter('translate')('additional_orders') + ":\n\n",
            // foodAddOrders: $filter('translate')('additional_orders') + ":\n\n"
        };
        var updateLabels = function () {
            helper.updatePolstLabels($scope, $filter, aoChange);
        };
        updateLabels();
        $scope.$watch(function () {
            return $localStorage.lang;
        }, function () {
            updateLabels();
        });
        var validContact = function () {
            if ($scope.polst.noProv || ($scope.polst.contactPhone !== undefined && $scope.polst.contactPhone.length > 0 && $scope.polst.contactTitle !== undefined && $scope.polst.contactTitle.length > 0 && $scope.polst.contactName !== undefined && $scope.polst.contactName.length > 0)) {
                return true;
            }
            return false;
        };
        var validPhysSign = function () {
            if (!$scope.user.phyiscian || ($scope.polst.provName !== undefined && $scope.polst.provName.length > 0 && $scope.polst.provLicenseNumber !== undefined && $scope.polst.provLicenseNumber.length > 0 && $scope.polst.provPhone !== undefined && $scope.polst.provPhone.length > 0)) {
                return true;
            }
            return false;
        };
        var validAdvDate = function () {
            if ($scope.polst.secD2 !== '0' || ($scope.polst.advDate !== undefined && $scope.polst.advDate.length > 0 && !$scope.form.pform.advDate.$error.pattern && $scope.polst.agentName !== undefined && $scope.polst.agentName.length > 0 && $scope.polst.agentPhone !== undefined && $scope.polst.agentPhone.length > 0)) {
                return true;
            }
            return false;
        };
        $scope.formSubmitted = false;
        $scope.createForm = function (pform, ev) {
            var valid = pform.$valid;
            console.log(pform);
            $scope.formSubmitted = true;
            $scope.hospiceChanged();
            if (valid && !$scope.hospiceInvalid && $scope.picFile !== undefined && $scope.polst.secA >= 0 && $scope.polst.secB >= 0 && $scope.polst.secD1 >= 0 && $scope.polst.secD2 >= 0 && validContact() && validPhysSign() && validAdvDate()) {
                if ($scope.polst.secC === undefined) {
                    $scope.polst.secC = -1;
                }
                if ($scope.hospice.selectedItem !== undefined && $scope.hospice.selectedItem !== null) {
                    $scope.polst.hospiceID = $scope.hospice.selectedItem.id;
                }
                if ($scope.physician.selectedItem !== undefined && $scope.physician.selectedItem !== null) {
                    $scope.polst.physicianID = $scope.physician.selectedItem.userid;
                }
                $scope.polst.hospiceName = $scope.hospice.searchText;
                $scope.myPromise = userService.uploadPolst($scope.picFile, $scope.polst).then(function () {
                        $mdDialog.show($mdDialog.alert().title("Form created").textContent("This POLST form has been successfully saved.").ok("Got it!").targetEvent(ev))
                            .then(function (response) {
                                $location.path("/dashboard");
                            });
                    }
                );
            } else {
                $mdToast.show({
                    templateUrl: 'dashboard/FormErrToast.html', position: 'bottom', hideDelay: 30000,
                    controller: ToastFormErrCtrl, parent: $document[0].querySelector('#buttonID')

                }).then(function(){
                });
            }
        };
        $scope.uncheckB = function () {
            $scope.polst.secB2a = false;
            $scope.polst.secB1a = false;
        };
        $scope.form = {};
    };
    UploadFormCtrl.$inject = ['$scope', 'userService', 'user', '$location', '$routeParams', '$filter', '$localStorage', 'helper', '$mdToast', '$mdDialog', '$document'];
    return UploadFormCtrl;
});

