define('super-user/super-user.services',['angular', 'common'], function (angular) {
    'use strict';
    var mod = angular.module('super-user.services', ['com.cd.common']);
    mod.factory('superUserService', ['$http', '$q', 'playRoutes', '$localStorage', function ($http, $q, playRoutes, $localStorage) {
        return {
            OrgQueriesByDate: function (sid, allDates, startDate, endDate, orgId, isHosApi, byEncounterDepartment, byFacility) {
                return playRoutes.com.cd.controllers.SuperAdmin.OrgQueriesByDate().post({
                    sid: sid,
                    byEncounterDepartment: byEncounterDepartment,
                    byFacility: byFacility,
                    allDates: allDates,
                    startDate: startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate(),
                    endDate: endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate(),
                    orgId: orgId,
                    isApi: isHosApi
                }).then(function (response) {
                    return response.data;
                });
            },
            formSubmissionsByDate: function (sid, allDates, startDate, endDate) {
                return playRoutes.com.cd.controllers.SuperAdmin.getFormSubmissionsByDate().post({
                    sid: sid,
                    allDates: allDates,
                    startDate: startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate(),
                    endDate: endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate()
                }).then(function (response) {
                    return response.data;
                });
            },
            AllOrgsByDate: function (sid, allDates, startDate, endDate) {
                return playRoutes.com.cd.controllers.SuperAdmin.AllOrgsByDate().post({
                    sid: sid,
                    allDates: allDates,
                    startDate: startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate(),
                    endDate: endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate()
                }).then(function (response) {
                    return response.data;
                });
            },
            getUserOrgs: function (userid, sid) {
                return playRoutes.com.cd.controllers.SuperAdmin.getUserOrgs().post({
                    sid: sid, userid: userid
                }).then(function(response){
                    return response.data;
                });
            },
            addUserOrg: function (userid, sid, orgid) {
                return playRoutes.com.cd.controllers.SuperAdmin.addUserOrg().post({
                    sid: sid, userid: userid, orgid: orgid
                }).then(function(response){
                    return response.data;
                });
            },
            removeUserOrg: function (userid, sid, orgid) {
                return playRoutes.com.cd.controllers.SuperAdmin.removeUserOrg().post({
                    sid: sid, userid: userid, orgid: orgid
                }).then(function(response){
                    return response.data;
                });
            },
            getOrgAdmins: function (orgid, sid) {
                return playRoutes.com.cd.controllers.SuperAdmin.getOrgAdmins().post({
                    sessionID: sid,
                    orgid: orgid
                }).then(function (response) {
                    return response.data;
                });
            },
            unlockUser: function (userid, UUID) {
                return playRoutes.com.cd.controllers.SuperAdmin.unlockUser().post({
                    userid: userid,
                    sid: UUID
                });
            },
            getOrg: function (sid, orgid) {
                return playRoutes.com.cd.controllers.SuperAdmin.getOrg().post({
                    sid: sid,
                    orgid: orgid
                }).then(function (r) {
                    return r;
                });
            },
            getOrgs: function (sid) {
                return playRoutes.com.cd.controllers.SuperAdmin.getOrgs().post({sid: sid}).then(function (r) {
                    return r;
                });
            },
            getAllUsers: function (sid) {
                return playRoutes.com.cd.controllers.SuperAdmin.getAllUsers().post({
                    sid: sid
                }).then(function (r) {
                    return r;
                });
            },
            getOrgUsers: function (sid, orgid) {
                return playRoutes.com.cd.controllers.SuperAdmin.getOrgUsers().post({
                    sid: sid,
                    orgid: orgid
                }).then(function (r) {
                    return r;
                });
            },
            addOrg: function (sid, org, credentials) {
                return playRoutes.com.cd.controllers.SuperAdmin.addOrg().post({
                    sid: sid,
                    org: org,
                    credentials: credentials
                }).then(function (r) {
                    return r;
                });
            },
            checkOrg: function (sid, name, scope) {
                return playRoutes.com.cd.controllers.SuperAdmin.checkOrg().post({
                    sid: sid,
                    name: name
                }).then(function (response) {
                    return response;
                }, function (response) {
                    if (response.status === 400) {
                        scope.orgExists = true;
                    }
                    return response;
                });
            }
        };
    }]);
    return mod;
});

