define('data-entry/data-entry.services',['angular', 'common'], function (angular) {
    'use strict';

    var mod = angular.module('data-entry.services', ['com.cd.common']);
    mod.factory('dataEntryService', ['$http', '$q', 'playRoutes', '$localStorage', function ($http, $q, playRoutes, $localStorage) {
        return {
            getPatient: function (sid, pid) {
                return playRoutes.com.cd.controllers.DataEntry.getPatient().post({
                    sid: sid, pid: pid
                });
            },
            getAdvanceDirective: function(sid, id){
                return playRoutes.com.cd.controllers.DataEntry.getAdvanceDirective().post({
                    sid: sid, id: id
                });
            },
            getAdvanceDirectives: function(sid, statusFilter){
                return playRoutes.com.cd.controllers.DataEntry.getAdvanceDirectives().post({
                    sid: sid, statusFilter: statusFilter
                });
            },
            updatePatientMatch: function (patient, sid, assigned_patient_id, id) {
                return playRoutes.com.cd.controllers.DataEntry.updatePatienMatch().post({
                    patient: patient, sid: sid, assigned_patient_id: assigned_patient_id, id: id
                });
            },
            checkReviewed: function (sid, pid) {
                return playRoutes.com.cd.controllers.DataEntry.checkReviewed().post({
                    sid: sid,
                    pid: pid
                });
            },
            hasMadieAccount: function (id, sid) {
                return playRoutes.com.cd.controllers.DataEntry.hasMadieAccount().post({
                    sid: sid,
                    id: id
                });
            },
            markAsReviewed: function (id, sid) {
                return playRoutes.com.cd.controllers.DataEntry.markAsReviewed().post({
                    sid: sid,
                    id: id
                }).then(function (response) {
                    return response;
                });
            },
            getPatientMatches: function (sessionId) {
                return playRoutes.com.cd.controllers.DataEntry.getPatientMatches().post({sid: sessionId}).then(function (response) {
                    return response;
                });
            },
            getABBYYPDF: function (sessionId, afid) {
                var params = {sid: sessionId, afid: afid};
                return $http.post(playRoutes.com.cd.controllers.DataEntry.getABBYYPDF().url, {
                    sid: sessionId,
                    afid: afid,
                    pref_org_id: $localStorage.pref_org_id
                }, {responseType: 'arraybuffer'}).then(function (response) {
                    return response;
                });
            },
            getPatientADHistory: function (sid, patientID) {
                return playRoutes.com.cd.controllers.Forms.getPatientADHistory().post({
                    sid: sid,
                    patientID: patientID
                }).then(function (response) {
                    return response;
                });
            },
            getPatientFormHistory: function (sid, patientID) {
                return playRoutes.com.cd.controllers.Forms.getPatientFormHistory().post({
                    sid: sid,
                    patientID: patientID
                }).then(function (response) {
                    return response;
                });
            },
            markUploadError: function (sid, afid, errorForms) {
                return playRoutes.com.cd.controllers.DataEntry.markUploadError().post({
                    sid: sid,
                    afid: afid,
                    errorForms: errorForms,
                }).then(function (response) {
                    return response;
                });
            },
            getABBYYForm: function (sessionId, afid) {
                var params = {sid: sessionId, afid: afid};
                return playRoutes.com.cd.controllers.DataEntry.getABBYYForm().post(params).then(function (response) {
                    return response;
                });
            },
            getABBYYForms: function (sessionId, statusFilter, orgFilter, formTypeFilter, quarterFilter, formIdFilter) {
                var params = {sid: sessionId, statusFilter: statusFilter, orgFilter: Number(orgFilter), formTypeFilter: formTypeFilter,
                quarterFilter: quarterFilter, formIdFilter: Number(formIdFilter)};
                return playRoutes.com.cd.controllers.DataEntry.getABBYYForms().post(params).then(function (response) {
                    return response;
                });
            },
            searchForCurrentAD: function (id) {
                return playRoutes.com.cd.controllers.AdvanceDirectives.searchForCurrentAD().post({
                    pid: id,
                    sid: $localStorage.sid
                }).then(function (response) {
                    return response;
                });

            },
            verifyAdvanceDirective: function (json, publish, sid, afid, mrn) {
                return playRoutes.com.cd.controllers.DataEntry.verifyAdvanceDirective().post({
                sid: sid, ad: json, publish: publish, afid: afid, mrn: mrn
                });
            },
            uploadAdvancedDirective: function (picFile, json, publish, orgid) {
                return $http.post(window.apiUrl + "/api/uploadAdvancedDirective", {
                    model: {
                        sid: $localStorage.sid,
                        pref_org_id: $localStorage.pref_org_id,
                        form: json,
                        publish: publish,
                        orgid: orgid
                    },
                    file: picFile
                }, {
                    transformRequest: function (data) {
                        var formData = new FormData();
                        formData.append("model", angular.toJson(data.model));
                        formData.append("photo", picFile);
                        // formData.append("status", current);
                        return formData;
                    },
                    headers: {'Content-Type': undefined}
                }).then(function (response) {
                    return response;
                });
            },
            uploadABBYYCsv: function (csvFile, sid, afNotes, id) {
                return $http.post(window.apiUrl + (window.isLinode ? '' : '') + '/api/uploadABBYYCsv', {
                    model: {sid: sid, afNotes: afNotes, id: id},
                    csvFile: csvFile
                }, {
                    transformRequest: function (data) {
                        var formData = new FormData();
                        formData.append("model", angular.toJson(data.model));
                        formData.append("csvFile", csvFile);
                        return formData;
                    },
                    headers: {'Content-Type': undefined}
                }).then(function (response) {
                    return response;
                });
            },
            uploadABBYYForm: function (pdfFile, csvFile, orgid, sid, afNotes, dateSubmitted, mrn, formType) {
                return $http.post(window.apiUrl + (window.isLinode ? '' : '') + '/api/uploadABBYYForm', {
                    model: {orgid: orgid, sid: sid, afNotes: afNotes, date_submitted: dateSubmitted, mrn: mrn, formType: formType},
                    csvFile: csvFile,
                    pdfFile: pdfFile

                }, {
                    transformRequest: function (data) {
                        var formData = new FormData();
                        formData.append("model", angular.toJson(data.model));
                        formData.append("pdfFile", pdfFile);
                        formData.append("csvFile", csvFile);
                        return formData;
                    },
                    headers: {'Content-Type': undefined}
                }).then(function (response) {
                    return response;
                });
            },
            claimAdBack: function (formstatus, assignedUserid, sessionID, formId) {
                return playRoutes.com.cd.controllers.DataEntry.claimAdBack().post({
                    formstatus: formstatus,
                    sessionID: sessionID,
                    assignedUserid: assignedUserid,
                    formId: formId
                }).then(function (response) {
                    return response;
                });
            },
            claimBack: function (formstatus, assignedUserid, sessionID, formId) {
                return playRoutes.com.cd.controllers.DataEntry.claimBack().post({
                    formstatus: formstatus,
                    sessionID: sessionID,
                    assignedUserid: assignedUserid,
                    formId: formId
                }).then(function (response) {
                    return response;
                });
            },
            rejectABBYYForm: function (sid, afid, reasoning, mdSigMissing, ptSigMissing, demoMissing, pid, nullify, polst, af, sigMissing, dobMissing, dateMissing) {
                return playRoutes.com.cd.controllers.DataEntry.rejectABBYYForm().post({
                    sid: sid,
                    nullify: nullify,
                    afid: afid,
                    pid: pid,
                    polst: polst,
                    afform: af,
                    reasoning: reasoning,
                    mdSigMissing: mdSigMissing,
                    ptSigMissing: ptSigMissing,
                    demoMissing: demoMissing,
                    sigMissing: sigMissing,
                    dobMissing: dobMissing,
                    dateMissing: dateMissing
                }).then(function (response) {
                    return response;
                });
            },
            publishABBYYForm: function (polst, publish, af, sid, mrn) {
                return playRoutes.com.cd.controllers.DataEntry.publishABBYYForm().post({
                    form: polst,
                    af: af,
                    publish: publish,
                    sid: sid,
                    mrn: mrn
                }).then(function (response) {
                    return response;
                });
            },

            retrieveOrg: function (sessionID, l) {
                return playRoutes.com.cd.controllers.DataEntry.retrieveOrg().post({
                    sessionID: sessionID,
                    text: l
                }).then(function (response) {
                    return response.data;
                });
            }


        };

    }]);
    return mod;
});

