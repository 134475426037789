define('super-user/super-all-analytics.controller',['angular'], function (angular) {
    'use strict';
    var AnalyticsAllOrgCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document, userService, dataEntryService, $window, $mdDialog, $sce, helper, superUserService, user, Papa) {
        $scope.user = user.data.User;
        $scope.isHosAPI = $scope.user.org.type === 'Hospital API';
        var tmp = moment().subtract(30, 'days');
        $scope.startDate = tmp.toDate();
        $scope.endDate = moment().toDate();
        $scope.allDates = false;
        $scope.reportReady = false;
        $scope.clearReport = function () {
            $scope.reportReady = false;
            $scope.exportReady = false;
            $scope.runningReport = false;
        };
        $scope.exportReady = false;
        $scope.runningReport = false;
        $scope.runTheReport = function () {
            $scope.runningReport = true;

            superUserService.AllOrgsByDate($scope.user.sessionID, $scope.allDates, $scope.startDate, $scope.endDate).then(function (r) {
                // console.log("r is ")
                // console.log(r);

                var total = 0;
                var allTotal = 0;
                var exactTotal = 0;
                $scope.queries = [];
                for (var i = 0; i < r.length; i++) {
                    total += r[i].count;
                    $scope.queries.push(r[i]);
                    allTotal += r[i].AllMatchCount;
                    exactTotal += r[i].ExactMatchCount;

                }
                $scope.queries.push({
                    total: true,
                    AllMatchCount: allTotal,
                    ExactMatchCount: exactTotal,
                    count: total,
                    name: 'Total Queries'
                });

                $scope.onReorder('count');
                $scope.reportReady = true;
                $scope.runningReport = false;
            });

        };

        $scope.goBack = function () {
            $location.path('/super/orgs').search({});
        };

        $scope.reportTable = {
            order: 'count'
        };
        $scope.onReorder = function (orderBy) {

            if (orderBy === 'count') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        return a.count > b.count ? -1 : a.count < b.count ? 1 : 0;
                    }
                });
            } else if (orderBy === '-count') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        return a.count > b.count ? 1 : a.count < b.count ? -1 : 0;
                    }
                });
            } else if (orderBy === 'exactMatch') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        return a.ExactMatchCount > b.ExactMatchCount ? -1 : a.ExactMatchCount < b.ExactMatchCount ? 1 : 0;
                    }
                });

            } else if (orderBy === '-exactMatch') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        return a.ExactMatchCount > b.ExactMatchCount ? 1 : a.ExactMatchCount < b.ExactMatchCount ? -1 : 0;
                    }
                });

            } else if (orderBy === 'allMatches') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        return a.AllMatchCount > b.AllMatchCount ? -1 : a.AllMatchCount < b.AllMatchCount ? 1 : 0;
                    }
                });

            } else if (orderBy === '-allMatches') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        return a.AllMatchCount > b.AllMatchCount ? 1 : a.AllMatchCount < b.AllMatchCount ? -1 : 0;
                    }
                });
            } else if (orderBy === 'name') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        if (a.name === undefined && b.name === undefined) {
                            return 0;
                        } else if (a.name === undefined) {
                            return 1;
                        } else if (b.name === undefined) {
                            return -1;
                        }
                        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 0;
                    }
                });
            } else if (orderBy === '-name') {
                $scope.queries.sort(function (a, b) {
                    if (a.total) {
                        return -1;
                    } else if (b.total) {
                        return 1;
                    } else {
                        if (a.name === undefined && b.name === undefined) {
                            return 0;
                        } else if (a.name === undefined) {
                            return -1;
                        } else if (b.name === undefined) {
                            return 1;
                        }
                        return a.name.toUpperCase() > b.name.toUpperCase() ? -1 : a.name.toUpperCase() < b.name.toUpperCase() ? 1 : 0;
                    }
                });
            }
        };
        $scope.exportReady = false;
        $scope.downloadAnalytics = function () {
            var dataToDownload = [];
            for (var i = 0; i < $scope.queries.length; i++) {
                dataToDownload.push({
                    name: $scope.queries[i].name,
                    type: $scope.queries[i].orgtype,
                    queries: $scope.queries[i].count,
                    exact_match: $scope.queries[i].ExactMatchCount,
                    all_match: $scope.queries[i].AllMatchCount
                });
            }
            Papa.unparse(dataToDownload).then(function (r) {
                var blob = new Blob(["\ufeff", r]);
                var url = URL.createObjectURL(blob);
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = blob;
                    $scope.fileURL = 'analytics.csv';
                    window.navigator.msSaveOrOpenBlob(blob, 'analytics.csv');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(blob);
                }
                $scope.exportReady = true;
            });
        };
    };
    AnalyticsAllOrgCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'superUserService', 'user', 'Papa'];
    return AnalyticsAllOrgCtrl;
});

