/**
 * Dashboard, shown after user is logged in. dashboard/main.js is the entry
 * module which serves as an entry point so other modules only have to include a
 * single module.
 */
define('dashboard/dashboard.module',['angular', './dashboard.routes', './dashboard.controllers'], function (angular, routes,
                                                                               controllers) {
    'use strict';
    var mod = angular.module('com.cd.dashboard', ['ngRoute',
        'dashboard.routes']);
    mod.controller('ChangeAssignedPhysicianCtrl', controllers.ChangeAssignedPhysicainCtrl);
    mod.controller("PolstCreateDialogCtrl", controllers.PolstCreateDialogCtrl);
    mod.controller("VerifyPhysicianCtrl", controllers.VerifyPhysicianCtrl);
    mod.controller("ToastFormErrCtrl", controllers.ToastFormErrCtrl);
    mod.controller("EditPatientCtrl", controllers.EditPatientCtrl);
    mod.controller('FinishNewPolstCtrl', controllers.FinishNewPolstCtrl);
    mod.controller("SelectPatientCtrl", controllers.SelectPatientCtrl);
    mod.controller("ConfirmSelectionCtrl", controllers.ConfirmSelectionCtrl);
    mod.controller('ToastCtrl', controllers.ToastCtrl);
    mod.controller('GenericToastCtrl', controllers.GenericToastCtrl);
    mod.controller('UserDetailsCtrl', controllers.UserDetailsCtrl);
    mod.controller('FaxFormCtrl', controllers.FaxFormCtrl);
    return mod;
});

