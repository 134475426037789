define('dashboard/select-patient.controller',['angular'], function (angular) {
    'use strict';
    var SelectPatientCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document, userService, $window, $mdDialog, $sce, helper, pts, polst, newPtDisabled) {
        $scope.newPtDisabled = newPtDisabled;
        $scope.pts = pts;
        for(var zz = 0; zz < pts.length; zz++){
            if($scope.pts[zz].phone !== undefined){
                $scope.pts[zz].phone = $filter('tel')($scope.pts[zz].phone.replace(/[^0-9]/g, ''), false);
            }
        }
        $scope.polst = polst;
        if($scope.polst.phone !== undefined){
            $scope.polst.phone = $filter('tel')($scope.polst.phone.replace(/[^0-9]/g, ''), false);
        }
        $scope.phone = $scope.polst.phone;

        $scope.cancel = function () {
            $mdDialog.hide();
        };
        $scope.swapValue = function(d, p){
              for(var i = 0; i < $scope.diffs.length; i++){
                    if($scope.diffs[i].v === d){
                        var tmp = $scope.diffs[i].o;
                        $scope.diffs[i].o = $scope.diffs[i].n;
                        $scope.diffs[i].n = tmp;
                        $scope.selectedPt[d] = $scope.diffs[i].n;
                    }
              }
        };
        $scope.ptSelected = function (p) {
            $scope.patientMatched = true;
            if (p !== undefined) {
                // existing patient, confirm data differences with user
                var diffs = [];
                if (p.fname !== $scope.compare.fname) {
                    diffs.push({lbl: "First Name", v: 'fname', o: p.fname, n: polst.fname});
                }
                if (p.lname !== $scope.compare.lname) {
                    diffs.push({lbl: "Last Name", v: 'lname', o: p.lname, n: polst.lname});
                }
                if (p.mname !== $scope.compare.mname) {
                    diffs.push({lbl: "Middle Name", v: 'mname', o: p.name, n: polst.mname});
                }
                if (p.street !== $scope.compare.street) {
                    diffs.push({lbl: "Street", v: 'street', o: p.street, n: polst.street});
                }
                if (p.city !== $scope.compare.city) {
                    diffs.push({lbl: "City", v: 'city', o: p.city, n: polst.city});
                }
                if (p.state !== $scope.compare.state) {
                    diffs.push({lbl: "State", v: 'state', o: p.state, n: polst.state});
                }
                if (p.zip !== $scope.compare.zip) {
                    diffs.push({lbl: "Zip", v: 'zip', o: p.zip, n: polst.zip});
                }
                if (p.dob !== $scope.compare.dob) {
                    diffs.push({lbl: "DOB", v: 'dob', o: p.dob, n: polst.dob});
                }
                if (p.ins !== $scope.compare.ins) {
                    diffs.push({lbl: "Insurance", v: 'ins', o: p.ins, n: polst.ins});
                }
                if (p.gender !== $scope.compare.gender) {
                    diffs.push({lbl: "Gender", v: 'gender', o: p.gender, n: polst.gender});
                }
                if (p.ssn !== $scope.compare.ssn) {
                    diffs.push({lbl: "SSN", v: 'ssn', o: p.ssn, n: polst.ssn});
                }
                if (p.phone !== $scope.compare.phone) {
                    diffs.push({lbl: "Phone", v: 'phone', o: p.phone, n: polst.phone});
                }
                if (diffs.length > 0) {
                    $scope.selected = true;
                    $scope.diffs = diffs;
                    $scope.selectedPt = p;
                    for (var i = 0; i < $scope.diffs.length; i++) {
                        var tmp = $scope.diffs[i].o;
                        $scope.diffs[i].o = $scope.diffs[i].n;
                        $scope.diffs[i].n = tmp;
                        $scope.selectedPt[$scope.diffs[i].v] = $scope.diffs[i].o;
                    }
                } else {
                    $mdDialog.hide({patient: p, confirmed: true});
                }
            } else {
                $mdDialog.hide({confirmed: true});
            }
        };
        $scope.confirm = function () {
            for (var i = 0; i < $scope.diffs.length; i++) {
                $scope.selectedPt[$scope.diffs[i].v] = $scope.diffs[i].o;
            }
            $mdDialog.hide({confirmed: true, patient: $scope.selectedPt});
        };
        $scope.unconfirm = function () {
            $mdDialog.hide();
        };


        $scope.viewExisting = function (id) {
            userService.downloadForm(id).then(function (response) {
                var file = new Blob([response.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    $scope.showDownload = true;
                } else {
                    console.log("not in .net version");
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });

        };
        $scope.viewADPDF = function (id) {
            if ($scope.fileUrl !== undefined) {
                window.open($scope.fileAD_URL, "_blank");
            } else {
                userService.downloadADForm(id).then(function (response) {
                    var file = new Blob([response.data], {type: "application/pdf"});
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        $scope.msFileAD = file;
                        $scope.fileAD_URL = 'tmp.pdf';
                        // window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                        $scope.showDownload = true;
                        window.navigator.msSaveOrOpenBlob($scope.msFileAD, 'AdvancedDirective.pdf');
                    } else {
                        $scope.showDownload = true;
                        $scope.fileAD_URL = URL.createObjectURL(file);
                        window.open($scope.fileAD_URL, "_blank");
                    }
                });
            }
        };
        $scope.compare = {};
        if (polst.fname !== undefined) {
            $scope.compare.fname = polst.fname.toUpperCase();
        }
        if (polst.lname !== undefined) {
            $scope.compare.lname = polst.lname.toUpperCase();
        }
        if (polst.mname !== undefined) {
            $scope.compare.mname = polst.mname.toUpperCase();
        }
        if (polst.street !== undefined) {
            $scope.compare.street = polst.street.toUpperCase();
        }
        if (polst.city !== undefined) {
            $scope.compare.city = polst.city.toUpperCase();
        }
        if (polst.state !== undefined) {
            $scope.compare.state = polst.state.toUpperCase();
        }
        if (polst.zip !== undefined) {
            $scope.compare.zip = polst.zip.toUpperCase();
        }
        if (polst.ssn !== undefined) {
            $scope.compare.ssn = polst.ssn.toUpperCase();
        }
        if (polst.dob !== undefined) {
            $scope.compare.dob = polst.dob.toUpperCase();
        }
        if (polst.phone !== undefined) {
            $scope.compare.phone = polst.phone.toUpperCase();
        }
        if (polst.ins !== undefined) {
            $scope.compare.ins = polst.ins.toUpperCase();
        }
        if (polst.gender !== undefined) {
            $scope.compare.gender = polst.gender.toUpperCase();
        }
    };
    SelectPatientCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', '$window', '$mdDialog', '$sce', 'helper', 'pts', 'polst', 'newPtDisabled'];
    return SelectPatientCtrl;
});
