/** Common helpers */
define('common/services/helper',['angular'], function (angular) {
    'use strict';
    var mod = angular.module('common.helper', []);
    mod.service('helper', ['$window', function ($window) {
        return {
            getTypes: function () {
                return [
                    {
                        id: 1,
                        name: "Nurse"
                    }, {
                        id: 2,
                        name: "Case Manager"
                    }, {
                        id: 3,
                        name: "Social Worker"
                    }, {
                        id: 4,
                        name: "Physician, PA, NP"
                    }, {
                        id: 5,
                        name: "Admin"
                    }, {
                        id: 6,
                        name: "Medical Assistants"
                    }, {
                        id: 7,
                        name: "Paramedics"
                    }, {
                        id: 9,
                        name: 'Analytics'
                    }];
            },
            orderUsers: function (order, users) {
                if (order === '-firstname') {
                    users.sort(function (a, b) {
                        if (a.firstname === undefined && b.firstname === undefined) {
                            return 0;
                        } else if (a.firstname === undefined) {
                            return 1;
                        } else if (b.firstname === undefined) {
                            return -1;
                        }
                        return a.firstname.toLowerCase() > b.firstname.toLowerCase() ? -1 : b.firstname.toLowerCase() > a.firstname.toLowerCase() ? 1 : 0;
                    });
                } else if (order === '-lastLogin') {
                    users.sort(function (a, b) {
                        if (a.lastLogin === undefined && b.lastLogin === undefined) {
                            return 0;
                        } else if (a.lastLogin === undefined) {
                            return -1;
                        } else if (b.lastLogin === undefined) {
                            return 1;
                        }
                        var a1 = moment.utc(a.lastLogin, 'YYYY-MM-DD HH:mm:ss');
                        var b1 = moment.utc(b.lastLogin, 'YYYY-MM-DD HH:mm:ss');
                        if (a1.isBefore(b1)) {
                            return -1;
                        } else if (a1.isAfter(b1)) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                } else if (order === 'lastLogin') {
                    users.sort(function (a, b) {
                        if (a.lastLogin === undefined && b.lastLogin === undefined) {
                            return 0;
                        } else if (a.lastLogin === undefined) {
                            return 1;
                        } else if (b.lastLogin === undefined) {
                            return -1;
                        }
                        var a2 = moment.utc(a.lastLogin, 'YYYY-MM-DD HH:mm:ss');
                        var b2 = moment.utc(b.lastLogin, 'YYYY-MM-DD HH:mm:ss');
                        if (a2.isBefore(b2)) {
                            return 1;
                        } else if (a2.isAfter(b2)) {
                            return -1;
                        } else {
                            return 0;
                        }

                    });
                } else if (order === '-lastname') {
                    users.sort(function (a, b) {
                        if (a.lastname === undefined && b.lastname === undefined) {
                            return 0;
                        } else if (a.lastname === undefined) {
                            return 1;
                        } else if (b.lastname === undefined) {
                            return -1;
                        }
                        return a.lastname.toLowerCase() > b.lastname.toLowerCase() ? -1 : (b.lastname.toLowerCase() > a.lastname.toLowerCase() ? 1 : 0);
                    });
                } else if (order === 'created') {
                    users.sort(function (a, b) {
                        return a.created > b.created ? -1 : b.created > a.created ? 1 : 0;
                    });
                } else if (order === '-email') {
                    users.sort(function (a, b) {
                        if (a.email === undefined && b.email === undefined) {
                            return 0;
                        } else if (a.email === undefined) {
                            return -1;
                        } else if (b.email === undefined) {
                            return 1;
                        }
                        return a.email.toLowerCase() > b.email.toLowerCase() ? -1 : b.email.toLowerCase() > a.email.toLowerCase() ? 1 : 0;
                    });
                } else if (order === 'userType') {
                    users.sort(function (a, b) {
                        return a.userType.toLowerCase() > b.userType.toLowerCase() ? -1 : b.userType.toLowerCase() > a.userType.toLowerCase() ? 1 : 0;
                    });
                } else if (order === 'firstname') {
                    users.sort(function (a, b) {
                        if (a.firstname === undefined && b.firstname === undefined) {
                            return 0;
                        } else if (a.firstname === undefined) {
                            return -1;
                        } else if (b.firstname === undefined) {
                            return 1;
                        }
                        return -1 * (a.firstname.toLowerCase() > b.firstname.toLowerCase() ? -1 : b.firstname.toLowerCase() > a.firstname.toLowerCase() ? 1 : 0);
                    });

                } else if (order === 'lastname') {
                    users.sort(function (a, b) {
                        if (a.lastname === undefined && b.lastname === undefined) {
                            return 0;
                        } else if (a.lastname === undefined) {
                            return -1;
                        } else if (b.lastname === undefined) {
                            return 1;
                        }
                        return -1 * (a.lastname.toLowerCase() > b.lastname.toLowerCase() ? -1 : b.lastname.toLowerCase() > a.lastname.toLowerCase() ? 1 : 0);
                    });

                } else if (order === '-created') {
                    users.sort(function (a, b) {
                        return -1 * (a.created > b.created ? -1 : b.created > a.created ? 1 : 0);
                    });

                } else if (order === 'email') {
                    users.sort(function (a, b) {
                        if (a.email === undefined && b.email === undefined) {
                            return 0;
                        } else if (a.email === undefined) {
                            return 1;
                        } else if (b.email === undefined) {
                            return -1;
                        }
                        return -1 * (a.email.toLowerCase() > b.email.toLowerCase() ? -1 : b.email.toLowerCase() > a.email.toLowerCase() ? 1 : 0);
                    });
                } else if (order === '-userType') {
                    users.sort(function (a, b) {
                        return -1 * (a.userType.toLowerCase() > b.userType.toLowerCase() ? -1 : b.userType.toLowerCase() > a.userType.toLowerCase() ? 1 : 0);
                    });
                }
                return users;
            },
            orderForms: function (order, forms) {
                console.log('order is: ', order);
                if (order === 'mrn') {
                    forms.sort(function (a, b) {
                        if (a.mrn === undefined && b.mrn === undefined) {
                            return 0;
                        } else if (a.mrn === undefined) {
                            return 1;
                        } else if (b.mrn === undefined) {
                            return -1;
                        }
                        return -1 * (a.mrn.toLowerCase() > b.mrn.toLowerCase() ? -1 : b.mrn.toLowerCase() > a.mrn.toLowerCase() ? 1 : 0);
                    });
                } else if (order === '-mrn') {
                    forms.sort(function (a, b) {
                        if (a.mrn === undefined && b.mrn === undefined) {
                            return 0;
                        } else if (a.mrn === undefined) {
                            return 1;
                        } else if (b.mrn === undefined) {
                            return -1;
                        }
                        return a.mrn.toLowerCase() > b.mrn.toLowerCase() ? -1 : b.mrn.toLowerCase() > a.mrn.toLowerCase() ? 1 : 0;
                    });
                } else if (order === 'assignedMD') {
                    forms.sort(function (a, b) {
                        if (a.phsyician === undefined && b.phsyician === undefined) {
                            return 0;
                        } else if (a.phsyician === undefined) {
                            return 1;
                        } else if (b.phsyician === undefined) {
                            return -1;
                        } else {
                            return a.phsyician.firstname.toLowerCase() > b.phsyician.firstname.toLowerCase() ? -1 : b.phsyician.firstname.toLowerCase() > a.phsyician.firstname.toLowerCase() ? 1 : 0;
                        }
                    });
                } else if (order === '-assignedMD') {
                    forms.sort(function (a, b) {
                        if (a.phsyician === undefined && b.phsyician === undefined) {
                            return 0;
                        } else if (a.phsyician === undefined) {
                            return -1;
                        } else if (b.phsyician === undefined) {
                            return 1;
                        } else {
                            return a.phsyician.firstname.toLowerCase() > b.phsyician.firstname.toLowerCase() ? 1 : b.phsyician.firstname.toLowerCase() > a.phsyician.firstname.toLowerCase() ? -1 : 0;
                        }
                    });
                } else if (order === 'assignedMDEmail') {
                    forms.sort(function (a, b) {
                        if (a.signed === undefined && b.signed === undefined) {
                            return 0;
                        } else if (a.phsyician === undefined) {
                            return 1;
                        } else if (b.phsyician === undefined) {
                            return -1;
                        } else {
                            return a.phsyician.email.toLowerCase() > b.phsyician.email.toLowerCase() ? -1 : b.phsyician.email.toLowerCase() > a.phsyician.email.toLowerCase() ? 1 : 0;
                        }
                    });
                } else if (order === '-assignedMDEmail') {
                    forms.sort(function (a, b) {
                        if (a.signed === undefined && b.signed === undefined) {
                            return 0;
                        } else if (a.phsyician === undefined) {
                            return -1;
                        } else if (b.phsyician === undefined) {
                            return 1;
                        } else {
                            return a.phsyician.email.toLowerCase() > b.phsyician.email.toLowerCase() ? 1 : b.phsyician.email.toLowerCase() > a.phsyician.email.toLowerCase() ? -1 : 0;
                        }
                    });
                } else if (order === 'hasSignature') {
                    forms.sort(function (a, b) {
                        if (a.signed === undefined && b.signed === undefined) {
                            return 0;
                        } else if (a.signed === undefined) {
                            return 1;
                        } else if (b.signed === undefined) {
                            return -1;
                        } else {
                            if (a.signed && !b.signed) {
                                return 1;
                            } else if (!a.signed && b.signed) {
                                return -1;
                            } else {
                                return 0;
                            }
                        }
                    });
                } else if (order === '-hasSignature') {
                    forms.sort(function (a, b) {
                        if (a.signed === undefined && b.signed === undefined) {
                            return 0;
                        } else if (a.signed === undefined) {
                            return -1;
                        } else if (b.signed === undefined) {
                            return 1;
                        } else {
                            if (a.signed && !b.signed) {
                                return -1;
                            } else if (!a.signed && b.signed) {
                                return 1;
                            } else {
                                return 0;
                            }
                        }
                    });
                } else if (order === '-fname') {
                    forms.sort(function (a, b) {
                        if (a.fname === undefined && b.fname === undefined) {
                            return 0;
                        } else if (a.fname === undefined) {
                            return 1;
                        } else if (b.fname === undefined) {
                            return -1;
                        }
                        return a.fname.toLowerCase() > b.fname.toLowerCase() ? -1 : b.fname.toLowerCase() > a.fname.toLowerCase() ? 1 : 0;
                    });
                } else if (order === '-firstname') {
                    forms.sort(function (a, b) {
                        if (a.firstname === undefined && b.firstname === undefined) {
                            return 0;
                        } else if (a.firstname === undefined) {
                            return 1;
                        } else if (b.firstname === undefined) {
                            return -1;
                        }
                        return a.firstname.toLowerCase() > b.firstname.toLowerCase() ? -1 : b.firstname.toLowerCase() > a.firstname.toLowerCase() ? 1 : 0;
                    });
                } else if (order === '-afid') {
                    forms.sort(function (a, b) {
                        if (a.afid === undefined && b.afid === undefined) {
                            return 0;
                        } else if (a.afid === undefined) {
                            return 1;
                        } else if (b.afid === undefined) {
                            return -1;
                        }
                        return -1 * (a.afid > b.afid ? -1 : b.afid > a.afid ? 1 : 0);
                    });
                } else if (order === 'afid') {
                    forms.sort(function (a, b) {
                        if (a.afid === undefined && b.afid === undefined) {
                            return 0;
                        } else if (a.afid === undefined) {
                            return 1;
                        } else if (b.afid === undefined) {
                            return -1;
                        }
                        return a.afid > b.afid ? -1 : b.afid > a.afid ? 1 : 0;
                    });
                } else if (order === '-id') {
                    forms.sort(function (a, b) {
                        if (a.id === undefined && b.id === undefined) {
                            return 0;
                        } else if (a.id === undefined) {
                            return 1;
                        } else if (b.id === undefined) {
                            return -1;
                        }
                        return -1 * (a.id > b.id ? -1 : b.id > a.id ? 1 : 0);
                    });
                } else if (order === 'id') {
                    forms.sort(function (a, b) {
                        if (a.id === undefined && b.id === undefined) {
                            return 0;
                        } else if (a.id === undefined) {
                            return 1;
                        } else if (b.id === undefined) {
                            return -1;
                        }
                        return a.id > b.id ? -1 : b.id > a.id ? 1 : 0;
                    });
                } else if (order === 'lastname') {
                    forms.sort(function (a, b) {
                        if (a.lname === undefined && b.lname === undefined) {
                            return 0;
                        } else if (a.lname === undefined) {
                            return 1;
                        } else if (b.lname === undefined) {
                            return -1;
                        }
                        return a.lname.toLowerCase() > b.lname.toLowerCase() ? -1 : (b.lname.toLowerCase() > a.lname.toLowerCase() ? 1 : 0);
                    });
                } else if (order === 'created') {
                    forms.sort(function (a, b) {
                        return a.created > b.created ? -1 : b.created > a.created ? 1 : 0;
                    });
                } else if (order === 'fname') {
                    forms.sort(function (a, b) {
                        if (a.fname === undefined && b.fname === undefined) {
                            return 0;
                        } else if (a.fname === undefined) {
                            return -1;
                        } else if (b.fname === undefined) {
                            return 1;
                        }
                        return -1 * (a.fname.toLowerCase() > b.fname.toLowerCase() ? -1 : b.fname.toLowerCase() > a.fname.toLowerCase() ? 1 : 0);
                    });
                } else if (order === 'firstname') {
                    forms.sort(function (a, b) {
                        if (a.firstname === undefined && b.firstname === undefined) {
                            return 0;
                        } else if (a.firstname === undefined) {
                            return -1;
                        } else if (b.firstname === undefined) {
                            return 1;
                        }
                        return -1 * (a.firstname.toLowerCase() > b.firstname.toLowerCase() ? -1 : b.firstname.toLowerCase() > a.firstname.toLowerCase() ? 1 : 0);
                    });

                } else if (order === '-lastname') {
                    forms.sort(function (a, b) {
                        if (a.lname === undefined && b.lname === undefined) {
                            return 0;
                        } else if (a.lname === undefined) {
                            return -1;
                        } else if (b.lname === undefined) {
                            return 1;
                        }
                        return -1 * (a.lname.toLowerCase() > b.lname.toLowerCase() ? -1 : b.lname.toLowerCase() > a.lname.toLowerCase() ? 1 : 0);
                    });

                } else if (order === '-created') {
                    forms.sort(function (a, b) {
                        return -1 * (a.created > b.created ? -1 : b.created > a.created ? 1 : 0);
                    });

                }
                return forms;
            },
            getStates: function () {
                return [
                    {
                        "name": "Alabama",
                        "abbreviation": "AL"
                    },
                    {
                        "name": "Alaska",
                        "abbreviation": "AK"
                    },
                    {
                        "name": "American Samoa",
                        "abbreviation": "AS"
                    },
                    {
                        "name": "Arizona",
                        "abbreviation": "AZ"
                    },
                    {
                        "name": "Arkansas",
                        "abbreviation": "AR"
                    },
                    {
                        "name": "California",
                        "abbreviation": "CA"
                    },
                    {
                        "name": "Colorado",
                        "abbreviation": "CO"
                    },
                    {
                        "name": "Connecticut",
                        "abbreviation": "CT"
                    },
                    {
                        "name": "Delaware",
                        "abbreviation": "DE"
                    },
                    {
                        "name": "District Of Columbia",
                        "abbreviation": "DC"
                    },
                    {
                        "name": "Federated States Of Micronesia",
                        "abbreviation": "FM"
                    },
                    {
                        "name": "Florida",
                        "abbreviation": "FL"
                    },
                    {
                        "name": "Georgia",
                        "abbreviation": "GA"
                    },
                    {
                        "name": "Guam",
                        "abbreviation": "GU"
                    },
                    {
                        "name": "Hawaii",
                        "abbreviation": "HI"
                    },
                    {
                        "name": "Idaho",
                        "abbreviation": "ID"
                    },
                    {
                        "name": "Illinois",
                        "abbreviation": "IL"
                    },
                    {
                        "name": "Indiana",
                        "abbreviation": "IN"
                    },
                    {
                        "name": "Iowa",
                        "abbreviation": "IA"
                    },
                    {
                        "name": "Kansas",
                        "abbreviation": "KS"
                    },
                    {
                        "name": "Kentucky",
                        "abbreviation": "KY"
                    },
                    {
                        "name": "Louisiana",
                        "abbreviation": "LA"
                    },
                    {
                        "name": "Maine",
                        "abbreviation": "ME"
                    },
                    {
                        "name": "Marshall Islands",
                        "abbreviation": "MH"
                    },
                    {
                        "name": "Maryland",
                        "abbreviation": "MD"
                    },
                    {
                        "name": "Massachusetts",
                        "abbreviation": "MA"
                    },
                    {
                        "name": "Michigan",
                        "abbreviation": "MI"
                    },
                    {
                        "name": "Minnesota",
                        "abbreviation": "MN"
                    },
                    {
                        "name": "Mississippi",
                        "abbreviation": "MS"
                    },
                    {
                        "name": "Missouri",
                        "abbreviation": "MO"
                    },
                    {
                        "name": "Montana",
                        "abbreviation": "MT"
                    },
                    {
                        "name": "Nebraska",
                        "abbreviation": "NE"
                    },
                    {
                        "name": "Nevada",
                        "abbreviation": "NV"
                    },
                    {
                        "name": "New Hampshire",
                        "abbreviation": "NH"
                    },
                    {
                        "name": "New Jersey",
                        "abbreviation": "NJ"
                    },
                    {
                        "name": "New Mexico",
                        "abbreviation": "NM"
                    },
                    {
                        "name": "New York",
                        "abbreviation": "NY"
                    },
                    {
                        "name": "North Carolina",
                        "abbreviation": "NC"
                    },
                    {
                        "name": "North Dakota",
                        "abbreviation": "ND"
                    },
                    {
                        "name": "Northern Mariana Islands",
                        "abbreviation": "MP"
                    },
                    {
                        "name": "Ohio",
                        "abbreviation": "OH"
                    },
                    {
                        "name": "Oklahoma",
                        "abbreviation": "OK"
                    },
                    {
                        "name": "Oregon",
                        "abbreviation": "OR"
                    },
                    {
                        "name": "Palau",
                        "abbreviation": "PW"
                    },
                    {
                        "name": "Pennsylvania",
                        "abbreviation": "PA"
                    },
                    {
                        "name": "Puerto Rico",
                        "abbreviation": "PR"
                    },
                    {
                        "name": "Rhode Island",
                        "abbreviation": "RI"
                    },
                    {
                        "name": "South Carolina",
                        "abbreviation": "SC"
                    },
                    {
                        "name": "South Dakota",
                        "abbreviation": "SD"
                    },
                    {
                        "name": "Tennessee",
                        "abbreviation": "TN"
                    },
                    {
                        "name": "Texas",
                        "abbreviation": "TX"
                    },
                    {
                        "name": "Utah",
                        "abbreviation": "UT"
                    },
                    {
                        "name": "Vermont",
                        "abbreviation": "VT"
                    },
                    {
                        "name": "Virgin Islands",
                        "abbreviation": "VI"
                    },
                    {
                        "name": "Virginia",
                        "abbreviation": "VA"
                    },
                    {
                        "name": "Washington",
                        "abbreviation": "WA"
                    },
                    {
                        "name": "West Virginia",
                        "abbreviation": "WV"
                    },
                    {
                        "name": "Wisconsin",
                        "abbreviation": "WI"
                    },
                    {
                        "name": "Wyoming",
                        "abbreviation": "WY"
                    }
                ];
            },
            validateDate: function (dateStr) {
                var tmp = dateStr;
                if (tmp !== undefined && tmp !== null && tmp.split('/').length >= 3) {
                    tmp = dateStr.split('/')[2] + '-' + dateStr.split('/')[0] + '-' + dateStr.split('/')[1];
                    // .substring(6) + '-' + dateStr.substring(0, 2) + '-' + dateStr.substring(3, 5);
                    return moment(tmp, 'YYYY-M-D', true).isValid();
                }
                return false;
            },
            updateAFLabels: function (scope, filter, aoChange) {
                scope.pt_last_name = filter('translate')('Patient_Last_Name');
                scope.pt_first_name = filter('translate')('Patient_First_Name');
                scope.pt_mid_name = filter('translate')('Patient_Middle_Name');
                scope.prepdate = filter('translate')('Date_Form_Prepared');
                scope.mrn = filter('translate')('Medical_Record_Number');
                scope.dob = filter('translate')('birth_date');
                scope.dob_verify = 'Verify DOB';
                scope.date = filter('translate')('date');
                scope.street = filter('translate')('street');
                scope.city = filter('translate')('city');
                scope.state = filter('translate')('state');
                scope.zip = filter('translate')('zip');
                scope.patient_information = filter('translate')('patient_information');
                scope.gender = filter('translate')('gender');
                scope.patient_information = filter('translate')('patient_information');
                scope.check_one = filter('translate')('check_one');
                scope.patient_information = filter('translate')('patient_information');
                scope.check_one = filter('translate')('check_one');
                scope.cpr_warning1 = filter('translate')('cpr_warning1');
                scope.cpr_warning2 = filter('translate')('cpr_warning2');
                scope.cpr_heading = filter('translate')('cpr_header');
                scope.cpr_option1 = filter('translate')('cpr_option1');
                scope.cpr_option2 = filter('translate')('cpr_option2');
                scope.medical_interventions_header = filter('translate')('medical_interventions_header');
                scope.medical_interventions_warning = filter('translate')('medical_interventions_warning');
                scope.medical_interventions_option1 = filter('translate')('medical_interventions_option1');
                scope.medical_interventions_option2 = filter('translate')('medical_interventions_option2');
                scope.medical_interventions_option3 = filter('translate')('medical_interventions_option3');
                scope.medical_interventions_option2a = filter('translate')('medical_interventions_option2a');
                scope.medical_interventions_option1a = filter('translate')('medical_interventions_option1a');
                scope.artificial_nutrition_heading = filter('translate')('artificial_nutrition_heading');
                scope.artificial_nutrition_warning = filter('translate')('artificial_nutrition_warning');
                scope.artificial_nutrition_option1 = filter('translate')('artificial_nutrition_option1');
                scope.artificial_nutrition_option2 = filter('translate')('artificial_nutrition_option2');
                scope.artificial_nutrition_option3 = filter('translate')('artificial_nutrition_option3');
                scope.discussed_with = filter('translate')('discussed_with');
                scope.discussed_with_patient = filter('translate')('discussed_with_patient');
                scope.discussed_with_decisionmaker = filter('translate')('discussed_with_decisionmaker');
                scope.additional_orders = filter('translate')('additional_orders');
                if (aoChange === false) {
                    // scope.afform.addOrders = filter('translate')('additional_orders') + ":\n\n";
                    // scope.afform.foodAddOrders = filter('translate')('additional_orders') + ":\n\n";
                }
                scope.signatures_header = filter('translate')('signatures_header');
                scope.ad_option1 = filter('translate')('ad_option1');
                scope.ad_option2 = filter('translate')('ad_option2');
                scope.ad_option3 = filter('translate')('ad_option3');
                scope.healthcare_agent = filter('translate')('healthcare_agent');
                scope.name = filter('translate')('name');
                scope.phone = filter('translate')('phone');
                scope.patient_signature_header = filter('translate')('patient_signature_header');
                scope.patient_signature_legal = filter('translate')('patient_signature_legal');
                scope.legal_rep_relationship = filter('translate')('legal_rep_relationship');
                scope.legal_rep_name = filter('translate')('legal_rep_name');
                scope.legal_rep_phone = filter('translate')('legal_rep_phone');
                scope.legal_rep_address = filter('translate')('legal_rep_address');
                scope.Healthcare_Provider_Assisting_with_Form_Preparation = filter('translate')('Healthcare_Provider_Assisting_with_Form_Preparation');
                scope.not_completed_by_physician = filter('translate')('not_completed_by_physician');
                scope.title = filter('translate')('title');
                scope.none = filter('translate')('none');
                scope.additional_contact = filter('translate')('additional_contact');
                scope.provider_directions = filter('translate')('provider_directions');
            },
            updateADLabels: function (scope, filter, aoChange) {
                scope.pt_last_name = filter('translate')('Patient_Last_Name');
                scope.pt_first_name = filter('translate')('Patient_First_Name');
                scope.pt_mid_name = filter('translate')('Patient_Middle_Name');
                scope.prepdate = filter('translate')('Date_Form_Prepared');
                scope.mrn = filter('translate')('Medical_Record_Number');
                scope.dob = filter('translate')('birth_date');
                scope.date = filter('translate')('date');
                scope.street = filter('translate')('street');
                scope.city = filter('translate')('city');
                scope.state = filter('translate')('state');
                scope.zip = filter('translate')('zip');
                scope.patient_information = filter('translate')('patient_information');
                scope.gender = filter('translate')('gender');
            },
            updatePolstLabels: function (scope, filter, aoChange) {
                scope.pt_last_name = filter('translate')('Patient_Last_Name');
                scope.pt_first_name = filter('translate')('Patient_First_Name');
                scope.pt_mid_name = filter('translate')('Patient_Middle_Name');
                scope.prepdate = filter('translate')('Date_Form_Prepared');
                scope.mrn = filter('translate')('Medical_Record_Number');
                scope.dob = filter('translate')('birth_date');
                scope.date = filter('translate')('date');
                scope.street = filter('translate')('street');
                scope.city = filter('translate')('city');
                scope.state = filter('translate')('state');
                scope.zip = filter('translate')('zip');
                scope.patient_information = filter('translate')('patient_information');
                scope.check_one = filter('translate')('check_one');
                scope.patient_information = filter('translate')('patient_information');
                scope.check_one = filter('translate')('check_one');
                scope.cpr_warning1 = filter('translate')('cpr_warning1');
                scope.cpr_warning2 = filter('translate')('cpr_warning2');
                scope.cpr_heading = filter('translate')('cpr_header');
                scope.cpr_option1 = filter('translate')('cpr_option1');
                scope.cpr_option2 = filter('translate')('cpr_option2');
                scope.medical_interventions_header = filter('translate')('medical_interventions_header');
                scope.medical_interventions_warning = filter('translate')('medical_interventions_warning');
                scope.medical_interventions_option1 = filter('translate')('medical_interventions_option1');
                scope.medical_interventions_option2 = filter('translate')('medical_interventions_option2');
                scope.medical_interventions_option3 = filter('translate')('medical_interventions_option3');
                scope.medical_interventions_option2a = filter('translate')('medical_interventions_option2a');
                scope.medical_interventions_option1a = filter('translate')('medical_interventions_option1a');
                scope.artificial_nutrition_heading = filter('translate')('artificial_nutrition_heading');
                scope.artificial_nutrition_warning = filter('translate')('artificial_nutrition_warning');
                scope.artificial_nutrition_option1 = filter('translate')('artificial_nutrition_option1');
                scope.artificial_nutrition_option2 = filter('translate')('artificial_nutrition_option2');
                scope.artificial_nutrition_option3 = filter('translate')('artificial_nutrition_option3');
                scope.discussed_with = filter('translate')('discussed_with');
                scope.discussed_with_patient = filter('translate')('discussed_with_patient');
                scope.discussed_with_decisionmaker = filter('translate')('discussed_with_decisionmaker');
                scope.additional_orders = filter('translate')('additional_orders');
                // if (aoChange === false) {
                // scope.polst.addOrders = filter('translate')('additional_orders') + ":\n\n";
                // scope.polst.foodAddOrders = filter('translate')('additional_orders') + ":\n\n";
                // }
                scope.signatures_header = filter('translate')('signatures_header');
                scope.ad_option1 = filter('translate')('ad_option1');
                scope.ad_option2 = filter('translate')('ad_option2');
                scope.ad_option3 = filter('translate')('ad_option3');
                scope.healthcare_agent = filter('translate')('healthcare_agent');
                scope.name = filter('translate')('name');
                scope.phone = filter('translate')('phone');
                scope.patient_signature_header = filter('translate')('patient_signature_header');
                scope.patient_signature_legal = filter('translate')('patient_signature_legal');
                scope.legal_rep_relationship = filter('translate')('legal_rep_relationship');
                scope.legal_rep_name = filter('translate')('legal_rep_name');
                scope.legal_rep_phone = filter('translate')('legal_rep_phone');
                scope.legal_rep_address = filter('translate')('legal_rep_address');
                scope.gender = filter('translate')('gender');
                scope.genders[0].name = filter('translate')('male');
                scope.genders[1].name = filter('translate')('female');
                scope.Healthcare_Provider_Assisting_with_Form_Preparation = filter('translate')('Healthcare_Provider_Assisting_with_Form_Preparation');
                scope.not_completed_by_physician = filter('translate')('not_completed_by_physician');
                scope.title = filter('translate')('title');
                scope.none = filter('translate')('none');
                scope.additional_contact = filter('translate')('additional_contact');
                scope.provider_directions = filter('translate')('provider_directions');
                scope.completing_POLST = filter('translate')('completing_POLST');
                scope.completing_option1 = filter('translate')('completing_option1');
                scope.completing_option2 = filter('translate')('completing_option2');
                scope.completing_option3 = filter('translate')('completing_option3');
                scope.completing_option4 = filter('translate')('completing_option4');
                scope.completing_option5 = filter('translate')('completing_option5');
                scope.completing_option6 = filter('translate')('completing_option6');
                scope.completing_option7 = filter('translate')('completing_option7');
                scope.completing_option8 = filter('translate')('completing_option8');
                scope.using_POLST = filter('translate')('using_POLST');
                scope.modifying_POLST = filter('translate')('modifying_POLST');
                scope.reviewing_POLST = filter('translate')('reviewing_POLST');
                scope.reviewing_recommended_when = filter("translate")('reviewing_recommended_when');
                scope.section_a = filter('translate')('section_a');
                scope.section_b = filter('translate')('section_b');
                scope.using_option1 = filter('translate')('using_option1');
                scope.using_option2 = filter('translate')('using_option2');
                scope.using_option3 = filter('translate')('using_option3');
                scope.using_option4 = filter('translate')('using_option4');
                scope.using_option5 = filter('translate')('using_option5');
                scope.using_option6 = filter('translate')('using_option6');
                scope.using_option7 = filter('translate')('using_option7');
                scope.reviewing_option1 = filter('translate')('reviewing_option1');
                scope.reviewing_option2 = filter('translate')('reviewing_option2');
                scope.reviewing_option3 = filter('translate')('reviewing_option3');
                scope.modifying_option1 = filter('translate')('modifying_option1');
                scope.modifying_option2 = filter('translate')('modifying_option2');
                scope.Create_your_POLST_form = filter('translate')('Create_your_POLST_form');
                scope.physician_signature_header = filter('translate')('physician_signature_header');
                scope.physician_legal = filter('translate')('physician_legal');
            },
            focus: function (id) {
                // timeout makes sure that it is invoked after any other event
                // has been triggered.
                // e.g. click events that need to run before the focus or
                // inputs elements that are in a disabled state but are enabled
                // when those events
                // are triggered.
                setTimeout(function () {
                    var element = $window.document.getElementById(id);
                    if (element) {
                        element.focus();
                    }
                });
            },
            findById: function (arr, id) {
                for (var i = 0; i < arr.length; i++) {
                    if (arr[i].id === id) {
                        return arr[i];
                    }
                }
                return undefined;
            },
            prepLine1: function (a) {
                var ret = '';
                if (a.line1 !== undefined) {
                    ret = ret + a.line1;
                }
                return ret;
            },
            prepLine2: function (a) {
                var ret = '';
                if (a.line2 !== undefined) {
                    ret = ret + " " + a.line2;
                }
                return ret;
            },
            getLangs: function () {
                var ret = [{
                    id: 1,
                    name: "English",
                    code: "en"
                }, {
                    id: 2,
                    name: "Japanese",
                    code: "ja"
                }, {
                    id: 3,
                    name: "Korean",
                    code: "ko"
                }, {
                    id: 4,
                    name: "Armenian",
                    code: "hy"
                }, {
                    id: 5,
                    code: "ru",
                    name: "Russian"
                }, {
                    id: 6,
                    code: "es",
                    name: "Spanish"
                }, {
                    id: 7,
                    code: "tl",
                    name: "Tagalog"
                }, {
                    id: 8,
                    code: "vi",
                    name: "Vietnamese"
                }, {
                    id: 9,
                    name: "Hmong",
                    code: "hmn"
                }, {
                    id: 10,
                    name: "Simplified Chinese",
                    code: "zh-CN"
                }, {
                    id: 11,
                    name: "Traditional Chinese",
                    code: "zh-TW"
                }];
                ret.sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                });
                return ret;
            },
            containsEmail: function (obj, list) {
                var i;
                for (i = 0; i < list.length; i++) {
                    if (list[i] !== undefined && list[i].email === obj.email) {
                        return true;
                    }
                }
                return false;
            },
            isValidZipCode: function (sZip) {
                return /^\d{5}$/.test(sZip);
            },
            scrollContent: function (eID) {
                console.log('scrolling to: ' + eID);
                var m = document.getElementById("scroller");
                var e = document.getElementById(eID);
                if (e !== null) {
                    m.scrollTop = e.offsetTop + 0;
                }
            },
            scrollTo: function (eID) {
                // This scrolling function
                // is from
                // http://www.itnewb.com/tutorial/Creating-the-Smooth-Scroll-Effect-with-JavaScript
                var i;
                var startY = currentYPosition();
                var stopY = elmYPosition(eID);
                if (stopY > 60) {
                    stopY = stopY - 60;
                }
                var distance = stopY > startY ? stopY - startY : startY - stopY;
                if (distance < 100) {
                    scrollTo(0, stopY);
                    return;
                }
                var speed = Math.round(distance / 100);
                if (speed >= 20) {
                    speed = 20;
                }
                var step = Math.round(distance / 25);
                var leapY = stopY > startY ? startY + step : startY - step;
                var timer = 0;
                if (stopY > startY) {
                    for (i = startY; i < stopY; i += step) {
                        setTimeout("window.scrollTo(0, " + leapY + ")", timer * speed);
                        leapY += step;
                        if (leapY > stopY) {
                            leapY = stopY;
                        }
                        timer++;
                    }
                    return;
                }
                for (i = startY; i > stopY; i -= step) {
                    setTimeout("window.scrollTo(0, " + leapY + ")", timer * speed);
                    leapY -= step;
                    if (leapY < stopY) {
                        leapY = stopY;
                    }
                    timer++;
                }

                function currentYPosition() {
                    // Firefox, Chrome, Opera, Safari
                    if (window.pageYOffset) {
                        return window.pageYOffset;
                    }
                    // Internet Explorer 6 - standards mode
                    if (document.documentElement && document.documentElement.scrollTop) {
                        return document.documentElement.scrollTop;
                    }
                    // Internet Explorer 6, 7 and 8
                    if (document.body.scrollTop) {
                        return document.body.scrollTop;
                    }
                    return 0;
                }

                function elmYPosition(eID) {
                    var elm = document.getElementById(eID);
                    var y = elm.offsetTop;
                    var node = elm;
                    while (node.offsetParent && node.offsetParent !== document.body) {
                        node = node.offsetParent;
                        y += node.offsetTop;
                    }
                    return y;
                }

            }
        };
    }]);
    mod.$inject = ['$window'];
    return mod;
});

