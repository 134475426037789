window.emulateLLUMC = false;
window.isNgrok = false;
window.isLinode = true;
window.isProd = false;
if (window.isProd) {
    window.isLinode = false;
    window.apiUrl = "https://adie.caredirectives.org";
} else {
    window.apiUrl = window.isLinode ? "https://demo.adie.caredirectives.org" : window.isNgrok ? 'https://caredirectives.ngrok.io' : 'http://127.0.0.1:9000';
}
var script = document.createElement('script');
script.type = 'text/javascript';
script.src = 'https://js.stripe.com/v3/';
document.body.appendChild(script);
script.onload = function() {
    window.stripe = Stripe('pk_test_nIQMjVfcSq7RbsALgHtozyqM');
    window.stripeElements = window.stripe.elements();
};
if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
    };
}
if (!String.prototype.endsWith) {
    String.prototype.endsWith = function (searchString, position) {
        var subjectString = this.toString();
        if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
            position = subjectString.length;
        }
        position -= searchString.length;
        var lastIndex = subjectString.lastIndexOf(searchString, position);
        return lastIndex !== -1 && lastIndex === position;
    };
}
// -- RequireJS config --
requirejs
    .config({
        // Packages = top-level folders; loads a contained file named
        // 'main.js"
        waitSeconds: 200,
        packages: ['common', 'home', 'user', 'dashboard', 'search',
            'provider', 'hospital'],
        shim: {
            'jsRoutes': {
                deps: [],
                // it's not a RequireJS module, so we have to tell it
                // what var is returned
                exports: 'jsRoutes'
            },
            'ng-file-upload': {
                deps: ['angular']
            },
            'templates': {
                deps: ['angular'],
                exports: 'templates'
            },
            'angular': {
                exports: 'angular'
            },
            'papaparse': {
                deps: ['angular']
            },
            'angular-papa-promise': {
                deps: ['angular', 'papaparse']
            },
            'raven': {
                deps: ['angular']
            },
            'raven-angular': {
                deps: ['raven', 'angular']
            },
            'angular-idle': {
                deps: ['angular']
            },
            'angular-material': {
                deps: ['angular', 'angular-sanitize', 'angular-route', 'angular-messages', 'angular-aria']
            },
            'angular-material-badge': {
                deps: ['angular', 'angular-material']
            },
            'angular-aria': {
                deps: ['angular']
            },
            'angular-messages': {
                deps: ['angular']
            },
            'angular-route': {
                deps: ['angular']
            },
            'angular-animate': {
                deps: ['angular']
            },
            'angular-sanitize': {
                deps: ['angular']
            },
            'angular-busy': {
                deps: ['angular']
            },
            'angular-simple-logger': {
                deps: ['angular']
            },
            'angular-translate': {
                deps: ['angular']
            },
            'signature_pad': {
                deps: ['angular']
            },
            'angular-storage': {
                deps: ['angular']
            }, 'ng-google-chart': {
                deps: ['angular']
            }, 'angular-material-data-table': {deps: ['angular', 'angular-material']},
            'requirejs-async': {}
        },
        paths: {
            'angular-translate': ['../assets/angular-translate'],
            'raven': '../assets/raven',
            'papaparse': '../assets/papaparse',
            'requirejs': ['../assets/require'],
            'requirejs-async': '../assets/requirejs-plugins/async',
            'angular-simple-logger': '../assets/angular-simple-logger',
            'angular': '../assets/angular',
            'angular-material-badge': '../assets/angular-material-badge',
            'raven-angular': '../assets/plugins/angular',
            'angular-papa-promise': '../assets/angular-papa-promise',
            'angular-route': '../assets/angular/angular-route',
            'angular-sanitize': '../assets/angular/angular-sanitize',
            'angular-animate': '../assets/angular-animate',
            'angular-busy': '../assets/angular-busy',
            'angular-material': '../assets/angular-material',
            'angular-aria': '../assets/angular-aria',
            'angular-messages': '../assets/angular-messages',
            'signature_pad': '../assets/signature_pad',
            'angular-idle': '../assets/angular-idle',
            'lodash': '../assets/lodash',
            'ng-file-upload': '../assets/ng-file-upload',
            'angular-storage': '../assets/ngStorage',
            'ng-google-chart': '../assets/ng-google-chart',
            'angular-material-data-table': '../assets/angular-material-data-table',
            'jsRoutes': [window.apiUrl + (window.isLinode ? '/api/jsroutes' : '/api/jsroutes')],
            'templates': ['templates']
        }
    });
requirejs.onError = function (err) {
    console.log(err);
};

// Load the app. This is kept minimal so it doesn't need much updating.
require(['angular', 'angular-route', 'angular-sanitize', 'angular-translate', 'templates', 'signature_pad',
    'angular-idle', 'raven', 'raven-angular', 'papaparse', 'angular-papa-promise', 'ng-google-chart',
    'angular-material-badge',
    'angular-material-data-table', 'angular-storage', 'angular-animate', 'angular-busy', 'ng-file-upload',
    'angular-material', 'angular-aria', 'angular-messages', './app'], function (angular) {
    angular.bootstrap(document, ['app', 'ngMdBadge', 'cgBusy', 'ngSanitize', 'ngStorage', 'md.data.table', 'templates', 'pascalprecht.translate',
        'ngAnimate', 'ngMaterial', 'ngFileUpload', 'ngIdle', 'ngRaven', 'papa-promise', 'googlechart'], {
        strictDi: true
    });
});

define("main", function(){});

