define('dashboard/notes.controller',['angular'], function (angular) {
    'use strict';
    var NotesCtrl = function ($scope, userService, user) {
        console.log(user);
        $scope.user =user;
    };
    NotesCtrl.$inject = ['$scope', 'userService', 'user'];
    return NotesCtrl;
});

