define('data-entry/ad-list-view.controller',['angular'], function (angular) {
    'use strict';
    var ADDataListViewCtrl = function ($scope, userService, user, dataEntryService, $location, $mdDialog) {
        $scope.user = user.data.User;
        $scope.statusFilter = 'not-processed';
        $scope.first = true;
        $scope.viewPDF = function (l) {
            userService.downloadADById(l.id).then(function(response){
                var file = new Blob([response.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });

        };
        $scope.getForms = function () {
            $scope.myPromise = dataEntryService.getAdvanceDirectives($scope.user.sessionID, $scope.statusFilter).then(function (r) {
                // return from API call
                $scope.formList = r.data;
                for (var i = 0; i < $scope.formList.length; i++) {
                    var tmp = moment.utc($scope.formList[i].created, 'YYYY-MM-DD HH:mm:ss');
                    $scope.formList[i].createdDate = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
                }
                if ($scope.first) {
                    $scope.first = false;
                    $scope.formList.sort(function (a, b) {
                        if (a.created === undefined && b.created === undefined) {
                            return 0;
                        } else if (a.created === undefined) {
                            return 1;
                        } else if (b.created === undefined) {
                            return -1;
                        }
                        var a2 = moment.utc(a.created, 'YYYY-MM-DD HH:mm:ss');
                        var b2 = moment.utc(b.created, 'YYYY-MM-DD HH:mm:ss');
                        if (a2.isBefore(b2)) {
                            return 1;
                        } else if (a2.isAfter(b2)) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });
                }
                $scope.onPaginate($scope.formTable.page, $scope.formTable.limit);
            });
        };
        $scope.getForms();
        $scope.formTable = {
            order: 'created',
            limit: 10,
            page: 1
        };
        $scope.onPaginate = function (page, limit) {
            console.log(page + ' ' + limit);
            $scope.formTable.page = page;
            $scope.formTable.limit = limit;
            $scope.reorderForms();
        };
        $scope.reorderForms = function () {
            var start = (($scope.formTable.page - 1) * $scope.formTable.limit);
            var end = start + $scope.formTable.limit;
            $scope.formView = [];
            console.log(start + ' ' + end + ' ' + $scope.formList.length);
            for (var i = start; i < end && i < $scope.formList.length; i++) {
                $scope.formView.push($scope.formList[i]);
            }
        };
        $scope.formView = [];
        $scope.onReorder = function (order) {
            console.log(order);
            if (order === 'id') {
                $scope.formList.sort(function (a, b) {
                    return a.id > b.id ? -1 : a.id < b.id ? 1 : 0;
                });
            } else if (order === '-id') {
                $scope.formList.sort(function (a, b) {
                    return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
                });
            } else if (order === '-orgid') {
                $scope.formList.sort(function (a, b) {
                    return a.org.name.toLowerCase() > b.org.name.toLowerCase() ? -1 : b.org.name.toLowerCase() > a.org.name.toLowerCase() ? 1 : 0;
                });
            } else if (order === 'orgid') {
                $scope.formList.sort(function (a, b) {
                    return a.org.name.toLowerCase() > b.org.name.toLowerCase() ? 1 : b.org.name.toLowerCase() > a.org.name.toLowerCase() ? -1 : 0;
                });
            } else if (order === '-created') {
                $scope.formList.sort(function (a, b) {
                    if (a.created === undefined && b.created === undefined) {
                        return 0;
                    } else if (a.created === undefined) {
                        return -1;
                    } else if (b.created === undefined) {
                        return 1;
                    }
                    var a1 = moment.utc(a.created, 'YYYY-MM-DD HH:mm:ss');
                    var b1 = moment.utc(b.created, 'YYYY-MM-DD HH:mm:ss');
                    if (a1.isBefore(b1)) {
                        return -1;
                    } else if (a1.isAfter(b1)) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            } else if (order === 'created') {
                $scope.formList.sort(function (a, b) {
                    if (a.created === undefined && b.created === undefined) {
                        return 0;
                    } else if (a.created === undefined) {
                        return 1;
                    } else if (b.created === undefined) {
                        return -1;
                    }
                    var a2 = moment.utc(a.created, 'YYYY-MM-DD HH:mm:ss');
                    var b2 = moment.utc(b.created, 'YYYY-MM-DD HH:mm:ss');
                    if (a2.isBefore(b2)) {
                        return 1;
                    } else if (a2.isAfter(b2)) {
                        return -1;
                    } else {
                        return 0;
                    }

                });
            } else if (order === 'formStatus') {
                $scope.formList.sort(function (a, b) {
                    return a.formStatus > b.formStatus ? 1 : a.formStatus < b.formStatus ? -1 : 0;
                });
            } else if (order === '-formStatus') {
                $scope.formList.sort(function (a, b) {
                    return a.formStatus > b.formStatus ? -1 : a.formStatus < b.formStatus ? 1 : 0;
                });

            }
            $scope.reorderForms();
        };
        $scope.getForms();
        $scope.filterByStatus = function () {
            $scope.getForms();
        };
        $scope.processForm = function (l) {
            $location.path('/data-entry/verify-ad').search({id: l.id});
        };
        $scope.uploadPOLST = function () {
            $location.path('/adupload');
        };
        $scope.claimBack = function (l) {
            var reviewed = false;
            if(l.is_pt_upload){
                dataEntryService.checkReviewed($scope.user.sessionID, l.patientID).then(function(r){
                    if(r.data.reviewed){
                        l.assigned_user_id = $scope.user.id;
                        dataEntryService.claimAdBack(l.formStatus, l.assignedUserid, $scope.user.sessionID, l.id).then(function (r) {
                            l.assignedUser = r.data;
                        }, function (e) {
                            console.log(e);
                        });
                    } else {
                        $mdDialog.show({
                            controller: 'ReviewPatientAlertCtrl',
                            templateUrl: 'data-entry/review-patient-alert.html',
                            locals: {},
                            parent: document.body,
                            clickOutsideToClose: true,
                            fullscreen: true
                        }).then(function (response) {
                            if(response !== undefined && response.review){
                                $mdDialog.show({
                                    controller: 'MatchDetailsCtrl',
                                    templateUrl: 'data-entry/match-details.html',
                                    locals: {user: $scope.user, patient: r.data.match},
                                    parent: document.body,
                                    clickOutsideToClose: true,
                                    fullscreen: true
                                }).then(function (response) {
                                    if(response !== undefined && response.reviewed){
                                        l.is_reviewed = true;
                                    } else if(response !== undefined && response.confirmed){
                                        l.is_reviewed = true;
                                        l.assigned_patient_id = response.patient.id;
                                    }
                                });
                            }
                        });
                    }
                });
            } else {
                l.formStatus = 'assigned';
                l.assignedUserid = $scope.user.id;
                dataEntryService.claimAdBack(l.formStatus, l.assignedUserid, $scope.user.sessionID, l.id).then(function (r) {
                    l.assignedUser = r.data;
                }, function (e) {
                    console.log(e);
                });
            }
        };

    };
    ADDataListViewCtrl.$inject = ['$scope', 'userService', 'user', 'dataEntryService', '$location', '$mdDialog'];
    return ADDataListViewCtrl;
});

