define('super-user/all-form-submission.controller',['angular'], function (angular) {
    'use strict';
    var AllFormSubmissionCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document, userService, dataEntryService, $window, $mdDialog, $sce, helper, superUserService, user, Papa) {
        $scope.user = user.data.User;
        // $scope.isHosAPI = $scope.user.org.type === 'Hospital API';

        var tmp = moment().subtract(30, 'days');
        $scope.startDate = tmp.toDate();
        $scope.endDate = moment().toDate();
        $scope.allDates = false;
        $scope.reportReady = false;
        $scope.clearReport = function () {
            $scope.reportReady = false;
            $scope.runningReport = false;
            $scope.exportReady = false;
        };
        var orgid = $scope.user.org.id;
        if ($localStorage.pref_org_id !== undefined) {
            orgid = $localStorage.pref_org_id;
        }
        userService.getOrg($scope.user.sessionID, orgid).then(function (r) {
            $scope.org = r.data;
        });
        $scope.runningReport = false;
        $scope.runReport = function () {
            $scope.runningReport = true;
            superUserService.formSubmissionsByDate($scope.user.sessionID, $scope.allDates, $scope.startDate, $scope.endDate).then(function (r) {
                $scope.ctrs = [];
                for (var c in r) {
                    $scope.ctrs.push(r[c]);
                }
                $scope.ctrs = $scope.ctrs.sort(function (a, b) {
                    return a.name.localeCompare(b.name);
                });
                $scope.runningReport = false;
                $scope.reportReady = true;
            });


        };
    };
    AllFormSubmissionCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'superUserService', 'user', 'Papa'];
    return AllFormSubmissionCtrl;
});

