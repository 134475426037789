/**
 * Dashboard controllers.
 */
define('dashboard/dashboard.controllers',['angular', './dashboard.controller', './toast.controller', './user-mgr.controller', './user-details.controller', './toast-error.controller', './verify-physician.controller', './admin-form.controller', './upload.controller', './signable.controller', './edit-patient.controller', './select-patient.controller', './confirm-selection.controller', './md-lookup.controller', './finish-new-polst.controller', './view-admins.controller', './polst-form-view.controller', './notes.controller', './view-pdf.controller', './fax-form.controller', './help.controller', './polst-create-dialog.controller', './pending-forms.controller', './change-assigned-physician.controller', './signed-forms.controller', './generic-toast.controller'], function (angular, DashboardCtrl, ToastCtrl, UserMgrCtrl, UserDetailsCtrl, ToastFormErrCtrl, VerifyPhysicianCtrl, AdminFormCtrl, UploadFormCtrl, SignableCtrl, EditPatientCtrl, SelectPatientCtrl, ConfirmSelectionCtrl, MDLookupCtrl, FinishNewPolstCtrl, ViewAdminsCtrl, PolstFormViewCtrl, NotesCtrl, ViewPDFCtrl, FaxFormCtrl, HelpCtrl, PolstCreateDialogCtrl, PendingFormsCtrl, ChangeAssignedPhysicianCtrl, SignedFormsCtrl, GenericToastCtrl) {
    'use strict';
    return {
        GenericToastCtrl: GenericToastCtrl,
        SignedFormsCtrl: SignedFormsCtrl,
        ChangeAssignedPhysicainCtrl: ChangeAssignedPhysicianCtrl,
        PendingFormsCtrl: PendingFormsCtrl,
        PolstCreateDialogCtrl: PolstCreateDialogCtrl,
        HelpCtrl: HelpCtrl,
        ViewPDFCtrl: ViewPDFCtrl,
        NotesCtrl: NotesCtrl,
        PolstFormViewCtrl: PolstFormViewCtrl,
        ViewAdminsCtrl: ViewAdminsCtrl,
        FinishNewPolstCtrl: FinishNewPolstCtrl,
        MDLookupCtrl: MDLookupCtrl,
        ConfirmSelectionCtrl: ConfirmSelectionCtrl,
        SelectPatientCtrl: SelectPatientCtrl,
        EditPatientCtrl: EditPatientCtrl,
        SignableCtrl: SignableCtrl,
        DashboardCtrl: DashboardCtrl,
        ToastCtrl: ToastCtrl,
        FaxFormCtrl: FaxFormCtrl,
        UserMgrCtrl: UserMgrCtrl,
        UserDetailsCtrl: UserDetailsCtrl,
        ToastFormErrCtrl: ToastFormErrCtrl,
        VerifyPhysicianCtrl: VerifyPhysicianCtrl,
        AdminFormCtrl: AdminFormCtrl,
        UploadFormCtrl: UploadFormCtrl
    };

})
;

