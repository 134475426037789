define('ice/ice-patient.controller',['angular'], function (angular) {
    'use strict';
    var IceNewPatientCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document, userService, dataEntryService, $window,
                                     $mdDialog, $sce, helper, analyticsService, user, Papa, iceService) {
        // logged in user
        $scope.user = user.data.User;
        $scope.demo = {
            showTooltip: false,
            tipDirection: 'top'
        };
        $scope.icePatient = {};
        $scope.emer_contact = {};
        $scope.facilitator = {};
        $scope.formSubmitted = false;
        $scope.view = false;
        $scope.review = false;
        $scope.states = helper.getStates();
        $scope.not_proxy = false;
        $scope.has_proxy = false;
        $scope.validateDOB = function (dateStr) {
            $scope.invalidCreateDOB = !helper.validateDate(dateStr);
        };
        $scope.genders = [{
            id: 1,
            name: "MALE"
        }, {
            id: 2,
            name: "FEMALE"
        }, {
            id: 3,
            name: 'UNDIFFERENTIATED'
        }];
        $scope.allergies = [];
        $scope.diagnosis = [];
        $scope.medications = [];
        $scope.emergency_contacts = [];
        $scope.proxy = [];
        $scope.id = Number($routeParams.id);
        $scope.mode = $routeParams.mode;
        if ($scope.mode === 'view') {
            $scope.view = true;
        } else if ($scope.mode === 'review') {
            $scope.review = true;
        }
        if ($scope.id !== undefined && $scope.id > 0) {
            iceService.getIcePatient($scope.id).then(function(d) {
                $scope.icePatient = d.data[0];
                if ($scope.icePatient.has_no_next_kin) {
                    $scope.emer_contact.choice = 'no_kin';
                } else if ($scope.icePatient.has_declined_emer_contact) {
                    $scope.emer_contact.choice = 'declined';
                }
                if ($scope.icePatient.has_acp_doc) {
                    $scope.icePatient.facilitator ='acp_doc';
                } else if ($scope.icePatient.has_declined_form) {
                    $scope.icePatient.facilitator = 'declined_form'
                }
                $scope.allergies = d.data[1];
                $scope.medications = d.data[2];
                $scope.diagnosis = d.data[3];
                // check if patient has emergency contacts
                if (d.data[4].length > 0) {
                    $scope.icePatient.emer_contact_choice = 'has_added_contact';
                    for (var i = 0; i < d.data[4].length; i++) {
                        d.data[4][i].is_emer_contact_proxy = d.data[4][i].is_emer_contact_proxy ? 'true' : 'false';
                        d.data[4][i].is_proxy_identified = d.data[4][i].is_proxy_identified ? 'true' : 'false';
                        d.data[4][i].has_copy = d.data[4][i].has_copy ? 'true' : 'false';
                        if (d.data[4][i].is_proxy) {
                            $scope.not_proxy = true;
                            $scope.icePatient.has_proxy = 'true';
                            // push to proxy array
                            $scope.proxy.push(d.data[4][i]);
                        } else {
                            $scope.not_proxy = true;
                            $scope.icePatient.has_proxy = 'false';
                            // only emergency contact
                            $scope.emergency_contacts.push(d.data[4][i]);
                        }
                    }
                }
            });
        }
        $scope.clearEasyScriptMdSig = function () {
            $scope.esPatientSigned = false;
            setTimeout(function () {
                var ctx = $scope.canvas.getContext("2d");
                ctx.fillStyle = '#FFFFFF';
                ctx.fillRect(0, 0, $scope.canvas.width, $scope.canvas.height);
            }, 100);
        };
        $scope.signPatient = function () {
            $scope.esPatientSigned = true;
            $scope.icePatient.signature = $scope.canvas.toDataURL('image/png');
        };
        $scope.update = function() {
            $scope.view = false;
            $scope.review = true;
            $location.path('/ice-patient').search({id: $scope.id, mode: 'review'});
        };
        $scope.addMedication = function() {
           var meds = {medication:'', dose:'', frequency: ''};
           $scope.medications.push(meds);
        };
        $scope.addAllergy = function() {
            var allergy = {allergy_name:''};
            $scope.allergies.push(allergy);
        };
        $scope.addDiagnosis = function() {
            var diagnosis = {diagnosis:''};
            $scope.diagnosis.push(diagnosis);
        };
        // this is used so an array does not keep the elements if user has changed their mind with options
        $scope.clearAll = function(type) {
          if (type === 'emer' && !$scope.view) {
              $scope.emergency_contacts = [];
          } else if (type === 'proxy' && !$scope.view) {
            $scope.proxy = [];
          }
        };
        $scope.addEmerContact = function(type) {
            var contact = {fname: '', lname: '', phone: '', alt_phone:'', relationship:'', is_emer_contact_proxy:'', is_proxy:'',
                            has_proxy:'', is_proxy_identified:''};
            if (type === 'emer' && !$scope.view) {
                contact.is_proxy = false;
                $scope.emergency_contacts.push(contact);
                console.log($scope.emergency_contacts);
            } else if (type === 'proxy') {
                contact.is_proxy = true;
                $scope.proxy.push(contact);
            }
        };
        $scope.removeMedication = function(index) {
            $scope.medications.splice(index, 1);
        };
        $scope.removeAllergy = function(index) {
            $scope.allergies.splice(index, 1);
        };
        $scope.removeDiagnosis = function(index) {
            $scope.diagnosis.splice(index, 1);
        };
        $scope.removeContact = function(index) {
            $scope.emergency_contacts.splice(index, 1);
        };
        $scope.removeProxy = function(index) {
            $scope.proxy.splice(index, 1);
        };
        $scope.notProxy = function(value) {
            $scope.not_proxy = !!value;
        };
        $scope.dashboardPolst = function() {
            $location.path('/dashboard').search({pid: $scope.id});
        };
        $scope.icePatientHelper = function() {
            // check emergency contact choice
            if ($scope.icePatient.emer_contact_choice === 'no_kin') {
                $scope.icePatient.has_no_next_of_kin = true;
            } else if ($scope.icePatient.emer_contact_choice === 'declined') {
                $scope.icePatient.has_declined_emer_contact = true;
            }
            // check if patient has proxy
            if ($scope.icePatient.has_proxy) {
                for (var i = 0; i < $scope.proxy.length; i++) {
                    $scope.emergency_contacts.push($scope.proxy[i]);
                }
            }
            // check if note is undefined
            if ($scope.icePatient.note === undefined) {
                $scope.icePatient.is_note_pending = true;
            }
            //check facilitator choice
            if ($scope.icePatient.facilitator === 'acp_doc') {
                $scope.icePatient.has_acp_doc = true;
            } else if ($scope.icePatient.facilitator === 'declined_form') {
                $scope.icePatient.has_declined_form = true;
            }
        };
        $scope.createPatient = function() {
            $scope.icePatientHelper();
            if ($scope.icePatient.fname !== undefined && $scope.icePatient.lname !== undefined && $scope.icePatient.dob !== undefined
                && !$scope.invalidCreateDOB && $scope.icePatient.emer_contact_choice !== undefined) {
                $scope.myPromise = iceService.createIcePatient($scope.icePatient, $scope.medications, $scope.allergies, $scope.diagnosis, $scope.emergency_contacts).then(function () {
                    $location.path('/ice-dashboard');
                });
            } else {
                $mdToast.show({
                    templateUrl: 'dashboard/FormErrToast.html', position: 'bottom', hideDelay: 30000,
                    controller: 'ToastFormErrCtrl', parent: $document[0].querySelector('#buttonID')

                });
            }
        };
        $scope.updatePatient = function() {
            $scope.icePatientHelper();
            if ($scope.icePatient.fname !== undefined && $scope.icePatient.lname !== undefined && $scope.icePatient.dob !== undefined
                && !$scope.invalidCreateDOB && $scope.icePatient.emer_contact_choice !== undefined) {
                $scope.myPromise2 = iceService.updateIcePatient($scope.icePatient, $scope.medications, $scope.allergies,
                    $scope.diagnosis, $scope.emergency_contacts, false).then(function () {
                    $location.path('/ice-dashboard');
                });
            } else {
                $mdToast.show({
                    templateUrl: 'dashboard/FormErrToast.html', position: 'bottom', hideDelay: 30000,
                    controller: 'ToastFormErrCtrl', parent: $document[0].querySelector('#buttonID')

                });
            }
        };
        $scope.noChanges = function() {
            iceService.updateIcePatient($scope.icePatient, $scope.medications, $scope.allergies,
                $scope.diagnosis, $scope.emergency_contacts, true).then(function () {
                    console.log('no changes');
                    $location.path('/ice-dashboard');
            });
        };

    };
    IceNewPatientCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'analyticsService', 'user', 'Papa', 'iceService'];
    return IceNewPatientCtrl;
});
