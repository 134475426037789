/**
 * Dashboard routes.
 */
define('super-user/super-user.routes',['angular', './super-user.controllers', 'common'], function (angular, controllers) {
    'use strict';

    var mod = angular.module('super-user.routes', ['com.cd.common']);
    mod
        .config([
            '$routeProvider',
            'userResolve',
            'adminResolve',
            'physResolve',
            'dataResolve',
            'superUserResolve',
            '$locationProvider',
            function ($routeProvider, userResolve, adminResolve, physResolve, dataResolve, superUserResolve, $locationProvider) {
                $locationProvider.hashPrefix('');
                $routeProvider.when(
                    '/super/orgs', {
                        templateUrl: 'super-user/org-admin.html',
                        controller: controllers.OrgAdminCtrl,
                        reloadOnSearch: false,
                        resolve: superUserResolve
                    }).when('/super-user/add-user', {
                    templateUrl: 'super-user/add-user.html',
                    controller: controllers.AddUserCtrl,
                    reloadOnSearch: false,
                    resolve: superUserResolve
                }).when('/super-user/form-submission', {
                    templateUrl: 'super-user/all-form-submission.html',
                    controller: controllers.AllFormSubmissionCtrl,
                    reloadONSearch: false,
                    resolve: superUserResolve
                }).when('/super-user/view-epolst', {
                    templateUrl: 'super-user/view-epolst.html',
                    controller: controllers.ViewEPolstCtrl,
                    reloadONSearch: false,
                    resolve: superUserResolve
                }).when('/super-user/manage-all-users', {
                    templateUrl: 'super-user/manage-all-users.html',
                    controller: controllers.ManageAllUsersCtrl,
                    reloadOnSearch: false,
                    resolve: superUserResolve
                }).when('/super-user/manage-org', {
                    templateUrl: 'super-user/manage-org-users.html',
                    controller: controllers.ManageOrgUsersCtrl,
                    reloadOnSearch: false,
                    resolve: superUserResolve
                }).when('/super-user/org-analytics', {
                    templateUrl: 'super-user/view-org-analytics.html',
                    controller: controllers.AnalyticsOrgCtrl,
                    reloadOnSearch: false,
                    resolve: superUserResolve
                }).when('/super-user/all-org-analytics', {
                    templateUrl: 'super-user/view-all-org-analytics.html',
                    controller: controllers.AnalyticsAllOrgCtrl,
                    reloadOnSearch: false,
                    resolve: superUserResolve
                }).when('/super-user/addOrg', {
                    templateUrl: 'super-user/add-org.html',
                    controller: controllers.AddOrgCtrl,
                    reloadOnSearch: false,
                    resolve: superUserResolve
                });
            }]);
    return mod;
});

