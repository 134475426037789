define('user/smart-login.controller',['angular'], function (angular) {
    'use strict';
    var SmartLoginCtrl = function ($scope, helper, userService, $location, $document, $routeParams) {
        // userService.setIsSmart(true);
        $scope.$on('$routeChangeSuccess', function () {
            var sid = $routeParams.sid;
            userService.importDocsFromEHR(sid).then(function () {
            });
            userService.setLogin(sid);
            $location.path("/dashboard");
        });
    };
    SmartLoginCtrl.$inject = ['$scope', 'helper', 'userService', '$location', '$document', '$routeParams'];
    return SmartLoginCtrl;
});

