define('user/smart-error.controller',['angular'], function (angular) {
    'use strict';
    var  SmartErrorCtrl = function ($scope, helper, userService, $location, $document, $routeParams) {
        localStorage.removeItem('sid');
        $scope.$on('$routeChangeSuccess', function () {
            $scope.error_msg = $routeParams.error_msg;
            $scope.details = $routeParams.details;
        });
    };
    SmartErrorCtrl.$inject = ['$scope', 'helper', 'userService', '$location', '$document', '$routeParams'];
    return SmartErrorCtrl;
});

