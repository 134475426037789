define('super-user/view-epolst.controller',['angular'], function (angular) {
    'use strict';
    var ViewEPolstCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document, userService, dataEntryService, $window, $mdDialog, $sce, helper, analyticsService, user, Papa) {
        $scope.user = user.data.User;


        // Functions for correct forms
        $scope.correctForms = [];
        $scope.pendingFormTable = {
            order: 'created',
            limit: 10,
            page: 1
        };

        $scope.formTable = {
            order: 'created',
            limit: 10,
            page: 1
        };

        $scope.formStates = [{
            label: 'Accepted',
            value: 'accepted'
        }, {
            label: 'Pending Signature',
            value: 'pending'
        }, {
            label: 'Rejected',
            value: 'rejected'
        }];
        $scope.mode = 'accepted';
        $scope.viewChanged = function () {
            console.log($scope.mode);
            if ($scope.mode === 'accepted') {
                $scope.formType = 'Accepted';
            } else if ($scope.mode === 'rejected') {
                $scope.formType = 'Rejected';
            } else if ($scope.mode === 'pending') {
                $scope.formType = 'Pending';
            }
            $scope.exportReady = false;
            $scope.rejectExportReady = false;
            $scope.pendingExportReady = false;
        };

        $scope.onPaginate = function (page, limit) {
            $scope.formTable.page = page;
            $scope.formTable.limit = limit;
            $scope.reorderForms();
        };

        $scope.searchInput = '';
        $scope.$watch('searchInput', function () {
            if ($scope.mode === 'accepted') {
                $scope.findMatches();
            } else if($scope.mode === 'pending'){
                $scope.reorderPendingForms();
            } else {
                $scope.reorderRejectedForms();
            }
        });
        $scope.findMatches = function () {
            $scope.onPaginate(1, $scope.formTable.limit);
        };
        $scope.initPolst = true;
        $scope.initAbbyy = true;
        $scope.initPending = true;

        analyticsService.getEPolstForms().then(function (response) {
            $scope.initPolst = false;
            $scope.forms = response.data;
            $scope.reorderForms();
        });
        $scope.reorderPendingForms = function () {
            $scope.pendingExportReady = false;
            var start = (($scope.pendingFormTable.page - 1) * $scope.pendingFormTable.limit);
            var end = start + $scope.pendingFormTable.limit;

            $scope.pendingCorrectForms = [];
            if ($scope.searchInput === '') {
                if ($scope.pendingForms !== undefined) {
                    for (var i = start; i < end && i < $scope.pendingForms.length; i++) {
                        $scope.pendingCorrectForms.push($scope.pendingForms[i]);
                    }
                }
            }
            if ($scope.searchInput !== '') {

                $scope.pendingCorrectForms = [];

                if ($scope.pendingForms !== undefined) {

                    for (var i = start; i < end && i < $scope.pendingForms.length; i++) {
                        if ($scope.pendingForms[i].afid.toString().indexOf($scope.searchInput) >= 0 || $scope.pendingForms[i].fname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.pendingForms[i].lname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || ($scope.pendingForms[i].fname + ' ' + $scope.pendingForms[i].lname).toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0) {
                            $scope.pendingCorrectForms.push($scope.pendingForms[i]);
                        }
                    }

                }
            }
        };
        $scope.reorderForms = function () {
            $scope.exportReady = false;
            var start = (($scope.formTable.page - 1) * $scope.formTable.limit);
            var end = start + $scope.formTable.limit;

            $scope.correctForms = [];
            if ($scope.searchInput === '') {

                if ($scope.forms !== undefined) {
                    for (var i = start; i < end && i < $scope.forms.length; i++) {
                        $scope.correctForms.push($scope.forms[i]);
                    }
                }
            }
            if ($scope.searchInput !== '') {

                $scope.correctForms = [];

                if ($scope.forms !== undefined) {

                    for (var i = start; i < end && i < $scope.forms.length; i++) {
                        if ($scope.forms[i].afid.toString().indexOf($scope.searchInput) >= 0 || $scope.forms[i].fname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.forms[i].lname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || ($scope.forms[i].fname + ' ' + $scope.forms[i].lname).toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0) {
                            $scope.correctForms.push($scope.forms[i]);
                        }
                    }

                }
            }
        };

        $scope.exportReady = false;
        $scope.pendingShowDownload = false;
        $scope.downloadPendingForms = function () {
            var formsToDownload = [];
            if ($scope.searchInput === '') {
                if ($scope.pendingForms !== undefined) {
                    for (var i = 0; i < $scope.pendingForms.length; i++) {
                        formsToDownload.push($scope.forms[i]);
                    }
                }
            }
            if ($scope.searchInput !== '') {
                if ($scope.pendingForms !== undefined) {
                    for (var i = 0; i < $scope.pendingForms.length; i++) {
                        if ($scope.pendingForms[i].afid.toString().indexOf($scope.searchInput) >= 0 || $scope.pendingForms[i].fname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.pendingForms[i].lname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || ($scope.pendingForms[i].fname + ' ' + $scope.pendingForms[i].lname).toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0) {
                            formsToDownload.push($scope.pendingForms[i]);
                        }
                    }

                }
            }
            Papa.unparse(formsToDownload).then(function (r) {
                var blob = new Blob(["\ufeff", r]);
                var url = URL.createObjectURL(blob);
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.pendingMsFile = blob;
                    $scope.pendingFileURL = 'forms.csv';
                    window.navigator.msSaveOrOpenBlob(blob, 'forms.csv');
                    $scope.pendingShowDownload = true;
                } else {
                    $scope.pendingShowDownload = true;
                    $scope.pendingFileURL = URL.createObjectURL(blob);
                }
                $scope.pendingExportReady = true;
            });
        };
        $scope.downloadForms = function () {
            var formsToDownload = [];
            if ($scope.searchInput === '') {
                if ($scope.forms !== undefined) {
                    for (var i = 0; i < $scope.forms.length; i++) {
                        formsToDownload.push($scope.forms[i]);
                    }
                }
            }
            if ($scope.searchInput !== '') {
                if ($scope.forms !== undefined) {
                    for (var i = 0; i < $scope.forms.length; i++) {
                        if ($scope.forms[i].afid.toString().indexOf($scope.searchInput) >= 0 || $scope.forms[i].fname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.forms[i].lname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || ($scope.forms[i].fname + ' ' + $scope.forms[i].lname).toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0) {
                            formsToDownload.push($scope.forms[i]);
                        }
                    }

                }
            }
            Papa.unparse(formsToDownload).then(function (r) {
                var blob = new Blob(["\ufeff", r]);
                var url = URL.createObjectURL(blob);
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = blob;
                    $scope.fileURL = 'forms.csv';
                    window.navigator.msSaveOrOpenBlob(blob, 'forms.csv');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(blob);
                }
                $scope.exportReady = true;
            });
        };
        $scope.changeAssignedPhysician = function(l, ev){
            $mdDialog.show({
                controller: 'ChangeAssignedPhysicianCtrl',
                templateUrl: 'dashboard/change-assigned-phys.html',
                locals: { polst: l, user: $scope.user},
                parent: document.body,
                clickOutsideToClose: true,
                fullscreen: true
            }).then(function (response) {
                if(response !== undefined){
                    l.phsyician = response.phys;
                }
            });
        };
        $scope.pendingOnReorder = function (order) {
            $scope.pendingForms = helper.orderForms(order, $scope.pendingForms);
            $scope.reorderPendingForms();
        };

        $scope.onReorder = function (order) {
            $scope.forms = helper.orderForms(order, $scope.forms);
            $scope.reorderForms();
        };

        $scope.openForm = function (form) {
            $location.path("dashboard").search({p: 1, f: form.id});
        };

        ////////////////////////////////////////
        // Functions for rejected forms
        $scope.rejectedForms = [];
        $scope.statusFilter = 'todos';

        $scope.rejectedFormTable = {
            order: 'created',
            limit: 10,
            page: 1
        };
        $scope.pendingOnPaginate = function (page, limit) {
            $scope.pendingFormTable.page = page;
            $scope.pendingFormTable.limit = limit;
            $scope.reorderPendingForms();
        };

        $scope.onPaginateReject = function (page, limit) {
            $scope.rejectedFormTable.page = page;
            $scope.rejectedFormTable.limit = limit;
            $scope.reorderRejectedForms();
        };

        $scope.reorderRejectedForms = function () {
            var start = (($scope.rejectedFormTable.page - 1) * $scope.rejectedFormTable.limit);
            var end = start + $scope.rejectedFormTable.limit;

            $scope.rejectedForms = [];
            if ($scope.searchInput === '') {

                if ($scope.Abbyforms !== undefined) {
                    for (var i = start; i < end && i < $scope.Abbyforms.length; i++) {
                        $scope.rejectedForms.push($scope.Abbyforms[i]);
                    }
                }
            }
            if ($scope.searchInput !== '') {
                $scope.rejectedForms = [];
                if ($scope.Abbyforms !== undefined) {
                    for (var i = start; i < end && i < $scope.Abbyforms.length; i++) {
                        if ($scope.Abbyforms[i].id.toString().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.Abbyforms[i].mrn.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.Abbyforms[i].fname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.Abbyforms[i].lname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || ($scope.Abbyforms[i].fname + ' ' + $scope.Abbyforms[i].lname).toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0) {
                            $scope.rejectedForms.push($scope.Abbyforms[i]);
                        }
                    }

                }
            }
        };

        $scope.parseCSVData = function (af) {
            Papa.parse(af.csvData, {}).then(function (r) {
                var arrlength = r.data[0].length;
                for (var j = 0; j < arrlength; j++) {
                    if (r.data[0][j] === 'FirstName') {
                        af.fname = r.data[1][j];
                    }
                    if (r.data[0][j] === 'LastName') {
                        af.lname = r.data[1][j];
                    }
                    if (r.data[0][j] === 'MedicalRecordNumber') {
                        af.mrn = r.data[1][j];
                    }
                }
                $scope.ctr++;
                if ($scope.ctr === $scope.Abbyforms.length) {
                    $scope.reorderRejectedForms();
                }
            });
        };
        // Used by both
        $scope.generatePDF = function (f) {
            userService.downloadFormById(f.id).then(function (response) {
                var file = new Blob([response.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });
        };

        $scope.formType = 'Accepted';

        $scope.onChange = function (formState) {
            $scope.formType = formState;
            $scope.exportReady = false;
            $scope.rejectExportReady = false;
        };

        $scope.onReorderRejects = function (order) {
            $scope.forms = helper.orderForms(order, $scope.Abbyforms);
            $scope.reorderAbbyyForms();
        };

        $scope.openAbbyyPDF = function (l) {
            dataEntryService.getABBYYPDF($scope.user.sessionID, l.id).then(function (response) {
                var file = new Blob([response.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });
        };
        $scope.rejectExportReady = false;
        $scope.rejectDownloadForms = function () {
            var formsToDownload = [];
            if ($scope.searchInput === '') {

                if ($scope.Abbyforms !== undefined) {
                    for (var i = 0; i < $scope.Abbyforms.length; i++) {
                        formsToDownload.push($scope.Abbyforms[i]);
                    }
                }
            }
            if ($scope.searchInput !== '') {
                if ($scope.Abbyforms !== undefined) {
                    for (var i = 0; i < $scope.Abbyforms.length; i++) {
                        if ($scope.Abbyforms[i].id.toString().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.Abbyforms[i].mrn.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.Abbyforms[i].fname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || $scope.Abbyforms[i].lname.toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0 || ($scope.Abbyforms[i].fname + ' ' + $scope.Abbyforms[i].lname).toLowerCase().indexOf($scope.searchInput.toLowerCase()) >= 0) {
                            formsToDownload.push($scope.Abbyforms[i]);
                        }
                    }

                }
            }
            Papa.unparse(formsToDownload).then(function (r) {
                var blob = new Blob(["\ufeff", r]);
                var url = URL.createObjectURL(blob);
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = blob;
                    $scope.rejectFileURL = 'rejected.csv';
                    window.navigator.msSaveOrOpenBlob(blob, 'rejected.csv');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.rejectFileURL = URL.createObjectURL(blob);
                }
                $scope.rejectExportReady = true;
            });
        };


    };
    ViewEPolstCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'analyticsService', 'user', 'Papa'];
    return ViewEPolstCtrl;
});

