define('data-entry/toast-success.controller',['angular'], function (angular) {
    'use strict';
    var ToastSuccessCtrl = function ($scope, $mdToast) {
        var isDlgOpen = false;
        $scope.closeToast = function ($event) {
            if (isDlgOpen)
                return;
            $mdToast.hide().then(function () {
                isDlgOpen = false;
            });
            $event.stopPropagation();
        };
    };
    ToastSuccessCtrl.$inject = ['$scope', '$mdToast'];
    return ToastSuccessCtrl;
});

