/**
 * Dashboard controllers.
 */
define('acp-billing/acp-billing.controllers',['angular', './billing-dashboard.controller', './acp-faqs.controller', './acp-guide.controller', './acp-note.controller', './acp-signup.controller', './edit-insurance-acp.controller', './add-addendum.controller'],
    function (angular, BillingDashboardCtrl, ACPFAQsCtrl, ACPGuideCtrl, ACPNoteCtrl, ACPSignupCtrl, EditInsuranceCtrl, AddAddendumCtrl) {
        'use strict';
        return {
            AddAddendumCtrl: AddAddendumCtrl,
            EditInsuranceCtrl: EditInsuranceCtrl,
            BillingDashboardCtrl: BillingDashboardCtrl,
            ACPSignupCtrl: ACPSignupCtrl,
            ACPFAQsCtrl: ACPFAQsCtrl,
            ACPGuideCtrl: ACPGuideCtrl,
            ACPNoteCtrl: ACPNoteCtrl
        };
    });

