define('user/pw-update.controller',['angular'], function (angular) {
    'use strict';
    var PwUpdate = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                  userService, dataEntryService, $window, $mdDialog, $sce, helper) {
        $scope.cancel = function () {
            $mdDialog.hide();
        };
    };
    PwUpdate.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper'];
    return PwUpdate;
});

