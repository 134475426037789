define('super-user/add-org.controller',['angular'], function (angular) {
    'use strict';
    var AddOrgCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                               userService, dataEntryService, $window, $mdDialog, $sce, helper, superUserService, user) {
        $scope.user = user.data.User;
        $scope.org = {};
        $scope.types = helper.getTypes();
        $scope.credentials = {usertype: "5"};
        $scope.emailExists = false;
        $scope.orgExists = false;
        $scope.emailChanged = function () {
            $scope.emailExists = false;
        };
        $scope.attemptedEmails = [];
        $scope.orgChanged = function () {
            $scope.orgExists = false;
        };

        $scope.addOrg = function (valid, ev) {
            $scope.addSubmitted = true;
            if (!$scope.adding && valid && $scope.org.name.length > 3 && $scope.org.type.length > 3) {
                $scope.adding = true;
                userService.checkEmail($scope.credentials, $scope).then(function (r) {
                    $scope.adding = false;
                    superUserService.checkOrg($scope.user.sessionID, $scope.org.name, $scope).then(function (r2) {
                        $scope.adding = false;
                        // all good let's add the org
                        if (!$scope.orgExists && !$scope.emailExists) {
                            $scope.myPromise = superUserService.addOrg($scope.user.sessionID, $scope.org, $scope.credentials).then(function (r) {
                                $location.path('/super/orgs');
                            });
                        }
                    }, function (e) {
                        $scope.adding = false;
                    });
                }, function (e) {
                    $scope.adding = false;
                });
            }

        };


    };
    AddOrgCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'superUserService', 'user'];
    return AddOrgCtrl;
});

