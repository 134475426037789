/**
 * Dashboard controllers.
 */
define('super-user/super-user.controllers',['angular', './org-admin.controller', './add-org.controller', './manage-org-users.controller', './add-user.controller', './super-user-analytics.controller', './super-all-analytics.controller', './user-orgs.controller', './manage-all-users.controller', './view-epolst.controller', './all-form-submission.controller'], function (angular, OrgAdminCtrl, AddOrgCtrl, ManageOrgUsersCtrl, AddUserCtrl, AnalyticsOrgCtrl, AnalyticsAllOrgCtrl, UserOrgsCtrl, ManageAllUsersCtrl, ViewEPolstCtrl, AllFormSubmissionCtrl) {
    'use strict';
    return {
        AllFormSubmissionCtrl: AllFormSubmissionCtrl,
        ViewEPolstCtrl: ViewEPolstCtrl,
        ManageAllUsersCtrl: ManageAllUsersCtrl,
        UserOrgsCtrl: UserOrgsCtrl,
        AddUserCtrl: AddUserCtrl,
        ManageOrgUsersCtrl: ManageOrgUsersCtrl,
        OrgAdminCtrl: OrgAdminCtrl,
        AddOrgCtrl: AddOrgCtrl,
        AnalyticsOrgCtrl: AnalyticsOrgCtrl,
        AnalyticsAllOrgCtrl: AnalyticsAllOrgCtrl
    };
});

