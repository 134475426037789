define('data-entry/review-patient-alert.controller',['angular'], function (angular) {
    'use strict';
    var ReviewPatientAlertCtrl = function ($scope, userService, dataEntryService, $location, $mdDialog) {
        $scope.cancel = function(){
            $mdDialog.hide();
        };
        $scope.review = function(){
            $mdDialog.hide({review: true});
        };
    };
    ReviewPatientAlertCtrl.$inject = ['$scope', 'userService', 'dataEntryService', '$location', '$mdDialog'];
    return ReviewPatientAlertCtrl;
});

