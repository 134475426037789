define('super-user/add-user.controller',['angular'], function (angular) {
    'use strict';
    var AddUserCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                userService, dataEntryService, $window, $mdDialog, $sce, helper, superUserService, user) {
        $scope.user = user.data.User;
        $scope.org = {};
        $scope.types = helper.getTypes();
        if ($scope.user.is_super_user) {
            $scope.types.push({id: 8, name: 'Data Entry'});
        }
        $scope.credentials = {};
        $scope.emailExists = false;
        $scope.orgExists = false;
        $scope.orgid = +$location.search()['o'];
        $scope.emailChanged = function () {
            $scope.emailExists = false;
        };
        $scope.attemptedEmails = [];
        $scope.smsUnique = true;
        $scope.smsChange = function () {
            $scope.smsUnique = true;
        };
        $scope.addUser = function (valid, ev) {
            $scope.addSubmitted = true;
            if (!$scope.adding && valid && $scope.credentials.usertype !== undefined) {
                $scope.adding = true;
                if ($scope.useSms) {
                    userService.checkSMSUnique($scope.credentials.cell_phone).then(function (r) {
                        if (r.data.unique) {
                            $scope.credentials.org_id = $scope.orgid;
                            $scope.credentials.email = undefined;
                            $scope.myPromise = userService.addUser($scope.credentials).then(function (response) {
                                    $scope.adding = false;
                                    $location.path('/super-user/manage-org').search({o: $scope.orgid});
                                },
                                function () {
                                    $scope.adding = false;
                                }
                            );
                        } else {
                            $scope.adding = false;
                            $scope.smsUnique = false;
                        }
                    });
                } else {
                    userService.checkEmail($scope.credentials, $scope).then(function (r) {
                        $scope.adding = false;
                        if (!$scope.emailExists) {
                            $scope.credentials.org_id = $scope.orgid;
                            $scope.myPromise = userService.addUser($scope.credentials).then(function (r) {
                                $location.path('/super-user/manage-org').search({o: $scope.orgid});
                            });
                        }
                    }, function (e) {
                        $scope.adding = false;
                    });
                }
            }
        };
        $scope.roleSelected = function () {
            if ($scope.credentials.usertype == 1) {
                $scope.credentials.access = true;
                $scope.credentials.signer = false;
                $scope.credentials.initiator = true;
                $scope.credentials.transfer = true;
                $scope.credentials.admin = false;
            } else if ($scope.credentials.usertype == 2) {
                $scope.credentials.access = true;
                $scope.credentials.signer = false;
                $scope.credentials.initiator = true;
                $scope.credentials.transfer = true;
                $scope.credentials.admin = false;
            } else if ($scope.credentials.usertype == 3) {
                $scope.credentials.access = true;
                $scope.credentials.signer = false;
                $scope.credentials.initiator = true;
                $scope.credentials.transfer = true;
                $scope.credentials.admin = false;
            } else if ($scope.credentials.usertype == 4) {
                $scope.credentials.access = true;
                $scope.credentials.signer = true;
                $scope.credentials.initiator = true;
                $scope.credentials.transfer = true;
                $scope.credentials.admin = false;
            } else if ($scope.credentials.usertype == 5) {
                $scope.credentials.access = true;
                $scope.credentials.signer = false;
                $scope.credentials.initiator = false;
                $scope.credentials.transfer = false;
                $scope.credentials.admin = true;
            } else if ($scope.credentials.usertype == 6) {
                $scope.credentials.access = true;
                $scope.credentials.signer = false;
                $scope.credentials.initiator = false;
                $scope.credentials.transfer = true;
                $scope.credentials.admin = false;
            } else if ($scope.credentials.usertype == 7) {
                $scope.credentials.access = true;
                $scope.credentials.signer = false;
                $scope.credentials.initiator = false;
                $scope.credentials.transfer = true;
                $scope.credentials.admin = false;
            } else if($scope.credentials.usertype == 9){
                $scope.credentials.access = false;
                $scope.credentials.signer = false;
                $scope.credentials.initiator = false;
                $scope.credentials.transfer = false;
                $scope.credentials.admin = false;
            }
        };
    };
    AddUserCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'superUserService', 'user'];
    return AddUserCtrl;
});

