define('home/header.controller',['angular'], function (angular) {
    'use strict';
    var HeaderCtrl = function ($scope, userService, helper, $location, $rootScope, $mdSidenav, $localStorage, $translate, Idle, Keepalive, $mdDialog) {
        $scope.isProd = window.isProd;
        // var user, UUID = $localStorage.sid;

        $scope.$on('IdleStart', function () {
            // the user appears to have gone idle
        });

        $scope.onTimeout = function () {
            $scope.countdown--;
            if ($scope.countdown > 0) {
                $scope.mytimeout = setTimeout($scope.onTimeout, 1000);
            } else {
                $scope.logoutDialog();
            }
        };
        $scope.countdown = 0;
        var confirm = $mdDialog.confirm().title("You are about to be logged out").textContent("You will " +
            "be logged out in {{countdown}} seconds. Do you want to stay logged in?").ok("Stay" +
            " logged in").cancel('Logout');

        $scope.showingDialog = false;
        $scope.$on('IdleWarn', function (e, countdown) {
            // follows after the IdleStart event, but includes a countdown until the user is considered timed out
            // the countdown arg is the number of seconds remaining until then.
            // you can change the title or display a warning dialog from here.
            // you can let them resume their session by calling Idle.watch()
            userService.checkLogin().then(function () {
                if (userService.getUser() !== undefined && !$scope.showingDialog) {
                    $scope.countdown = 60;
                    $scope.mytimeout = setTimeout($scope.onTimeout, 1000);
                    $scope.showingDialog = true;
                    $mdDialog.show({
                        template: '<md-dialog><md-toolbar><div class="md-toolbar-tools">' +
                        '<h2>You are about to be logged out</h2></div></md-toolbar><md-dialog-content><div class="md-dialog-content"><p>You will be logged out in {{ctrl.parent.countdown}} seconds. Do you want to stay logged in?</p></div></md-dialog-content><md-dialog-actions><md-button ng-click="ctrl.parent.closeDialog()" class="md-primary">Stay logged in</md-button><md-button ng-click="ctrl.parent.logoutDialog()">Logout</md-button></md-dialog-actions></md-dialog>',
                        locals: {
                            countdown: countdown
                        },
                        controller: function () {
                            this.parent = $scope;
                        },
                        controllerAs: 'ctrl'
                    }).then(function (answer) {
                    });
                }
            });
        });
        $scope.closeDialog = function () {
            clearTimeout($scope.mytimeout);
            $mdDialog.hide();
            $scope.showingDialog = false;
            userService.checkLogin().then(function () {
                if (userService.getUser() === undefined) {
                    $location.search({});
                    console.log('isSmart = ', userService.getIsSmart());
                    if (userService.getIsSmart()) {
                        $location.path('/smartTimeout');
                    } else {
                        $location.path('/');
                    }
                }
            }, function () {
                $location.search({});
                console.log('isSmart = ', userService.getIsSmart());
                if (userService.getIsSmart()) {
                    $location.path('/smartTimeout');
                } else {
                    $location.path('/');
                }
            });
        };
        $scope.logoutDialog = function () {
            clearTimeout($scope.mytimeout);
            $mdDialog.hide();
            Idle.interrupt();
            $scope.showingDialog = false;
            userService.logout();
        };

        $scope.$on('IdleTimeout', function () {
            $mdDialog.hide();
            // the user has timed out (meaning idleDuration + timeout has passed without any activity)
            // this is where you'd log them out
            // Idle.interrupt();
            if ($scope.user.smartSession !== undefined && $scope.user.smartSession.isLLUMC) {
                window.open('', '_self', '');
                window.close();
            }
            $scope.watchingIdle = false;
            userService.logout();
        });

        $scope.$on('IdleEnd', function () {
            // the user has come back from AFK and is doing stuff. if you are warning them, you can use this to hide the dialog
        });

        $scope.$on('Keepalive', function () {
            // do something to keep the user's session alive
            userService.checkLogin().then(function () {
            });
        });


        if ($localStorage.lang === undefined) {
            $localStorage.lang = 1;
        }
        $scope.language = $localStorage.lang;
        $scope.langs = helper.getLangs();
        for (var i = 0; i < $scope.langs.length; i++) {
            if ($scope.langs[i].id === Number($scope.language)) {
                $translate.use($scope.langs[i].code);
            }
        }
        $scope.languageChanged = function (language) {
            for (var i = 0; i < $scope.langs.length; i++) {
                if ($scope.langs[i].id === Number(language)) {
                    $translate.use($scope.langs[i].code);
                }
            }
            $localStorage.lang = language;
        };
        $scope.goToLink = function (link, idx, toggle) {
            if (toggle) {
                $scope.toggleSidenav('left');
            }
            if (idx === undefined) {
                $location.path(link);
            } else if (idx === 0) {
                $location.path(link).search({p: 0});
            } else {
                $location.path(link).search({p: 1});
            }
        };
        $scope.hideHeaders = false;
        userService.checkLogin().then(function () {
        }, function () {
        });
        $scope.acpMenu = [{
            link: '/dashboard',
            title: 'Back to Registry',
            icon: 'arrow_back',
        }, {
            link: '/acp-billing',
            title: 'ACP Dashboard',
            icon: 'receipt'
        }, {
            link: '/acp-note',
            title: 'New Visit Note',
            icon: 'add'
        }, {
            link: '/acp-faqs',
            title: 'ACP Billing FAQs',
            icon: 'question_answer'
        }, {
            link: '/acp-guide',
            title: 'Conversation Guide',
            icon: 'help'
        }];
        $scope.menu = [{
            link: '/dashboard',
            title: 'Registry',
            icon: 'dashboard',
            index: 0
        }, {
            link: '/dashboard',
            title: 'New POLST',
            icon: 'add',
            index: 1
        }, {
            link: '/acp-billing',
            title: 'ACP Billing',
            icon: 'receipt',
            billing_active: true,
            test_only: true
        }, {
            link: '/pending-forms',
            title: 'Pending Forms',
            icon: 'tab_unselected',
            nonMD: true
        }, {
            link: '/viewSignable',
            title: 'Pending Forms',
            icon: 'tab_unselected',
            onlyMD: true
        }, {
            link: '/view-signed-forms',
            title: 'View Signed Forms',
            icon: 'assignment',
            onlyMD: true
        }, {
            link: '/mdlookup',
            title: 'Physician Lookup',
            icon: 'person',
            index: undefined
        }, {
            link: '/view-admins',
            title: 'View Admins',
            icon: 'work'
        }, {
            link: '/user-settings',
            title: 'SMS Authentication',
            icon: 'settings'
        }, {
            link: '/select-preferred-org',
            title: 'Change Affiliation',
            icon: 'settings',
            hide: true
        }, {
            link: '/notes',
            title: 'Announcements',
            icon: 'announcement'
        }, {
            link: '/help',
            title: 'Help',
            icon: 'help'
        }];
        $scope.analyticsMenu = [{
            link: '/analytics/dashboard',
            title: 'Analytics',
            icon: 'multiline_chart',
            index: undefined
        }, {
            link: '/analytics/detailed-query',
            title: 'Detailed Analytics',
            icon: 'multiline_chart',
            index: undefined
        }, {
            link: '/analytics/view-polst',
            title: 'View Forms',
            icon: 'folder',
            index: 2
        }, {
            link: '/analytics/form-submission',
            title: 'Form Submissions'
            // }, {
            //     link: '/adminforms',
            //     title: 'View Forms',
            //     icon: 'cloud',
            //     index: 0
        }];
        $scope.adminMenu = [{
            link: '/analytics/dashboard',
            title: 'Analytics',
            icon: 'multiline_chart',
            index: undefined
        }, {
            link: '/analytics/detailed-query',
            title: 'Detailed Analytics',
            icon: 'multiline_chart',
            index: undefined
        }, {
            link: '/usermgr',
            title: 'Manage Users',
            icon: 'supervisor_account',
            index: 0
        }, {
            link: '/usermgr',
            title: 'Add User',
            icon: 'add',
            index: 1
        }, {
            link: '/analytics/view-polst',
            title: 'View Forms',
            icon: 'folder',
            index: 2
        }, {
            link: '/analytics/form-submission',
            title: 'Form Submissions'
            // }, {
            //     link: '/adminforms',
            //     title: 'View Forms',
            //     icon: 'cloud',
            //     index: 0
        }];
        $scope.dataEntryMenu = [{
            link: '/data-entry/upload',
            title: 'Upload Forms',
            icon: 'file_upload',
            index: undefined
        }, {
            link: '/data-entry/list',
            icon: 'folder_open',
            title: 'Form Processing',
            index: undefined
        }, {
            link: '/data-entry/patient-matching',
            title: 'MADiE Patient Matches'
        }];
        $scope.superUserMenu = [{
            link: '/super/orgs',
            icon: 'supervisor_account',
            title: 'Organization Admin',
            index: undefined
        }, {
            link: '/super-user/manage-all-users',
            icon: 'supervised_user_circle',
            title: 'Account Admin',
            index: undefined
        }, {
            link: '/super-user/form-submission',
            title: 'Form Submissions',
            index: undefined
        }, {
            link: '/super-user/view-epolst',
            title: 'View ePOLST Forms'
        }];
        $scope.showWelcome = false;
        $scope.user = {};
        $scope.user.fname = "";
        $scope.watchingIdle = false;
        var uploadAdded = false;
        $rootScope.$on('rootScope:login', function (event, data) {
            if (!$scope.watchingIdle) {
                Idle.watch();
                $scope.watchingIdle = true;
            }
            $scope.user = data;
            if ($scope.user.organizations.length > 1) {
                for (var i = 0; i < $scope.menu.length; i++) {
                    if ($scope.menu[i].link === '/select-preferred-org') {
                        $scope.menu[i].hide = false;
                    }
                }
            }
            for (var ii = 0; ii < $scope.user.organizations.length; ii++) {
                if ($scope.user.organizations[ii].id === $localStorage.pref_org_id) {
                    $scope.pref_org = $scope.user.organizations[ii];
                }
            }
            $scope.isLoggedIn = true;
        });
        $rootScope.$on('rootScope:changeOrg', function (event, data) {
            for (var ii = 0; ii < $scope.user.organizations.length; ii++) {
                if ($scope.user.organizations[ii].id === $localStorage.pref_org_id) {
                    $scope.pref_org = $scope.user.organizations[ii];
                }
            }
        });
        $rootScope.$on('rootScope:logout', function (event, data) {
            if ($scope.isLoggedIn) {
                console.log('isSmart = ', userService.getIsSmart());
                if (userService.getIsSmart()) {
                    $location.path('/smartTimeout');
                } else {
                    $location.path('/');
                }
            }
            $scope.isLoggedIn = false;
        });
        $scope.closePopup = function () {
        };
        $scope.logout = function () {
            userService.logout();
            $scope.user = undefined;
            $location.search({});
            console.log('isSmart = ', userService.getIsSmart());
            if (userService.getIsSmart()) {
                $location.path('/smartTimeout');
            } else {
                $location.path('/');
            }
            window.reload();
        };
        $scope.mode = 'registry';
        $scope.$on('$routeChangeSuccess', function () {
            if ($location.path().indexOf('/acp-') === 0) {
                $scope.mode = 'acp';
            } else {
                $scope.mode = 'registry';
            }
            if ($location.path() === "/" || $location.path().indexOf("/home") === 0) {
                $rootScope.pageTitle = "Care Directives -- Sign In";
                $scope.hideHeaders = true;
                $scope.hideFooters = false;
            } else if ($location.path().indexOf("/view-pdf") === 0) {
                $scope.hideHeaders = true;
                $rootScope.pageTitle = "ADiE -- POLST Form";
            } else if ($location.path().indexOf("/forgotpassword") === 0) {
                $rootScope.pageTitle = "Care Directives -- Forgot Password";
                $scope.hideHeaders = true;
                $scope.hideFooters = false;
            } else if ($location.path().indexOf("/setpassword") === 0) {
                $scope.hideHeaders = true;
                $rootScope.pageTitle = "Care Directives -- Set Password";
                $scope.hideFooters = false;
            } else if ($location.path().indexOf("/privacy") === 0) {
                $scope.hideHeaders = true;
                $scope.hideFooters = true;
                $rootScope.pageTitle = "Care Directives -- Privacy Policy";
            } else if ($location.path().indexOf("/contact") === 0) {
                $scope.hideHeaders = true;
                $scope.hideFooters = true;
                $rootScope.pageTitle = "Care Directives -- Contact Us";
            } else if ($location.path().indexOf("/terms") === 0) {
                $scope.hideHeaders = true;
                $scope.hideFooters = true;
                $rootScope.pageTitle = "Care Directives -- Terms of Use";
            }
            else if ($location.path() === "/register") {
                $scope.hideHeaders = true;
                $rootScope.pageTitle = "Care Directives -- Register";
            } else if ($location.path().indexOf("/dashboard") === 0) {
                $scope.hideHeaders = false;
                $rootScope.pageTitle = "Care Directives -- Dashboard";
                $scope.hideFooters = false;
            } else if ($location.path().indexOf("addform") === 0) {
                $rootScope.pageTitle = "Care Directives -- New POLST";
                $scope.hideHeaders = false;
                $scope.hideFooters = false;
            } else if ($location.path().indexOf("/usermgr") === 0) {
                $rootScope.pageTitle = "Care Directives -- Manage Users";
                $scope.hideHeaders = false;
                $scope.hideFooters = false;
            } else if ($location.path().indexOf("/adminforms") === 0) {
                $rootScope.pageTitle = "Care Directives -- Manage Users";
                $scope.hideHeaders = false;
                $scope.hideFooters = false;
            } else {
                $scope.hideHeaders = false;
                $scope.hideFooters = false;
            }
            $scope.updateBadges();
        });
        $scope.updateBadges = function () {
            $scope.numPending = 0;
            $scope.menu[3].badgeCtr = 0;
            $scope.menu[4].badgeCtr = 0;
            console.log($scope.user);
            if ($scope.isLoggedIn && $scope.user.smartSession === undefined && !$scope.user.phyiscian) {
                userService.getNumPending($scope.user.sessionID).then(function (response) {
                    $scope.numPending = response.data.num;
                    $scope.menu[3].badgeCtr = $scope.numPending;
                });
            }
            if ($scope.isLoggedIn && $scope.user.smartSession === undefined && $scope.user.phyiscian) {
                userService.numToSign().then(function (response) {
                    $scope.numPending = response.data.num;
                    $scope.menu[4].badgeCtr = $scope.numPending;
                });
            }
        };
        $rootScope.$on('rootScope:updateBadges', function (event, data) {
            $scope.updateBadges();
        });
        $scope.toggleSidenav = function (menuId) {
            $mdSidenav(menuId).toggle();
        };
        $scope.logout = function () {
            userService.logout();
            $scope.user = undefined;
            $scope.isLoggedIn = false;
            $location.search({});
            console.log('isSmart = ', userService.getIsSmart());
            if (userService.getIsSmart()) {
                $location.path('/smartTimeout');
            } else {
                $location.path('/');
            }
        };
    };
    HeaderCtrl.$inject = ['$scope', 'userService', 'helper', '$location', '$rootScope', '$mdSidenav', '$localStorage', '$translate', 'Idle', 'Keepalive', '$mdDialog'];
    return HeaderCtrl;
});

