define('home/contact.controller',['angular'], function (angular) {
    'use strict';
    var ContactCtrl = function ($scope, userService) {

        $scope.contact = false;
        $scope.contactUs = function (valid) {
            $scope.regSubmitted = true;
            if (valid) {
                $scope.isProcessing = true;
                $scope.myPromise = userService.contactUs($scope.credentials, $scope).then(function () {
                    $scope.isProcessing = false;
                    $scope.contact = true;
                });
            }
        };
    };
    ContactCtrl.$inject = ['$scope', 'userService'];
    return ContactCtrl;
});

