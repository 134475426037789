define('dashboard/toast.controller',['angular'], function (angular) {
    'use strict';
    var ToastCtrl = function ($scope, $mdToast, credentials, $mdDialog, orgs, admin) {
        var isDlgOpen = false;
        $scope.closeToast = function ($event) {
            if (isDlgOpen)
                return;
            $mdToast.hide().then(function () {
                isDlgOpen = false;
            });
            $event.stopPropagation();
        };
        $scope.openMoreInfo = function (e) {
            if (isDlgOpen)
                return;
            isDlgOpen = true;
            $mdDialog.show({
                controller: 'UserDetailsCtrl',
                templateUrl: 'user/userDetails.html',
                locals: {user: credentials, orgs: orgs, admin: admin},
                parent: document.body,
                targetEvent: e,
                clickOutsideToClose: true,
                fullscreen: true
            }).then(function (response) {
                isDlgOpen = false;
            });

        };
    };
    ToastCtrl.$inject = ['$scope', '$mdToast', 'credentials', '$mdDialog', 'orgs', 'admin'];
    return ToastCtrl;
});

