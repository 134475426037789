define('user/analytics-signup.controller',['angular'], function (angular) {
    'use strict';
    var AnalyticsSignupCtrl = function ($scope, helper, userService, $location, $document) {
        $scope.isProd = window.isProd;
        $scope.error = false;
        $scope.success = false;
        $scope.submitted = false;
        $scope.signupUser = function (credentials) {
            $scope.error = false;
            if (!$scope.submitted) {
                $scope.submitted = true;
                $scope.myPromsie = userService.signupAnalyticsUser(credentials).then(function (response) {
                    $scope.success = true;
                    console.log('response', response);
                }, function (error) {
                    $scope.submitted = false;
                    console.log('error', error);
                    $scope.error = true;
                    $scope.errorTxt = error.data;
                });
            }
        };
    };
    AnalyticsSignupCtrl.$inject = ['$scope', 'helper', 'userService', '$location', '$document'];
    return AnalyticsSignupCtrl;
});

