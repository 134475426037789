define('analytics/analytics.services',['angular', 'common'], function (angular) {
    'use strict';
    var mod = angular.module('analytics.services', ['com.cd.common']);
    mod.factory('analyticsService', ['$http', '$q', 'playRoutes', '$localStorage', function ($http, $q, playRoutes, $localStorage) {
        return {
            queriesByDate: function (sid, allDates, startDate, endDate, byEncounterDepartment, byFacility) {
                return playRoutes.com.cd.controllers.Analytics.queriesByDate().post({
                    sid: sid,
                    byEncounterDepartment: byEncounterDepartment,
                    byFacility: byFacility,
                    allDates: allDates,
                    startDate: startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate(),
                    endDate: endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate(),
                    pref_org_id: $localStorage.pref_org_id
                }).then(function (response) {
                    return response.data;
                });
            },
            formSubmissionsByDate: function (sid, allDates, startDate, endDate) {
                return playRoutes.com.cd.controllers.Analytics.getFormSubmissionsByDate().post({
                    sid: sid,
                    allDates: allDates,
                    startDate: startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate(),
                    endDate: endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate(),
                    pref_org_id: $localStorage.pref_org_id
                }).then(function (response) {
                    return response.data;
                });
            },
            APIqueriesByDate: function (sid, allDates, startDate, endDate) {
                return playRoutes.com.cd.controllers.Analytics.APIqueriesByDate().post({
                    sid: sid,
                    allDates: allDates,
                    startDate: startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate(),
                    endDate: endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate(),
                    pref_org_id: $localStorage.pref_org_id
                }).then(function (response) {
                    return response.data;
                });
            },
            detailedAPIqueriesByDate: function (sid, allDates, startDate, endDate, orgid) {
                return playRoutes.com.cd.controllers.Analytics.detailedAPIqueriesByDate().post({
                    sid: sid,
                    allDates: allDates,
                    startDate: startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate(),
                    endDate: endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate(),
                    orgId: orgid
                }).then(function (response) {
                    return response.data;
                });
            },
            getOrgPendingForms: function () {
                return playRoutes.com.cd.controllers.Analytics.getOrgPendingForms().post({
                    sid: $localStorage.sid,
                    pref_org_id: $localStorage.pref_org_id
                });
            },
            getEPolstForms: function(){
                return playRoutes.com.cd.controllers.SuperAdmin.getEPolstForms().post({
                    sid: $localStorage.sid
                });
            },
            getPolstForms: function () {
                return playRoutes.com.cd.controllers.Forms.getPolstForms().post({
                    sid: $localStorage.sid,
                    pref_org_id: $localStorage.pref_org_id
                });
            },
            getRejectedADs: function(){
                return playRoutes.com.cd.controllers.Analytics.getRejectedADs().post({
                    sid: $localStorage.sid,
                    pref_org_id: $localStorage.pref_org_id
                });
            },
            getAcceptedADs: function(){
                return playRoutes.com.cd.controllers.Analytics.getAcceptedADs().post({
                    sid: $localStorage.sid,
                    pref_org_id: $localStorage.pref_org_id
                });
            },
            // getAdminABBYYForms: function () {
            //     return playRoutes.com.cd.controllers.DataEntry.getAdminABBYYForms().post({
            //         sid: $localStorage.sid,
            //         pref_org_id: $localStorage.pref_org_id
            //     });
            // }
            getAdminABBYYForms: function (sessionId, statusFilter) {
                var params = {sid: sessionId,
                    statusFilter: statusFilter,
                    pref_org_id: $localStorage.pref_org_id
                };
                return playRoutes.com.cd.controllers.DataEntry.getAdminABBYYForms().post(params).then
                (function (response) {
                    return response;
                });
            }

        };
    }]);
    return mod;
});

