define('analytics/detailed-query-analytics.controller',['angular'], function (angular) {
    'use strict';
    var DetailedQueryAnalyticsCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document, userService, dataEntryService, $window, $mdDialog, $sce, helper, analyticsService, user, superUserService, Papa) {
        $scope.user = user.data.User;
        var orgid = $localStorage.pref_org_id;
        if ($scope.user.is_super_user && $location.search()['oid'] !== undefined) {
            orgid = +$location.search()['oid'];
            superUserService.getOrg($scope.user.sessionID, orgid).then(function (r) {
                $scope.org = r.data;
                $scope.isHosAPI = ($scope.org.type === 'Hospital API' || $scope.org.type === 'API Test');
            });
        } else {
            $scope.isHosAPI = $scope.user.org.type === 'Hospital API';
        }
        var tmp = moment().subtract(1, 'days');
        $scope.startDate = tmp.toDate();
        $scope.endDate = moment().toDate();
        $scope.allDates = false;
        $scope.reportReady = false;
        $scope.exportReady = false;
        $scope.clearReport = function () {
            $scope.reportReady = false;
            $scope.exportReady = false;
        };
        $scope.detailedQuery = {page: 1, limit: 10};
        $scope.onPaginate = function (page, limit) {
            $scope.detailedQuery.page = page;
            $scope.detailedQuery.limit = limit;
            $scope.orderUsers();
        };
        $scope.orderUsers = function () {
            var start = (($scope.detailedQuery.page - 1) * $scope.detailedQuery.limit);
            var end = (($scope.detailedQuery.page) * $scope.detailedQuery.limit);
            $scope.view = [];
            for (var i = start; i < end && i < $scope.queries.length; ++i) {
                $scope.view.push($scope.queries[i]);
            }
        };
        $scope.view = [];
        $scope.runReport = function () {
            $scope.runningReport = true;
            analyticsService.detailedAPIqueriesByDate($scope.user.sessionID, $scope.allDates, $scope.startDate, $scope.endDate, orgid).then(function (r) {
                $scope.queries = [];
                for (var i = 0; i < r.length; i++) {
                    r[i].momentTimestamp = moment.utc(r[i].timestamp);
                    r[i].timestampFormatted = r[i].momentTimestamp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY')
                    $scope.queries.push(r[i]);
                }
                $scope.onReorder('num_searched');
                $scope.reportReady = true;
                $scope.onPaginate($scope.detailedQuery.page, $scope.detailedQuery.limit);
                $scope.runningReport = false;
            });
        };
        $scope.exportReady = false;
        $scope.downloadAnalytics = function () {
            var dataToDownload = $scope.queries;

            Papa.unparse(dataToDownload).then(function (r) {
                var blob = new Blob(["\ufeff", r]);
                var url = URL.createObjectURL(blob);
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = blob;
                    $scope.fileURL = 'analytics.csv';
                    window.navigator.msSaveOrOpenBlob(blob, 'analytics.csv');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(blob);
                }
                $scope.exportReady = true;
            });
        };
        $scope.reportTable = {
            order: ''
        };
        $scope.viewPatient = function (form, e) {
            $mdDialog.show({
                controller: 'EditPatientCtrl',
                templateUrl: 'dashboard/editPatient.html',
                locals: {form: form, user: $scope.user},
                parent: document.body,
                targetEvent: e,
                clickOutsideToClose: true,
                fullscreen: true
            }).then(function (response) {
                if (response) {
                    $mdToast.show({
                        hideDealy: 10000,
                        position: 'bottom right',
                        controller: 'ToastCtrl',
                        templateUrl: 'dashboard/patientSavedToast.html',
                        locals: {credentials: response, orgs: $scope.orgs, admin: $scope.user}
                    });
                }
            });
        };
        $scope.viewAD = function (id) {
            if ($scope.fileUrl !== undefined) {
                window.open($scope.fileAD_URL, "_blank");
            } else {
                userService.downloadADForm(id).then(function (response) {
                    var file = new Blob([response.data], {type: "application/pdf"});
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        $scope.msFileAD = file;
                        $scope.fileAD_URL = 'tmp.pdf';
                        // window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                        $scope.showDownload = true;
                        window.navigator.msSaveOrOpenBlob($scope.msFileAD, 'polst.pdf');
                    } else {
                        $scope.showDownload = true;
                        $scope.fileAD_URL = URL.createObjectURL(file);
                        window.open($scope.fileAD_URL, "_blank");
                    }
                });
            }
        };
        $scope.getForm = function (form) {
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                $scope.viewForm(form);
            } else {
                $scope.polstPDFBusy = userService.downloadForm(form.id_patient).then(function (response) {
                    var file = new Blob([response.data], {type: "application/pdf"});
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        $scope.msFile = file;
                        $scope.fileURL = 'tmp.pdf';
                        // window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                        $scope.showDownload = true;
                    } else {
                        $scope.fileURL = URL.createObjectURL(file);
                    }
                    $scope.viewForm(form);
                });
            }
        };
        $scope.viewForm = function (form) {
            // window.open('/#/view-pdf?id=' + form.id + '&sid=' + $scope.user.sessionID, 'POLST', 'width=800');
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.open('/#/view-pdf?id=' + form.id + '&sid=' + $scope.user.sessionID, 'POLST', 'width=1000,resizable=1');
            } else {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob($scope.msFile, 'polst.pdf');
                } else {
                    window.open($scope.fileURL, "_blank");
                }
            }
        };
        $scope.openForm = function (id) {
            $location.path('/dashboard');
            $location.search({p: 1, f: id});
        };
        $scope.onReorder = function (orderBy) {
            console.log(orderBy);
            if (orderBy === 'fname') {
                $scope.queries.sort(function (a, b) {
                    if (a.fname === undefined && b.fname === undefined) {
                        return 0;
                    } else if (a.fname === undefined) {
                        return 1;
                    } else if (b.fname === undefined) {
                        return -1;
                    }
                    return a.fname.toUpperCase() > b.fname.toUpperCase() ? 1 : a.fname.toUpperCase() < b.fname.toUpperCase() ? -1 : 0;
                });
            } else if (orderBy === '-fname') {
                $scope.queries.sort(function (a, b) {
                    if (a.fname === undefined && b.fname === undefined) {
                        return 0;
                    } else if (a.fname === undefined) {
                        return -1;
                    } else if (b.fname === undefined) {
                        return 1;
                    }
                    return a.fname.toUpperCase() > b.fname.toUpperCase() ? -1 : a.fname.toUpperCase() < b.fname.toUpperCase() ? 1 : 0;
                });
            } else if(orderBy === 'mrn') {
                $scope.queries.sort(function (a, b) {
                    if (a.mrn === undefined && b.mrn === undefined) {
                        return 0;
                    } else if (a.mrn === undefined) {
                        return 1;
                    } else if (b.mrn === undefined) {
                        return -1;
                    } else {
                        return a.mrn > b.mrn ? -1 : a.mrn < b.mrn ? 1 : 0;
                    }
                });
            }else if(orderBy === '-mrn'){
                $scope.queries.sort(function (a, b) {
                    if (a.mrn === undefined && b.mrn === undefined) {
                        return 0;
                    } else if (a.mrn === undefined) {
                        return -1;
                    } else if (b.mrn === undefined) {
                        return 1;
                    } else {
                        return a.mrn > b.mrn ? 1 : a.mrn < b.mrn ? -1 : 0;
                    }
                });
            } else if (orderBy === 'timestamp') {
                console.log('order by timestamp?');
                $scope.queries.sort(function (a, b) {
                    if (a.timestamp === undefined && b.timestamp === undefined) {
                        return 0;
                    } else if (a.timestamp === undefined) {
                        return 1;
                    } else if (b.timestamp === undefined) {
                        return -1;
                    } else {
                        if (a.momentTimestamp.isBefore(b.momentTimestamp)) {
                            return 1;
                        } else if (a.momentTimestamp.isAfter(b.momentTimestamp)) {
                            return -1;
                        } else {
                            return 0;
                        }
                    }
                });
            } else if (orderBy === '-timestamp') {
                $scope.queries.sort(function (a, b) {
                    if (a.timestamp === undefined && b.timestamp === undefined) {
                        return 0;
                    } else if (a.timestamp === undefined) {
                        return -1;
                    } else if (b.timestamp === undefined) {
                        return 1;
                    } else {
                        if (a.momentTimestamp.isBefore(b.momentTimestamp)) {
                            return -1;
                        } else if (a.momentTimestamp.isAfter(b.momentTimestamp)) {
                            return 1;
                        } else {
                            return 0;
                        }
                    }
                });
            } else if (orderBy === 'lname') {
                $scope.queries.sort(function (a, b) {
                    if (a.lname === undefined && b.lname === undefined) {
                        return 0;
                    } else if (a.lname === undefined) {
                        return 1;
                    } else if (b.lname === undefined) {
                        return -1;
                    }
                    return a.lname.toUpperCase() > b.lname.toUpperCase() ? 1 : a.lname.toUpperCase() < b.lname.toUpperCase() ? -1 : 0;
                });
            } else if (orderBy === '-lname') {
                $scope.queries.sort(function (a, b) {
                    if (a.lname === undefined && b.lname === undefined) {
                        return 0;
                    } else if (a.lname === undefined) {
                        return -1;
                    } else if (b.lname === undefined) {
                        return 1;
                    }
                    return a.lname.toUpperCase() > b.lname.toUpperCase() ? -1 : a.lname.toUpperCase() < b.lname.toUpperCase() ? 1 : 0;
                });
            }
            else if (orderBy === 'querier') {
                $scope.queries.sort(function (a, b) {
                    if (a.querierFname + a.querierLname === undefined && b.querierFname + b.querierLname === undefined) {
                        return 0;
                    } else if (a.querierFname + a.querierLname === undefined) {
                        return 1;
                    } else if (b.querierFname + b.querierLname === undefined) {
                        return -1;
                    }
                    return (a.querierFname.toUpperCase() + a.querierLname.toUpperCase()) > (b.querierFname.toUpperCase() + b.querierLname.toLocaleUpperCase()) ? 1 :
                        (a.querierFname.toUpperCase() + a.querierLname.toUpperCase()) < (b.querierFname.toUpperCase() + b.querierLname.toLocaleUpperCase()) ? -1 : 0;
                });
            } else if (orderBy === '-querier') {
                $scope.queries.sort(function (a, b) {
                    if (a.querierFname + a.querierLname === undefined && b.querierFname + b.querierLname === undefined) {
                        return 0;
                    } else if (a.querierFname + a.querierLname === undefined) {
                        return -1;
                    } else if (b.querierFname + b.querierLname === undefined) {
                        return 1;
                    }
                    return a.querierFname.toUpperCase() + a.querierLname.toUpperCase() > b.querierFname.toUpperCase() + b.querierLname.toLocaleUpperCase() ? -1
                        : a.querierFname.toUpperCase() + a.querierLname.toUpperCase() < b.querierFname.toUpperCase() + b.querierLname.toLocaleUpperCase() ? 1 : 0;
                });
            }
            else if (orderBy === 'dob') {
                $scope.queries.sort(function (a, b) {
                    if (a.dob === undefined && b.dob === undefined) {
                        return 0;
                    } else if (a.dob + a.dob === undefined) {
                        return 1;
                    } else if (b.dob + b.dob === undefined) {
                        return -1;
                    }
                    var atmp = new Date(a.dob);
                    var btmp = new Date(b.dob);
                    return atmp > btmp ? 1 : atmp < btmp ? -1 : 0;
                });
            } else if (orderBy === '-dob') {
                $scope.queries.sort(function (a, b) {
                    if (a.dob === undefined && b.dob === undefined) {
                        return 0;
                    } else if (a.dob + a.dob === undefined) {
                        return -1;
                    } else if (b.dob === undefined) {
                        return 1;
                    }
                    var atmp2 = new Date(a.dob);
                    var btmp2 = new Date(b.dob);
                    return atmp2 > btmp2 ? -1 : atmp2 < btmp2 ? 1 : 0;
                });
            }
            $scope.orderUsers();
        };
    };
    DetailedQueryAnalyticsCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'analyticsService', 'user', 'superUserService', 'Papa'];
    return DetailedQueryAnalyticsCtrl;
});

