define('acp-billing/acp-note.controller',['angular'], function (angular) {
    'use strict';
    var ACPNoteCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document, userService, dataEntryService, $window, $mdDialog, $sce, helper, analyticsService, user, Papa, acpBillingService) {
        $scope.user = user.data.User;
        $scope.visit = {};
        $scope.states = helper.getStates();
        $scope.invalidVisitDate = true;
        $scope.validateVisitDate = function (dateStr) {
            $scope.invalidVisitDate = !helper.validateDate(dateStr);
        };
        $scope.invalidEffectiveDate = false;
        $scope.validateEffectiveDate = function (dateStr) {
            if (dateStr.length > 0) {
                $scope.invalidEffectiveDate = !helper.validateDate(dateStr);
            } else {
                $scope.invalidEffectiveDate = false;
            }
        };
        $scope.validateCreateDOB = function (dateStr) {
            $scope.invalidCreateDOB = !helper.validateDate(dateStr);
        };
        $scope.editInsurance = function(){
            $mdDialog.show({
                controller: 'EditInsuranceAcpCtrl',
                templateUrl: 'acp-billing/edit-insurance-acp.html',
                locals: {visit: $scope.visit},
                parent: document.body,
                clickOutsideToClose: true,
                fullscreen: true
            }).then(function (response) {
                if (response) {
                    $scope.visit.plan_name = response.visit.plan_name;
                    $scope.visit.insured_id = response.visit.insured_id;
                    $scope.visit.member_id = response.visit.member_id;
                    $scope.visit.effective_date = response.visit.effective_date;
                    $mdToast.show({
                        hideDealy: 12000,
                        position: 'bottom right',
                        controller: 'GenericToastCtrl',
                        templateUrl: 'dashboard/generic-toast.html',
                        locals: {msg: 'Insurance information updated'}
                    });
                }
            });
        };
        $scope.newNote = function(){
            $scope.old_visit = $scope.visit;
            $scope.read_only = false;
            $scope.sign = false;
            $scope.visit = {};
            $scope.visit.fname = $scope.old_visit.fname;
            $scope.visit.lname = $scope.old_visit.lname;
            $scope.visit.mname = $scope.old_visit.mname;
            $scope.visit.street = $scope.old_visit.street;
            $scope.visit.city = $scope.old_visit.city;
            $scope.visit.state = $scope.old_visit.state;
            $scope.visit.zip = $scope.old_visit.zip;
            $scope.visit.dob = $scope.old_visit.dob;
            $scope.visit.gender = $scope.old_visit.gender;
            $scope.visit.phone = $scope.old_visit.phone;
            $scope.visit.ssn = $scope.old_visit.ssn;
            $scope.visit.plan_name = $scope.old_visit.plan_name;
            $scope.visit.insured_id = $scope.old_visit.insured_id;
            $scope.visit.group_id = $scope.old_visit.group_id;
            $scope.visit.effective_date = $scope.old_visit.effective_date;
            $scope.esMdSigned = false;
            $scope.invalidVisitDate = true;
            $mdToast.show({
                hideDealy: 12000,
                position: 'bottom right',
                controller: 'GenericToastCtrl',
                templateUrl: 'dashboard/generic-toast.html',
                locals: {msg: 'New note selected, please complete and sign the note.'}
            });
        };
        $scope.addAddendum = function(){
            $mdDialog.show({
                controller: 'AddAddendumCtrl',
                templateUrl: 'acp-billing/add-addendum.html',
                locals: {visit: $scope.visit},
                parent: document.body,
                clickOutsideToClose: true,
                fullscreen: true
            }).then(function (response) {
                if (response) {
                    if($scope.visit.addendums === undefined){
                        $scope.visit.addendums = [];
                    }
                    $scope.visit.addendums.unshift({note: response.note, created: moment.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY')});
                    $mdToast.show({
                        hideDealy: 12000,
                        position: 'bottom right',
                        controller: 'GenericToastCtrl',
                        templateUrl: 'dashboard/generic-toast.html',
                        locals: {msg: 'Addendum added'}
                    });
                }
            });
        };
        $scope.updateHasAD = function () {
            $scope.visit.has_ad = $scope.visit.yn_has_ad === 'yes';
            if (!$scope.visit.has_ad) {
                $scope.visit.yn_is_ad_in_emr = undefined;
                $scope.visit.yn_proxy_identified = undefined;
                $scope.updateHasAdInEMR();
                $scope.updateProxyIdentified();
                $scope.visit.proxy_phone = '';
                $scope.visit.proxy_name = '';
                $scope.visit.proxy_relationship = '';
            }
        };
        $scope.updateHasMedicalOrder = function () {
            $scope.visit.has_medical_order = $scope.visit.yn_has_medical_order === 'yes';
        };
        $scope.updateHasMedicalSurrogate = function(){
            $scope.visit.has_healthcare_surrogate    = $scope.visit.yn_has_healthcare_surrogate === 'yes';
        };
        $scope.updateIsMedicalOrderInEMR = function () {
            $scope.visit.is_medical_order_in_emr = $scope.visit.yn_is_medical_order_in_emr === 'yes';
        };
        $scope.updateHasAdInEMR = function () {
            $scope.visit.is_ad_in_emr = $scope.visit.yn_is_ad_in_emr === 'yes';
        };
        $scope.updateProxyIdentified = function () {
            $scope.visit.proxy_identified = $scope.visit.yn_proxy_identified === 'yes';
        };
        $scope.formSubmitted = false;
        $scope.valid_capacity = true;
        $scope.read_only = false;
        $scope.edit = false;
        $scope.genders = [{
            id: 1,
            name: "MALE"
        }, {
            id: 2,
            name: "FEMALE"
        }, {
            id: 3,
            name: 'UNDIFFERENTIATED'
        }];

        $scope.id = Number($routeParams.id);
        $scope.sign = Number($routeParams.sign) === 1 ? true : false;
        if ($scope.id !== undefined && $scope.id > 0) {
            $scope.read_only = true;
            $scope.busyMsg = 'Loading Note ...';
            $scope.myPromise = acpBillingService.getVisit($scope.id).then(function (d) {
                $scope.esMdSigned = false;
                $scope.visit = d.data;
                if($scope.visit.addendums !== undefined){
                    for(var i = 0; i < $scope.visit.addendums.length; i++){
                        $scope.visit.addendums[i].createdTS = moment.utc($scope.visit.addendums[i].created);
                        $scope.visit.addendums[i].created =$scope.visit.addendums[i].createdTS.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY');
                    }
                }
                if($scope.visit.md_signed_date !== undefined) {
                    $scope.visit.tmpts = moment.utc($scope.visit.md_signed_date);
                    $scope.visit.md_signed_date = $scope.visit.tmpts.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY');
                }
                if($scope.visit.nurse_signed_date !== undefined) {
                    $scope.visit.tmpts = moment.utc($scope.visit.nurse_signed_date);
                    $scope.visit.nurse_signed_date = $scope.visit.tmpts.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY');
                }
                $scope.visit.visit_date = $scope.visit.visit_date.substr(0, 10);
                $scope.invalidVisitDate = false;
                if ($scope.visit.prev_other_present !== undefined) {
                    $scope.visit.prev_other_chk = true;
                }
                if ($scope.visit.has_capacity) {
                    $scope.visit.decisionMakingCapacity = 'hasCap';
                } else {
                    $scope.visit.decisionMakingCapacity = 'lacksCap';
                }
            });
        }
        $scope.editMode = function () {
            $scope.read_only = false;
            $scope.edit = true;
        };
        $scope.signVisitMD = function(){
            $scope.busyMsg = 'Signing Note ...';
            $scope.myPromise = acpBillingService.signVisitMD($scope.visit).then(function () {
                $location.path('/acp-billing');
            });
        };
        $scope.saveNote = function () {
            $scope.busyMsg = 'Saving Note ...';
            $scope.formSubmitted = true;
            if ($scope.visit.decisionMakingCapacity === 'hasCap') {
                $scope.visit.has_capacity = true;
                $scope.valid_capacity = true;
            } else if ($scope.visit.decisionMakingCapacity === 'lacksCap') {
                $scope.visit.has_capacity = false;
                $scope.valid_capacity = true;
            } else {
                $scope.valid_capacity = false;
            }

            if ($scope.visit.fname !== undefined && $scope.visit.lname !== undefined && $scope.visit.dob !== undefined && !$scope.invalidCreateDOB
                && $scope.valid_capacity && $scope.visit.conversation_duration !== undefined && $scope.esMdSigned) {
                $scope.myPromise = acpBillingService.saveVisit($scope.visit).then(function () {
                    $location.path('/acp-billing').search({});
                });
            } else {
                $mdToast.show({
                    templateUrl: 'dashboard/FormErrToast.html', position: 'bottom', hideDelay: 30000,
                    controller: 'ToastFormErrCtrl', parent: $document[0].querySelector('#buttonID')

                });
            }
        };
        $scope.updateNote = function () {
            $scope.formSubmitted = true;
            if ($scope.visit.decisionMakingCapacity === 'hasCap') {
                $scope.visit.has_capacity = true;
                $scope.valid_capacity = true;
            } else if ($scope.visit.decisionMakingCapacity === 'lacksCap') {
                $scope.visit.has_capacity = false;
                $scope.valid_capacity = true;
            } else {
                $scope.valid_capacity = false;
            }
            if ($scope.visit.fname !== undefined && $scope.visit.lname !== undefined && $scope.visit.dob !== undefined && !$scope.invalidCreateDOB
                && $scope.valid_capacity && $scope.visit.conversation_duration !== undefined && $scope.esMdSigned) {
                $scope.myPromise = acpBillingService.updateVisit($scope.id, $scope.visit).then(function () {
                    $scope.read_only = true;
                });
            } else {
                $mdToast.show({
                    templateUrl: 'dashboard/FormErrToast.html', position: 'bottom', hideDelay: 30000,
                    controller: 'ToastFormErrCtrl', parent: $document[0].querySelector('#buttonID')

                });
            }
        };
        $scope.clearEasyScriptMdSig = function () {
            $scope.esMdSigned = false;
            setTimeout(function () {
                var ctx = $scope.canvas.getContext("2d");
                ctx.fillStyle = '#FFFFFF';
                ctx.fillRect(0, 0, $scope.canvas.width, $scope.canvas.height);
            }, 100);
        };
        $scope.signMD = function () {
            $scope.esMdSigned = true;
            $scope.visit.physSigURL = $scope.canvas.toDataURL('image/png');
        };
        setTimeout(function () {
            $scope.connectEasyScript('physSignatureContainer');
        }, 1);
        $scope.connectEasyScript = function (id) {
            var prevPoint;
            console.log('connecting to EasyScript device ...');
            //Get the canvas element from the document.
            $scope.canvas = $document[0].getElementById(id).children[0];
            // var canvas = $document[0].getElementById(id);
            //Create a new ScriptelEasyScript handler
            $scope.scriptel = new ScriptelEasyScript();
            //Adds a signature listener to the document that will automatically catch
            //any signatures "typed" within the document body.
            //
            //WARNING!!!!!
            //If you are creating pop-up window (modal) and
            //are registering the listener to document make sure you
            //call removeSignatureListener when the modal is
            //destroyed otherwise the library will continue listening.
            $scope.scriptel.addSignatureListener($document[0]);
            // scriptel.addSignatureListener(document);
            var ctx3 = $scope.canvas.getContext("2d");
            ctx3.fillStyle = '#FFFFFF';
            ctx3.fillRect(0, 0, $scope.canvas.width, $scope.canvas.height);

            //Adds a streaming callback to the EasyScript library,
            //this gets notified when events happen.
            $scope.scriptel.registerStreamingCallback(function (e) {
                switch (e.type) {
                    case "ScriptelSignatureMetaData":
                        var ctx2 = $scope.canvas.getContext("2d");
                        ctx2.fillStyle = '#FFFFFF';
                        ctx2.fillRect(0, 0, $scope.canvas.width, $scope.canvas.height);
                        break;
                    case "ScriptelNewStroke":
                        prevPoint = null;
                        break;
                    case "ScriptelCoordinate":
                        if (prevPoint != null) {
                            // prevPoint.y = prevPoint.y - 5;
                            $scope.scriptel.drawSegmentOnCanvas(prevPoint, e, $scope.canvas, {
                                "strokeStyle": "black",
                                "lineWidth": 2
                            });
                        }
                        prevPoint = e;
                        break;
                    case "ScriptelCancelSignature":
                        var ctx = $scope.canvas.getContext("2d");
                        ctx.fillStyle = '#FFFFFF';
                        ctx.fillRect(0, 0, $scope.canvas.width, $scope.canvas.height);
                        prevPoint = null;
                        break;
                    case "ScriptelSignatureComplete":
                        if ($scope.mode === 'pt-sig') {
                            $scope.sign();
                        } else if ($scope.mode === 'md-sig') {
                            $scope.signMD();
                        }
                        $scope.$apply();
                        break;
                    case "ScriptelCardSwipe":
                        break;

                }
            });
        };
    };
    ACPNoteCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'analyticsService', 'user', 'Papa', 'acpBillingService'];
    return ACPNoteCtrl;
});

