define('data-entry/verify-view.controller',['angular'], function (angular) {
    'use strict';
    var dobMismatch = function ($scope, afform, $mdDialog) {
        $scope.afform = afform;
        $scope.cancel = function () {
            $mdDialog.hide();
        };
    };
    dobMismatch.$inject = ['$scope', 'afform', '$mdDialog'];
    var DataVerifyViewCtrl = function ($scope, userService, user, dataEntryService, $routeParams, helper, $filter, Papa, $mdDialog, $location, $mdToast, $document) {


        $scope.listView = function () {
            $location.path('/data-entry/list');
        };
        $scope.user = user.data.User;
        $scope.searching = false;
        $scope.pdfViewed = false;
        $scope.viewCurrent = function () {
            userService.downloadFormById($scope.afform.polst.id).then(function (r) {
                var file = new Blob([r.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });
        };
        $scope.viewNew = function () {
            dataEntryService.getABBYYPDF($scope.user.sessionID, $scope.af.id).then(function (r) {
                var file = new Blob([r.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });
        };
        dataEntryService.getABBYYForm($scope.user.sessionID, Number($routeParams.afid)).then(function (response) {
            $scope.af = response.data;
            if ($scope.af.is_pt_upload && $scope.af.patientID > 0) {
                $scope.ptSearchDone = true;
                $scope.patientSelected = true;
                $scope.ptSearchDone = true;
                $scope.patientMatched = true;
                dataEntryService.getPatient($scope.user.sessionID, $scope.af.patientID).then(function (response) {
                    var patient = response.data;
                    $scope.afform.patientID = $scope.af.patientID;
                    $scope.afform.PatientDOB1 = patient.dob;
                    $scope.afform.dob = patient.dob;
                    $scope.afform.fname = patient.fname;
                    $scope.afform.lname = patient.lname;
                    $scope.afform.mname = patient.mname;
                    $scope.afform.street = patient.street;
                    $scope.afform.city = patient.city;
                    $scope.afform.state = patient.state;
                    $scope.afform.zip = patient.zip;
                    $scope.afform.phone = patient.phone;
                    $scope.phone = patient.phone;
                    $scope.afform.ssn = patient.ssn;
                    $scope.afform.gender = patient.gender;
                    if(patient.gender === 'MALE'){
                        $scope.afform.gender = 'Male';
                    } else if(patient.gender === 'FEMALE'){
                        $scope.afform.gender = 'Female';
                    }
                    $scope.afform.afid = $scope.af.id;
                    $scope.afform.polst = patient;
                    updateLabels();
                    dataEntryService.getABBYYPDF($scope.user.sessionID, $scope.af.id).then(function (response) {
                        $scope.file = new Blob([response.data], {type: "application/pdf"});
                    });
                    $scope.myField = {
                        label: $scope.af.afNotes === undefined ? '' : $scope.af.afNotes.replace(new RegExp('\\n', 'g'), '<br/>')
                    };
                    Papa.parse($scope.af.csvData, {}).then(function (r) {
                        var arrlength = r.data[0].length;
                        for (var j = 0; j < arrlength; j++) {
                            $scope.afform[r.data[0][j]] = r.data[1][j];
                        }
                        // convert to POLST format
                        // demographics
                        $scope.mrn = $scope.afform.MedicalRecordNumber;
                        if($scope.mrn === undefined || $scope.mrn.length === 0){
                            $scope.mrn = $scope.af.mrn;
                        }
                        // $scope.afform.gender = $scope.afform.Gender;
                        $scope.afform.provLicenseNumber = $scope.afform.PhysicianLicense;
                        $scope.afform.physSigDate = $scope.afform.PhysicianSigDate;
                        $scope.afform.PtPhone = $scope.afform.PtPhone === undefined ? '' : $filter('tel')($scope.afform.PtPhone.replace(/[^0-9]/g, ''), false);
                        // PT signature info
                        $scope.afform.sigName = $scope.afform.PatientDecisionMakerPrint;
                        $scope.afform.PtDMPhoneNumber = $scope.afform.PtDMPhoneNumber === undefined ? '' : $filter('tel')($scope.afform.PtDMPhoneNumber.replace(/[^0-9]/g, ''), false);
                        $scope.afform.sigPhone = $scope.afform.PtDMPhoneNumber;
                        $scope.afform.sigDate = $scope.afform.PtDMSigDate;
                        $scope.afform.sigRelationship = $scope.afform.PatientRelationship;
                        var sigAddr = $scope.afform.PtDMAddress;
                        var sigStreet = "";
                        var sigCity = undefined;
                        var sigState = undefined;
                        var sigZip = undefined;
                        var arr = sigAddr === undefined ? [] : sigAddr.split(" ");
                        if (arr.length > 0) {
                            var matches = sigAddr.match(/\d+$/);
                            var hasZip = false;
                            if (matches) {
                                hasZip = true;
                            }
                            for (var j = arr.length - 1; j >= 0; j--) {
                                if (hasZip) {
                                    if (j === arr.length - 1) {
                                        sigZip = arr[j];
                                    } else if (j === arr.length - 2) {
                                        sigState = arr[j];
                                    } else if (j === arr.length - 3) {
                                        sigCity = arr[j];
                                    } else {
                                        sigStreet = arr[j] + " " + sigStreet;
                                    }
                                } else {
                                    if (j === arr.length - 1) {
                                        sigState = arr[j];
                                    } else if (j === arr.length - 2) {
                                        sigCity = arr[j];
                                    } else {
                                        sigStreet = arr[j] + " " + sigStreet;
                                    }
                                }
                            }
                        }
                        sigStreet = sigStreet.trim();
                        if (sigStreet.length === 0) {
                            sigStreet = undefined;
                        }
                        $scope.afform.sigStreet = sigStreet;
                        $scope.afform.sigCity = sigCity;
                        $scope.afform.sigState = sigState;
                        $scope.afform.sigZip = sigZip;
                        // physician info
                        $scope.afform.PhysicianPhone = $scope.afform.PhysicianPhone === undefined ? '' : $filter('tel')($scope.afform.PhysicianPhone.replace(/[^0-9]/g, ''), false);
                        $scope.afform.supervisingPhys = $scope.afform.NPPASupervisingName;
                        $scope.afform.provName = $scope.afform.PrintDRName;
                        $scope.afform.provPhone = $scope.afform.PhysicianPhone;
                        // Preparer
                        $scope.afform.creatorName = $scope.afform.PreparerName;
                        $scope.afform.PreparerPhone = $scope.afform.PreparerPhone === undefined ? '' : $filter('tel')($scope.afform.PreparerPhone.replace(/[^0-9]/g, ''), false);
                        $scope.afform.creatorPhone = $scope.afform.PreparerPhone;
                        // HC Agent info
                        $scope.afform.HCAgentPhone = $scope.afform.HCAgentPhone === undefined ? '' : $filter('tel')($scope.afform.HCAgentPhone.replace(/[^0-9]/g, ''), false);
                        $scope.afform.agentName = $scope.afform.HCAgentName;
                        $scope.afform.agentPhone = $scope.afform.HCAgentPhone;
                        // additional contact
                        $scope.afform.contactName = $scope.afform.AdditionalContactName;
                        $scope.afform.contactTitle = $scope.afform.AdditionalContactRelationship;
                        $scope.afform.AdditionalContactPhone = $scope.afform.AdditionalContactPhone === undefined ? '' : $filter('tel')($scope.afform.AdditionalContactPhone.replace(/[^0-9]/g, ''), false);
                        $scope.afform.contactPhone = $scope.afform.AdditionalContactPhone;
                        if ($scope.afform.AdditionalContact.toLowerCase() === 'yes') {
                            $scope.afform.additionalContactNone = true;
                        } else {
                            $scope.afform.additionalContactNone = false;
                        }
                        // POLST specific
                        $scope.afform.foodAddOrders = $scope.afform.C_AdditionalOrders;
                        $scope.afform.advDate = $scope.afform.AD_Date_Added;
                        $scope.afform.created = $scope.afform.DateFormPrepared;
                        $scope.afform.addOrders = $scope.afform.B_AdditionalOrders;
                        $scope.afform.secA = $scope.afform.secB = $scope.afform.secC = $scope.afform.secD1 = $scope.afform.secD2 = -1;
                        if ($scope.afform.CPR.toLowerCase() === 'attempt') {
                            $scope.afform.secA = 0;
                        } else if ($scope.afform.CPR.toLowerCase() === 'donotattempt' || $scope.afform.CPR.toLowerCase() === 'do not attempt') {
                            $scope.afform.secA = 1;
                        }
                        if ($scope.afform.MedInterventions.toLowerCase() === 'fulltreatment') {
                            $scope.afform.secB = 0;
                        } else if ($scope.afform.MedInterventions.toLowerCase() === 'selectivetreatment') {
                            $scope.afform.secB = 1;
                        } else if ($scope.afform.MedInterventions.toLowerCase() === 'comfortbasedtreatment') {
                            $scope.afform.secB = 2;
                        }
                        if ($scope.afform.TrialPeriod.toLowerCase() === 'yes') {
                            $scope.afform.secB1a = true;
                        } else {
                            $scope.afform.secB1a = false;
                        }
                        if ($scope.afform.RequestTransfer.toLowerCase() === 'yes') {
                            $scope.afform.secB2a = true;
                        } else {
                            $scope.afform.secB2a = false;
                        }
                        if ($scope.afform.ArtificiallyAdministeredNutrition.toLowerCase() === 'longterm') {
                            $scope.afform.secC = 0;
                        } else if ($scope.afform.ArtificiallyAdministeredNutrition.toLowerCase() === 'trial') {
                            $scope.afform.secC = 1;
                        } else if ($scope.afform.ArtificiallyAdministeredNutrition.toLowerCase() === 'noartificial') {
                            $scope.afform.secC = 2;
                        }
                        if ($scope.afform.DiscussedWith.toLowerCase() === 'legallyrecognizeddecisionmaker') {
                            $scope.afform.secD1 = 1;
                        } else if ($scope.afform.DiscussedWith.toLowerCase() === 'ptcapacity') {
                            $scope.afform.secD1 = 0;
                        }
                        if ($scope.afform.AdvancedDirective.toLowerCase() === 'advanceddirectivedated') {
                            $scope.afform.secD2 = 0;
                        } else if ($scope.afform.AdvancedDirective.toLowerCase() === 'advanceddirectivenotavailable') {
                            $scope.afform.secD2 = 1;
                        } else if ($scope.afform.AdvancedDirective.toLowerCase() === 'noadvanceddirective' || $scope.afform.AdvancedDirective.toLowerCase() === 'no advance directive') {
                            $scope.afform.secD2 = 2;
                        }
                        // validate dates
                        // $scope.validateDate($scope.afform.dob);
                        // $scope.validateDate1($scope.afform.PatientDOB1);
                        $scope.validateDatePrepared($scope.afform.created);
                        $scope.validatePhysSigDate($scope.afform.physSigDate);
                        $scope.validatePatientSigDate($scope.afform.sigDate);
                        if ($scope.afform.advDate.length > 0) {
                            $scope.validateCreateAdvDate($scope.afform.advDate);
                        }
                        var tmp = moment.utc($scope.afform.created);
                        $scope.currentDate = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
                        var newDate = moment($scope.afform.created);
                        $scope.newDate = $scope.afform.created;
                        if (newDate.isBefore(tmp)) {
                            $scope.archiveRec = true;
                        } else {
                            $scope.archiveRec = false;
                        }
                        $mdDialog.show({
                            controller: 'ArchiveOrPublishPolstCtrl',
                            templateUrl: 'data-entry/archive-or-publish-polst.html',
                            locals: {afform: $scope.afform},
                            parent: document.body,
                            clickOutsideToClose: false,
                            fullscreen: true
                        }).then(function (response) {
                            if (response !== undefined) {
                                if (response.reject === true) {
                                    $scope.rejectForm();
                                } else {
                                    $scope.publish = response.publish;
                                    $scope.selectMD();
                                }
                            } else {
                                $scope.selectMD();
                            }
                        });
                    });
                });
            } else {

                $scope.afform = {};
                $scope.afform.afid = $scope.af.id;
                updateLabels();
                dataEntryService.getABBYYPDF($scope.user.sessionID, $scope.af.id).then(function (response) {
                    $scope.file = new Blob([response.data], {type: "application/pdf"});
                });
                $scope.myField = {
                    label: $scope.af.afNotes === undefined ? '' : $scope.af.afNotes.replace(new RegExp('\\n', 'g'), '<br/>')
                };
                Papa.parse($scope.af.csvData, {}).then(function (r) {
                    var arrlength = r.data[0].length;
                    for (var j = 0; j < arrlength; j++) {
                        $scope.afform[r.data[0][j]] = r.data[1][j];
                    }
                    // convert to POLST format
                    // demographics
                    $scope.mrn = $scope.afform.MedicalRecordNumber;
                    if($scope.mrn === undefined || $scope.mrn.length === 0){
                        $scope.mrn = $scope.af.mrn;
                    }
                    $scope.afform.gender = $scope.afform.Gender;
                    if ($scope.afform.gender === '') {
                        $scope.afform.gender = 'Unknown';
                    }
                    $scope.afform.dob = $scope.afform.PatientDOB;
                    $scope.afform.provLicenseNumber = $scope.afform.PhysicianLicense;
                    $scope.afform.physSigDate = $scope.afform.PhysicianSigDate;
                    $scope.afform.street = $scope.afform.PtStreet;
                    $scope.afform.city = $scope.afform.PtCity;
                    $scope.afform.state = $scope.afform.PtState;
                    $scope.afform.zip = $scope.afform.PtZip;
                    $scope.afform.fname = $scope.afform.FirstName;
                    $scope.afform.lname = $scope.afform.LastName;
                    $scope.afform.mname = $scope.afform.MiddleName;
                    $scope.afform.PtPhone = $scope.afform.PtPhone === undefined ? '' : $filter('tel')($scope.afform.PtPhone.replace(/[^0-9]/g, ''), false);
                    $scope.afform.phone = $scope.afform.PtPhone;
                    $scope.afform.ssn = $scope.afform.PtSSN;
                    // PT signature info
                    $scope.afform.sigName = $scope.afform.PatientDecisionMakerPrint;
                    $scope.afform.PtDMPhoneNumber = $scope.afform.PtDMPhoneNumber === undefined ? '' : $filter('tel')($scope.afform.PtDMPhoneNumber.replace(/[^0-9]/g, ''), false);
                    $scope.afform.sigPhone = $scope.afform.PtDMPhoneNumber;
                    $scope.afform.sigDate = $scope.afform.PtDMSigDate;
                    $scope.afform.sigRelationship = $scope.afform.PatientRelationship;
                    var sigAddr = $scope.afform.PtDMAddress;
                    var sigStreet = "";
                    var sigCity = undefined;
                    var sigState = undefined;
                    var sigZip = undefined;
                    var arr = sigAddr === undefined ? [] : sigAddr.split(" ");
                    if (arr.length > 0) {
                        var matches = sigAddr.match(/\d+$/);
                        var hasZip = false;
                        if (matches) {
                            hasZip = true;
                        }
                        for (var j = arr.length - 1; j >= 0; j--) {
                            if (hasZip) {
                                if (j === arr.length - 1) {
                                    sigZip = arr[j];
                                } else if (j === arr.length - 2) {
                                    sigState = arr[j];
                                } else if (j === arr.length - 3) {
                                    sigCity = arr[j];
                                } else {
                                    sigStreet = arr[j] + " " + sigStreet;
                                }
                            } else {
                                if (j === arr.length - 1) {
                                    sigState = arr[j];
                                } else if (j === arr.length - 2) {
                                    sigCity = arr[j];
                                } else {
                                    sigStreet = arr[j] + " " + sigStreet;
                                }
                            }
                        }
                    }
                    sigStreet = sigStreet.trim();
                    if (sigStreet.length === 0) {
                        sigStreet = undefined;
                    }
                    $scope.afform.sigStreet = sigStreet;
                    $scope.afform.sigCity = sigCity;
                    $scope.afform.sigState = sigState;
                    $scope.afform.sigZip = sigZip;
                    // physician info
                    $scope.afform.PhysicianPhone = $scope.afform.PhysicianPhone === undefined ? '' : $filter('tel')($scope.afform.PhysicianPhone.replace(/[^0-9]/g, ''), false);
                    $scope.afform.supervisingPhys = $scope.afform.NPPASupervisingName;
                    $scope.afform.provName = $scope.afform.PrintDRName;
                    $scope.afform.provPhone = $scope.afform.PhysicianPhone;
                    // Preparer
                    $scope.afform.creatorName = $scope.afform.PreparerName;
                    $scope.afform.PreparerPhone = $scope.afform.PreparerPhone === undefined ? '' : $filter('tel')($scope.afform.PreparerPhone.replace(/[^0-9]/g, ''), false);
                    $scope.afform.creatorPhone = $scope.afform.PreparerPhone;
                    // HC Agent info
                    $scope.afform.HCAgentPhone = $scope.afform.HCAgentPhone === undefined ? '' : $filter('tel')($scope.afform.HCAgentPhone.replace(/[^0-9]/g, ''), false);
                    $scope.afform.agentName = $scope.afform.HCAgentName;
                    $scope.afform.agentPhone = $scope.afform.HCAgentPhone;
                    // additional contact
                    $scope.afform.contactName = $scope.afform.AdditionalContactName;
                    $scope.afform.contactTitle = $scope.afform.AdditionalContactRelationship;
                    $scope.afform.AdditionalContactPhone = $scope.afform.AdditionalContactPhone === undefined ? '' : $filter('tel')($scope.afform.AdditionalContactPhone.replace(/[^0-9]/g, ''), false);
                    $scope.afform.contactPhone = $scope.afform.AdditionalContactPhone;
                    if ($scope.afform.AdditionalContact.toLowerCase() === 'yes') {
                        $scope.afform.additionalContactNone = true;
                    } else {
                        $scope.afform.additionalContactNone = false;
                    }
                    // POLST specific
                    $scope.afform.foodAddOrders = $scope.afform.C_AdditionalOrders;
                    $scope.afform.advDate = $scope.afform.AD_Date_Added;
                    $scope.afform.created = $scope.afform.DateFormPrepared;
                    $scope.afform.addOrders = $scope.afform.B_AdditionalOrders;
                    $scope.afform.secA = $scope.afform.secB = $scope.afform.secC = $scope.afform.secD1 = $scope.afform.secD2 = -1;
                    if ($scope.afform.CPR.toLowerCase() === 'attempt') {
                        $scope.afform.secA = 0;
                    } else if ($scope.afform.CPR.toLowerCase() === 'donotattempt' || $scope.afform.CPR.toLowerCase() === 'do not attempt') {
                        $scope.afform.secA = 1;
                    }
                    if ($scope.afform.MedInterventions.toLowerCase() === 'fulltreatment') {
                        $scope.afform.secB = 0;
                    } else if ($scope.afform.MedInterventions.toLowerCase() === 'selectivetreatment') {
                        $scope.afform.secB = 1;
                    } else if ($scope.afform.MedInterventions.toLowerCase() === 'comfortbasedtreatment') {
                        $scope.afform.secB = 2;
                    }
                    if ($scope.afform.TrialPeriod.toLowerCase() === 'yes') {
                        $scope.afform.secB1a = true;
                    } else {
                        $scope.afform.secB1a = false;
                    }
                    if ($scope.afform.RequestTransfer.toLowerCase() === 'yes') {
                        $scope.afform.secB2a = true;
                    } else {
                        $scope.afform.secB2a = false;
                    }
                    if ($scope.afform.ArtificiallyAdministeredNutrition.toLowerCase() === 'longterm') {
                        $scope.afform.secC = 0;
                    } else if ($scope.afform.ArtificiallyAdministeredNutrition.toLowerCase() === 'trial') {
                        $scope.afform.secC = 1;
                    } else if ($scope.afform.ArtificiallyAdministeredNutrition.toLowerCase() === 'noartificial') {
                        $scope.afform.secC = 2;
                    }
                    if ($scope.afform.DiscussedWith.toLowerCase() === 'legallyrecognizeddecisionmaker') {
                        $scope.afform.secD1 = 1;
                    } else if ($scope.afform.DiscussedWith.toLowerCase() === 'ptcapacity') {
                        $scope.afform.secD1 = 0;
                    }
                    if ($scope.afform.AdvancedDirective.toLowerCase() === 'advanceddirectivedated') {
                        $scope.afform.secD2 = 0;
                    } else if ($scope.afform.AdvancedDirective.toLowerCase() === 'advanceddirectivenotavailable') {
                        $scope.afform.secD2 = 1;
                    } else if ($scope.afform.AdvancedDirective.toLowerCase() === 'noadvanceddirective' || $scope.afform.AdvancedDirective.toLowerCase() === 'no advance directive') {
                        $scope.afform.secD2 = 2;
                    }
                    // validate dates
                    $scope.validateDate($scope.afform.dob);
                    $scope.validateDate1($scope.afform.PatientDOB1);
                    $scope.validateDatePrepared($scope.afform.created);
                    $scope.validatePhysSigDate($scope.afform.physSigDate);
                    $scope.validatePatientSigDate($scope.afform.sigDate);
                    if ($scope.afform.advDate.length > 0) {
                        $scope.validateCreateAdvDate($scope.afform.advDate);
                    }
                    if ($scope.afform.dob !== $scope.afform.PatientDOB1) {
                        $mdDialog.show({
                            controller: dobMismatch,
                            templateUrl: 'data-entry/dobMismatch.html',
                            locals: {afform: $scope.afform},
                            parent: document.body,
                            clickOutsideToClose: false,
                            fullscreen: true
                        }).then(function () {

                        }, function () {

                        });

                    }
                });
            }
        });
        $scope.rejectForm = function () {
            $mdDialog.show({
                controller: 'RejectPolstCtrl',
                templateUrl: 'data-entry/reject-polst.html',
                locals: {afform: $scope.afform, pid: $scope.afform.patientID, afid: $scope.af.id, af: $scope.af, mrn: $scope.mrn},
                parent: document.body,
                clickOutsideToClose: false,
                fullscreen: true
            }).then(function (response) {
                if (response !== undefined && response.rejected) {
                    $scope.listView();
                }
            });
        };
        $scope.viewPDF = function () {
            userService.downloadForm($scope.afform.form.id).then(function (response) {
                var file = new Blob([response.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });
        };
        $scope.states = helper.getStates();
        $scope.form = {};
        $scope.genders = [{
            id: 1,
            name: "MALE"
        }, {
            id: 2,
            name: "FEMALE"
        }, {
            id: 3,
            name: 'UNDIFFERENTIATED'
        }];
        $scope.clearSecA = function () {
            $scope.afform.secA = -1;
        };
        $scope.clearSecB = function () {
            $scope.afform.secB = -1;
            $scope.afform.secB1a = false;
            $scope.afform.secB2a = false;
        };
        $scope.clearSecC = function () {
            $scope.afform.secC = -1;
        };
        $scope.clearSecD = function () {
            $scope.afform.secD1 = -1;
        };
        $scope.clearSecAD = function () {
            $scope.afform.secD2 = -1;
        };
        $scope.validateCreateAdvDate = function (dateStr) {
            $scope.invalidCreateAdvDate = !helper.validateDate(dateStr) && $scope.afform.advDate.length > 0;
        };
        $scope.validateDatePrepared = function (dateStr) {
            $scope.invalidDatePrepared = !helper.validateDate(dateStr);
        };
        $scope.validatePatientSigDate = function (dateStr) {
            $scope.invalidPatientSigDate = !helper.validateDate(dateStr) && $scope.afform.sigDate.length > 0;
        };
        $scope.validatePhysSigDate = function (dateStr) {
            $scope.invalidPhysSigDate = !helper.validateDate(dateStr) && $scope.afform.physSigDate.length > 0;
        };
        $scope.validateDate1 = function (dateStr) {
            $scope.invalidDOB1 = !helper.validateDate(dateStr);
        };
        $scope.validateDate = function (dateStr) {
            $scope.invalidDOB = !helper.validateDate(dateStr);
        };
        var aoChange = false;
        $scope.taChanged = function () {
            aoChange = true;
        };
        var updateLabels = function () {
            helper.updateAFLabels($scope, $filter, aoChange);
        };
        $scope.viewPDF = function () {
            $scope.pdfViewed = true;

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                $scope.msFile = $scope.file;
                $scope.fileURL = 'tmp.pdf';
                window.navigator.msSaveOrOpenBlob($scope.file, 'polst.pdf');
                $scope.showDownload = true;
            } else {
                $scope.showDownload = true;
                $scope.fileURL = URL.createObjectURL($scope.file);
                window.open($scope.fileURL, "_blank");
            }
        };
        $scope.selectMD = function () {
            $mdDialog.show({
                controller: 'SelectMDCtrl',
                templateUrl: 'data-entry/select-md.html',
                locals: {afform: $scope.afform},
                parent: document.body,
                clickOutsideToClose: false,
                fullscreen: true
            }).then(function (response) {
                if (response !== undefined) {
                    $scope.phys = response.phys;
                }
            });
        };
        $scope.searchPts = function () {
            if (!$scope.searching) {
                $scope.searching = true;
                userService.matchPatient({
                    lname: $scope.afform.lname,
                    fname: $scope.afform.fname,
                    mname: $scope.afform.mname,
                    street: $scope.afform.street,
                    city: $scope.afform.city,
                    state: $scope.afform.state,
                    zip: $scope.afform.zip,
                    dob: $scope.afform.dob,
                    ssn: $scope.afform.ssn,
                    gender: $scope.afform.gender,
                    ins: undefined,
                    phone: $scope.afform.phone === undefined ? undefined : $scope.afform.phone.replace(/[^0-9]/g, ''),
                    mrn: $scope.mrn,
                    orgid: $scope.af.orgid
                }).then(function (response) {
                    $scope.pts = response.data;
                    $scope.newPtDisabled = false;
                    for (var zz = 0; zz < $scope.pts.length; zz++) {
                        $scope.pts[zz].score = Math.round(($scope.pts[zz].score * 100)) + "%";
                        if ($scope.pts[zz].score === '100%' || $scope.pts[zz].score === '200%') {
                            $scope.newPtDisabled = true;
                        }
                    }
                    $scope.searching = false;
                    $scope.formHistory = undefined;
                    if ($scope.pts.length > 0) {
                        $mdDialog.show({
                            controller: 'SelectAFPatientCtrl',
                            templateUrl: 'data-entry/selectAFPatient.html',
                            locals: {pts: $scope.pts, polst: $scope.afform, newPtDisabled: $scope.newPtDisabled},
                            parent: document.body,
                            clickOutsideToClose: false,
                            fullscreen: true
                        }).then(function (response) {
                            if (response !== undefined && response.confirmed && response.patient !== undefined) {
                                $scope.afform.polst = response.patient.form;
                                $scope.patientSelected = true;
                                $scope.ptSearchDone = true;
                                $scope.patientMatched = true;
                                if (response.patient !== undefined) {
                                    $scope.afform.patientID = response.patient.id;
                                    var patient = response.patient;
                                    $scope.afform.PatientDOB1 = patient.dob;
                                    $scope.afform.dob = patient.dob;
                                    $scope.afform.fname = patient.fname;
                                    $scope.afform.lname = patient.lname;
                                    $scope.afform.mname = patient.mname;
                                    $scope.afform.street = patient.street;
                                    $scope.afform.city = patient.city;
                                    $scope.afform.state = patient.state;
                                    $scope.afform.zip = patient.zip;
                                    $scope.afform.phone = patient.phone;
                                    $scope.phone = patient.phone;
                                    $scope.afform.ssn = patient.ssn;
                                    $scope.afform.gender = patient.gender;
                                    dataEntryService.getPatientFormHistory($scope.user.sessionID, response.patient.id).then(function (response) {
                                        $scope.formHistory = response.data;
                                        for (var i = 0; i < $scope.formHistory.length; i++) {
                                            var tmp2 = moment.utc($scope.formHistory[i].created);
                                            $scope.formHistory[i].friendlyDate = tmp2.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
                                        }
                                        $scope.formHistory.sort(function (a, b) {
                                            var ad = moment(a.created);
                                            var bd = moment(b.created);
                                            if (ad.isAfter(bd)) {
                                                return -1;
                                            } else if (ad.isBefore(bd)) {
                                                return 1;
                                            } else {
                                                return 0;
                                            }
                                        });
                                    });
                                }
                                // removed as it overwrites ABBYY data
                                /* $scope.afform.sigName = $scope.afform.fname + " " + ($scope.afform.mname === undefined ? "" : $scope.afform.mname) + " " + $scope.afform.lname;
                                 $scope.afform.sigStreet = $scope.afform.street;
                                 $scope.afform.sigCity = $scope.afform.city;
                                 $scope.afform.sigState = $scope.afform.state;
                                 $scope.afform.sigZip = $scope.afform.zip;
                                 $scope.afform.sigPhone = $scope.afform.phone;*/
                                // show dialog to confirm what to do with this form.
                                var tmp = moment.utc($scope.afform.polst.created);
                                $scope.currentDate = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
                                var newDate = moment($scope.afform.created);
                                $scope.newDate = $scope.afform.created;
                                if (newDate.isBefore(tmp)) {
                                    $scope.archiveRec = true;
                                } else {
                                    $scope.archiveRec = false;
                                }
                                $mdDialog.show({
                                    controller: 'ArchiveOrPublishPolstCtrl',
                                    templateUrl: 'data-entry/archive-or-publish-polst.html',
                                    locals: {afform: $scope.afform},
                                    parent: document.body,
                                    clickOutsideToClose: false,
                                    fullscreen: true
                                }).then(function (response) {
                                    if (response !== undefined) {
                                        if (response.reject === true) {
                                            $scope.rejectForm();
                                        } else {
                                            $scope.publish = response.publish;
                                            $scope.selectMD();
                                        }
                                    } else {
                                        $scope.selectMD();
                                    }
                                });
                            } else if (response !== undefined && response.confirmed) {
                                $scope.selectMD();
                                $scope.searching = false;
                                $scope.patientSelected = true;
                                $scope.ptSearchDone = true;
                                $scope.patientMatched = true;
                                /*
                                $scope.afform.sigName = $scope.afform.fname + " " + ($scope.afform.mname === undefined ? "" : $scope.afform.mname) + " " + $scope.afform.lname;
                                $scope.afform.sigStreet = $scope.afform.street;
                                $scope.afform.sigCity = $scope.afform.city;
                                $scope.afform.sigState = $scope.afform.state;
                                $scope.afform.sigZip = $scope.afform.zip;
                                $scope.afform.sigPhone = $scope.afform.phone;
                                */
                            }
                        });
                    } else {
                        $scope.selectMD();
                        $scope.searching = false;
                        $scope.patientSelected = true;
                        $scope.ptSearchDone = true;
                        $scope.patientMatched = true;
                        /*
                        $scope.afform.sigName = $scope.afform.fname + " " + ($scope.afform.mname === undefined ? "" : $scope.afform.mname) + " " + $scope.afform.lname;
                        $scope.afform.sigStreet = $scope.afform.street;
                        $scope.afform.sigCity = $scope.afform.city;
                        $scope.afform.sigState = $scope.afform.state;
                        $scope.afform.sigZip = $scope.afform.zip;
                        $scope.afform.sigPhone = $scope.afform.phone;
                        */
                    }
                });
            }
        };
        $scope.viewForm = function (id) {
            userService.downloadFormById(id).then(function (r) {
                var file = new Blob([r.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });
        };
        $scope.markAsUploadError = function () {
            dataEntryService.markUploadError($scope.user.sessionID, $scope.af.id, null).then(function () {
                $scope.listView();
            });
        };
        var validContact = function () {
            if ($scope.afform.noProv || ($scope.afform.contactPhone !== undefined && $scope.afform.contactPhone.length > 0 && $scope.afform.contactTitle !== undefined && $scope.afform.contactTitle.length > 0 && $scope.afform.contactName !== undefined && $scope.afform.contactName.length > 0)) {
                return true;
            }
            return false;
        };
        var validAdvDate = function () {
            if ($scope.afform.secD2 !== '0' || ($scope.afform.advDate !== undefined && $scope.afform.advDate.length > 0 && !$scope.form.pform.advDate.$error.pattern && $scope.afform.agentName !== undefined && $scope.afform.agentName.length > 0 && $scope.afform.agentPhone !== undefined && $scope.afform.agentPhone.length > 0)) {
                return true;
            }
            return false;
        };
        $scope.creating = false;
        $scope.createForm = function (valid, ev) {
            $scope.formSubmitted = true;
            if ($scope.afform.secC === undefined) {
                $scope.afform.secC = -1;
            }
            if ($scope.phys !== undefined) {
                $scope.afform.physicianID = $scope.phys.userid;
            }
            if (!$scope.creating) {
                $scope.creating = true;
                $scope.myPromise = dataEntryService.publishABBYYForm($scope.afform, $scope.publish === 1, $scope.af, $scope.user.sessionID, $scope.mrn).then(function () {
                        $mdDialog.show($mdDialog.alert().title("Form created").textContent("This POLST form has been successfully published.").ok("Got it!").targetEvent(ev))
                            .then(function (response) {
                                $scope.creating = false;
                                $scope.listView();
                            }, function (e) {
                                $scope.craeting = false;
                            });
                    }
                );
            }
        };
    };
    DataVerifyViewCtrl.$inject = ['$scope', 'userService', 'user', 'dataEntryService', '$routeParams', 'helper', '$filter', 'Papa', '$mdDialog', '$location', '$mdToast', '$document'];
    return DataVerifyViewCtrl;
});

