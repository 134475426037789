define('dashboard/polst-form-view.controller',['angular'], function (angular) {
    'use strict';
    var PolstFormViewCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                      userService, dataEntryService, $window, $mdDialog, $sce, helper, superUserService, user) {
        $scope.user = user.data.User;
        $scope.addOrg = function () {
            $location.path('super-user/addOrg');
        };
        userService.getPolstForms.then($scope.user.sessionID).then(function (r) {
            $scope.polst = r.data;
            $scope.onReorder('name');
        });
        $scope.polstDetails = function (o) {
            $location.path('super-user/manage-polst').search({o: o.id});
        };
        $scope.onPaginate = function (page, limit) {
            $scope.polstTable.page = page;
            $scope.polstTable.limit = limit;
            $scope.reorderOrgs();
        };
        $scope.reorderOrgs = function () {
            var start = (($scope.polstTable.page - 1) * $scope.polstTable.limit);
            var end = start + $scope.polstTable.limit;
            $scope.polstView = [];
            for (var i = start; i < end && i < $scope.polsts.length; i++) {
                $scope.polstView.push($scope.polsts[i]);
            }
        };
        $scope.polstView = [];
        $scope.polstTable = {
            order: 'name',
            limit: 10,
            page: 1
        };
        $scope.onReorder = function (order) {
            if (order === '-name') {
                $scope.polsts.sort(function (a, b) {
                    return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : b.name.toLowerCase() > a.name.toLowerCase() ? 1 : 0;
                });
            } else if (order === 'name') {
                $scope.polsts.sort(function (a, b) {
                    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
                });
            } else if (order === '-type') {
                $scope.polsts.sort(function (a, b) {
                    return a.type.toLowerCase() > b.type.toLowerCase() ? -1 : b.type.toLowerCase() > a.type.toLowerCase() ? 1 : 0;
                });
            } else if (order === 'type') {
                $scope.polsts.sort(function (a, b) {
                    return a.type.toLowerCase() > b.type.toLowerCase() ? 1 : b.type.toLowerCase() > a.type.toLowerCase() ? -1 : 0;
                });
            }
            $scope.onPaginate($scope.polstTable.page, $scope.polstTable.limit);
        };
        $scope.searchTextChange = function () {
            $scope.polst = undefined;
            $scope.polstUsers = undefined;
        };
        $scope.selectedItemChange = function (item) {
            if (item !== undefined) {
                $scope.polst = item;
                superUserService.getOrgAdmins(item.id, $scope.user.sessionID).then(function (r) {
                    $scope.polstUsers = r;
                });
            }
        };
    };
    PolstFormViewCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'superUserService', 'user'];
    return PolstFormViewCtrl;
});

