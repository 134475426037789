define('data-entry/select-md.controller',['angular'], function (angular) {
    'use strict';
    var SelectMDCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                 userService, dataEntryService, $window, $mdDialog, $sce, helper, afform) {
        $scope.mdName = afform.provName;
        $scope.searchTextChange = function(txt){
            $scope.phys = undefined;
        };
        $scope.cancel = function(){
            $mdDialog.hide();
        };
        $scope.selectMd = function(){
            $mdDialog.hide({phys: $scope.phys});
        };
        $scope.cancelNoUser = function(){
            $mdDialog.hide({phys: undefined});
        };
        $scope.physicianSearch = function (txt) {
            if (txt.length >= 1) {
                return userService.searchPhysicians(txt).then(function (data) {
                    for (var z = 0; z < data.length; z++) {
                        data[z].fullname = data[z].firstname + " " + data[z].lastname;
                    }
                    return data;
                });
            }
            return [];
        };
    };
    SelectMDCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'afform'];
    return SelectMDCtrl;
});

