/**
 * Dashboard routes.
 */
define('acp-billing/acp-billing.routes',['angular', './acp-billing.controllers', 'common'], function (angular, controllers) {
    'use strict';
    var mod = angular.module('acp-billing.routes', ['com.cd.common']);
    mod
        .config([
            '$routeProvider',
            'userResolve',
            'adminResolve',
            'physResolve',
            'acpBillingResolve',
            'dataResolve',
            '$locationProvider',
            function ($routeProvider, userResolve, adminResolve, physResolve, acpBillingResolve, dataResolve, $locationProvider) {
                $locationProvider.hashPrefix('');
                $routeProvider.when(
                    '/acp-billing', {
                        templateUrl: 'acp-billing/billing-dashboard.html',
                        controller: controllers.BillingDashboardCtrl,
                        reloadOnSearch: false,
                        resolve: acpBillingResolve
                    }).when('/acp-faqs', {
                    templateUrl: 'acp-billing/acp-faqs.html',
                    controller: controllers.ACPFAQsCtrl,
                    resolve: acpBillingResolve
                }).when('/acp-signup', {
                    controller: controllers.ACPSignupCtrl,
                    templateUrl: 'acp-billing/acp-signup.html',
                    //TODO: add resolveer
                }).when('/acp-guide', {
                    controller: controllers.ACPGuideCtrl,
                    templateUrl: 'acp-billing/acp-guide.html',
                    resolve: userResolve
                }).when('/acp-note', {
                    controller: controllers.ACPNoteCtrl,
                    templateUrl: 'acp-billing/acp-note.html',
                    resolve: acpBillingResolve
                });
            }]);
    return mod;
});

