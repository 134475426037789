define('data-entry/archive-or-publish-polst.controller',['angular'], function (angular) {
    'use strict';
    var ArchiveOrPublishPolstCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                              userService, dataEntryService, $window, $mdDialog, $sce, helper, afform) {
        console.log(afform);
        var tmp = moment.utc(afform.polst.created);
        var polstID = afform.polst.id;
        var afID = afform.afid;
        $scope.afform = afform;
        $scope.user = userService.getUser();
        $scope.currentDate = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
        var newDate = moment(afform.created);
        $scope.newDate = afform.created;
        if (newDate.isBefore(tmp)) {
            $scope.archiveRec = true;
            $scope.publish = 0;
        } else {
            $scope.archiveRec = false;
            $scope.publish = 1;
        }
        dataEntryService.getPatientFormHistory($scope.user.sessionID, afform.patientID).then(function (response) {
            $scope.formHistory = response.data;
            for (var i = 0; i < $scope.formHistory.length; i++) {
                var tmp2 = moment.utc($scope.formHistory[i].created);
                $scope.formHistory[i].friendlyDate = tmp2.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
            }
            $scope.formHistory.sort(function (a, b) {
                var ad = moment(a.created);
                var bd = moment(b.created);
                if (ad.isAfter(bd)) {
                    return -1;
                } else if (ad.isBefore(bd)) {
                    return 1;
                } else {
                    return 0;
                }
            });
        });
        $scope.viewCurrent = function () {
            userService.downloadFormById(polstID).then(function (r) {
                var file = new Blob([r.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });
        };
        $scope.reject = function () {
            $mdDialog.hide({reject: true});
        };
        $scope.confirm = function () {
            $mdDialog.hide({publish: $scope.publish});
        };
        $scope.viewForm = function (id) {
            userService.downloadFormById(id).then(function (r) {
                var file = new Blob([r.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });
        };
        $scope.viewNew = function () {
            dataEntryService.getABBYYPDF($scope.user.sessionID, afID).then(function (r) {
                var file = new Blob([r.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    $scope.msFile = file;
                    $scope.fileURL = 'tmp.pdf';
                    window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    $scope.showDownload = true;
                } else {
                    $scope.showDownload = true;
                    $scope.fileURL = URL.createObjectURL(file);
                    window.open($scope.fileURL, "_blank");
                }
            });
        };
    };
    ArchiveOrPublishPolstCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'afform'];
    return ArchiveOrPublishPolstCtrl;
});

