define('dashboard/polst-create-dialog.controller',['angular'], function (angular) {
    'use strict';
    var PolstCreateDialogCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document, userService, $window, $mdDialog, $sce, helper, polst, user) {
        // one of 'pending', 'pt-sig', 'pt-signed', 'md-sig', 'md-signed'
        $scope.mode = 'pending';
        $scope.polst = polst;
        $scope.user = user;
        if ($scope.user.smartSession === undefined || !user.smartSession.isLLUMC) {
            if($scope.user.phyiscian || user.smartSession !== undefined){
                $scope.polst.physicianID = $scope.user.id;
                $scope.polst.provName = $scope.user.firstname + ' ' + $scope.user.lastname;
                $scope.polst.provPhone = $scope.user.phone;
                $scope.polst.provLicenseNumber = $scope.user.licenseNo;
            } else {
                $scope.polst.provName = $scope.polst.supervisingPhys;
            }
            console.log($scope.polst);
            if ($scope.polst.patient_signed) {
                $scope.mode = 'md-sig';
                setTimeout(function () {
                    $scope.connectEasyScript('physSignatureContainer');
                }, 1);
            } else {
                $scope.mode = 'pt-sig';
                setTimeout(function () {
                    $scope.connectEasyScript('ptSignatureContainer');
                }, 1);
            }
        } else {
            // $scope.polst.provName = $scope.polst.supervisingPhys;
            $scope.polst.provName = $scope.polst.supervisingPhys;
            if ($scope.polst.patient_signed) {
                $scope.mode = 'pt-signed';
            }
        }
        $scope.socket = {};
        $scope.mdSigReady = false;
        $scope.mdImage = {};
        $scope.ptSigReady = false;
        $scope.ptImage = {};
        $scope.saving = false;
        $scope.saveForm = function () {
            if (!$scope.saving) {
                var tmp = moment.utc($scope.polst.created, 'YYYY-MM-DD HH:mm:ss');
                if ($scope.polst.physSigDate === undefined && $scope.polst.physSigURL !== undefined) {
                    tmp = moment();
                    $scope.polst.physSigDate = tmp.format('MM/DD/YYYY');
                }
                $scope.saving = true;
                $scope.myPromise = userService.createPolst($scope.polst).then(function (r) {
                        $scope.saving = false;
                        $mdDialog.hide(r);
                    }, function (err) {
                        $scope.saving = false;
                    }
                );
            }
        };
        $scope.savePending = function () {
            $scope.saveForm();
        };
        $scope.savePendingMD = function () {
            $scope.saveForm();
        };
        $scope.proceedToMDSig = function () {
            $scope.mode = 'md-sig';
            $scope.socket.ready = false;
            if ($scope.user.smartSession !== undefined && $scope.user.smartSession.isLLUMC) {
                var port = "8443";
                if ($scope.user.smartSession.scriptel_port !== undefined) {
                    port = $scope.user.smartSession.scriptel_port;
                }
                $scope.socket.webSocketUrl = "wss://localhost:" + port;
                console.log('websocket url: ' + $scope.socket.webSocketUrl);
                $scope.socket.ready = false;
                setTimeout(function () {
                    console.log('setTimeout ....');
                    $scope.showedDisconnect = false;
                    $scope.connectSocket('physicianSigCanvas');
                }, 500);
            } else {
                setTimeout(function () {
                    $scope.showedDisconnect = false;
                    $scope.connectEasyScript('physSignatureContainer');
                }, 500);
            }
        };
        $scope.clearMDSig = function () {
            $scope.mdSigReady = false;
        };
        $scope.clearPtSig = function () {
            $scope.ptSigReady = false;
        };
        $scope.saveMDSig = function () {
            $scope.polst.physSigURL = $scope.mdImage.src;
            $scope.mode = 'md-signed';
            if ($scope.socket.socket !== undefined) {
                $scope.socket.socket.close();
            }
            $scope.saveForm();
        };
        $scope.savePtSig = function () {
            $scope.polst.sigURL = $scope.ptImage.src;
            $scope.mode = 'pt-signed';
            if ($scope.socket.socket !== undefined) {
                $scope.socket.socket.close();
            }
        };
        $scope.proceedToSigs = function () {
            $scope.mode = 'pt-sig';
            if ($scope.user.smartSession !== undefined && $scope.user.smartSession.isLLUMC) {
                var port = "8443";
                if ($scope.user.smartSession.scriptel_port !== undefined) {
                    port = $scope.user.smartSession.scriptel_port;
                }
                $scope.socket.webSocketUrl = "wss://localhost:" + port;
                console.log('websocket url: ' + $scope.socket.webSocketUrl);
                $scope.socket.ready = false;
                setTimeout(function () {
                    console.log('setTimeout ....');
                    $scope.showedDisconnect = false;
                    $scope.connectSocket('patientSigCanvas');
                }, 500);
            } else {
                setTimeout(function () {
                    $scope.connectEasyScript('ptSignatureContainer');
                }, 500);
            }
        };
        $scope.backFromMDSig = function () {
            if ($scope.socket.socket !== undefined) {
                $scope.socket.socket.close();
            }
            $scope.mode = 'pt-signed';
        };
        $scope.backFromPtSig = function () {
            if ($scope.socket.socket !== undefined) {
                $scope.socket.socket.close();
            }
            $scope.mode = 'pending';
        };
        $scope.esPtSignedNext = function () {
            console.log($scope.user);
            if ($scope.user.phyiscian) {
                $scope.mode = 'md-sig';
                setTimeout(function () {
                    $scope.connectEasyScript('physSignatureContainer');
                }, 1);
            } else {
                $scope.mode = 'pt-signed';
            }
        };
        $scope.clearEasyScriptMdSig = function () {
            $scope.esMdSigned = false;
            setTimeout(function () {
                var ctx = $scope.canvas.getContext("2d");
                ctx.fillStyle = '#FFFFFF';
                ctx.fillRect(0, 0, $scope.canvas.width, $scope.canvas.height);
            }, 100);
        };
        $scope.clearEasyScriptSig = function () {
            $scope.esPtSigned = false;
            setTimeout(function () {
                var ctx = $scope.canvas.getContext("2d");
                ctx.fillStyle = '#FFFFFF';
                ctx.fillRect(0, 0, $scope.canvas.width, $scope.canvas.height);
            }, 100);
        };
        $scope.esMdSignedNext = function () {
            $scope.saveForm();
        };
        $scope.esMdSigned = false;
        $scope.esPtSigned = false;
        $scope.sign = function () {
            $scope.esPtSigned = true;
            $scope.polst.sigURL = $scope.canvas.toDataURL('image/png');
        };
        $scope.signMD = function () {
            $scope.esMdSigned = true;
            $scope.polst.physSigURL = $scope.canvas.toDataURL('image/png');
        };
        $scope.connectEasyScript = function (id) {
            var prevPoint;
            console.log('connecting to EasyScript device ...');
            //Get the canvas element from the document.
            $scope.canvas = $document[0].getElementById(id).children[0];
            // var canvas = $document[0].getElementById(id);
            //Create a new ScriptelEasyScript handler
            $scope.scriptel = new ScriptelEasyScript();
            //Adds a signature listener to the document that will automatically catch
            //any signatures "typed" within the document body.
            //
            //WARNING!!!!!
            //If you are creating pop-up window (modal) and
            //are registering the listener to document make sure you
            //call removeSignatureListener when the modal is
            //destroyed otherwise the library will continue listening.
            $scope.scriptel.addSignatureListener($document[0]);
            // scriptel.addSignatureListener(document);
            var ctx3 = $scope.canvas.getContext("2d");
            ctx3.fillStyle = '#FFFFFF';
            ctx3.fillRect(0, 0, $scope.canvas.width, $scope.canvas.height);

            //Adds a streaming callback to the EasyScript library,
            //this gets notified when events happen.
            $scope.scriptel.registerStreamingCallback(function (e) {
                switch (e.type) {
                    case "ScriptelSignatureMetaData":
                        var ctx2 = $scope.canvas.getContext("2d");
                        ctx2.fillStyle = '#FFFFFF';
                        ctx2.fillRect(0, 0, $scope.canvas.width, $scope.canvas.height);
                        break;
                    case "ScriptelNewStroke":
                        prevPoint = null;
                        break;
                    case "ScriptelCoordinate":
                        if (prevPoint != null) {
                            // prevPoint.y = prevPoint.y - 5;
                            $scope.scriptel.drawSegmentOnCanvas(prevPoint, e, $scope.canvas, {
                                "strokeStyle": "black",
                                "lineWidth": 2
                            });
                        }
                        prevPoint = e;
                        break;
                    case "ScriptelCancelSignature":
                        var ctx = $scope.canvas.getContext("2d");
                        ctx.fillStyle = '#FFFFFF';
                        ctx.fillRect(0, 0, $scope.canvas.width, $scope.canvas.height);
                        prevPoint = null;
                        break;
                    case "ScriptelSignatureComplete":
                        if ($scope.mode === 'pt-sig') {
                            $scope.sign();
                        } else if ($scope.mode === 'md-sig') {
                            $scope.signMD();
                        }
                        $scope.$apply();
                        break;
                    case "ScriptelCardSwipe":
                        break;

                }
            });
        };
        $scope.connectSocket = function (id) {
            $scope.socket.ready = true;
            $scope.$apply();
            console.log('connecting to socket, cavnas id: ' + id);
            var canvas = $document[0].getElementById(id);
            if (canvas != null) {
                var ctx = canvas.getContext('2d');
                ctx.strokeStyle = 'black';
                var lastPoint = null;
                console.log('connecting to: ' + $scope.socket.webSocketUrl);
                var socket = new WebSocket($scope.socket.webSocketUrl);
                $scope.socket.socket = socket;
                socket.onopen = function () {
                    console.log("Web Socket Open");
                };
                socket.onclose = function () {
                    // window.alert('Signature pad has been disconnected');
                    console.log("Web Socket Closed");
                };
                socket.onmessage = function (evt) {
                    var msg = JSON.parse(evt.data);
                    console.log('got message: ', msg);
                    if (msg._class === "ConnectionOpen") {
                        //We've just connected to the server.
                        if (msg.serverInfo.devices.length > 0) {
                            $scope.showedDisconnect = false;
                            //Lets open the first available device:
                            var device = msg.serverInfo.devices[0];
                            console.log('device: ', device);
                            if (device.product.indexOf('ScripTouch') === -1) {
                                window.alert('Please move to a work station with a Scriptel device.');
                            }
                            console.log("Opening device", device.uuid, ": ", device.manufacturer, device.product);
                            var obj = {"_class": "DeviceOpenRequest", "uuid": device.uuid};
                            socket.send(JSON.stringify(obj));
                        } else {
                            window.alert('No signature pad was found.');
                        }
                    } else if (msg._class === 'ConnectionDeviceMembershipChange') {
                        if (msg.devices.devices.length > 0) {
                            $scope.showedDisconnect = false;
                            //Lets open the first available device:
                            var device2 = msg.devices.devices[0];
                            console.log('device: ', device2);
                            if (device2.product.indexOf('ScripTouch') === -1) {
                                window.alert('Please move to a work station with a ST1750 device.');
                            }
                            console.log("Opening device", device2.uuid, ": ", device2.manufacturer, device2.product);
                            var obj3 = {"_class": "DeviceOpenRequest", "uuid": device2.uuid};
                            socket.send(JSON.stringify(obj3));
                        } else {
                            window.alert('No signature pad was found.');
                        }

                    } else if (msg._class === 'DeviceCloseResponse') {
                        if (!$scope.showedDisconnect) {
                            $scope.showedDisconnect = true;
                            window.alert(msg.reason);
                        }
                    } else if (msg._class === "DeviceOpenResponse") {
                        //We've just connected to the device, lets send our preferred
                        //rendering settings
                        var obj2 = {
                            "_class": "RenderSettingsUpdateRequest",
                            "renderSettings":
                                {
                                    "_class": "RenderSettings",
                                    "type": "image/svg+xml",
                                    "scale": 1
                                }
                        };
                        socket.send(JSON.stringify(obj2));
                        //We need to size our canvas relative to the size of the screen on the device.
                        canvas.width = msg.device.displayInfo.width;
                        canvas.height = msg.device.displayInfo.height;
                        // canvas.width = device.displayInfo.width;
                        // canvas.height = device.displayInfo.height;
                        console.log("Set canvas size to ", canvas.width, "x", canvas.height);
                    } else if (msg._class === "PenDown") {
                        console.log('penDown');
                        lastPoint = msg;
                    } else if (msg._class === "PenUp" || msg._class === "PenMove") {
                        ctx.beginPath();
                        ctx.moveTo(lastPoint.x, lastPoint.y);
                        ctx.lineTo(msg.x, msg.y);
                        ctx.stroke();
                        lastPoint = msg;
                    } else if (msg._class === 'RenderedImage') {
                        // The server just sent us a signature
                        var img = {};
                        img.width = msg.width;
                        img.height = msg.height;
                        img.src = msg.data;
                        if (img.src !== 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnN0PSJodHRwOi8vd3d3LnNjcmlwdGVsLmNvbS94bWwvc3Znc2lnbmF0dXJlIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIyNDAiIGhlaWdodD0iNjQiPgo8L3N2Zz4K') {
                            if (id === 'patientSigCanvas') {
                                $scope.ptSigReady = true;
                                $scope.ptImage = img;
                                $scope.$apply();
                            } else if (id === 'physicianSigCanvas') {
                                $scope.mdSigReady = true;
                                $scope.mdImage = img;
                                $scope.$apply();
                            }
                        } else {
                            $scope.ptSigReady = false;
                            $scope.mdSigReady = false;
                            $scope.$apply();
                        }
                    } else if (msg._class === "ButtonPress") {
                        //A button was pressed, clear the screen.
                        if (msg.id !== 4) {
                            ctx.fillStyle = "white";
                            ctx.fillRect(0, 0, canvas.width, canvas.height);
                        } else {
                            ctx.fillStyle = "white";
                            ctx.fillRect(0, 0, canvas.width, canvas.height);
                        }
                    }
                };
            }
        };
    };
    PolstCreateDialogCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', '$window', '$mdDialog', '$sce', 'helper', 'polst', 'user'];
    return PolstCreateDialogCtrl;
});
