define('dashboard/edit-patient.controller',['angular'], function (angular) {
    'use strict';
    var EditPatientCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                    userService, $window, $mdDialog, $sce, helper, form) {
        $scope.patient = form;
        $scope.editPatient = function (valid, ev) {
            $scope.addSubmitted = true;
            if (valid) {
                $scope.myPromise = userService.updatePatient($scope.patient).then(function(r){
                    $mdDialog.hide(true);
                });
            }
        };
        $scope.cancel = function () {
            $mdDialog.hide();
        };
    };
    EditPatientCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', '$window', '$mdDialog', '$sce', 'helper', 'form'];
    return EditPatientCtrl;
});
