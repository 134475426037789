define('analytics/form-submission.controller',['angular'], function (angular) {
    'use strict';
    var FormSubmissionCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document, userService, dataEntryService, $window, $mdDialog, $sce, helper, analyticsService, user, Papa) {
        $scope.user = user.data.User;
        // $scope.isHosAPI = $scope.user.org.type === 'Hospital API';

        var tmp = moment().subtract(30, 'days');
        $scope.startDate = tmp.toDate();
        $scope.endDate = moment().toDate();
        $scope.allDates = false;
        $scope.reportReady = false;
        $scope.clearReport = function () {
            $scope.reportReady = false;
            $scope.runningReport = false;
            $scope.exportReady = false;
        };
        var orgid = $scope.user.org.id;
        if ($localStorage.pref_org_id !== undefined) {
            orgid = $localStorage.pref_org_id;
        }
        userService.getOrg($scope.user.sessionID, orgid).then(function (r) {
            $scope.org = r.data;
        });
        $scope.runningReport = false;
        $scope.runReport = function () {
            $scope.runningReport = true;
            analyticsService.formSubmissionsByDate($scope.user.sessionID, $scope.allDates, $scope.startDate, $scope.endDate).then(function (r) {
                $scope.ctrs = r;
                $scope.runningReport = false;
                $scope.reportReady = true;
            });


        };
    };
    FormSubmissionCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'analyticsService', 'user', 'Papa'];
    return FormSubmissionCtrl;
});

