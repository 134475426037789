define('dashboard/view-admins.controller',['angular'], function (angular) {
    'use strict';
    var ViewAdminsCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document,
                                   userService, dataEntryService, $window, $mdDialog, $sce, helper) {
        userService.getOrgAdmins().then(function (response) {
            $scope.admins = response.data;
        });
    };
    ViewAdminsCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper'];
    return ViewAdminsCtrl;
});

