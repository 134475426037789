define('acp-billing/acp-guide.controller',['angular'], function (angular) {
    'use strict';
    var ACPGuideCtrl = function ($scope, $location, $routeParams, $filter, $localStorage, $mdToast, $document, userService, dataEntryService, $window, $mdDialog, $sce, helper, analyticsService, user, Papa) {
        $scope.faqs = function () {
            $location.path('/acp-faqs');
        };
        $scope.guide = function () {
            $location.path('/acp-guide');
        };
        $scope.newNote = function () {
            $location.path('/acp-note');
        };
        $scope.dashboard = function(){
            $location.path('/acp-billing');
        };
    };
    ACPGuideCtrl.$inject = ['$scope', '$location', '$routeParams', '$filter', '$localStorage', '$mdToast', '$document', 'userService', 'dataEntryService', '$window', '$mdDialog', '$sce', 'helper', 'analyticsService', 'user', 'Papa'];
    return ACPGuideCtrl;
});

