define('dashboard/signable.controller',['angular'], function (angular) {
    'use strict';
    var SignableCtrl = function ($scope, userService, $location, $routeParams, $localStorage, helper, $filter, user, $mdDialog) {
        $scope.user = user.data.User;
        $scope.formTemplate = "dashboard/POLST.html";
        $scope.formTable = {
            order: 'created',
            page: 1,
            limit: 5
        };
        $scope.physician = {};
        $scope.polst = {};
        $scope.physicianSignable = true;
        $scope.signPolstForm = function(){
            $mdDialog.show({
                controller: 'PolstCreateDialogCtrl',
                templateUrl: 'dashboard/polst-create-dialog.html',
                locals: {polst: $scope.polst, user: $scope.user},
                parent: document.body,
                escapeToClose: false,
                clickOutsideToClose: false,
                fullscreen: true
            }).then(function (response) {
                console.log(response);
                var confirm = $mdDialog.confirm().title("Form created").textContent("This POLST form has been successfully saved.").ok("View New Form").cancel('Got it!');
                $mdDialog.show(confirm)
                    .then(function (response2) {
                        setTimeout(function () {
                            helper.scrollContent("createPolst");
                        }, 100);
                        userService.numToSign().then(function (resposne) {
                            $scope.numToSign = resposne.data.num;
                        }, function () {

                        });
                        $location.search({p: 1, f: response.data.id});
                    }, function (err) {
                        $location.search({p: 0});
                        setTimeout(function () {
                            helper.scrollContent("viewSignable");
                        }, 100);
                        if ($scope.user.smartSession !== undefined) {
                            $scope.loadedSmartContext = false;
                        }
                        setTimeout(function () {
                            reinit();
                        }, 10);
                    });

            }, function(err){

            });
        };
        var getForm = function () {
            $scope.hideCreate = true;
            $scope.patientMatched = true;
            $scope.busyMsg = 'Loading Form ...';
            $scope.myPromise = userService.getForm($scope.fid).then(function (response) {
                $scope.loaded = true;
                $scope.polst = response.data;
                var tmp = moment.utc($scope.polst.created, 'YYYY-MM-DD HH:mm:ss');
                $scope.polst.dateCreated = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
                if ($scope.polst.physSigDate !== undefined) {
                    tmp = moment.utc($scope.polst.physSigDate, 'YYYY-MM-DD');
                    $scope.polst.datePhysSigned = tmp.format('MM/DD/YYYY');
                }
                if ($scope.polst.sigURL) {
                    $scope.savedSig = true;
                }
                if ($scope.polst.physSigURL) {
                    $scope.savedSig2 = true;
                }
            });
        };
        $scope.resetEmpty2 = function () {
            $scope.isEmpty2 = false;
        };
        $scope.clearSig2 = function () {
            $scope.isEmpty2 = false;
            $scope.savedSig2 = false;
            $scope.polst.physSigURL = undefined;
        };
        $scope.physicianSearch = function () {
            return [];
        };
        $scope.sign2 = function (sig2) {
            if (sig2.isEmpty) {
                $scope.isEmpty2 = true;
            } else {
                $scope.isEmpty2 = false;
                $scope.savedSig2 = true;
                $scope.polst.physSigURL = sig2.dataUrl;
                if (!$scope.polst.signed && $scope.hideCreate) {
                    console.log($scope.polst.physSigURL);
                    if ($scope.polst.physSigURL !== undefined && $scope.polst.physSigURL.length > 0) {
                        var confirm = $mdDialog.confirm().title("Execute this form?").textContent("Signing will finalize this form.").ok("Sign").cancel('Cancel');
                        $mdDialog.show(confirm)
                            .then(function () {
                                $scope.busyMsg = 'Saving Form ...';
                                $scope.myPromise = userService.signForm({
                                    formid: $scope.polst.id,
                                    physSigURL: $scope.polst.physSigURL
                                }).then(function () {
                                    $location.search({p: 0});
                                });
                            }, function () {
                                $scope.clearSig2();
                            });
                    } else {
                        $mdDialog.show($mdDialog.alert()
                            .clickOutsideToClose(true)
                            .title('Information missing')
                            .textContent('Please save your signature and fill out the name, phone number, and license number information above.')
                            .ok('Got it!'));
                    }
                }
            }
        };
        $scope.formsVisible = [];
        $scope.pdfs = [];
        $scope.sig = {};
        $scope.form = {};
        $scope.onPaginate = function (page, limit) {
            $scope.formsVisible = [];
            for (var i = (page - 1) * 5; i < $scope.forms.length && i < page * 5; i++) {
                var f = $scope.forms[i];
                if (f.pdfInfo === undefined) {
                    $scope.generatePDF(f);
                }
                $scope.formsVisible.push(f);
            }
        };
        $scope.generatePDF = function (f) {
            f.pdfInfo = {};
            userService.downloadFormById(f.id).then(function (response) {
                var file = new Blob([response.data], {type: "application/pdf"});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    f.pdfInfo.msFile = file;
                    // window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                    f.pdfInfo.showDownload = true;
                } else {
                    f.pdfInfo.showDownload = true;
                    f.pdfInfo.fileURL = URL.createObjectURL(file);
                }
            });

        };
        $scope.showForm = function (f) {
            $location.search({p: 1, f: f.id});
        };
        $scope.viewPDF = function (f) {
            var i;
            if (f === undefined) {
                for (i = 0; i < $scope.forms.length; i++) {
                    if ($scope.forms[i].id === $scope.fid) {
                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                            window.navigator.msSaveOrOpenBlob($scope.forms[i].pdfInfo.msFile, 'polst.pdf');
                        } else {
                            window.open($scope.forms[i].pdfInfo.fileURL, "_blank");
                        }
                    }
                }

            } else {
                for (i = 0; i < $scope.forms.length; i++) {
                    if ($scope.forms[i].id === f.id) {
                        window.open($scope.forms[i].pdfInfo.fileURL, "_blank");
                    }
                }
            }
        };
        $scope.onReorder = function (order) {
            if (order === '-created') {
                $scope.forms.sort(function (a, b) {
                    if (a.created === undefined && b.created === undefined) {
                        return 0;
                    } else if (a.created === undefined) {
                        return -1;
                    } else if (b.created === undefined) {
                        return 1;
                    }
                    var a1 = moment.utc(a.created, 'YYYY-MM-DD HH:mm:ss');
                    var b1 = moment.utc(b.created, 'YYYY-MM-DD HH:mm:ss');
                    if (a1.isBefore(b1)) {
                        return -1;
                    } else if (a1.isAfter(b1)) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
            } else if (order === 'created') {
                $scope.forms.sort(function (a, b) {
                    if (a.created === undefined && b.created === undefined) {
                        return 0;
                    } else if (a.created === undefined) {
                        return 1;
                    } else if (b.created === undefined) {
                        return -1;
                    }
                    var a2 = moment.utc(a.created, 'YYYY-MM-DD HH:mm:ss');
                    var b2 = moment.utc(b.created, 'YYYY-MM-DD HH:mm:ss');
                    if (a2.isBefore(b2)) {
                        return 1;
                    } else if (a2.isAfter(b2)) {
                        return -1;
                    } else {
                        return 0;
                    }

                });
            } else if (order === 'fname') {
                $scope.forms.sort(function (a, b) {
                    return a.fname > b.fname ? -1 : b.fname > a.fname ? 1 : 0;
                });
            } else if (order === '-id') {
                forms.sort(function (a, b) {
                    if(a.id === undefined && b.id === undefined){
                        return 0;
                    } else if(a.id === undefined){
                        return 1;
                    } else if(b.id === undefined){
                        return -1;
                    }
                    return -1 * (a.id > b.id ? -1 : b.id > a.id ? 1 : 0);
                });
            } else if (order === 'id') {
                forms.sort(function (a, b) {
                    if(a.id === undefined && b.id === undefined){
                        return 0;
                    } else if(a.id === undefined){
                        return 1;
                    } else if(b.id === undefined){
                        return -1;
                    }
                    return a.id > b.id ? -1 : b.id > a.id ? 1 : 0;
                });
            } else if (order === '-fname') {
                $scope.forms.sort(function (a, b) {
                    return -1 * (a.fname > b.fname ? -1 : b.fname > a.fname ? 1 : 0);
                });
            } else if (order === 'lname') {
                $scope.forms.sort(function (a, b) {
                    return a.lname > b.lname ? -1 : b.lname > a.lname ? 1 : 0;
                });
            } else if (order === '-lname') {
                $scope.forms.sort(function (a, b) {
                    return -1 * (a.lname > b.lname ? -1 : b.lname > a.lname ? 1 : 0);
                });
            }
            $scope.onPaginate($scope.formTable.page, $scope.formTable.limit);
        };
        var getForms = function () {
            userService.numToSign().then(function (resposne) {
                $scope.numToSign = resposne.data.num;
            }, function () {

            });
            userService.getFormsToSign().then(function (response) {
                $scope.forms = response.data;
                for (var i = 0; i < $scope.forms.length; i++) {
                    var tmp = moment.utc($scope.forms[i].created, 'YYYY-MM-DD HH:mm:ss');
                    $scope.forms[i].dateCreated = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
                    tmp = moment.utc($scope.forms[i].updated, 'YYYY-MM-DD HH:mm:ss');
                    $scope.forms[i].dateUpdated = tmp.tz('America/Los_Angeles').format('hh:mm a MM/DD/YYYY z');
                }
                $scope.onReorder("created");
                $scope.onPaginate($scope.formTable.page, $scope.formTable.limit);
            });
        };
        getForms();
        $scope.genders = [{
            id: 1,
            name: "MALE"
        }, {
            id: 2,
            name: "FEMALE"
        }, {
            id: 3,
            name: 'UNDIFFERENTIATED'
        }];
        var updateLabels = function () {
            helper.updatePolstLabels($scope, $filter, aoChange);
        };
        updateLabels();
        $scope.$watch(function () {
            return $localStorage.lang;
        }, function () {
            updateLabels();
        });
        $scope.swapTabs = function (idx) {
            $scope.tabIndex = idx;
            if (idx === 1) {
                $location.search('p', idx);
            } else {

                $location.search({p: idx});
            }
        };
        $scope.fid = undefined;
        var reset = function () {
            $scope.patientMatched = false;
            $scope.ptSearchDone = false;
            $scope.savedSig2 = false;
            $scope.polst = {
                secD2: 2,
                gender: '',
                sigRelationship: 'Self',
                // addOrders: $filter('translate')('additional_orders') + ":\n\n",
                // foodAddOrders: $filter('translate')('additional_orders') + ":\n\n",
                notCompByPhys: $scope.user.userType === "Physician, PA, NP"
            };
            if ($scope.sig.clear !== undefined) {
                $scope.sig.clear();
            }
            $scope.fid = undefined;
            $scope.formSubmitted = false;
            $scope.isEmpty = false;
            $scope.savedSig = false;
            $scope.searchErr = false;
            $scope.numResults = false;
            $scope.oneResult = false;
            $scope.search = {};
            $scope.hideCreate = false;
            $scope.searchForm = undefined;
            if ($scope.form.pform !== undefined) {
                $scope.form.pform.$setPristine();
                $scope.form.pform.$setUntouched();
            }
        };
        var reinit = function () {
            reset();
            $scope.p = Number($routeParams.p);
            if ($scope.p === undefined || isNaN($scope.p)) {
                $scope.p = 0;
            }
            if ($location.search().f !== undefined) {
                $scope.fid = Number($routeParams.f);
                getForm();
            }
            $scope.tabIndex = $scope.p;
            if ($scope.p === 0) {
                getForms();
            }
            if ($scope.fid === undefined && $scope.p === 1) {
                $location.search({p: 0});
            }

        };
        $scope.$on('$routeChangeSuccess', function () {

            reinit();
        });
        $scope.$on('$locationChangeSuccess', function () {
            reinit();
        });
        // POLST:
        var aoChange = false;
        $scope.taChanged = function () {
            aoChange = true;
        };
        $scope.polst = {
            secD2: 2,
            addOrders: $filter('translate')('additional_orders') + ":\n\n",
            foodAddOrders: $filter('translate')('additional_orders') + ":\n\n"
        };
        $scope.viewPDF = function () {
            if ($scope.fileUrl !== undefined) {
                window.open($scope.fileURL, "_blank");
            } else {
                userService.downloadForm($scope.polst.patientID).then(function (response) {
                    var file = new Blob([response.data], {type: "application/pdf"});
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        $scope.msFile = file;
                        $scope.fileURL = 'tmp.pdf';
                        // window.navigator.msSaveOrOpenBlob(file, 'polst.pdf');
                        $scope.showDownload = true;
                        window.open('/#/view-pdf?id=' + $scope.polst.id + '&sid=' + $scope.user.sessionID, 'POLST', 'width=1000,resizable=1');
                    } else {
                        $scope.showDownload = true;
                        $scope.fileURL = URL.createObjectURL(file);
                        window.open($scope.fileURL, "_blank");
                    }
                });
            }
        };
    };
    SignableCtrl.$inject = ['$scope', 'userService', '$location', '$routeParams', '$localStorage', 'helper', '$filter', 'user', '$mdDialog'];
    return SignableCtrl;
});

