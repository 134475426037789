define('app',['angular', 'raven', 'raven-angular', 'papaparse', 'home/home.module', 'user/user.module', 'dashboard/dashboard.module', 'data-entry/data-entry.module', 'analytics/analytics.module', 'super-user/super-user.module', 'acp-billing/acp-billing.module', 'ice/ice.module'], function (angular, Raven, ngRaven, Papa) {
    'use strict';
    window.Papa = Papa;
    // We must already declare most dependencies here (except for common), or the submodules' routes
    // will not be resolved
    Raven
        .config(!window.isProd ? 'https://8856da1ac6fd46d9be2ee59e9e0615b2@sentry.io/232945'
            : "https://40d575ceaaff4" +
            "7379a87465e5e8fe7d7@sentry.io/232946", {
            release: '0.1',
            environment: window.isProd ? 'prod' : window.isLinode ? 'test' : 'dev'
        })
        .addPlugin(ngRaven, angular)
        .install();
    var mod = angular.module('app', ['com.cd.home', 'com.cd.user', 'com.cd.dashboard', 'com.cd.dataEntry', 'com.cd.analytics', 'com.cd.acp', 'com.cd.ice', 'com.cd.superUser']).config(['$compileProvider', '$mdThemingProvider', function ($compileProvider, $mdThemingProvider) {
        $mdThemingProvider.setNonce('Q2FyZURpcmVjdGl2ZXM=');
        $compileProvider.debugInfoEnabled(false);
    }]);
    return mod;
});
